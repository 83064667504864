import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResResult } from '../models';
import { environment } from '../../../environments/environment';

@Injectable()
export class OrganizationBannerService {
    constructor(
        private http: HttpClient
    ) { }

    async getAll() {
        const res = await this.http.get<ResResult>(`${environment.apiUrl}/organizationBanner/getAll`, {}).toPromise();
        return res;
    }

    async getByOrgIds(orgId, orgTypeId, batchId) {
        const params = new HttpParams()
            .set('orgId', orgId)
            .set('orgTypeId', orgTypeId)
            .set('batchId', batchId);
        const res = await this.http.get<ResResult>(`${environment.apiUrl}/organizationBanner/getByOrgIds`, { params: params }).toPromise();
        return res;
    }

    async getById() {
        const res = await this.http.get<ResResult>(`${environment.apiUrl}/organizationBanner/getById`, {}).toPromise();
        return res;
    }

    async getByBannerId(bannerId) {
        const res = await this.http.get<ResResult>(`${environment.apiUrl}/organizationBanner/getByBannerId/${bannerId}`, {}).toPromise();
        return res;
    }

    // default should upload header image
    createBanner(formData) {
        return this.http.post(`${environment.apiUrl}/organizationBanner/createBanner`, formData);
    }

    updateBanner(formData) {
        return this.http.post(`${environment.apiUrl}/organizationBanner/updateBanner`, formData);
    }

    createPitchBanner(formData) {
        return this.http.post(`${environment.apiUrl}/organizationBanner/createPitchBanner`, formData);
    }

    deleteBanner(id, uid) {
        return this.http.post(`${environment.apiUrl}/organizationBanner/delete`, { id, uid });
    }
}
