import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {
    AppSettingsService,
    OrganizationService
} from '@common/services';

@Component({
    selector: 'profile-about',
    templateUrl: './profile-about.html',
    styleUrls: ['profile-about.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProfileAboutComponent implements OnInit {
    @Output() open = new EventEmitter();

    @Input() description;
    @Input() email;
    @Input() website;
    @Input() facebook;
    @Input() linkedin;
    @Input() twitter;
    @Input() instagram;
    @Input() country;
    @Input() industry;

    @Input() skills;
    @Input() companySize;

    countriesOptions: any;
    displayCountry = '';
    displayCompanySize = '';


    constructor(
        private appSettingsSrv: AppSettingsService,
        private orgSrv: OrganizationService
    ) {

        this.countriesOptions = this.appSettingsSrv.countryOptions();
        if (this.country) {
            this.onGetCountryName(this.country);
        }
    }

    ngOnInit(): void {

        if (this.companySize) {
            this.displayCompanySize = this.orgSrv.GetDisplayCompanySize(this.companySize, '');
        }
    }

    onGetCountryName(value) {
        if (this.country) {
            const found = this.countriesOptions.find(element => element.value == value);

            if (found) {
                this.country = found['text'];
            }
        }
    }

    onClick() {
        this.open.emit();
    }

}