import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentCardComponent implements OnInit {

  @Input() class: string;
  @Input() currentTeamStatus;
  @Input() selected;
  @Input() studentState;
  @Output() click = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick(event: any) {
    event.stopPropagation();
    this.click.emit();
  }

}
