import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, UtilityService } from '@common/services';

import base64 from 'crypto-js/enc-base64';
import utf8 from 'crypto-js/enc-utf8';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';

import demoConfig from './demo.config.json';

@Injectable({
    providedIn: 'root',
})
export class DemoInterceptor implements HttpInterceptor {

    secret = 'SHHHHHHHHH';

    constructor(private utilityService: UtilityService, private configService: ConfigService) {
       
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.configService.config || !this.configService.config.demoEnabled) {
            return next.handle(request);
        }

    

        return next.handle(request);
    }

    _savePosts() {
   
    }

    _createToken() {
        const stringifiedData: CryptoJS.lib.WordArray = utf8.parse(
            JSON.stringify({
                id: 'test123',
                firstName: 'Testy',
                lastName: 'McTestface',
                email: 'test@test.test',
            })
        );
        const encodedData = this._base64url(stringifiedData);
        const stringifiedHeader = utf8.parse(
            JSON.stringify({
                alglg: 'HS256',
                typ: 'JWT',
            })
        );
        const encodedHeader = this._base64url(stringifiedHeader);
        const signature = hmacSHA256(`${encodedHeader}.${encodedData}`, this.secret);

        return `${encodedHeader}.${encodedData}.${this._base64url(signature)}`;
    }

    _base64url(source: CryptoJS.lib.WordArray) {
        // Encode in classical base64
        let encodedSource = base64.stringify(source);

        // Remove padding equal characters
        encodedSource = encodedSource.replace(/=+$/, '');

        // Replace characters according to base64url specifications
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');

        return encodedSource;
    }
}
