export class User {
    uid: string;
    id: string;
    userId: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    email: string;
    firsttime: boolean;
    InfoId: string;
    IsAdmin: boolean;
    image: string;
    imageName = '';
    imageUrl = '';
    lastlogin: string;
    status: number; // for notifications. if 0, means admin has not dismissed. 1 if dismissed
    verified: boolean;
    deleted: boolean;
    roles: any;
    employeeStatus: number; // 0 = co-founder 1 = member
    CoFounderForm: any;
    form: any;
    dob: string;
    fullname: string;
    gender: string;
    nationality: string;
    linkedin = '';
    contactNo: string;
    country: string;
    cvId: string;
    email_password: string;
    forgotPwAt: number;
    personalvalue: any;
    website: string;
    markets: string;
    locations: string;
    skills: string;
    bio: string;
    twitter: string;
    medium: string;
    instagram: string;
    overview: string;
    facebook: string;
    title: string;
    isPublic: boolean;
}
