export class Comment {
    id: string;
    comment: string;
    discussionTopicId: string;
    username: string;
    orgName: string;
    createdBy: string;
    createdByUid: string;
    createdAt: string;
    updatedBy: string;
    updatedByUid: string;
    updatedAt: string;
    createdByOrgName: string;
    deleted = false;
    status = 0;
}
