import { Component, HostListener, Input, OnInit, ElementRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lab-receipt-list',
  templateUrl: './receipt-list.component.html',
  styleUrls: ['./receipt-list.component.scss']
})
export class ReceiptListComponent implements OnInit {
  @Input() data;


  constructor(
 
  ) { }

  ngOnInit(): void {

  }

}
