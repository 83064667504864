import { Injectable } from '@angular/core';
import { FileUpload } from '../models/file';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UploadFileService {
    constructor(private http: HttpClient) { }


    uploadFile(file: File) {
        let formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/upload`, formData);
    }

    updatePhoto(file: File) {
        let formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/updateProfilePic`, formData);
    }

    uploadSingleFile(file: File, uid: string = "") {
        // uploadPicOnly
        let formData = new FormData();
        formData.append('file', file);
        formData.append('uid', uid);
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/uploadSingleFile`, formData);
    }

    uploadOrgLogo(file: File, orgId: string = "") {
        // uploadPicOnly
        let formData = new FormData();
        formData.append('file', file);
        formData.append('orgId', orgId);
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/uploadOrgLogo`, formData);
    }

    uploadImage(file: File) {
        // uploadPicOnly
        let formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/uploadPicOnly`, formData);
    }

    setIdNType(id: string, type: string) {
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/setIdNType`, {
            id: id, type: type
        });
    }

    getPhotoById(id: string, type: string) {
        return this.http.post(`${environment.apiUrl}/fileUpload/getFileByUid`, {
            id: id, type: type
        });
    }

    uploadFileClient(file: File) {

        let formData = new FormData();
        formData.append('file', file);
        return this.http.post(`${environment.assetAPIUrl}/fileUpload/uploadClient`, formData);
    }

    getUploadedFile(path) {
        return `${environment.assetAPIUrl}/` + path;
    }

    updateImageUrl(url, uid) {
        return this.http.post(`${environment.apiUrl}/fileUpload/updateUserImage`, {
            uid: uid,
            url: url
        });

    }

    // insert or update image data into file table.
    // type: user or org
    // if type is user, it must has uid.
    // if type is org, it must has orgId.
    updateOrInserImageData(filename,
        mimetype,
        size,
        path,
        uid,
        orgId,
        type) {
        // updateImageData
        return this.http.post(`${environment.apiUrl}/fileUpload/updateImageData`, {
            uid: uid,
            filename: filename,
            mimetype: mimetype,
            size: size,
            path: path,
            orgId: orgId,
            type: type,
        });
    }

    async getUploadedFileById(id) {
        return await this.http.get(`${environment.apiUrl}/fileUpload/getFileById/${id}`).toPromise();
    }
}
