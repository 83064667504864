export class PostNotification {
    id: string = '';
    postId: string = '';
    postByUid: string = '';
    title: string = '';
    content: string = '';
    type: number;
    createdAt: string = '';
    createdBy: string = '';
    updatedAt: string = '';
    updatedBy: string = '';
}
