import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    User
} from '@common/models';
import { OrganizationService, UserService } from '@common/services';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NotificationSettingsComponent implements OnInit {
    notificationForm: FormGroup;
    currentUser;
    isLoading: boolean = true;
    toastRef;
    existInstitutionRole:boolean = false;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private userService: UserService,
        private toastr: ToastrService,
        private orgSrv: OrganizationService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.notificationForm = this.fb.group({
            deliverable: [''],
            deliverableComments: [''],
            schedule: [''],
            tasks: [''],
            tasksComments: ['']
        })
    }

    ngOnInit() {
        this.orgSrv.getOrgByUid(this.currentUser.id).then(res => {
            if (res !== null) {
                res.forEach(org => {
                    if (org['organizationtype'] === 'institution') {
                        this.existInstitutionRole = true;
                    }
                })
            } 
        })

        this.userService.getUserDataByUid(this.currentUser.id).subscribe(res => {
            if (res['result'] === 'successful') {
                let data = res['data'];
             
                this.notificationForm.patchValue({
                    deliverable: data.deliverable,
                    deliverableComments: data.deliverableComments,
                    schedule: data.schedule,
                    tasks: data.tasks,
                    tasksComments: data.tasksComments
                  }); 

                  this.isLoading = false;
            }
        })  
    }

     // toggle switch
     handleChecked(event, data) {
        if (event === true) {
          this.notificationForm.value[data] = 1;
        } else if (event === false) {
          this.notificationForm.value[data] = 0;
        }
      }

      onSubmit() {
        const values = this.notificationForm.value;

        let data = {
            uid: this.currentUser.id,
            deliverable: values.deliverable,
            deliverableComments: values.deliverableComments,
            schedule: values.schedule,
            tasks: values.tasks,
            tasksComments: values.tasksComments
        }
        
        for (const property in data) {
            if (typeof data[property] === 'boolean') {
                if (data[property] === true) {
                    data[property] = 1;
                } else {
                    data[property] = 0
                }
            }
        }

        this.userService.updateNotificationSettings(data).subscribe( res => {
            if (res['result'] === 'successful') {
                this.showActionMsg(`Notification settings saved successfully`, 'Success');
            }
        })
      }

      showActionMsg(msg, title) {
        this.toastRef = this.toastr.show(msg, title, {
            disableTimeOut: false,
            tapToDismiss: false,
            toastClass: 'toast border-blue',
            closeButton: false,
        });

    }
    

}
