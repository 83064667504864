
import { PersonalValue } from './personalValue';
import { PersonalPersonality } from './personalPersonality';
import { PersonalStartup } from './personalStartup';

export class Individual {
    id: string;
    uid: string;
    fullname: string;
    dob: string;
    gender: string;
    email: string;
    contactNo: string;
    linkedin: string;
    nationality: string;
    country: string;
    cv: string;
    cvId: string;
    hasStartupForm = false;
    hasAdvisorForm = false;
    hasInvestorForm = false;
    hasServiceProviderForm = false;
    hasIndividualForm = true;
    hasCompanyProfile: boolean;
    personalvalue: PersonalValue;
    personalpersoVal: PersonalPersonality;
    personalStartupVal: PersonalStartup;
    createdIndividualAt: string;
    createdIndividualBy: string;
    updatedIndividualAt: string;
    updatedIndividualBy: string;
}
