import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lab-dialog-edit-batch',
  templateUrl: './dialog-edit-batch.component.html',
  styleUrls: ['./dialog-edit-batch.component.scss']
})
export class DialogEditBatchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
