import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  InstitutionService,
  UtilityService,
  ConfigService
} from '@common/services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-modal-deliverable-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        right: '0px'
      })),
      state('close', style({
        opacity: 0,
        right: '-50px'
      })),
      transition('open => close', [
        animate('.3s')
      ]),
      transition('close => open', [
        animate('.3s')
      ])
    ])
  ],
  templateUrl: './modal-deliverable-list.component.html',
  styleUrls: ['./modal-deliverable-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalDeliverableListComponent implements OnInit {
  @Input() open: boolean;
  @Input() currentUser: any;
  //type: projectTeam, mentor, programmer 
  // noted projectTeam cannot rating
  @Input() type: string;
  @Input() selectedOrgId: string;
  @Input() organizationType: string;

  loading: boolean = true;

  public _team: any;
  @Input() set team(object) {
    this.loading = true;

    this._team = object;
    if (this._team) {
      this.initDeliverable(this._team.batchId,
        this._team.id);
    } else {
      this.loading = false;
    }
  }
  public _program: any;
  @Input() set program(object) {
    this.loading = true;
    this._program = object;
    if (this._team) {
      this.initDeliverable(this._team.batchId,
        this._team.id);

    } else {
      this.loading = false;
    }
  }

  @Output() close = new EventEmitter();
  @Output() openComments = new EventEmitter();

  @ViewChild('filterList') filterList: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;

  public isOpenDialog: boolean = false;
  public isOpenDialogAttach: boolean = false;
  public isOpenDialogLink: boolean = false;
  public isOpenDialogComment: boolean = false;

  toastRef;
  count: number = 0;
  searchText: string = '';
  filterType: string = 'all';

  deliverables: any[] = [];
  presentations: any[] = [];
  comments: any[] = [];
  current: string = '';
  isShowPivot: boolean = false;

  projectTeamList: any[] = [];
  ratingTypeList: any[] = [];

  //submission
  programs: any;
  deliverable: any;
  selectedDeliverable: any;


  constructor(
    private toastr: ToastrService,
    private institutionSrv: InstitutionService,
    private utilitySrv: UtilityService,
    private cdf: ChangeDetectorRef,
    private configSrv: ConfigService
  ) { }

  ngOnInit() {
    // get ratingType
    this.institutionSrv.getInstitutionType().then(
      res => {
        if (res.result === 'successful') {
          if (res["ratingType"]) {
            this.ratingTypeList = res["ratingType"];
          }
        }
      }
    ).catch(error => {
      console.log('getInstitutionType Error: ', error)
    });;
  }


  initDeliverable(batchId, projectTeamId) {
    this.institutionSrv.getProjectProgressData(batchId,
      projectTeamId).then((res) => {

        if (res['result'] == 'successful') {
          this.programs = res['programs'];
          if (this._program) {
            this.programs = this.programs.filter(program => program.name === this._program.name);
          }
          this.deliverables = res['deliverables'];
          this.programs.map(program => {
            program['deliverables'] = this.deliverables[program.name];
          });
          this.comments = res['commentData'];
        }

        this.cdf.detectChanges();

      }).catch(error => {
        console.error("initDeliverable", error);

        this.cdf.detectChanges();
      }).finally(() => {
        this.loading = false;
      })
  }

  // close modal batch list
  onCloseModal() {
    this.searchText = "";
    if (this.isOpenDialog) this.onToggleDialogWarning();
    if (this.isOpenDialogAttach) this.onToggleDialogAttach();
    this.close.emit();
  }

  // Attention
  onToggleSubmitDialog(event, deliverable) {
    this.selectedDeliverable = deliverable;
    this.deliverable = event;

    switch (event.deliverableTypeKey) {
      case "link":
        this.isOpenDialogLink = !this.isOpenDialogLink;
        break;
      case "comment":
        this.isOpenDialogComment = !this.isOpenDialogComment;
        break;
      case "checkbox":
        this.isOpenDialog = !this.isOpenDialog;
        break;
      case "file":
        this.isOpenDialogAttach = !this.isOpenDialogAttach;
        break;
      case "textbox":
        this.isOpenDialogComment = !this.isOpenDialogComment;
        break;
      //textbox
    }
  }

  // Attention
  onToggleDialogWarning() {
    this.isOpenDialog = !this.isOpenDialog;
  }

  // Attach
  onToggleDialogAttach() {
    this.isOpenDialogAttach = !this.isOpenDialogAttach;
  }

  // Link
  onToggleDialogLink() {
    this.isOpenDialogLink = !this.isOpenDialogLink;
  }

  // Comment
  onToggleDialogComment() {
    this.isOpenDialogComment = !this.isOpenDialogComment;
  }

  onToggleComments(lecture: string) {
    this.openComments.emit(lecture);

  }

  // lecture update
  onLectureSubmit(checked: boolean, deliverable: any) {
    this.count += 1;
    /*  this.toastRef = this.toastr.show(this.count === 1 ? `Updated ${this.count} time` : `Updated ${this.count} times`, 'Lecture', {
       disableTimeOut: false,
       tapToDismiss: true,
       toastClass: 'toast border-green',
       closeButton: false,
     });
     console.log('send lecture action'); */
    const rating = {
      organizationId: this._team.id,
      submission: true,
      deliverableId: deliverable.id,
      userId: this.currentUser.id,
      batchId: this._team.batchId
    }

    this.institutionSrv.updateRating(rating).subscribe(
      response => {

        if (response['result'] == 'successful') {

          this.showActionMsg("Successfully");
          let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
          let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
          _deliverable.submission = true;
        } else {
          this.showActionMsg("Failed");
        }
      },
      error => {
        this.showActionMsg("Failed");
        console.error('updateRating', error)
      }
    );
  }

  // attach upload
  onAttachSubmit(event: any, deliverable: any) {
    if (event) {
      let formData = new FormData();
      formData.append('file', event);
      formData.append('organizationId', this._team.id);
      formData.append('submission', event.name);
      formData.append('ratingTypeId', deliverable.ratingTypeId);
      formData.append('deliverableId', deliverable.id);
      formData.append('userId', this.currentUser.id);
      formData.append('batchId', this._team.batchId);
      let _file = event;
      this.institutionSrv.updateFileRating(formData).subscribe(
        response => {
          if (response['result'] == 'successful') {
            this.showActionMsg("Successfully");
            let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
            let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
            _deliverable.submission = _file.name;

          } else {
            this.showActionMsg("Failed");
          }
        },
        error => {
          this.showActionMsg("Failed");
          console.error("fileUpload failed", error);
        }
      );

    }
    /* this.toastRef = this.toastr.show('Uploaded successful', 'Attach', {
      disableTimeOut: false,
      tapToDismiss: true,
      toastClass: 'toast border-purple',
      closeButton: false,
    });
    console.log('send attach action'); */
  }

  // link update
  onLinkSubmit(url: string, deliverable: any) {
    const rating = {
      organizationId: this._team.id,
      submission: url ? url : null,
      deliverableId: deliverable.id,
      userId: this.currentUser.id,
      batchId: this._team.batchId
    }

    let _url = url;
    this.institutionSrv.updateRating(rating).subscribe(

      response => {

        if (response['result'] == 'successful') {

          this.showActionMsg("Successfully");
          let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
          let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
          _deliverable.submission = _url;
        } else {
          this.showActionMsg("Failed");
        }
      },
      error => {
        this.showActionMsg("Failed");
        console.error('updateRating', error)
      }
    );

  }

  showActionMsg(msg) {
    this.toastRef = this.toastr.show('Successfully updated', 'Link', {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-blue',
      closeButton: false,
    });

  }

  onCommentSubmit(msg: string) {
    this.toastRef = this.toastr.show(msg, 'Comment', {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-green',
      closeButton: false,
    });
  }

  onFilterSelect(event: any) {
    const { childNodes } = this.filterList.nativeElement
    const list = [...childNodes]
    list.forEach(item => {
      if (item.dataset.option === event.target.dataset.option && item.classList.contains('selected')) return;
      item.classList.remove('selected');
      if (item.dataset.option === event.target.dataset.option) return item.classList.add('selected');
    })
    // Store filter type for search using
    if (this.filterType !== event.target.dataset.option) this.filterType = event.target.dataset.option;
  }

  onSearchChange(event: any) {
    this.searchText = event.target.value;
  }

  onKeydown(event: any) {
    const key = event.key || event.which
    // press 'Enter' to do research with filter option
    if (key === 13 || key === 'Enter') return console.log('search ===========', { keywords: this.searchText, type: this.filterType });
  }

  // for clear search event
  onClearSearchText() {
    this.searchText = '';
    this.searchInputRef.nativeElement.focus();
  }

  onBatchExpand(event: any) {
    const target = event.target;
    target.parentNode.classList.toggle('collapse');

    const isExpaned = target.parentNode.classList.contains('collapse')
    target.parentNode.setAttribute('aria-expanded', !isExpaned);
    target.nextSibling.setAttribute('aria-hidden', isExpaned);

  }

  onOpenComments(lecture: any) {
    let _comments = this.comments.filter(comment => { return comment.ratingId == lecture.ratingId })
    lecture['projectTeamId'] = this._team.id;
    this.openComments.emit({ lecture: lecture, comments: _comments });
  }

  deliverableTotal(deliverables) {
    if (!deliverables) {
      return 0;
    }
    else {
      let avaiableDels = deliverables.filter(del => del.deliverableTypeKey != 'na')
      return avaiableDels.length;
    }
  }

  onToggleShowPivot() {
    this.isShowPivot = !this.isShowPivot;
  }

  isSubmitted(value) {

    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    }
    return !this.utilitySrv.IsNullOrEmpty(value);
  }

  onCleanSubmission(event, programId) {

    let _ratingId = event;
    let _programId = programId;
    this.institutionSrv.deleteRating(event).subscribe(
      response => {
        if (response['result'] == 'successful') {

          this.showActionMsg("Successfully");
          this.programs.map(program => {
            if (program.id == _programId) {
              program.deliverables.map(deliverable => {
                if (deliverable.ratingId == _ratingId) {
                  deliverable.submission = "";
                  deliverable.fileName = "";
                }
              })
            }
          });

        } else {
          this.showActionMsg("Failed");
        }
        this.cdf.detectChanges();
      },
      error => {
        this.showActionMsg("Failed");
        console.error('updateRating', error)
      }
    );
  }

  onImgError(event) {
    event.target.src = this.configSrv.defaultUserLogo;
  }

  onSubmitRating(event, deliverable) {
    this.selectedDeliverable = deliverable;
    let _ratingType = this.ratingTypeList.filter(ratingType => ratingType.score == event);
    if (_ratingType.length > 0) {
      // organizationId must be selectedOrgId because mentor or programmer will rate selected organization
      const rating = {
        organizationId: this.selectedOrgId,
        deliverableId: deliverable.id,
        ratingTypeId: _ratingType[0].id,
        userId: this.currentUser.id,
        batchId: this._team.batchId
      }

      let _score = event;
      this.institutionSrv.updateRating(rating).subscribe(
        response => {
          if (response['result'] == 'successful') {
            this.showActionMsg("Successfully");
            let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
            let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
            _deliverable.score = _score;
          } else {
            this.showActionMsg("Failed");
          }
        },
        error => {
          this.showActionMsg("Failed");
          console.error('updateRating', error)
        }
      );
    } else {
      this.showActionMsg("Failed");
    }
  }
}
