export class FileUpload {
    description: string;
    key: string;
    file: File;
    name: string;
    url: string;
    progress: number;
    createdAt: Date = new Date();
    lastModified: number;
    lastModifiedDate: Date = new Date();
    type: string;
    constructor(file: File) {
        this.file = file;
    }
}
