export class TimelineOption {
    constructor(title, value: number, displayText, key) {
        this.title = title;
        this.value = value;
        this.key = key;
        this.displayText = displayText;
    }
    title: string = '';
    value: number;
    key: string = '';
    displayText: string = '';
}