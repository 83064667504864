import { Component, 
    EventEmitter, 
    Input, 
    OnInit, 
    Output, 
    ViewEncapsulation,
    ChangeDetectorRef,
    ViewContainerRef,
    ViewChild } from '@angular/core';
import {
    FormBuilder,
    Validators,
    FormGroup
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'lab-dialog-select-submission',
    templateUrl: './dialog-select-submission.component.html',
    styleUrls: ['./dialog-select-submission.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class DialogSelectSubmissionComponent implements OnInit {
    @Input() title;
    @Input() class?: string;
    @Input() show: boolean;
    _program: any;
    @Input() set program(object) {
        this.selectedPhase =[];
        this.selectedSubmission =[];
      
        if (object) {
            this._program = object;
            this._program.filter((program) => {
                program['checked'] = false;
                if (program.deliverable.length > 0) {
                    for (let i = 0; i < program.deliverable.length; i++) {
                        program.deliverable[i]['checked'] = false;
                        // if (program.deliverable[i].submissionTypeId !== 'ed24d224-941b-11eb-a8b3-0242ac130003') {
                        //     this.submissions.push(program.deliverable[i]);
                        // }
                    }
                }
            });
            // console.log(this._program);
        }
    }

    @Output() close = new EventEmitter();
    @Output() next = new EventEmitter();

    submissions: any[] = [];

    pivotForm: FormGroup;
    submitted: boolean = false;
    selectedPhase: any[] = [];
    selectedSubmission: any[] = [];

    @ViewChild("outlet", {read: ViewContainerRef}) outletRef: ViewContainerRef;

    constructor(
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        private cdf: ChangeDetectorRef,
    ) {
        this.pivotForm = this.formBuilder.group({
            allAgreeChecked: false,
            mentorsAgreeChecked: false,
            selfAgreeChecked: false,
            submitAgreeChecked: false,
            description: ['', Validators.required]
        });
    }

    ngOnInit() {
        this.submitted = false;
    }



    get f() { return this.pivotForm.controls; }

    onClose() {
        if (this._program.length > 0) {
            this._program.forEach(function (phase) {
                phase['checked'] = false;
                if ( phase.deliverable.length > 0) {
                    phase.deliverable.forEach(function (deliverable) {
                    deliverable['checked'] = false;
              })
              }
          })
        }

        this._program =[...this._program];
        
        this.selectedPhase =[];
        this.selectedSubmission =[];
        this.outletRef.clear();
        this.cdf.markForCheck();
        this.close.emit();
        this.submitted = false;
    

    }

    onSelect(type, phaseindex, phaseId, phaseTitle, submId, subTitle, deliverableIndex, event) {
    
        if (event.target.checked) { // if user check a checkbox
            if (type === 1) { // if user check a phase
                // let check = this.selectedPhase.includes(phaseId);
                let check = this.selectedPhase.some(e => e.id == phaseId);
                console.log(check);
                if (!check) {
                    this.selectedPhase.push({ id: phaseId, title: phaseTitle });
                    // this.selectedPhaseTitle.push(title);
                    this._program[phaseindex]['checked'] = true;
                    if (this._program[phaseindex].deliverable.length > 0) {
                        for (let i = 0; i < this._program[phaseindex].deliverable.length; i++) {
                            this.selectedSubmission.push({ phaseId: phaseId, phaseTitle: phaseTitle, subId: this._program[phaseindex].deliverable[i].id, subTitle: this._program[phaseindex].deliverable[i].title });
                            this._program[phaseindex].deliverable[i]['checked'] = true;
                        }
                    }
                }
            } else if (type === 2) { // if user check a submission
                let checkPhase = this.selectedPhase.some(e => e.id == phaseId);
                if (!checkPhase) {
                    // this._program[phaseindex]['checked'] = true;
                    this.selectedPhase.push({ id: phaseId, title: phaseTitle });
                }
                let check = this.selectedSubmission.some(e => e.subId == submId);
                if (!check) {
                    this._program[phaseindex].deliverable[deliverableIndex]['checked'] = true;
                    this.selectedSubmission.push({ phaseId: phaseId, phaseTitle: phaseTitle, subId: submId, subTitle: subTitle });
                    console.log(this.selectedSubmission);
                    // this._program[phaseindex].deliverable[subindex]['checked'] = true;
                }
            }
        } else if (!event.target.checked) { // if user uncheck a checkbox
            console.log('uncheck');
            if (type === 1) {
                let check = this.selectedPhase.some(e => e.id == phaseId);
                if (check) {
                    let index = this.selectedPhase.findIndex(({ id }) => id === phaseId);
                    this.selectedPhase.splice(index, 1);
                    this.selectedSubmission = this.selectedSubmission.filter(item => item.phaseId !== phaseId);
                    this._program[phaseindex]['checked'] = false;
                    if (this._program[phaseindex].deliverable.length > 0) {
                        for (let i = 0; i < this._program[phaseindex].deliverable.length; i++) {
                            this._program[phaseindex].deliverable[i]['checked'] = false;
                        }
                    }
                }
            } else if (type === 2) {
                let check = this.selectedSubmission.some(e => e.subId == submId);
                if (check) {
                    this._program[phaseindex].deliverable[deliverableIndex]['checked'] = false;
                    this.selectedSubmission = this.selectedSubmission.filter(item => item.subId !== submId);
                    console.log('uncheck submission', this.selectedSubmission);
                }
            }
        }
    }

    onSubmit() {
        this.submitted = true;
        if (this.selectedPhase.length === 0 && this.selectedSubmission.length === 0) {
            this.toastr.show('Please select the phase or submission you wish to pivot!', '', {
                disableTimeOut: false,
                tapToDismiss: true,
                toastClass: 'toast border-red',
                closeButton: false,
            });
            return
        }

        this.submitted = false;

        if (this._program.length > 0) {
            this._program.forEach(function (phase) {
                phase['checked'] = false;
                if ( phase.deliverable.length > 0) {
                    phase.deliverable.forEach(function (deliverable) {
                    deliverable['checked'] = false;
              
              })
              }
          })
        }

     
        this.next.emit({ selectedPhase: this.selectedPhase, selectedSubmission: this.selectedSubmission });
        // this.restartForm.reset();
        this.selectedPhase =[];
        this.selectedSubmission =[];
        
        this.cdf.detectChanges();
    }




}
