import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-dialog-abort',
  templateUrl: './dialog-abort.component.html',
  styleUrls: ['./dialog-abort.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogAbortComponent implements OnInit {
  @Input() title;
  @Input() class?: string;
  @Input() show: boolean;
  @Input() allowCancel: boolean;
  @Input() role;

  @Output() close = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() onDropout = new EventEmitter();

  public Editor = ClassicEditor;
  dropoutForm: FormGroup;
  submitted: boolean = false;

  qns1 = '';
  qns2 = '';

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {

    this.dropoutForm = this.formBuilder.group({
      allAgreeChecked: false,
      mentorsAgreeChecked: false,
      submitAgreeChecked: false,
      description: ['', Validators.required]
    });

    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'link',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo'
        ]
      },
      language: 'en'
    };

  }

  ngOnInit() {
    this.submitted = false;
    if (this.role === 'project') {
      this.qns1 = 'All members of your team have agreed to abort this project';
      this.qns2 = 'Consultants have been consulted and agreed the best way forward is to abort the project';
    } else if (this.role === 'professionalservice') {
      this.qns1 = 'As a Consultant designated to this Project Team, you have agreed to abort this project';
      this.qns2 = 'All Project Team members and Program Managers will be notified, and will need to agree to abort this project';
    } else if (this.role === 'batch') {
      this.qns1 = 'All Project Team members and Consultants will be notified and they will need to agree to abort this project';
      this.qns2 = 'Consultants have been consulted and agreed the best way forward is to abort the project';
    }
  }

  get f() { return this.dropoutForm.controls; }

  onClose() {
    this.close.emit();
    this.submitted = false;
  }

  onReject() {
    this.submitted = true;
    if (this.dropoutForm.invalid) {
      return
    }
    this.reject.emit(this.dropoutForm.value);
  }

  onSubmit() {
    // console.log('restart => get values =========', this.dropoutForm.value);
    this.submitted = true;
    if (this.dropoutForm.invalid
      || !this.dropoutForm.value.allAgreeChecked
      || !this.dropoutForm.value.mentorsAgreeChecked
      || !this.dropoutForm.value.submitAgreeChecked
    ) {
      this.toastr.show('Please check all checkbox and fill in the reason for restart!', '', {
        disableTimeOut: false,
        tapToDismiss: true,
        toastClass: 'toast border-red',
        closeButton: false,
      });
      return
    }
    // console.log('dropout');
    this.submitted = false;
    this.onDropout.emit(this.dropoutForm.value);
    this.dropoutForm.reset();
  }

}
