import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../../environments/environment";

@Injectable()
export class InvitationCodeService {
    constructor(private http: HttpClient) { }

    getCode(email, type) {
        return this.http.get(`${environment.apiUrl}/invitationCode/code/${email}/${type}`);
    }

    getAllCodes() {
        return this.http.get(`${environment.apiUrl}/invitationCode/getAllCodes`);
    }

    getCodeByOrg(organizationId, batchId) {
        const params = new HttpParams()
            .set('orgId', organizationId)
            .set('batchId', batchId);
        return this.http.get(`${environment.apiUrl}/invitationCode/getCodeByOrg`,
            { params: params });
    }

    generateCode(type, orgId, orgBatchId) {

        return this.http.post(`${environment.apiUrl}/invitationCode/generateCode`,
            {
                type, orgId, orgBatchId
            })
    }

    deleletCode(id) {
        return this.http.post(`${environment.apiUrl}/invitationCode/deleteCode`,
            {
                id
            })
    }

    updateCode(id, email, userId) {
        return this.http.post(`${environment.apiUrl}/invitationCode/updateCode`,
            {
                id,
                email,
                userId
            })
    }

    async checkCode(email, code) {
        return await this.http.post(`${environment.apiUrl}/invitationCode/checkCode`,
            {
                email,
                code
            }).toPromise();
    }
    async IsExist(code) {
        return await this.http.get(`${environment.apiUrl}/invitationCode/isExist/${code}`).toPromise();
    }

    async getDataByCode(code) {
        return await this.http.post(`${environment.apiUrl}/invitationCode/getDataByCode`, {
            code: code
        }).toPromise();
    }

    async getDataByUid(uid) {
        return await this.http.post(`${environment.apiUrl}/invitationCode/getDataByUid`, {
            uid: uid
        }).toPromise();
    }

    async addInvRecord(code, uid) {
        return await this.http.post(`${environment.apiUrl}/invitationCode/addInvRecord`, {
            code: code, uid: uid
        }).toPromise();
    }

    async updateRecord(id) {
        return await this.http.post(`${environment.apiUrl}/invitationCode/updateRecord`, {
            id: id
        }).toPromise();
    }

    getInvitType(value) {
        let type = "Aspiring";
        switch (value) {

            case "2":
                type = "Founder";
                break;
            case "3":
                type = "Consultant";
                break;
            case "4":
                type = "Program Manager";
                break;
            case "4":
                type = "Program Manager";
                break;
        }
        return type

    }


}
