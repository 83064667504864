import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Individual,
  PersonalValue,
  PersonalPersonality,
  PersonalQuizzes,
  ResResult
} from '../models';
import { UtilityService } from './utility.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class IndividualService {
  items: Observable<any[]>;

  constructor(
    private http: HttpClient,
    private utility: UtilityService
  ) { }

  async getPersonalValueByUid(uid: string) {
    const params = new HttpParams().set('uid', uid);
    const _resResult = await this.http
      .get<ResResult>(
        `${environment.apiUrl}/individual/getPersonalValueByUid`,
        { params: params }
      )
      .toPromise();
    if ((_resResult.result == 'successful')) {
      return _resResult.data as PersonalValue;
    } else {
      return null;
    }
  }

  // for individual form page
  createIndividual(individual: Individual, uid: string) {
    return this.http.post(
      `${environment.apiUrl}/individual/create`,
      individual
    );
  }

  getDataByIdDB(id: string) {
    return this.http.post(`${environment.apiUrl}/user/getUserDataByUid`, {
      uid: id,
    });
  }



  // for individual form page
  getDataByIdIndividual(id: string) {
    return this.http.post(`${environment.apiUrl}/individual/getDataByUserId`, {
      uid: id,
    });
  }

  // for individual form page
  isExist(uid: string) {
    return this.http.post(`${environment.apiUrl}/individual/isExist`, {
      uid: uid,
    });
  }

  AddPersonalValue(personalValue: PersonalValue) {
    return this.http.post(`${environment.apiUrl}/individual/addPersonalValue`, {
      personalValue,
    });
  }

  UpdatePersonalValueById(uid: string, personalValue: PersonalValue) {
    return this.http.post(
      `${environment.apiUrl}/individual/updatePersonalValue`,
      {
        uid: uid,
        personalValue: personalValue,
      }
    );
  }

  DeletePersonalValue(uid: string) {
    return this.http
      .post(`${environment.apiUrl}/individual/deletePersonalValue`, {
        uid: uid,
      })
      .subscribe(
        (data) => {

        },
        (error) => {
          console.error('DeletePersonalValue error', error);
        }
      );
  }

  AddPersonalPersonalityByUid(
    uid: string,
    personalPersonality: PersonalPersonality
  ) {
    return this.http.post(
      `${environment.apiUrl}/individual/addPersonalPersonality`,
      { uid: uid, personalPersonality: personalPersonality }
    );
  }

  UpdatePersonalPersonalityByUid(
    uid: string,
    personalPersonality: PersonalPersonality
  ) {
    return this.http.post(
      `${environment.apiUrl}/individual/updatePersonalPersonality`,
      { uid, personalPersonality }
    );
  }

  DeletePersonalPersonalityByUid(uid: string) {
    return this.http
      .post(`${environment.apiUrl}/individual/deletePersonalPersonality`, {
        uid: uid,
      })
      .subscribe(
        (data) => {

        },
        (error) => {
          console.error('DeletePersonalPersonalityByUid error', error);
        }
      );
  }


  async getPersonalQuizzes() {
    const res = await this.http.get<ResResult>(`${environment.apiUrl}/individual/getPersonalQuizzes`
      , {}).toPromise();
    return res.data as PersonalQuizzes;
  }
  async getPersonalQuizzesByUid(uid: string) {
    const params = new HttpParams().set('uid', uid);
    const res = await this.http.get<ResResult>(`${environment.apiUrl}/individual/getPersonalQuizzesByUid`
      , { params: params }).toPromise();
    return res.data as PersonalQuizzes;
  }

  async getPersonalQuizzesById(id: string) {
    const params = new HttpParams().set('id', id);
    const res = await this.http.get<ResResult>(`${environment.apiUrl}/individual/getPersonalQuizzesById`
      , { params: params }).toPromise();
    return res.data as PersonalQuizzes;
  }

  insertPersonalQuizzes(uid: any, data: PersonalQuizzes) {
    return this.http.post(`${environment.apiUrl}/individual/insertPersonalQuizzes`, { uid, data });
  }

  updatePersonalQuizzes(uid: any, data: PersonalQuizzes) {
    return this.http.post(`${environment.apiUrl}/individual/updatePersonalQuizzes`, { uid, data });
  }

  async getPersonalTraitsPercentage(uid: string) {
    const params = new HttpParams().set('uid', uid);
    const result = await this.http.get<ResResult>(`${environment.apiUrl}/individual/getPersonalQuizzesByUid`
      , { params: params }).toPromise();
  }

  // Quizzes

  calculate_Hipster_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let HIPSTER_Numerator = 0;
      // c9
      if (PersonalQuizzes.q1 === 1) {
        HIPSTER_Numerator += 1;
      }

      if (PersonalQuizzes.q2 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q3 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q4 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q5 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q6 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q7 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q8 === 1) {
        HIPSTER_Numerator += 1;
      }

      if (PersonalQuizzes.q9 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q10 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q11 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q12 === 1) {
        HIPSTER_Numerator += 1;
      }
      if (PersonalQuizzes.q13 === 1) {
        HIPSTER_Numerator += 1;
      }

      let HIPSTER_Denominator = 0;
      if (PersonalQuizzes.q1 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q2 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q3 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q4 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q5 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q6 === 4) {
        HIPSTER_Denominator += 1;
      }

      if (PersonalQuizzes.q7 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q8 === 4) {
        HIPSTER_Denominator += 1;
      }

      if (PersonalQuizzes.q9 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q10 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q11 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q12 === 4) {
        HIPSTER_Denominator += 1;
      }
      if (PersonalQuizzes.q13 === 4) {
        HIPSTER_Denominator += 1;
      }
      let score = (HIPSTER_Numerator / (13 - HIPSTER_Denominator));

      _percentage = (score * 100).toFixed(0).toString() + '%';
    } else {
      _percentage = '0%';
    }

    return _percentage;
  }

  calculate_Hacker_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let _numerator = 0;
      let _denominator = 0;
      if (PersonalQuizzes.q1 === 2) {
        _numerator += 1;
      }

      if (PersonalQuizzes.q2 === 2) {
        _numerator += 1;
      }

      if (PersonalQuizzes.q3 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q4 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q5 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q6 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q7 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q8 === 2) {
        _numerator += 1;
      }

      if (PersonalQuizzes.q9 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q10 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q11 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q12 === 2) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q13 === 2) {
        _numerator += 1;
      }

      // Denominator
      if (PersonalQuizzes.q1 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q2 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q3 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q4 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q5 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q6 === 4) {
        _denominator += 1;
      }

      if (PersonalQuizzes.q7 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q8 === 4) {
        _denominator += 1;
      }

      if (PersonalQuizzes.q9 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q10 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q11 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q12 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q13 === 4) {
        _denominator += 1;
      }
      let score = (_numerator / (13 - _denominator));

      _percentage = (score * 100).toFixed(0).toString() + '%';

    } else {
      _percentage = '0%';
    }
    return _percentage;
  }

  calculate_Hustler_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let _numerator = 0;
      let _denominator = 0;
      if (PersonalQuizzes.q1 === 3) {
        _numerator += 1;
      }

      if (PersonalQuizzes.q2 === 3) {
        _numerator += 1;
      }

      if (PersonalQuizzes.q3 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q4 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q5 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q6 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q7 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q8 === 3) {
        _numerator += 1;
      }

      if (PersonalQuizzes.q9 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q10 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q11 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q12 === 3) {
        _numerator += 1;
      }
      if (PersonalQuizzes.q13 === 3) {
        _numerator += 1;
      }

      // Denominator
      if (PersonalQuizzes.q1 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q2 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q3 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q4 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q5 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q6 === 4) {
        _denominator += 1;
      }

      if (PersonalQuizzes.q7 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q8 === 4) {
        _denominator += 1;
      }

      if (PersonalQuizzes.q9 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q10 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q11 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q12 === 4) {
        _denominator += 1;
      }
      if (PersonalQuizzes.q13 === 4) {
        _denominator += 1;
      }
      let score = (_numerator / (13 - _denominator));

      _percentage = (score * 100).toFixed(0).toString() + '%';

    } else {
      _percentage = '0%';
    }
    return _percentage;
  }

  calculate_Drive_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let _sum1 = 0;
      let _sum2 = 0;
      if (PersonalQuizzes.q1 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q2 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q3 === 4) {
        _sum1 += 1;
      }
      if (PersonalQuizzes.q4 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q5 === 4) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q8 === 4) {
        _sum2 += 1;
      }

      if (PersonalQuizzes.q16 === 3) {
        _sum2 += 1;
      }

      let score = ((4 - _sum1) / 4) - (0.1 * _sum2);

      _percentage = (score * 100).toFixed(0).toString() + '%';

    } else {
      _percentage = '0%';
    }
    return _percentage;
  }

  calculate_Curiosity_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let _sum1 = 0;
      let _sum2 = 0;
      if (PersonalQuizzes.q5 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q6 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q7 === 4) {
        _sum1 += 1;
      }
      if (PersonalQuizzes.q8 === 4) {
        _sum1 += 1;
      }

      // sum2
      if (PersonalQuizzes.q10 === 4) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q11 === 4) {
        _sum2 += 1;
      }

      if (PersonalQuizzes.q14 === 4) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q16 === 3) {
        _sum2 += 1;
      }

      let score = ((4 - _sum1) / 4) - (0.1 * _sum2);

      _percentage = (score * 100).toFixed(0).toString() + '%';

    } else {
      _percentage = '0%';
    }

    return _percentage;
  }

  calculate_Collaborative_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let _sum1 = 0;
      let _sum2 = 0;
      if (PersonalQuizzes.q9 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q10 === 4) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q11 === 4) {
        _sum1 += 1;
      }
      if (PersonalQuizzes.q12 === 4) {
        _sum1 += 1;
      }

      // sum2
      if (PersonalQuizzes.q3 === 4) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q6 === 4) {
        _sum2 += 1;
      }

      if (PersonalQuizzes.q14 === 1) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q16 === 3) {
        _sum2 += 1;
      }

      let score = ((4 - _sum1) / 4) - (0.1 * _sum2);

      _percentage = (score * 100).toFixed(0).toString() + '%';

    } else {
      _percentage = '0%';
    }
    return _percentage;
  }

  calculate_Humility_percentage(PersonalQuizzes) {
    let _percentage = '0%';
    if (!this.utility.IsNullOrEmpty(PersonalQuizzes)) {
      let _sum1 = 0;
      let _sum2 = 0;
      let _sum3 = 0;
      let _sum4 = 0;
      if (PersonalQuizzes.q13 === 1) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q14 === 1) {
        _sum1 += 1;
      }

      if (PersonalQuizzes.q15 === 1) {
        _sum1 += 1;
      }
      if (PersonalQuizzes.q16 === 1) {
        _sum1 += 1;
      }

      // sum2
      if (PersonalQuizzes.q13 === 2) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q14 === 2) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q15 === 2) {
        _sum2 += 1;
      }
      if (PersonalQuizzes.q16 === 2) {
        _sum2 += 1;
      }

      // sum3
      if (PersonalQuizzes.q13 === 3) {
        _sum3 += 1;
      }
      if (PersonalQuizzes.q14 === 3) {
        _sum3 += 1;
      }
      if (PersonalQuizzes.q15 === 3) {
        _sum3 += 1;
      }
      if (PersonalQuizzes.q16 === 3) {
        _sum3 += 1;
      }

      // sum4
      if (PersonalQuizzes.q13 === 4) {
        _sum4 += 1;
      }
      if (PersonalQuizzes.q14 === 4) {
        _sum4 += 1;
      }
      if (PersonalQuizzes.q15 === 4) {
        _sum4 += 1;
      }
      if (PersonalQuizzes.q16 === 4) {
        _sum4 += 1;
      }

      let score = (_sum1 / 4) + (_sum2 / 8) + (_sum3 / 16) + (_sum4 / 32);

      _percentage = (score * 100).toFixed(0).toString() + '%';

    } else {
      _percentage = '0%';
    }
    return _percentage;
  }

  mbIndicatorNumberToString(mb: string) {
    switch (mb) {
      case "1":
        return "ISTJ";
      case "2":
        return "ISTP";
      case "3":
        return "ISFP";
      case "4":
        return "ISFJ";
      case "5":
        return "INFJ";
      case "6":
        return "INTJ";
      case "7":
        return "INFP";
      case "8":
        return "INTP";
      case "9":
        return "ESTP";
      case "10":
        return "ESTJ";
      case "11":
        return "ESFJ";
      case "12":
        return "ESFP";
      case "13":
        return "ENFP";
      case "14":
        return "ENTP";
      case "15":
        return "ENFJ";
      case "16":
        return "ENTJ";
      case "":
        return "";
    }
  }

}
