import { Component, OnInit, ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'ui-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
