import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line-bar',
  templateUrl: './line-bar.component.html',
  styleUrls: ['./line-bar.component.css']
})
export class LineBarComponent implements OnInit {
  // To represent percentage values in the bar graph, pass in a string number with % affixed to the back. e.g. "5%"
  @Input() value: string;
  @Input() label: string;
  @Input() MaxValue: string;

  isValueAbsolute = false;
  absoluteValue: number;
  linewidth:string;

  constructor() { }

  ngOnInit() {
   
    this.linewidth = this.value+ '%';
      // this.isValueAbsolute = true;
      // var intValue = Number(this.value);

      // // If showing absolute values (e.g. num of industries instead of % of industries, i multiply by 5% to represent the width of the bar)
      // this.absoluteValue = intValue;
      // this.value = (intValue * 5).toString() + '%';
    
  }

}
