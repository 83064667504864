import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import {
  InstitutionService,
  OrganizationService,
  NotificationService,
  UtilityService,
  ToDoListService,
  DataService,
  UserService,
  EmailService
} from '@common/services';
import { programNotification } from '@common/models';
import { environment } from '../../../../environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { getTime } from 'date-fns';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lab-todo-comments',
  templateUrl: './todo-comments.component.html',
  styleUrls: ['./todo-comments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TodoCommentsComponent implements OnInit, AfterViewInit {
  currentBatch;
  _todo: any;
  loading: boolean = true;
  @Input() set todo(object) {
    this._todo = object;
    if (this._todo) {
      this.getComments(this._todo.toDoId);
    }
  };
  @Input() showComments: any;
  @Input() title: string;
  // only mentors and programmer can do private comment.
  @Input() isSupportPrivate: boolean = false;
  @Input() currentUser: any;
  @Input() program: any;

  @Output() close = new EventEmitter();

  toDo: any;
  message: string = '';
  isChecked: boolean = false;
  sendDisabeld: boolean = true;
  toDoComments: any[];
  editMode: boolean = false;
  editCommentId: string = '';
  updatedBy: string = '';

  public Editor = ClassicEditor;
  public model = {
    editorData: ''
  }

  constructor(
    private todoSrv: ToDoListService,
    private dataSrv: DataService,
    private toastr: ToastrService,
    // private institutionSrv: InstitutionService,
    private cdr: ChangeDetectorRef,
    // private orgSrv: OrganizationService,
    // private notiSrv: NotificationService,
    // private utilitySrv: UtilityService,
    private userService: UserService,
    private emailSrv: EmailService
  ) {

    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'link',
          'bold',
          'italic',
          '|',
          'bulletedList',
          'numberedList',
          '|',
          'link',   
          'undo',
          'redo'
        ]
      },
      language: 'en',
      link:  {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://'
      }
    };

  }

  ngOnInit() {
    this.currentBatch = JSON.parse(localStorage.getItem('currentBatch') || '{}');
  }

  ngAfterViewInit() {
    
  }

  onChange(event: any) {
    this.message = event.target.textContent;
    this.sendDisabeld = event.target.textContent.length > 0;
  }

  onToggleChecked() {
    this.cdr.detectChanges();
    this.isChecked = !this.isChecked;
  }

  onClose(status) {
    // this.close.emit();
    // clear edit message when close modal
    this.editMode = false;
    this.message = '';
    this.editCommentId = '';
    this.updatedBy = '';

    // this.cdr.detectChanges();
    if (status === 'close') this.dataSrv.openTaskModalDialog([false]);
  }

  onReady(editor: ClassicEditor) {
    this.Editor = editor;
  }

  onClear() {
    this.message =  '';
  }

  // onComment() {
  //   const params = {
  //     organizationId: this._rating.projectTeamId,
  //     programId: this._rating.programId,
  //     deliverableId: this._rating.id,
  //     ratingId: this._rating.ratingId,
  //     comment: this.message,
  //     type: this.isChecked ? 1 : 0,
  //     source: 1,
  //     status: 'null',
  //     userId: this.currentUser.id
  //   };

  //   this.institutionSrv.createComment(params).subscribe(
  //     comRes => {

  //       if (comRes['result'] === 'successful') {
  //         params['fullname'] = this.currentUser.fullname;
  //         params['imageUrl'] = this.currentUser.imageUrl;
  //         params['createdAt'] = comRes['data'][8];
  //         params['createdBy'] = comRes['data'][9];

  //         this.commentData.push(params);
  //         this.message = "";
  //         this.cdr.detectChanges();
  //         if (this.isChecked !== true) {

  //           this.orgSrv.getOrgAndOwnerById(this._rating.projectTeamId).subscribe(res => {
  //             if (res['result'] === 'successful') {
  //               let email = res['ownerInfo']['email'];
  //               let name = res['ownerInfo']['fullname'];
  //               let newNotification = new programNotification();
  //               let title = this.currentUser.fullname + ' added a comment to a deliverable check it out now!';
  //               let content = this.currentUser.fullname + ' added a comment to a deliverable check it out now!';
  //               newNotification.commentId = comRes['commentId'];
  //               newNotification.uid = res['data']['owner'];
  //               newNotification.title = title;
  //               newNotification.content = content;
  //               newNotification.type = 0;
  //               this.notiSrv.sendProgramCommentNotification(newNotification,
  //                 this.currentUser.id,
  //                 email, name).subscribe(notiRes => {
  //                 }, (error) => {
  //                   console.error("sendProgramCommentNotification :", error);
  //                 });
  //             } else {

  //             }
  //           }, (error) => {
  //             console.error("getOrgAndOwnerById :", error);
  //           });
  //         }
  //         //  this.toasterSrv.showToast('comment', 'successfully', 'success');
  //       } else {
  //         //   this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
  //       }
  //       this.message = '';
  //       this.isChecked = false;

  //     },
  //     error => {
  //       /*   this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
  //         console.error("createComment", error) */
  //     },
  //     () => {
  //       /*   const programIds = this.programs.map(value => value.id);
  //         this.getComment(programIds); */
  //     }
  //   );
  // }

  // initComment(deliverableId, ratingId) {
  //   console.log('deliverableId======', deliverableId)
  //   console.log('ratingId======', ratingId)
  //   try {
  //     this.loading = true;
  //     this.institutionSrv.getDeliverableCommentData(deliverableId, ratingId).then(res => {
  //       if (res["result"] == "successful") {
  //         this.commentData = res["data"];
  //       } else {
  //         this.commentData = [];
  //       }
  //     }).catch((error) => {
  //       console.log("error", error);
  //       this.commentData = [];
  //     }).finally(() => {
  //       this.loading = false;
  //       this.cdr.detectChanges();
  //     })
  //   } catch (error) {

  //   }
  // }

  getComments(toDoId) {
    this.loading = true;
    this.todoSrv.getCommentByToDoId(toDoId).subscribe(data => {
      if (data['result'] === 'successful') {
        this.toDoComments = data['data'];
      } else {
        this.toDoComments = [];
      }
    }, (error) => {
      console.error('getCommentByToDoId :', error);
    }, () => {
      this.loading = false;
      this.cdr.detectChanges();
    });
  }

   // check editMode is true or false
  onHandleSubmit(event: Event, mode: boolean) {
    if (this.currentBatch['batchStatus'] === 2) {
      this.toastr.show('Batch has already expired. Sending comments has been disabled', '', {
        disableTimeOut: false,
        tapToDismiss: true,
        toastClass: 'toast border-red',
        closeButton: false,
      });
      return;
    }
    // no action when click submit or update if there is no message.
    if (this.message == '') return event.preventDefault();;
  
    // call onUpdateComment or onComment
    mode ? this.onUpdateComment() : this.onComment();
  }

  onComment() {
    this.loading = true;

    let commentData = { 
      toDoId: this._todo.toDoId,
      uid: this.currentUser.id,
      userName: this.currentUser.fullname,
      comment: this.message
    }

    this.todoSrv.addToDoComment(commentData).subscribe(res => {
      if (res['result'] === 'successful') {
        // Check assignees notification Setting and send email
        let assigneesData = JSON.parse(this._todo['assignees']);

        // update status to tell todo-item about update comments count
        this.todoSrv.updateStatus({ status: 'added-comment', todoId: this._todo.toDoId});

        // show toaster message
        this.showActionMsg('Success!', 'Your comment has been added.')
        
        if (assigneesData.length > 0) {
          assigneesData.forEach(assignee => {
            this.userService.getUserDataByUid(assignee['value']).subscribe(res => {
              if (res['result'] === 'successful') {
                if (res['data']['tasksComments'] === true) {
                  // Send the email
                  let receiverName = res['data']['fullname'];
                  let commentAuthor = this.currentUser.fullname
                  let commentAuthorUid = this.currentUser.id;
                  let title = this.currentUser.fullname + ' added a comment to a task. Check it out now!';
                  let receiver = res['data']['email']
                  this.emailSrv.sendTaskCommentEmail(title, receiver, receiverName, commentData['comment'], commentAuthor, commentAuthorUid).subscribe(res => {
                    // console.log(res)
                  })
                }
              }
            })
          })
        }
        this.getComments(this._todo.toDoId);
        this.message = '';
        // document.getElementById('textarea')!.innerText = '';

      }
    }, (error) => {
      console.log(error);
    }, () => {
      this.cdr.detectChanges();
      this.loading = false;
    }
    )
  }

  onDeleteComment(commentId) {
    const params = {
      id: commentId,
      userId: this.currentUser.id
    }

    this.todoSrv.deleteTodoComment(params).subscribe(res => {
      if (res['result'] === 'successful') {
        this.getComments(this._todo.toDoId);

        // update status to tell todo-item about update comments count
        this.todoSrv.updateStatus({ status: 'deleted-comment', todoId: this._todo.toDoId});

        // show toaster message
        this.showActionMsg('Success!', 'Your comment has been deleted.')
        
        // if (this.toDoComments.length > 0) {
        //   this.subtractCount.emit();
        // }

      }
    })
  }

  onEditComment(value) {
    this.editMode = true;
    this.message = value.text;
    this.editCommentId = value.commentId;
    this.updatedBy = value.uid;

  }

  onUpdateComment() {

    const params = {
      comment: this.message,
      updateBy: this.updatedBy,
      id: this.editCommentId
    }

    this.todoSrv.updateTodoComment(params).subscribe(res => {

      if (res['result'] === 'successful') {
        this.getComments(this._todo.toDoId);
        // show toaster message
        this.showActionMsg('Success!', 'Your comment has been updated.')
      }

    })

    this.message = '';
    this.isChecked = false;
    this.editMode = false;
  }

  formatDateTime(dateString) {
    const parsed = moment(new Date(parseInt(dateString)))

    if (!parsed.isValid()) {
        return dateString
    }

    return parsed.format('MMM D, YYYY, HH:mmA');
  }

  showActionMsg(title, msg) {
    this.toastr.show(msg, title, {
        tapToDismiss: true,
        toastClass: 'toastpayment ',
        titleClass: 'green-title',
        closeButton: false,
        timeOut: 5000
    });
}
}