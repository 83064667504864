import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ui-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ]
})
export class RadioComponent implements ControlValueAccessor {
  @Input() name: string;
  @Input('value') _value;
  @Input() checkedValue: string;

  modelValue: any;

  get value() {
    return this._value;
  }

  set value(value) {
    if (!!value) {
      this._value = value;
      this.onChange(value);
      this.onTouched();
    }
  }

  onChange: any = () => { };

  onTouched: any = () => { };

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn
  }

  writeValue(value) {
    this.modelValue = value;
  }

  toggleChange(event) {


    // Is a mapping here necessary to assign the outer ngModel bound
    // property it's new value??
  }

  valueChanged(event) {
    this.onChange(this.modelValue);
  }

}
