import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProgramActivityService {

    constructor(
        private http: HttpClient
    ) {

    }

    addActivity(batchId, teamId, desc, uid, type, role) { // type 1 = restart, type 2 = abort and type 3 = pivot
        return this.http.post<any>(`${environment.apiUrl}/programActivity/addActivity`, {
            batchId: batchId,
            teamId: teamId,
            desc: desc,
            uid: uid,
            type: type,
            role: role
        });
    }

    getActivity(batchId, projecteamId) {
        return this.http.get<any>(`${environment.apiUrl}/programActivity/getActivity/${batchId}/${projecteamId}`);
    }

    getCompletedActivity(batchId, projecteamId) {
        return this.http.get<any>(`${environment.apiUrl}/programActivity/getCompletedActivity/${batchId}/${projecteamId}`);
    }

    getLatestActivity(batchId, projecteamId) {
        return this.http.get<any>(`${environment.apiUrl}/programActivity/getLatestActivity/${batchId}/${projecteamId}`);
    }

    updateActivity(activityId, desc, uid, role, status, requestStatus) {
        return this.http.post<any>(`${environment.apiUrl}/programActivity/updateActivity`, {
            activityId: activityId,
            desc: desc,
            uid: uid,
            role: role,
            status: status,
            requestStatus: requestStatus
        });
    }

    cancelActivity(activityId, uid) {
        return this.http.post<any>(`${environment.apiUrl}/programActivity/cancelActivity`, {
            activityId: activityId,
            uid: uid
        });
    }

   async getActivityStatus(activityId){
        return await this.http.get<any>(`${environment.apiUrl}/programActivity/activityStatue/${activityId}`).toPromise();
   }

    addPivotRecord(activityId, phaseId, phaseTitle, subId, subTitle, uid) {
        return this.http.post<any>(`${environment.apiUrl}/programActivity/addPivotRecord`, {
            activityId: activityId,
            phaseId: phaseId,
            phaseTitle: phaseTitle,
            subId: subId,
            subTitle: subTitle,
            uid: uid
        });
    }

   async  addPivotRecords(id, data, uid) {
        return await this.http.post<any>(`${environment.apiUrl}/programActivity/pivotRecords`, {
            id,
            data: data,
            uid: uid
        });
    }

    getPivotData(id) {
        return this.http.get<any>(`${environment.apiUrl}/programActivity/getPivotData/${id}`);
    }

    addActivityIndividual(data) {
        return this.http.post<any>(`${environment.apiUrl}/programActivity/addActivityIndividual`, {
            data: data
        });
    }

    getActivitylog(batchId, projecteamId, uid) {
        return this.http.get<any>(`${environment.apiUrl}/programActivity/getActivitylog/${batchId}/${projecteamId}/${uid}`);
    }

    updateLog(activityId, uid) {
        return this.http.post<any>(`${environment.apiUrl}/programActivity/updateLog`, {
            activityId: activityId,
            uid: uid
        });
    }

    addActivityHistory(data) {
        return this.http.post<any>(`${environment.apiUrl}/programActivity/addActivityHistory`, {
            data: data
        });
    }

    getActivityHistory(id) {
        return this.http.get<any>(`${environment.apiUrl}/programActivity/getActivityHistory/${id}`);
    }

}

