import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ConfigService, UtilityService } from '@common/services';
import { environment } from 'environments/environment';

@Component({
  selector: 'ui-avatar-name',
  templateUrl: './avatar-name.component.html',
  styleUrls: ['./avatar-name.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarNameComponent implements OnInit {
  @Input() class?: string;
  @Input() avatar?: string;
  @Input() type?: string;
  @Input() color?: string;
  @Input() name: string;
  @Input() selected: boolean;

  @Output() click = new EventEmitter();

  constructor(
    public utilitySrv: UtilityService,
    private configSrv: ConfigService
  ) { }

  ngOnInit() {
    
  }

  onClick(event: any) {
    event.stopPropagation();
    this.click.emit();
  }
  onImgError(event) {
    event.target.src = this.configSrv.defaultUserLogo;
  }
}
