import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'app-form',
  templateUrl: './app-form.component.html',
  styleUrls: ['./app-form.component.css']
})
export class AppFormComponent {

  public _IsBoardLine: boolean = false;
  public _IsShadow: boolean = true;
  @Input() set IsBoardLine(isLine) {
    this._IsBoardLine = isLine;
  }
  @Input() set IsShadow(IsShadow) {
    this._IsShadow = IsShadow;
  }
}
