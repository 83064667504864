import { Component, ViewChild, OnInit, Input } from '@angular/core';

import { ChartComponent, ApexLegend, ApexChart } from 'ng-apexcharts';
import { ApexChartOptions } from '../../../models';
import { ActvityProps } from '../../../models';

@Component({
  selector: 'app-brush-chart',
  templateUrl: './brush-chart.component.html',
  styleUrls: ['./brush-chart.component.css'],
})
export class BrushChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @ViewChild('chart1') chart1: ChartComponent;

  public chartOptions1: Partial<ApexChartOptions> ;
  public chartOptions2: Partial<ApexChartOptions> ;
  legend: ApexLegend;
  @Input() props: ActvityProps;
  @Input() type;
  @Input() showUpchart = true;
  @Input() showBottomchart = true;
  constructor() {
    //https://apexcharts.com/docs/options/legend/#
    this.legend = {
      horizontalAlign: 'left',
      inverseOrder: true,
    }
  }
  ngOnInit() {
    try {
      if (this.props != null) {
        const type = this.type;
        const props: ActvityProps = this.props;
        let minXValue;
        let maxXValue;

        if (props.lastEntryData) {
          const lastEntryData = new Date(props.lastEntryData);
          maxXValue = lastEntryData.setDate(lastEntryData.getDate() - 0); // 1 day ago
          minXValue = lastEntryData.setDate(lastEntryData.getDate() - 20); // 20 days back
        } else {
          minXValue = props.minXValue;
          maxXValue = props.maxXValue;
        }


        this.chartOptions1 = {
          series: props.series,
          noData: {
            text: 'There is no data',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#008f7c',
              fontSize: '14px',
              fontFamily: 'Arial'
            }
          },
          chart: {
            events: {
              click: (e) => {
                if (e.toElement.innerText) {
                  if (e.toElement.innerText == "unselect all") {

                    const legendItems = e.toElement.parentElement.parentElement.querySelectorAll(".apexcharts-legend-marker")
                    if (legendItems) {
                      for (let i = 0; i < legendItems.length; i++) {
                        if (!legendItems[i].classList.contains('apexcharts-inactive-legend')) {
                          this.chart.toggleSeries(legendItems[i].parentElement.getAttribute('seriesname'));
                        } else if (legendItems[i].parentElement.getAttribute('seriesname') == 'unselectxall') {
                          this.chart.resetSeries()
                        }
                      }
                    }
                  }
                }
              }
            },
            id: `${props.id}2`,
            height: props.height ? props.height : '430',
            width: '100%',
            fontFamily: `var(--quickSand)`,
            type: type,
            toolbar: {
              autoSelected: 'pan',
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: true,
                zoomout: true,
                reset: true,
                pan: false,
              },
              show: true,
            },
            selection: {
              enabled: true,

            }
          },
          colors: [
            '#008f7c', '#5073B8', '#F1973C', '#F16548', '#A166AB', '#0F98AD', '#84C253',
            '#F148A3', '#c0c400', '#00e3ba', '#90A2E4', '#DC8B8B',
            '#FF6633', '#ff6b38', '#FF33FF', '#ffff00', '#00B3E6',
            '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
            '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
            '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
            '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
            '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
            '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
            '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
            '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
            '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
          ],
          stroke: {
            curve: 'straight',
            lineCap: 'round',
            colors: [
              '#008f7c', '#5073B8', '#F1973C', '#F16548', '#A166AB', '#0F98AD', '#84C253',
              '#F148A3', '#c0c400', '#00e3ba', '#90A2E4', '#DC8B8B',
              '#FF6633', '#ff6b38', '#FF33FF', '#ffff00', '#00B3E6',
              '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
              '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
              '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
              '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
              '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
              '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
              '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
              '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
              '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'],
            width: 1,
          },
          dataLabels: {
            enabled: false,
          },
          title: {
            text: props.title,
            align: 'left',
            style: {
              fontFamily: 'var(--quickSand)',
              fontSize: '22px',
              fontWeight: '700',
              color: '#263238',
            },
          },
          xaxis: {
            type: 'datetime',
            min: new Date(this.formatDate(minXValue)).getTime(),
            max: new Date(this.formatDate(maxXValue)).getTime()
          },
        };


        this.chartOptions2 = {
          series: props.series,
          noData: {
            text: 'There is no data',
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
              color: '#008f7c',
              fontSize: '14px',
              fontFamily: 'Arial'
            }
          },
          chart: {
            id: `${props.id}1`,
            height: 300,
            type: 'area',
            brush: {
              target: `${props.id}2`,
              enabled: true,
            },
            selection: {
              enabled: true,
              xaxis: {
                min: minXValue,
                max: new Date(this.formatDate(maxXValue)).getTime()
              }
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              opacityFrom: 0.91,
              opacityTo: 0.1,
            },
          },
          xaxis: {
            type: 'datetime',
            tooltip: {
              enabled: false,
            },
            max: maxXValue,
          },
          yaxis: {
            tickAmount: 2,
          },
        };
      }
    } catch (error) {
      console.error(error);
    }

  }
  formatDate(date) {

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    /* if (month.length < 2)
      month = '0' + month; */
    if (day.length < 2)
      day = '0' + day;
    const monthNames = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return day + " " + monthNames[month] + " " + year;
  }

}
