
export class PersonalValue {
    id: string;
    drive = 1;
    intellectualCuriousity = 1;
    coachability = 1;
    humility = 1;
    hipster = 1;
    hacker = 1;
    hustler = 1;
    hi1: string; // hipster question 1
    hi2: string;
    hi3: string;
    hi4: string;
    ha1: string; // hacker question 1
    ha2: string;
    ha3: string;
    ha4: string;
    hu1: string; // hustler question 1
    hu2: string;
    hu3: string;
    hu4: string;
    uid: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}
