import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripeElements
} from '@stripe/stripe-js';
import { StripeService, StripeCardComponent, StripePaymentElementComponent } from 'ngx-stripe';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lab-dialog-credit-card',
  templateUrl: './dialog-credit-card.component.html',
  styleUrls: ['./dialog-credit-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogCreditCardComponent implements OnInit {
  @Input() last4: string;
  @Input() exp_month: number;
  @Input() exp_year: string;
  @Input() brand: string;
  @Input() show;
  @Output() close = new EventEmitter();
  @Output() saveCard = new EventEmitter();
  cardNumberErrorMessage;
  cardCvcErrorMessage;
  cardExpiryErrorMessage;
  type = 'normal';
  elements: StripeElements;
  card;
  cardNumber;
  cardExpiry;
  cardCvc;
  cardForm: FormGroup;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#383838',
        fontWeight: '300',
        fontFamily: '"Helvetica", Helvetica, sans-serif',
        fontSize: '14px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
      invalid: {
        iconColor: '#F32823',
        color: '#F32823'
      }
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };


  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
  ) { 
    this.cardForm = this.fb.group({
      name: ['']
    });
  }

  ngOnInit(): void {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.cardNumber = this.elements.create('cardNumber', this.cardOptions);
          this.cardNumber.mount('#card-number');
          this.cardExpiry = this.elements.create('cardExpiry', this.cardOptions);
          this.cardExpiry.mount('#card-expiry');
          this.cardCvc = this.elements.create('cardCvc', this.cardOptions);
          this.cardCvc.mount('#card-cvc');
        }
      });
  }

  onClick() {
    this.close.emit();
  }

  onSubmit() {
    this.saveCard.emit();
  }

}
