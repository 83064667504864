
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-user-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class UserButtonComponent implements OnInit {
  @Input() buttonConfig: any;
  @Input() btStyle: any;
  @Input() btSrc: any;
  @Input() btIcon: any;
  @Input() btClass: any;
  @Input() btText: any;
  @Input() IsLowercase: boolean = true;
  @Output() textBtnClickEmt: EventEmitter<string> = new EventEmitter<string>();
  @Output() imgBtnClickEmt: EventEmitter<string> = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {


  }

  onTextBtnClick() {
    this.textBtnClickEmt.emit('');
  }

  onImgBtnClick() {
    this.imgBtnClickEmt.emit('');
  }
}
