import { Component, OnInit, Output, Input, AfterViewInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import { ConfigService, DataService, EatToasterService, EmailService, InstitutionService, OrganizationBatchService, OrganizationService, RoleService, UserService } from '@common/services';
import { Msg } from '@modules/_shared/models';
import { User } from '@common/models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
    selector: 'tag-consultant-user-list',
    templateUrl: './tag-consultant-user-list.component.html',
    styleUrls: ['./tag-consultant-user-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TagConsultantUserListComponent implements OnInit, AfterViewInit {
    _currentOrg: any;

    @Input() set currentOrg(object) {

        if (object) {
            this._currentOrg = object;
            this.loading = true;
            this.users = [];
        }
    }
    @Input() type;
    @Input() batchId;
    @Output() onTagConsultant = new EventEmitter<any>();
    currentUser: any;
    profile_users: Array<any>[];
    users: Array<any> = [];
    searchText;
    sortedCollection: any[];

    emailTitle: string;
    msgType = '';

    typingTimer;
    toastRef;

    displaytext: string = '';
    loading: boolean = true;

    constructor(
        private userSrv: UserService,
        private dataSrv: DataService,
        private emailSrv: EmailService,
        public globals: ConfigService,
        private toastr: ToastrService,
        private orgBatchSrv: OrganizationBatchService,
        private orgSrv: OrganizationService,
        private roleSrv: RoleService,
        private institutionSrv: InstitutionService,
        private router: Router
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        this.getUsersWithlimit();
    }

    ngAfterViewInit() {

    }

    getUsersWithlimit() { // to get a few users data to display to the user
        if (this._currentOrg?.id) {
            this.orgBatchSrv.getParticipantByBatch(this._currentOrg.organizationId, this.batchId).then(data => {
                if (data['status'] === 'success') {
                    this.users = data['data'];
                } else {
                    this.users = [];
                }
                this.loading = false;
            });
        }
    }

    onSearchChange() {
        clearTimeout(this.typingTimer);
        if (this.searchText) { // check if the user typed something

            this.typingTimer = setTimeout(() => { // trigger function to search after 1 second
                this.doneTyping(this.searchText);
            }, 1000);
        } else { // if user didn't input any value return the default
            this.getUsersWithlimit();
        }
    }

    doneTyping(value) { // get data that contain value input by the user
        if (this._currentOrg?.id) {
            this.userSrv.searchUsers(value, this._currentOrg.organizationId, this._currentOrg.id).subscribe(res => {
                if (res['result'] === 'successful') {
                    this.users = res['data'];
                } else {
                    this.users = [];
                    this.displaytext = `Sorry, we cannot find <span class="highlight">${this.searchText}</span> in our members list. Would you want to try to send an email to tell
                your friend`;
                }
            }, error => {
                console.log(error);
            });
        }
    }

    onClose() {
        this.searchText = '';
    }

    invNoti(email, type, index?) {
        const _noti = new Msg();

        if (type === 1) {
            this.users[index].inviteCount = 1;
        }

        if (email !== '') {
            this.userSrv.getUserDataByEmailDB(email).subscribe(res => { // retrieve user data

                let roleId = 9; // projectstaff

                // get invited user userrole
                this.roleSrv.getUserRoleWUidnRoleName(res['data'].id, roleId.toString()).subscribe(userrole => { // check if user have this role

                    if (userrole['result'] === 'failed') {
                        let userRoleList: Array<any> = [];

                        userRoleList.push({
                            userRoleId: '',
                            userId: res['data'].id,
                            roleID: roleId,
                            available: 0
                        });

                        this.roleSrv.updateUserRoleByUid2(userRoleList).subscribe(userRoleRes => { // add user role if the user doesn't have it

                            if (userRoleRes['result'] === 'successful') {
                                userRoleList[0].userRoleId = userRoleRes['userRoleId'];
                                this.onAddUserToOrg(userRoleList[0].userRoleId);
                            }
                        }, (error) => {
                            console.error("updateUserRoleByUid2 :", error);
                        });
                    } else {
                        this.onAddUserToOrg(userrole['data'][0].userRoleId);
                    }
                }, (error) => {
                    console.error("getUserRoleWUidnRoleName :", error);
                });
            });
        } else {
            // this.ShowMsg('Please indicate role and/or the user', 1);
        }
    }

    onAddUserToOrg(userRoleId) { // add user into organization
        this.orgSrv.addUserToOrgUser(
            userRoleId,
            this._currentOrg.organizationId,
            this._currentOrg.organizationTypeId,
            '',
            '',
            this.currentUser.id
        ).subscribe(NewUser => {
            if (NewUser['result'] === 'successful') { }
        });
    }

    onInvite(id) {
        this.users[id].inviteCount = 1;
    }

    showActionMsg(title, msg) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: 'green-title',
            closeButton: false,
            timeOut: 5000
        });
    }

    addMentor(selectedUserId) {
        // mentor's company
        // let orgId = this.InvForm.value.orgId;

        let params = {}
        let _mentorId = '';
        let _clientType = 'user';

        params = {
            batchId: this.batchId,
            masterId: this._currentOrg.organizationId,
            clientId: selectedUserId,
            clientType: _clientType,
            userId: selectedUserId,
        };

        _mentorId = this._currentOrg.organizationId;



        this.institutionSrv.addMentorManagement(params).subscribe(
            (res) => {
                if (res['result'] == 'successful') {
                    if (res.data) {
                        // this.mentorShip = this.mentorShip.filter(mentee => mentee.orgId != _mentorId);
                        // this.getMentorManagement(this._params.batchId,
                        //     this._params.orgId, null,
                        //     'organization');
                        // this.onAdded.emit(organization);
                        this.showActionMsg("Mentorship", "The mentor added successfully");
                        this.getUsersWithlimit();
                        this.router.navigate(['./w/batch/profile'],
                            {
                                queryParams: {
                                    id: this.currentUser.id,
                                    batchId: this.batchId,
                                    orgId: this._currentOrg?.id,
                                    orgType: 'batch',
                                }
                            });
                        this.onTagConsultant.emit();
                    } else {

                    }

                }
            }, (error) => console.log(error),
            () => { }
        );

    }

}
