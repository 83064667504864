export class Msg {
    id: string;
    batchId: string;
    requesterUid: string;
    requesterOrgId: string;
    invitedOrgId: string;
    invitedUid: string;
    requestDate: string;
    organizationTypeId: string;
    requesterOrgTypeId: string;
    matchedOrgType: string;
    title: string;
    content: string;
    invitationType: string;
    status = 0;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    receiverName: string;
    receiverId: string;
    receiverOrgName: string;
    receiverOrgId: string;
    orgName: string;
    orgId: string;
    orgType: number;
    senderId: string;
    date: string;
    type: number; // 0: invitation, 1: notification
    invitedOrgType: string; // 0: startup 1: investor 2: advisor 3: SP
    clicked = 0;
    accepted = false;
    ignored = false;
    senderUid: string;
    receiverUid: string;
}

