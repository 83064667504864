import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import {
  AppSettingsService,
  OrganizationService,
  UtilityService
} from '@common/services';

@Component({
  selector: 'ui-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit {
  @Input() class?: string;
  @Input() contacts?: any;

  @Input() email;
  @Input() website;
  @Input() facebook;
  @Input() instagram;
  @Input() twitter;
  @Input() linkedin;
  // email: any;
  // website: any;
  // facebook: any;
  // linkedin: any;
  // twitter: any;
  // instagram: any;

  constructor(
    private appSettingsSrv: AppSettingsService,
    private orgSrv: OrganizationService,
    private utilitySrv: UtilityService,
  ) {

  }

  ngOnInit() {
    if (this.contacts) {
      try {
        // this.utilitySrv.IsNullOrEmpty()
        this.email = this.utilitySrv.IsNullOrEmpty(this.contacts.email) ? '' : this.contacts.email;
        this.website = this.utilitySrv.IsNullOrEmpty(this.contacts.website) ? '' : this.contacts.website;
        this.facebook = this.utilitySrv.IsNullOrEmpty(this.contacts.facebook) ? '' : this.contacts.facebook;
        this.linkedin = this.utilitySrv.IsNullOrEmpty(this.contacts.linkedin) ? '' : this.contacts.linkedin;
        this.twitter = this.utilitySrv.IsNullOrEmpty(this.contacts.twitter) ? '' : this.contacts.twitter;
        this.instagram = this.utilitySrv.IsNullOrEmpty(this.contacts.instagram) ? '' : this.contacts.instagram;
      } catch (error) {
        console.error("contacts error ", error);
      }
    }
  }


  navigateExternalRoute(link) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'http://';

      url += link;
      window.open(url, '_blank');
    } else {
      window.open(link, '_blank');
    }
  }

}
