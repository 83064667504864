import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { User } from '@common/models';
import { ConfigService, UtilityService } from '@common/services';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'ui-profile-header',
    templateUrl: './profile-header.component.html',
    styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent implements OnInit {
    @Input() banner?: string;
    @Input() logo?: string;
    @Input() name;
    @Input() id;
    @Input() tagline;
    @Input() type;
    @Input() currentOrg;
    @Input() owner;

    @Output() click = new EventEmitter();

    @Output() editProfile = new EventEmitter();

    currentOrgId: string;
    currentUser;
    currentOrgType: string;
    viewCopiedUser;

    constructor(
        public utility: UtilityService,
        public globals: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private toastrSrv: ToastrService
    ) {
        this.route.queryParams.subscribe((params) => {
            this.currentOrgId = params['orgId'];
            this.currentOrgType = params['orgType'];
        });

        this.viewCopiedUser = this.route.snapshot.paramMap.get('uid');

        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    @Input() set params(object) {
        if (object) {
            if (object['orgId']) {
                this.currentOrgId = object['orgId'];
            }
            if (object['orgType']) {
                this.currentOrgType = object['orgType'];
            }
            if (object['uid']) {
                this.viewCopiedUser = object['uid'];
            }
        }
    }

    ngOnInit() { }

    // todo
    shareOrgProfile(name: any, id: string) {
        if (!this.utility.IsNullOrEmpty(name)) {
            let nameId = name.toLowerCase().split(' ').join('-');
            let selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            // selBox.value = location.origin + "/profile/" + this.currentUser.id; //

            selBox.value = location.origin + '/w/organization-profile/' + id;

            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
            this.toastrSrv.show('copied', 'Share', {
                disableTimeOut: false,
                tapToDismiss: true,
                toastClass: 'toast border-green',
                closeButton: false,
            });
        } else {
            this.toastrSrv.show('Failed to generate the shared link', 'Share', {
                disableTimeOut: false,
                tapToDismiss: true,
                toastClass: 'toast border-red',
                closeButton: false,
            });
        }
    }

    shareUserProfile(name: any, id: string) {
        if (!this.utility.IsNullOrEmpty(name)) {
            let nameId = name.toLowerCase().split(' ').join('-');
            let selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            // selBox.value = location.origin + "/profile/" + this.currentUser.id; //
            selBox.value = location.origin + '/profile/' + id;
            if (this.type === 'consult') {
                selBox.value = location.origin + '/w/organization-profile/' + id;
            }
            document.body.appendChild(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            document.body.removeChild(selBox);
            this.toastrSrv.show('copied', 'Share', {
                disableTimeOut: false,
                tapToDismiss: true,
                toastClass: 'toast border-green',
                closeButton: false,
            });
        } else {
            this.toastrSrv.show('Failed to generate the shared link', 'Share', {
                disableTimeOut: false,
                tapToDismiss: true,
                toastClass: 'toast border-red',
                closeButton: false,
            });
        }
    }

    // copy link to share profile page
    onCopyText(name: any, id: string) {
        if (this.type === 'user') {
            return this.shareUserProfile(name, id);
        }

        if (this.type === 'startup') {
            return this.shareOrgProfile(name, id);
        }

        if (this.type === 'consult') {
            return this.shareOrgProfile(name, id);
        }

        if (this.type === 'lab') {
            return this.shareOrgProfile(name, id);
        }
    }

    // edit profile page
    onEdit() {
        if (this.type === 'user') {
            if (this.editProfile) this.editProfile.emit();
            else this.goToEditProfile();
            return;
        }
        this.goToPage(this.type);

        // /*     profile-header -> onEdit() edit startup profile */
        // if (this.type === 'startup') {
        //   this.goToPage(this.type);
        //   return;
        // }

        // /*     profile-header -> onEdit() edit consult profile */
        // if (this.type === 'consult') {
        //   this.goToPage(this.type);
        //   return;
        // }

        // /*     profile-header -> onEdit() edit institution profile */
        // if (this.type === 'lab') {
        //   this.goToPage(this.type);
        //   return;
        // }
    }

    goToPage(path: string) {
        this.router.navigate([`./w/${path}/edit-profile`], {
            queryParams: {
                orgId: this.currentOrgId,
                orgType: this.currentOrgType,
            },
        });
    }

    goToEditProfile() {
        this.router.navigate(['./w/edit'], {
            queryParams: {
                uid: this.viewCopiedUser,
            },
        });
    }
}
