export class ProgramNotification {
    id: string = '';
    commentId: string = '';
    bookingId: string = '';
    uid: string = '';
    title: string = '';
    content: string = '';
    type: number; // 0 = expert comment on deliverables notification, 1 = mentoring session cancelled
    status = 0;
    createdAt: string = '';
    createdBy: string = '';
    updatedAt: string = '';
    updatedBy: string = '';
}
