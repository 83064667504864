import { AppCommonService } from './app-common.service';
import { ConfigService } from './config.service';

import { UtilityService } from './utility.service';
import { ActivityService } from './activity.service';
import { EmailService } from './email.service';
import { ConfirmDialogService } from './confirm-dialog.service';
import { UserService, UserCacheService } from './user.service';
import { DataService } from './data.service';
import {
    OrganizationService,
    OrganizationsCacheService
} from './organization.service';
import { OrganizationBatchService } from './organizationBatch.service';
import { OrganizationBannerService } from './organizationBanner.service';

import { InvitationCodeService } from './invitation-code.service';
import { InstitutionDashboardService } from './institution-dashboard.service';
import { ParntershipService } from './partnership.service';
import { ProductService } from './product.service';
import { PromoCodeService } from './promoCode.service';
import { PaypalService } from './paypal.service';
import { PaymentService } from './payment.service';
import { InstitutionService, ProfileCacheService } from './institution.service';
import { WidgetService } from './widget.service';
import { WidgetGroupService } from './widgetGroup.service';
import { IndividualService } from './individual.service';
import { RoleService } from './role.service';
import { AlertService } from './alert.service';
import { AppSettingsService } from './appSettings.service';
import { UploadFileService } from './uploadFile.service';
import { EatToasterService } from './eattoaster.service';
import { TableService } from './table.service';
import { ToDoRelationshipService } from './toDoRelationship.service';
import { ToDoListService } from './toDo.service';
import { NewActivityService } from './newActivity.service';
import { NotificationService } from './notification.service';
import { VbpService } from './vbp.service';
import { AdminService } from './admin.service';
import { ExploreService } from './explore.service';
import { MenuService } from './menu.service';
import { WidgetSetService } from './widgetSet.service';
import { UserTourService } from './user-tour.service';
import { OrganizationRelationshipService } from './organizationRelationship.service';
import { ResourcesService } from './resources.service';
import { DocViewerService } from './docViewer.service';
import { ProgramActivityService } from './program-activity.service';
import { CheckoutService } from './checkout.service';
import { PreviousRouteService } from './previous-route.service';
import { SocketService } from './socketService.service';
import { WebSocketService } from './web-socket.service';

export const services = [
    MenuService,
    NotificationService,
    AlertService,
    AppSettingsService,
    UploadFileService,
    TableService,
    ToDoListService,
    ToDoRelationshipService,
    EatToasterService,
    WidgetService,
    WidgetGroupService,
    RoleService,
    ParntershipService,
    PaypalService,
    PaymentService,
    ProfileCacheService,
    InstitutionService,
    InvitationCodeService,
    InstitutionDashboardService,
    IndividualService,
    OrganizationService,
    OrganizationsCacheService,
    OrganizationBatchService,
    UserService,
    DataService,
    ConfirmDialogService,
    AppCommonService,
    ConfigService,
    ProductService,
    PromoCodeService,
    UtilityService,
    UserCacheService,
    ActivityService,
    EmailService,
    NewActivityService,
    VbpService,
    AdminService,
    ExploreService,
    WidgetSetService,
    UserTourService,
    OrganizationBannerService,
    OrganizationRelationshipService,
    ResourcesService,
    DocViewerService,
    ProgramActivityService,
    CheckoutService,
    PreviousRouteService,
    SocketService,
    WebSocketService
];

export * from './app-common.service';
export * from './alert.service';
export * from './appSettings.service';
export * from './config.service';
export * from './utility.service';
export * from './activity.service';
export * from './email.service';
export * from './confirm-dialog.service';
export * from './user.service';
export * from './data.service';
export * from './organization.service';
export * from './organizationBatch.service';
export * from './organizationBanner.service';
export * from './socketService.service';
export * from './web-socket.service';

export * from './invitation-code.service';
export * from './institution-dashboard.service';
export * from './individual.service';
export * from './partnership.service';
export * from './product.service';
export * from './promoCode.service';
export * from './institution.service';
export * from './widget.service';
export * from './widgetGroup.service';

export * from './role.service';

export * from './uploadFile.service';
export * from './eattoaster.service';
export * from './table.service';
export * from './toDo.service';
export * from './toDoRelationship.service';
export * from './newActivity.service';
export * from './notification.service';
export * from './paypal.service';
export * from './payment.service';
export * from './vbp.service';

export * from './admin.service';
export * from './explore.service';
export * from './menu.service';
export * from './widgetSet.service';
export * from './user-tour.service';
export * from './organizationRelationship.service';
export * from './resources.service';
export * from './docViewer.service';
export * from './program-activity.service';
export * from './checkout.service';
export * from './previous-route.service';