import {
    Component,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@common/models';

@Component({
    selector: 'business-profile-program',
    templateUrl: './business-profile-program.html',
    styleUrls: ['business-profile-program.scss'],
})
export class BusinessProfileProgramComponent implements OnInit {
    currentUser: any;
    currentOrgId = '';

    constructor(
        private route: ActivatedRoute
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            
            this.currentOrgId = params['orgId'];
        });
    }
}