export class OrganizationBatch {
    id: string;
    organizationId: string;
    organizationTypeId: string;
    userRoleId: string;
    name: string;
    description: string;
    startDate: string;
    demoDate: string;
    uid: string;
}
