export class PersonalStartup {
    id: string;
    userId: string;
    learning = '';
    coaches = '';
    nbCoaches = '';
    reactCoaches = '';
    profXp = '';
    lookingFor = '';
    createdAt = '';
    createdBy = '';
    updatedAt = '';
    updatedBy = '';
}
