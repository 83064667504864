export class UserTour {
    id: string;
    uid: string;
    type: number = 0;
    status: false;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
}
