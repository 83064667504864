export class VbpButton {
  id: string;
  vbpId: string;
  title: string;
  description: string;
  backgroundColour: string;
  imageOriginalName: string;
  imageName: string;
  imagePath: string;
  link: string;
  link2: string;
  vTitle2: string;
  link3: string;
  vTitle3: string;
  link4: string;
  vTitle4: string;
  link5: string;
  vTitle5: string;
  updatedBy: string;
}
