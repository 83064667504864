import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'ui-app-widgets',
  templateUrl: './app-widgets.component.html',
  styleUrls: ['./app-widgets.component.scss'],
})
export class AppWidgetsComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
  }

  handleEdit() {
    console.log('編輯widgets')
  }

  @ViewChild('item')
  item: ElementRef;

}
