import { ActivityBarComponent } from './activity/activity.component';
import { AppWidgetsComponent } from './app-widgets/app-widgets.component';

import { BannerComponent } from './banner/banner.component';
import { BatchBannerComponent } from './batch-banner/batch-banner.component';
import { BatchListComponent } from './batch-list/batch-list.component';
import { BusinessProfileMembersComponent } from './business-profile-members/business-profile-members';
import { MentorProfileMembersComponent } from './mentor-profile-members/mentor-profile-members.component'
import { StartupProfileMembersComponent } from './startup-profile-members/startup-profile-members.component';
import { BatchesComponent } from './batches/batches.component';
import { BusinessProfileProgramComponent } from './business-profile-program/business-profile-program';
import { BatchProfileAboutComponent } from './batch-profile-banner/batch-profile-banner.component';
import { BatchProfileMembersComponent } from './batch-profile-members/batch-profile-members.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DialogWarningComponent } from './dialog-warning/dialog-warning.component';
import { DialogAttachComponent } from './dialog-attach/dialog-attach.component';
import { DialogDelivertableComponent } from './dialog-deliverable/dialog-deliverable.component';
import { DialogCriteriaComponent } from './dialog-criteria/dialog-criteria.component';
import { DialogLinkComponent } from './dialog-link/dialog-link.component';
import { DialogCommentComponent } from './dialog-comment/dialog-comment.component';
import { DialogInputComponent } from './dialog-input/dialog-input.component';
import { DialogSubmissionComponent } from './dialog-submission/dialog-submission.component';
import { DialogEditComponent } from './dialog-edit-business-profile/dialog-edit-business-profile.component';
import { DialogDeleteComponent } from './dialog-delete/dialog-delete.component';
import { DialogAddBatchPlanComponent } from './dialog-add-batch-plan/dialog-add-batch-plan.component';
import { DialogConfirmBatchComponent } from './dialog-confirm-batch/dialog-confirm-batch.component';
import { DialogProcessPaymentComponent } from './dialog-process-payment/dialog-process-payment.component';
import { DialogBuyPlansComponent } from './dialog-buy-plans/dialog-buy-plans.component';
import { DialogAssignPlanComponent } from './dialog-assign-plan/dialog-assign-plan.component';
import { DialogCancelSubscriptionComponent } from './dialog-cancel-subscription/dialog-cancel-subscription.component';
import { DialogCancelCreditsComponent } from './dialog-cancel-credits/dialog-cancel-credits.component';
import { DialogLaunchBatchComponent } from './dialog-launch-batch/dialog-launch-batch.component';
import { DialogEditBatchComponent } from './dialog-edit-batch/dialog-edit-batch.component';
import { DialogStopBatchComponent } from './dialog-stop-batch/dialog-stop-batch.component';
import { DialogCreditCardComponent } from './dialog-credit-card/dialog-credit-card.component';
import { GoogleCalendarComponent } from './google-calendar/google-calendar.component';
import { HeaderComponent } from './header/header.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { LatestBenefitsComponent } from './latest-benefits/latest-benefits.component';
import { ModalBatchListComponent } from './modal-batch-list/modal-batch-list.component';
import { ModalDeliverableListComponent } from './modal-deliverable-list/modal-deliverable-list.component';
import { ModalDeliverableEditListComponent } from './modal-deliverable-edit-list/modal-deliverable-edit-list.component';
import { ModalMentorshipListComponent } from './modal-mentorship-list/modal-mentorship-list.component';
import { ModalOrganizationListComponent } from './modal-organization-list/modal-organization-list.component';
import { ModalProgramDeliverableListComponent } from './modal-program-deliverable-list/modal-program-deliverable-list.component';
import { MembersCardComponent } from './business-profile-members/business-profile-members-card/members-card';
import { ModalUserListComponent } from './modal-user-list/modal-user-list.component';
import { ModalPresentationBlockEditListComponent } from './modal-presentationblock-edit-list/modal-presentationblock-edit-list.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';

import { ProgramComponent } from './programs/programs.component';
import { OrgCardComponent } from './batch-profile-members/batch-org-card/batch-org-card.component';

import { SidebarListAvatarComponent } from './sidebar-list-avatar/sidebar-list-avatar.component';
import { SettingsNavComponent } from './settings-nav/settings-nav.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarCalendarComponent } from './sidebar-calendar/sidebar-calendar.component';
import { TasksComponent } from './tasks/tasks.component';
import { UpcomingEventComponent } from './upcoming-event/upcoming-event.component';
import { ProfileInvolvementsComponent } from './profile-nav/profile-involvements/profile-involvements.component';
import { DialogResourcesComponent } from './dialog-resources/dialog-resources.component';
import { FileInfoComponent } from './file-info/file-info.component';
import { DialogPivotComponent } from '../components/dialog-pivot/dialog-pivot.component';
import { DialogRestartComponent } from './dialog-restart/dialog-restart.component';
import { DialogAbortComponent } from './dialog-abort/dialog-abort.component';
import { DialogUploadComponent } from './dialog-upload/dialog-upload.component';
import { DialogRemoveComponent } from './dialog-remove/dialog-remove.component';
import { DialogSwapOwnerShipComponent } from './dialog-swapOwnership/dialog-swapOwnership.component';
import { DialogDeleteAssociatedComponent } from './dialog-delete-associated/dialog-delete-associated.component';
import { DialogSelectSubmissionComponent } from './dialog-select-submission/dialog-select-submission.component';
import { BillingComponent } from './billing/billing.component';
import { ReceiptListComponent } from './receipt-list/receipt-list.component';
import { CreditReceiptComponent } from './credit-receipt/credit-receipt.component';
import { ModalStudentRegistrationComponent } from './modal-student-registration/modal-student-registration.component';
import { StudentProfileComponent } from './student-profile/student-profile.component';
import { ModalBatchUserListComponent } from './modal-batch-user-list/modal-batch-user-list.component';
import { ParticipantProfileMembersComponent } from './participant-profile-members/participant-profile-members.component';
import { BatchFiltersComponent } from './batch-profile-members/batch-filters/batch-filters.component';
import { TagConsultantUserListComponent } from './tag-consultant-user-list/tag-consultant-user-list.component';

// import { InviteCardComponent } from '../widgets/invite-card/invite-card.component';

export const components = [
    ActivityBarComponent,
    AppWidgetsComponent,
    BatchesComponent,
    BannerComponent,
    BatchBannerComponent,
    BatchListComponent,
    BillingComponent,
    BusinessProfileMembersComponent,
    MentorProfileMembersComponent,
    StartupProfileMembersComponent,
    ParticipantProfileMembersComponent,
    StudentProfileComponent,
    BusinessProfileProgramComponent,
    BatchProfileAboutComponent,
    BatchProfileMembersComponent,
    DialogWarningComponent,
    DialogConfirmBatchComponent,
    DialogProcessPaymentComponent,
    DialogBuyPlansComponent,
    DialogAssignPlanComponent,
    DialogCancelSubscriptionComponent,
    DialogCancelCreditsComponent,
    DialogLaunchBatchComponent,
    DialogEditBatchComponent,
    DialogStopBatchComponent,
    DialogAttachComponent,
    DialogDelivertableComponent,
    DialogCriteriaComponent,
    DialogLinkComponent,
    DialogCommentComponent,
    DialogInputComponent,
    DialogSubmissionComponent,
    DialogRemoveComponent,
    DialogSwapOwnerShipComponent,
    DialogDeleteAssociatedComponent,
    DialogSelectSubmissionComponent,
    ProgramComponent,

    HeaderComponent,
    HeaderBarComponent,
    SidebarComponent,
    SidebarCalendarComponent,

    TasksComponent,
    UpcomingEventComponent,
    LatestBenefitsComponent,

    ProfileNavComponent,
    MembersCardComponent,
    ModalBatchListComponent,
    ModalPresentationBlockEditListComponent,
    ModalUserListComponent,
    ModalBatchUserListComponent,
    TagConsultantUserListComponent,
    ModalDeliverableListComponent,
    ModalDeliverableEditListComponent,
    ModalOrganizationListComponent,
    ModalMentorshipListComponent,
    ModalStudentRegistrationComponent,
    ModalProgramDeliverableListComponent,
    OrgCardComponent,
    NotificationComponent,
    SidebarListAvatarComponent,
    SettingsNavComponent,
    ChangePasswordComponent,
    GoogleCalendarComponent,
    NotificationSettingsComponent,
    DialogEditComponent,
    DialogDeleteComponent,
    DialogCreditCardComponent,
    DialogAddBatchPlanComponent,
    /*  ProfileInvolvementsComponent, */
    DialogResourcesComponent,
    FileInfoComponent,
    DialogPivotComponent,
    DialogRestartComponent,
    DialogAbortComponent,
    DialogUploadComponent,
    ReceiptListComponent,
    CreditReceiptComponent,
    // InviteCardComponent,
    BatchFiltersComponent,
];

export { ActivityBarComponent } from './activity/activity.component';
export * from './banner/banner.component';
export { BatchBannerComponent } from './batch-banner/batch-banner.component';
export { BatchListComponent } from './batch-list/batch-list.component';
export { ProgramComponent } from './programs/programs.component';
export * from './header/header.component';
export { HeaderBarComponent } from './header-bar/header-bar.component';
export * from './sidebar/sidebar.component';
export * from './batches/batches.component';
export * from './tasks/tasks.component';
export * from './upcoming-event/upcoming-event.component';
export * from './latest-benefits/latest-benefits.component';
export * from './app-widgets/app-widgets.component';
export * from './profile-nav/profile-nav.component';
export * from './modal-batch-list/modal-batch-list.component';
export * from './modal-mentorship-list/modal-mentorship-list.component';
export { ModalPresentationBlockEditListComponent } from './modal-presentationblock-edit-list/modal-presentationblock-edit-list.component';

export * from './dialog-warning/dialog-warning.component';
export * from './dialog-attach/dialog-attach.component';
export * from './dialog-deliverable/dialog-deliverable.component';
export * from './dialog-link/dialog-link.component';
export * from './dialog-comment/dialog-comment.component';
export { DialogInputComponent } from './dialog-input/dialog-input.component';
export { DialogSubmissionComponent } from './dialog-submission/dialog-submission.component';
export * from './profile-banner/profile-banner';
export * from './profile-about/profile-about';
export * from './business-profile-members/business-profile-members';
export * from './mentor-profile-members/mentor-profile-members.component';
export * from './startup-profile-members/startup-profile-members.component';
export * from './startup-profile-members/startup-profile-members.component';
export * from './business-profile-members/business-profile-members-card/members-card';
export * from './business-profile-program/business-profile-program';
export * from './modal-user-list/modal-user-list.component';
export * from './modal-organization-list/modal-organization-list.component';
export * from './batch-profile-banner/batch-profile-banner.component';
export * from './batch-profile-members/batch-profile-members.component';
export * from './batch-profile-members/batch-org-card/batch-org-card.component';
export * from './notification/notification.component';
export * from './modal-deliverable-list/modal-deliverable-list.component';
export * from './modal-deliverable-edit-list/modal-deliverable-edit-list.component';
export * from './settings-nav/settings-nav.component';
export * from './change-password/change-password.component';
export * from './google-calendar/google-calendar.component';
export * from './notification-settings/notification-settings.component';
export * from './dialog-edit-business-profile/dialog-edit-business-profile.component';
export { ProfileInvolvementsComponent } from './profile-nav/profile-involvements/profile-involvements.component';
export * from './dialog-delete/dialog-delete.component';
export * from './dialog-credit-card/dialog-credit-card.component';
export * from './dialog-add-batch-plan/dialog-add-batch-plan.component';
export * from './dialog-confirm-batch/dialog-confirm-batch.component';
export * from './dialog-cancel-subscription/dialog-cancel-subscription.component';
export * from './dialog-cancel-credits/dialog-cancel-credits.component';
export * from './dialog-launch-batch/dialog-launch-batch.component';
export * from './dialog-stop-batch/dialog-stop-batch.component';
export * from './dialog-resources/dialog-resources.component';
export * from './file-info/file-info.component';
export * from './billing/billing.component';
export * from './dialog-edit-batch/dialog-edit-batch.component';
export * from './dialog-buy-plans/dialog-buy-plans.component';
export * from './dialog-assign-plan/dialog-assign-plan.component';
export * from './dialog-process-payment/dialog-process-payment.component';
export * from  './receipt-list/receipt-list.component';
export * from './credit-receipt/credit-receipt.component';
// export * from '../widgets/invite-card/invite-card.component'