import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class PromoCodeService {

    constructor(
        private http: HttpClient) {
    }

    getAllPromo() {
        return this.http.get(`${environment.apiUrl}/promoCode/getAllPromo`,
            {
            });
    }

    async getPromoById(id) {
        return await this.http.post(`${environment.apiUrl}/promoCode/getPromoById`, {
            id: id
        }).toPromise();
    }

    getPromoByPromoCode(promoCode) {
        return this.http.post(`${environment.apiUrl}/promoCode/getPromoByPromoCode`, {
            promoCode: promoCode
        });
    }

    addNewPromo(promo, uid) {
        return this.http.post(`${environment.apiUrl}/promoCode/addNewPromo`,
            {
                data: promo,
                uid: uid
            });
    }

    updatePromo(promo, uid) {
        return this.http.post(`${environment.apiUrl}/promoCode/updatePromo`,
            {
                data: promo,
                uid: uid
            });
    }

    deletePromo(id) {
        return this.http.post(`${environment.apiUrl}/promoCode/deletePromo`, {
            id: id
        });
    }

    usePromoCode(promo, uid) {
        return this.http.post(`${environment.apiUrl}/promoCode/updatePromo`,
        {
            data: promo,
            uid: uid
        });
    }

}
