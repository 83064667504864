import { Component, ViewChild, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ApexChartOptions } from '../../../models';


@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.css']
})
export class DonutChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  @Input() props: any = undefined;
  @Input() expandOnClickSegment = false;
  @Output() onClickGraph: EventEmitter<any> = new EventEmitter();
  public chartOptions: Partial<ApexChartOptions>;
  constructor() { }
  ngOnInit() {
    this.generateChart(this.props);
  }
  generateChart(_props) {
    let props = _props;
    let percentage = props.percentage;
    let total = props.total;
    let totalValue = props.totalValue;
    let title = props.title;
    let data = props.data;
    let labels = props.labels;
    let colors = props.colors;
    let offset = props.offset;
    let position = props.position;
    let size = props.size;
    let type = props.type;
    let legendShow = props.legendShow;
    let labelsShow = props.labelsShow;
    let onClickGraph = this.onClickGraph;
    let expandOnClickSegment = this.expandOnClickSegment;
    if (data.length === 1) {
      data.push(0);
      labels.push('0');
    }

    this.chartOptions = {
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: '100%',
              height: '150',
            },
            legend: {
              show: legendShow,
            },
            plotOptions: {
              pie: {
                expandOnClick: expandOnClickSegment,
                donut: {
                  labels: {
                    show: labelsShow,
                    name: {
                      fontSize: '10px',
                      fontWeight: 'bold',
                    },
                    value: {
                      fontSize: '15px',
                      fontWeight: 'bold',
                    },
                    total: {
                      show: true,
                      label: title,
                      fontSize: '15px',
                      fontWeight: 'bold',
                    },
                  },
                },
              },
            },
          },
        },
      ],
      series: data,
      chart: {
        fontFamily: 'Nunito, sans-serif',
        width: '100%',
        height: '150',
        type: type,
        sparkline: {
          enabled: true,
        },

        events: {
          dataPointSelection: function (event, chartContext, config) {
            let returnObj = {
              event: event,
              chartContext: chartContext,
              config: config,
              props: props
            };
            onClickGraph.emit(returnObj);
          },
          legendClick: function (chartContext, seriesIndex, config) {
            let returnObj = {
              seriesIndex: seriesIndex,
              chartContext: chartContext,
              config: config,
              props: props
            };
            onClickGraph.emit(returnObj);
          },
        },
      },
      noData: {
        text: 'No Data available',
      },
      colors: colors,
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: expandOnClickSegment,
          donut: {
            // size: '50%',
            labels: {
              show: true,
              name: {
                fontSize: '22px',
                fontWeight: 'bold',
                formatter: function (val: string) {

                  return val.length > 7 ? val.substring(0, 7) + '...' : val;
                  // if (chartType === 0 && val === '') {
                  //   return '';
                  // }
                  // return val;
                },
              },
              value: {
                fontSize: '15px',
                fontWeight: 'bold',

                formatter: function (val: any) {
                  // return val;
                  // if (chartType === 0) {
                  //   return `${String(Math.round((data[0] / 100) * total))}/10`;
                  // }
                  // if (chartType === 1) {
                  //   return `${val}%`;
                  // }
                  val = String(Math.round((val / 100) * total));
                  if (percentage) {
                    return val + '%';
                  }
                  return val;
                },
              },
              total: {
                show: true,
                label: title.length > 7 ? title.substring(0, 7) + '...' : title,
                fontWeight: 'bold',
                formatter: function (w) {
                  // if (chartType === 0) {
                  //   return `${String(Math.round((data[0] / 100) * total))}/10`;
                  // }
                  let val = String(
                    Math.round(
                      (w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) /
                        100) *
                      total
                    )
                  );
                  if (percentage) {
                    return totalValue;
                  }
                  if (total !== totalValue) {
                    return totalValue;

                  }
                  return val;
                },
              },
            },
          },
        },
      },
      labels: labels,
      legend: {
        show: legendShow,
        fontSize: '14px',
        fontWeight: 700,
        position: position,
        offsetY: 0,
        // offsetX: 0,
        labels: {
          useSeriesColors: false,
        },
        // markers: {
        //   // show: false,
        //   width: 0,
        //   height: 0,
        //   strokeWidth: 0,
        //   strokeColor: '#fff',
        //   radius: 0,
        //   customHTML: undefined,
        //   onClick: undefined,
        //   offsetX: 0,
        //   offsetY: 0,
        // },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },

        formatter: function (seriesName, opts) {
          // return (
          // seriesName + ':  ' + `${opts.w.globals.series[opts.seriesIndex]}%`
          // );

          // if (chartType === 1) {
          //   return (
          //     seriesName + ':  ' + `${opts.w.globals.series[opts.seriesIndex]}%`
          //   );
          // }
          let val = (
            seriesName +
            ':  ' +
            String(
              Math.round(
                (opts.w.globals.series[opts.seriesIndex] / 100) * total
              )
            )
          );
          if (percentage) {
            return val + '%';
          }

          return val;
        },
        itemMargin: {},
      },
    };
  }

}
