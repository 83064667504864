export class OrganizationInfo {
    id: string;
    name: string;
    website: string;
    facebook: string;
    instagram: string;
    linkedin: string;
    locations: string;
    funding: string;
    companySize: string;
    industry: string;
    description: string;
    markets: string;
}
