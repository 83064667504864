import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'lab-layout-website',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './layout-website.component.html',
    styleUrls: ['layout-website.component.scss'],
})
export class LayoutWebSiteComponent implements OnInit {

    currentUser: any;

    constructor(
        private router: Router,
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        if (this.currentUser !== '{}') {
            // 20220719 todo
            /*    if (this.router.url.indexOf('/w/organization-profile') > 0) {
   
               } else {
                   this.router.navigate(['./w'], {});
               } */
        } else {

        }
    }

    ngOnInit() { }

}
