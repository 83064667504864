import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@common/models';
import { DataService, InstitutionService, OrganizationService } from '@common/services';
import { BatchesService } from '@modules/workspace/services';
import { DocViewerService } from '@common/services';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lab-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class BatchListComponent implements OnInit, OnDestroy {
  updatedBatchStatusSub;
  updatedBatchStatus;
  currentUser;
  showLaunchButton;
  currentUserBatches: string[] = [];
  public _isOwner: boolean;
  public _batches: any[] = [];
  @Input() set batches(objects) {
    if (objects && objects.length > 0) {
      this._batches = objects;
    } else {
      this._batches = [];
    }
  }
  @Input() set isOwner(object) {
    this._isOwner = object;
  }
  @Output() selected = new EventEmitter();
  @Output() launch = new EventEmitter();
  @Output() stop = new EventEmitter();
  

  params: any;

  constructor(
    public batchesService: BatchesService,
    private route: ActivatedRoute,
    private router: Router,
    private institutionSrv: InstitutionService,
    private docSrv: DocViewerService,
    private dataSrv: DataService,
    private orgSrv: OrganizationService
    ) {
    this.currentUser = new User();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

    // get page's params
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

    this.institutionSrv.getInstitutionOwnerByUserId(this.currentUser.id).pipe(take(1)).subscribe(res => {
      if (res['result'] === 'successful') {
        this.showLaunchButton = res['data'].some(institute => {
          if (institute.id == this.params['orgId']) {
            return true
          }
          return false;
        })
      }
    }, () => {

    }, () => {

      // if (this.currentUser?.IsAdmin == 1) {
      //   this.showLaunchButton = true;
      //   console.log('this.showLaunchButton===', this.showLaunchButton)
      // }
    });
  }

  ngOnInit() {
    this.updatedBatchStatusSub = this.dataSrv.getUpdatedBatchStatus().subscribe(res => {
      this.updatedBatchStatus = res
      if (Object.keys(this.updatedBatchStatus).length > 0) {
        if (this.updatedBatchStatus.status === 'stop') {
          if (this._batches.length > 0) {
            this._batches = this._batches.map(batch => (batch.id === this.updatedBatchStatus.batchId ? {
              ...batch, plan_id: '0', credit_id : '0'
            } : batch))
          }
        }
        if (this.updatedBatchStatus.status === 'running') {
          if (this._batches.length > 0) {
            this._batches = this._batches.map(batch => (batch.id === this.updatedBatchStatus.batchId ? {
              ...batch, plan_id: this.updatedBatchStatus.planId, credit_id : this.updatedBatchStatus.creditId
            } : batch))
          }
        }
        if (this.updatedBatchStatus.status === 'notYetRunning') {
          if (this._batches.length > 0) {
            this._batches = this._batches.map(batch => (batch.id === this.updatedBatchStatus.batchId ? {
              ...batch, plan_id: this.updatedBatchStatus.planId, credit_id : this.updatedBatchStatus.creditId
            } : batch))
          }
        }
      }
    })

    this.institutionSrv.getBatchByUserid(this.currentUser.id).then(res => {
      if (res['result'] === 'successful') {
        let resultArr = res['data']; 
        resultArr.forEach((batch) => {
          this.currentUserBatches.push(batch.id);
        });
      }
    }).catch((error) => {
      console.error('getBatchWidgetByuid :', error);
    });
  }

  ngOnDestroy(): void {
    this.updatedBatchStatusSub.unsubscribe();
  }

  handleSelected(event, item: any) {
    // make sure close program resources preview when go to batch
    this.docSrv.docViewStatus(false);
    this.selected.emit(item)
    if (this.currentUserBatches.includes(item.id)) {

      this.selected.emit(item);
    }

  }

  handleLaunch(batchData, batchObj) {
    this.launch.emit(batchObj);
  }

  handleStop(batchData, batchObj) {
    this.stop.emit(batchObj);
  }

  createBatchLink() {
    this.router.navigate(['/w/create-program'], {
      queryParamsHandling: 'preserve'
    });
  }

  billingLink() {

    this.dataSrv.onChangeSettingTab(4);
    this.dataSrv.onChangeBillingOrgId(this.params['orgId'])

    this.router.navigate(['/w/settings/billing'], {
      queryParamsHandling: 'preserve'
    })
  }

  redirectToSubscriptionPage() {
    this.router.navigate(['w/subscription-plans'], {
      queryParamsHandling: 'preserve'
    })
  }

  yourCustomAuthorizedFunction() {

  }

}
