import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lab-dialog-cancel-credits',
  templateUrl: './dialog-cancel-credits.component.html',
  styleUrls: ['./dialog-cancel-credits.component.scss']
})
export class DialogCancelCreditsComponent implements OnInit {
  @Input() show: string;
  @Input() batch;
  @Input() desc: string;
  @Input() type: string;
  @Input() subscription;

  @Output() close = new EventEmitter();
  @Output() deleteCredit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.close.emit();
  }

  onSubmit() {
    this.deleteCredit.emit({
      batch: this.batch,
      subscription: this.subscription
    });
  }

}
