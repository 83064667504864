import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  ResResult
} from '../models';
import { environment } from '../../../environments/environment';


@Injectable()
export class OrganizationRelationshipService {

  constructor(
    private http: HttpClient
  ) {
  }

  async getAll() {
    const res = await this.http.get<ResResult>(`${environment.apiUrl}/organizationRelationship/getAll`, {}).toPromise();
    return res;
  }

  insert(matchingId: string,
    requesterOrgId: string,
    requesterOrgTypeId: string,
    matchedOrgId: string,
    matchedOrgTypeId: string,
    uid: string
  ) {
    const res = this.http.post(`${environment.apiUrl}/organizationRelationship/insert`, {
      matchingId: matchingId,
      requesterOrgId: requesterOrgId,
      requesterOrgTypeId: requesterOrgTypeId,
      matchedOrgId: matchedOrgId,
      matchedOrgTypeId: matchedOrgTypeId,
      deleted: 0,
      uid: uid
    });

    return res;
  }

  getMatchedOrg(orgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getDataByorgId`,
      { orgId: orgId });
  }

  checkIfOrganizationRelationshipExist(requesterOrgId, matchedOrgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getDataByrequesterOrgIdNmatchedOrgId`,
      { requesterOrgId: requesterOrgId, matchedOrgId: matchedOrgId });
  }

  getDataByOrgIdSorted(orgId, currentUid, currentOrgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getDataByOrgIdSorted`,
      { orgId: orgId, currentUid: currentUid, currentOrgId: currentOrgId });
  }

  // get  all organizations which have relationship with orgId
  getRelationshipOrgByOrgId(orgId) {
    return this.http.post<any[]>(`${environment.apiUrl}/organizationRelationship/getDataByOrgIdSorted`,
      { orgId });
  }

  getMatchedProjects(orgId, currentUid, currentOrgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getMatchedProjects`,
      { orgId: orgId, currentUid: currentUid, currentOrgId: currentOrgId });
  }

  getMatchedFunders(orgId, currentUid, currentOrgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getMatchedFunders`,
      { orgId: orgId, currentUid: currentUid, currentOrgId: currentOrgId });
  }

  getMatchedExperts(orgId, currentUid, currentOrgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getMatchedExperts`,
      { orgId: orgId, currentUid: currentUid, currentOrgId: currentOrgId });
  }

  getAllMatchedOrgOwner(orgId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/getAllMatchedOrgOwner`,
      { orgId: orgId });
  }

  deleteOrgRelationship(relationshipId) {
    return this.http.post(`${environment.apiUrl}/organizationRelationship/updateOrgRelationshipStatus`,
      { relationshipId: relationshipId });
  }

}
