import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  InstitutionService,
} from '@common/services';
@Component({
  selector: 'lab-dialog-input',
  templateUrl: './dialog-input.component.html',
  styleUrls: ['./dialog-input.component.scss']
})
export class DialogInputComponent implements OnInit {
  @Input() title: string = '';
  @Input() field: string = '';
  @Input() value: string = '';
  @Input() show: string;

  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();
  submissionTypes: any;
  constructor(private institutionSrv: InstitutionService,) { }

  ngOnInit() {
    this.institutionSrv.getInstitutionType().then(
      res => {
        if (res.result === 'successful') {

          if (res["submissionType"]) {
            this.submissionTypes = res["submissionType"];
          }
        }
      }
    ).catch(error => {
      console.log('getInstitutionType Error: ', error)
    });;
  }

  onClick() {
    this.close.emit();
  }

  onSubmit(value: string) {

    let _submissionTypeId = this.GetSubmissionTypeId('na');
    this.submit.emit({
      name: this.value,
      typeId: _submissionTypeId,
      type: "NA"
    });
    this.value = "";
  }

  onChange(e: any) {
    this.value = e.target.value
  }

  GetSubmissionTypeId(value) {
    try {
      let _types = this.submissionTypes.filter(type => type['keyname'] == value);
      if (_types.length > 0) {
        return _types[0]['id'];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

}
