import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'consultantFilter',
})
export class ConsultantFilterPipe implements PipeTransform {
    transform(items: any[], filterPendingConsultants: boolean = false, projectsAssigned : boolean = false, projectsNotAssigned : boolean = false): any[] {
        if (!items) {
            return [];
        }

        return items.filter((user) => {
            let exists: boolean = false;
 
            //Consultants filter
            if (filterPendingConsultants && user.status === "inviting") {
                exists = true;
            }
            if (user.status !== "inviting" && projectsAssigned && (user.userCount || user.participants_count)) {
                exists = true;
            }
            if (user.status !== "inviting" && projectsNotAssigned && !user.userCount && !user.participants_count) {
                exists = true;
            }

            return exists;
        });
    }
}
