export class Organization {
    id: string;
    organizationId: string;
    organizationTypeId: string;
    title: string;
    titleOthers: string;
    address1: string;
    address2: string;
    postalCode: string;
    uen: string;
    name: string;
    website: string;
    companyEmail: string;
    companyNumber: string;
    linkedin: string;
    facebook: string;
    instagram: string;
    approve = 1;
    country: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    type: number; // 0 = startup || 1 = funder || 2 = institution || 3 = professional services
    deleted = 0;
    compLogo = '';
    roleId: number;
    industry: string;
    industryOthers: string;
    companySize: number;
    tagline: string;
    description: string;
    uenVerified = false;
    contactPerson: string;
    contactPersonNumber: string;
    pastOrg: string;
    pastPosition: string;
    yearOfExp: string;
    bio: string;
    markets: string;
    twitter: string;
    medium: string;
    locations: string;
    companystructureLogo = '';
    owner: string;
    mentoring = false;
}