import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

@Component({
  selector: 'lab-dialog-assign-plan',
  templateUrl: './dialog-assign-plan.component.html',
  styleUrls: ['./dialog-assign-plan.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogAssignPlanComponent implements OnInit {
  @Input() show: string;
  @Input() batch;
  @Input() plans;
  toastRef;
  selected: any = {};
  isSelected: boolean = false;
  step = 1;
  batchStatusForm: FormGroup;
  submitted:boolean = false;
  typeProperty: number;
  extendEndDate;
  @Output() close = new EventEmitter();
  @Output() assignPlan = new EventEmitter();
  @Output() redirectToPurchasePage = new EventEmitter();

  constructor(
    private toastr: ToastrService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.batchStatusForm = this.fb.group({
      batchStatus: ['', Validators.required]
    });
  }


  get f() {
    return this.batchStatusForm.controls;
  }

  onSelected(event) {
    this.selected = event;
    this.isSelected = true
  }

  onNext() {
    var startDate = this.batch.startDate;
      var endDate = this.batch.demoDate;

      var startDateMoment = moment(Number(startDate));
      var endDateMoment = moment(Number(endDate));

      var diff = Math.ceil(endDateMoment.diff(startDateMoment, 'month', true));
    if (Object.keys(this.selected).length === 0) {
      this.showActionMsg('Failed', `Please select a batch`);
      return;
    }

    if (this.selected.duration != 0) {
      

      // if (diff > this.selected.duration) {
      //   this.showActionMsg('Fail', `The duration of the batch is longer than the duration of the credit pack`);
      //   return;
      // }
    }
    const remainingCredits = this.selected.totalCredit - this.selected.creditUsed;
    
    if (remainingCredits > 0 && this.batch.batchStatus === 2 && this.selected.planId.includes('pi_')) {
      this.step = 1.5;
      this.extendEndDate = moment().add(this.selected.duration, 'months').format("D MMMM YYYY");
    } else if (remainingCredits > 0) {
      this.step = 2;
    } else {
      this.showActionMsg('Failed', `There are no more credits`)
    }
  }

  onNext2() {
    this.step = 2;
  }

  onPrev() {
    this.step = 1 ;
  }

  onClose() {
    this.close.emit();
  }

  onPurchase() {
    this.redirectToPurchasePage.emit();
  }

  onSubmit() {
    this.submitted = true;
    if (this.f.batchStatus.status === 'INVALID') {
      return;
    }
    if (this.f.batchStatus.status === 'VALID') {
      this.assignPlan.emit({
      batch: this.batch,
      selectedData: this.selected,
      status: this.batchStatusForm.get('batchStatus')?.value
    });
    }
    
  }

  showActionMsg(title, msg) {
    this.toastRef = this.toastr.show(msg, title, {
      tapToDismiss: true,
      toastClass: 'toastpayment ',
      titleClass: 'green-title',
      closeButton: false,
      timeOut: 5000
    });
  }
}
