import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ParntershipService {

    constructor(
        private http: HttpClient) {
    }

    getAllData() {
        return this.http.get(`${environment.apiUrl}/partnership/getAll`);
    }

    getDisplayPartners() {
        return this.http.get(`${environment.apiUrl}/partnership/getDisplayPartners`);
    }

    getPartnersShipTags() {
        return this.http.get(`${environment.apiUrl}/partnership/getPartnersShipTags`);
    }

    insertPartner(partner, uid) {
        return this.http.post(`${environment.apiUrl}/partnership/insertPartner`,
            { partner, uid });
    }

    updatePartner(partner, uid) {
        return this.http.post(`${environment.apiUrl}/partnership/updatePartner`,
            { partner, uid });
    }

    deletePartner(id, uid) {
        return this.http.post(`${environment.apiUrl}/partnership/deletePartner`,
            { id, uid });
    }

    updateAvailableOfPartnersShip(id, available, uid) {
        return this.http.post(`${environment.apiUrl}/partnership/updateAvailableOfPartnersShip`,
            { id, available, uid });
    }


}
