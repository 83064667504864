import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';

import {
  AppSettingsService,
  OrganizationService,
  UtilityService
} from '@common/services';

@Component({
  selector: 'lab-profile-about',
  templateUrl: './profile-about.component.html',
  styleUrls: ['./profile-about.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileAboutComponent implements OnInit {
  @Input() intro?: any;
  @Input() info?: any;

  @Input() email;
  @Input() website;
  @Input() facebook;
  @Input() instagram;
  @Input() twitter;
  @Input() linkedin;
  @Input() country;
  @Input() size;
  @Input() industry;

  countriesOptions: any;
  // country = '';
  companySize = '';
  // industry: string = '';
  location: string = '';

  radioBoundProperty: number;

  constructor(
    private appSettingsSrv: AppSettingsService,
    private orgSrv: OrganizationService,
    private utility: UtilityService,
  ) {
    // this.appSettingsSrv.getCountryOptions().subscribe(data => {
    //   this.countriesOptions = data;
    //   // console.log('profile-about -> appSettingsSrv() ===================', this.utility.IsNullOrEmpty(this.info.location));

    //   if (!this.utility.IsNullOrEmpty(this.info.location)) {
    //     this.location = this.info.location;
    //   } else {
    //     this.location = ''
    //   }
    // }, error => {
    //   console.log(error);
    // });
    // this.countriesOptions = this.appSettingsSrv.countryOptions();
  }

  ngOnInit(): void {


    // this.industry = this.info.industry || '';

    this.companySize = this.info.size ? this.orgSrv.GetDisplayCompanySize(this.info.size, '') : '';

  }

  onGetCountryName(value: string) {
    const found = this.countriesOptions.find(element => element.value == value);

    this.location = found ? found['text'] : '';
  }
}
