export class PersonalQuizzes {
    id: string;
    userId: string;
    q1 = 0;
    q2 = 0;
    q3 = 0;
    q4 = 0;
    q5 = 0;
    q6 = 0;
    q7 = 0;
    q8 = 0;
    q9 = 0;
    q10 = 0;
    q11 = 0;
    q12 = 0;
    q13 = 0;
    q14 = 0;
    q15 = 0;
    q16 = 0;
    deleted = 0;
    createdAt = '';
    createdBy = '';
    updatedAt = '';
    updatedBy = '';
}
