export class Discussion {
  id: string;
  discussionId: string;
  title: string;
  description: string;
  organizationId: string;
  venue: string;
  time: any;
  createdBy: string;
  createdByUid: string;
  createdAt: string;
  updatedBy: string;
  updatedByUid: string;
  updatedAt: string;
  users = '';
  comments: any;
  deleted = false;
  numOfUser = 0;
  numOfResponse = 0;
  batchId: string;
}

