import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'projectFilter',
})
export class ProjectFilterPipe implements PipeTransform {
    transform(items: any[], filterConsultantTagged: boolean = false, filterConsultantNotTagged: boolean = false): any[] {
        if (!items) {
            return [];
        }

        return items.filter((user) => {
            let exists: boolean = false;

            //project teams
            if (filterConsultantTagged && user.consultant_count > 0) {
                exists = true;
            }
            if ((filterConsultantNotTagged && user.consultant_count == 0)  || (filterConsultantNotTagged && user.consultant_count == null)) {
                exists = true;
            }

            return exists;
        });
    }
}
