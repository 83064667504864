export class CoFounder {
    id = '';
    userId = '';
    location = '';
    companyStage = '';
    sectors = '';
    sectorsOthers = '';
    appPertain = '';
    appPertainOthers = '';
    endUsers = '';
    endUsersOthers = '';
    regions = '';
    supportProgram = '';
    supportProgramOthers = '';
    workIn = '';
    companyEarn = '';
    noOfCoFounder = '';
    sizeOfCompany = '';
    roles = '';
    rolesOthers = '';
    office = '';
    fundingStage = '';
    fundingStageOthers = '';
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}
