import {
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
} from '@angular/core';
import {
    ConfigService,
    DataService,
    ToDoListService,
    ToDoRelationshipService,
} from '@common/services';
import { AuthenticationService } from '@modules/auth/services';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Component({
    selector: 'lab-task-info',
    templateUrl: './task-info.component.html',
    styleUrls: ['./task-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskInfoComponent implements OnInit {
    @Input() class?: string;
    @Input() data: any;

    showComments: boolean = false;
    isOpenComments: boolean = true;
    currentUser: any;
    status: string;
    comments: any[];
    assetUrl: string;
    assignees: any = [];
    assigneesLoaded: Observable<boolean>;
    isLoading = false;
    currentBatch;

    constructor(
        private todoSrv: ToDoListService,
        private authSrv: AuthenticationService,
        private globals: ConfigService,
        private todoRelationshipSrv: ToDoRelationshipService,
        private dataSrv: DataService,
        private cdr: ChangeDetectorRef,
        private toastr: ToastrService,
    ) {
        this.currentUser = this.authSrv.getUserData();
        this.assetUrl = this.globals.defaultAssetUrl;
    }

    ngOnInit() {
        this.currentBatch = JSON.parse(localStorage.getItem('currentBatch') || '{}');

        this.dataSrv.openTaskDialog.pipe(debounceTime(500)).subscribe(
            (res) => {
                if (res[0] === true) {
                    this.getAssignees(this.data.toDoId);
                    this.getStatus(res[1]['status']);
                }
            },
            (error) => {
                console.log(error);
            },
            () => {
                this.cdr.detectChanges();
            }
        );
    }

    getStatus(status: number) {
        if (status === 0) this.status = 'New';
        if (status === 1) this.status = 'In Progress';
        if (status === 2) this.status = 'Completed';
        if (status === 3) this.status = 'Incomplete';
    }

    // getComments(toDoId) {
    //   this.comments = [];

    //   this.todoSrv.getCommentByToDoId(toDoId).subscribe(data => {
    //     this.comments = data['data'];

    //   }, (error) => {
    //     console.error('getCommentByToDoId :', error);
    //   });
    // }

    getAssignees(toDoId) {
        this.todoRelationshipSrv.getAssigneesByToDoId(toDoId).subscribe(
            (res) => {
                if (res['result'] === 'successful') {
                    this.assignees = res['data'];
                }
            },
            (error) => {
                console.error(error);
            },
            () => {
                this.cdr.detectChanges();
            }
        );
    }

    redirectUrlNewTab(html: string): any {
        html = html.replace(/<a /g, '<a target="_blank" ');
        return html;
    }

    onEdit() {
        if (this.currentBatch.batchStatus === 2) {
            this.toastr.show('Batch has already expired, Edit Task feature has been disabled', '', {
              disableTimeOut: false,
              tapToDismiss: true,
              toastClass: 'toast border-red',
              closeButton: false,
            });
            return;
          }

        this.dataSrv.openEditTaskModalDialog([true, this.data]);
    }
}
