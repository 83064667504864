import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  HostListener,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges
} from '@angular/core';

@Component({

  selector: 'ui-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectorComponent implements OnInit {
  isExpanded: boolean = false;
  _selected: any;

  @Input() set selected(object) {
    this._selected = object;
    this.selectedOption.emit(object);
  }

  @Input() class?: string;
  _options: any[] = [
  ]
  @Input() set options(object) {
    if (object) {
      if (object.length > 0) {
        this._selected = object[0];
        this.onSelected(object[0]);
      }
      this._options = object;
    }
  };


  @ViewChild('input') inputRef: ElementRef;

  @HostListener('document:click', ['$event'])
  clickedOut(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) return
    if (this.isExpanded) return this.isExpanded = !this.isExpanded;
  }



  @Input() batch;
  @Output() getBatchData = new EventEmitter<any>();
  @Output() selectedOption = new EventEmitter<any>();

  constructor(private eRef: ElementRef
    , private cdr: ChangeDetectorRef) { }

  ngOnInit() {


  }

  onToggleExpanded() {
    this.isExpanded = !this.isExpanded;
  }

  onSelected(option) {
    this._selected = option;
    this.selectedOption.emit(option);
    this.isExpanded = !this.isExpanded;
    this.cdr.detectChanges();
  }

  onSelectedV2(option: string, batchId) {
    this._selected = option;
    this.onGetBatchData(batchId);
  }

  onGetBatchData(batchId) {
    this.getBatchData.emit(batchId);
  }

}
