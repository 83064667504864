export class Event {
    id: string;
    title: string;
    eventDate: number;
    startTime: any;
    endTime: any;
    location: string;
    banner: string;
    link: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    deleted = false;
}

