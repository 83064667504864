import { Injectable } from "@angular/core";
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class SocketService {
    private socket: Socket;

    constructor(){}

    // connect to Socket.io server
    connect(): void {
        this.socket = io(environment.apiUrl);
    }

    disconnect(): void {
        if (this.socket) this.socket.disconnect();
    }

    // send event
    emit(eventName: string, data?: any): void { 
        if (this.socket) this.socket.emit(eventName, data);
    }

    // register and observable event
    on(eventName: string): Observable<any> {
        return new Observable<any>((observer => 
            {
                if (this.socket) this.socket.on(eventName, (data: any) => observer.next(data));
            }
        ))
    }

}