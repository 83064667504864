import { Injectable } from '@angular/core';

import {
  ResResult
} from '../models';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class NotificationService {

  constructor(private http: HttpClient) {

  }

  async getNotificationByUid(uid: string) {
    const params = new HttpParams().set('uid', uid);
    return await this.http.get<ResResult>(`${environment.apiUrl}/notification/getReceiverDataByUid`
      , { params: params }).toPromise();
  }

  matchStatus(value) {
    return this.http.post<any>(`${environment.apiUrl}/notification/matchStatus`, value);
  }

  sendCommentNotification(data, uid) {
    return this.http.post(`${environment.apiUrl}/notification/sendCommentNotification`, {
      data: data,
      uid: uid
    });
  }

  getPostNotificationByUid(uid) {
    return this.http.post(`${environment.apiUrl}/notification/getPostNotificationByUid`, {
      uid: uid,
    });
  }

  updatePostNotificationStatus(notification) {
    return this.http.post(`${environment.apiUrl}/notification/updatePostNotificationStatus`,
      {
        data: notification
      });
  }

  getAllInvitationNotificationByUid(uid) {
    return this.http.post(`${environment.apiUrl}/notification/getAllInvitationNotificationByUid`, {
      uid: uid,
    });
  }

  sendProgramCommentNotification(data, uid, email, name) {
    return this.http.post(`${environment.apiUrl}/notification/sendProgramCommentNotification`, {
      data: data,
      uid: uid,
      email: email,
      name: name
    });
  }

  sendTaskCommentNotification(data, uid, email, name) {
    return this.http.post(`${environment.apiUrl}/notification/sendTaskCommentNotification`, {
      data: data,
      uid: uid,
      email: email,
      name: name
    });
  }

  getProgramCommentNotificationByUid(uid) {
    return this.http.post(`${environment.apiUrl}/notification/getProgramCommentNotificationByUid`, {
      uid: uid,
    });
  }

  updateProgramCommentNotificationStatus(notification) {
    return this.http.post(`${environment.apiUrl}/notification/updateProgramCommentNotificationStatus`,
      {
        data: notification
      });
  }

  async cancelBookingNotification(notification) {
    return await this.http.post(`${environment.apiUrl}/notification/cancelBookingNotification`,
      {
        data: notification
      }).toPromise();
  }

  async newBookingNotification(notification) {
    return await this.http.post(`${environment.apiUrl}/notification/newBookingNotification`,
      {
        data: notification
      }).toPromise();
  }

  sendRestartNotification(notification) {
    return this.http.post(`${environment.apiUrl}/notification/sendRestartNotification`,
      {
        data: notification
      });
  }

}
