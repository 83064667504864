export class Individual {
    id: string;
    uid: string;
    fullname: string;
    dob: string;
    gender: string;
    email: string;
    contactNo: string;
    linkedin: string;
    nationality: string;
    country: string;
    cv: string = '';
    cvId: string = '';
    hasStartupForm = false;
    hasAdvisorForm = false;
    hasInvestorForm = false;
    hasServiceProviderForm = false;
    hasIndividualForm = true;
    hasCompanyProfile: boolean;

    //new properties for individual
    //profile
    website: string;

    //profile2
    overview: string;
    bio: string;
    skills: string;
    markets: string;

    //profile3
    facebook: string;
    twitter: string;
    medium: string;
    instagram: string;

    createdIndividualAt: string;
    createdIndividualBy: string;
    updatedIndividualAt: string;
    updatedIndividualBy: string;
}
