import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionsComponent implements OnInit {

  @Input() class?: string;

  constructor() { }

  ngOnInit() {
  }

}
