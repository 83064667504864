import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
    ConfigService,
    DataService,
    EatToasterService,
    InstitutionService,
    OrganizationRelationshipService,
    OrganizationService,
    RoleService,
    ToDoListService,
    UtilityService,
    WidgetService,
    WidgetSetService
} from '@common/services';
import { formatDate } from '@angular/common';
import { User, TodoItem } from '@common/models';

@Component({
    selector: 'lab-activity-bar',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.scss']
})
export class ActivityBarComponent implements OnInit {
    @Input() notification: any;
    @Input() notifType: any; //1: Invitation, 2: Todo (Task), 3: Community Post Comment, 4: program notification, 5: Invitation
    @Input() when: any;
    @Input() index: any;
    @Output() dismissNotif = new EventEmitter();
    @Input() logo;
    @Input() name;

    currentUser: User;
    funderId;
    expertId;
    institutionId;
    IsExecuting = false;
    userRole: Array<any> = [];
    standardUserRole: Array<any> = [];
    waitingToRoute: boolean = false;

    constructor(
        private toasterSrv: EatToasterService,
        private dataSrv: DataService,
        private orgSrv: OrganizationService,
        private roleSrv: RoleService,
        private orgRelationshipSrv: OrganizationRelationshipService,
        private insSrv: InstitutionService,
        private widgetSrv: WidgetService,
        private widgetSetSrv: WidgetSetService,
        private todoSrv: ToDoListService,
        private router: Router,
        public utility: UtilityService,
        public globals: ConfigService,
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.dataSrv.getAsyncSubject().subscribe(
            (value: any) => {
                this.funderId = value.funderId;
                this.expertId = value.expertId;
                this.institutionId = value.institutionId;
            },
            (error) => console.log(error),
            () => { }
        );
    }

    ngOnInit() {
    }

    onDismissNotif() {
        this.dismissNotif.emit({ notifType: this.notifType, when: this.when, index: this.index, notification: this.notification });
    }

    join(invitation) {
        this.waitingToRoute = true;
        // update invitation status - 0 new - 1 an action has been done
        if (invitation.status === 0) {
            invitation.status = 1;
            this.dataSrv.updateNotificationStatus(invitation).subscribe(data => {
                if (data['result'] === 'successful') {
                    this.dataSrv.updateNumberOfNotifcation(true);
                }
            });
        }

        try {
            if (invitation.requesterOrgId !== ''
                && this.currentUser.id !== ''
                && invitation.invitationType !== ''
                && !this.IsExecuting) {
                // 0 invited as a user
                if (invitation.invitationType === '0') {
                    let orgTypeId = '';
                    let organization: any;
                    this.orgSrv.getOrgAndOrgTypeByOrgId(invitation.requesterOrgId).subscribe(orgData => {

                        orgTypeId = orgData['data'][0].organizationTypeId;
                        organization = orgData['data'][0];

                        let roleId = 9; // projectstaff
                        if (orgTypeId === this.funderId) {
                            roleId = 10; // funderstaff
                        } else if (orgTypeId === this.institutionId) {
                            roleId = 11; // institutionstaff
                        } else if (orgTypeId === this.expertId) {
                            roleId = 12; // expertstaff
                        }

                        // get invited user userrole
                        this.roleSrv.getUserRoleWUidnRoleName(this.currentUser.id, roleId.toString()).subscribe(userrole => {

                            if (userrole['result'] === 'failed') {
                                this.userRole.push({
                                    userRoleId: '',
                                    userId: this.currentUser.id,
                                    roleID: roleId,
                                    available: 0
                                });

                                this.roleSrv.updateUserRoleByUid2(this.userRole).subscribe(data => {

                                    // add user into organization
                                    this.orgSrv.addUserToOrgUser(this.userRole[0].userRoleId,
                                        invitation.requesterOrgId,
                                        orgTypeId,
                                        '',
                                        '',
                                        this.currentUser.id).subscribe(NewUser => {
                                            if (NewUser['result'] === 'successful') {

                                                const today = new Date;
                                                const todayformated = formatDate(today, 'dd/MM/yyyy', 'en');
                                                let todo = new TodoItem();
                                                let assigner = invitation.content.slice(invitation.content.indexOf('am')
                                                    + 3, invitation.content.indexOf('&'));
                                                let assignee = invitation.content.slice(invitation.content.indexOf('Hello')
                                                    + 6, invitation.content.indexOf(','));
                                                todo.organizationId = invitation.requesterOrgId;
                                                todo.assigner = assigner;
                                                todo.assigneeName = assignee;
                                                todo.assigneeEmail = '';
                                                todo.assigneeUid = this.currentUser.id;

                                                todo.description = 'Sucessfully invited ' + assignee + ' into the company on ' + todayformated + '.';
                                                todo.dueDate = '-';
                                                todo.urgency = '-';
                                                todo.createdBy = this.currentUser.id;
                                                todo.status = 2;
                                                todo.userName = assigner;

                                                this.todoSrv.insert(todo).subscribe(NewData => { });
                                                localStorage.setItem('currentOrg', JSON.stringify(organization));
                                                setTimeout(() => {
                                                    if (roleId === 9) {
                                                        this.standardUserRole.map((role, i) => {
                                                            if (role.roleID === 7) {
                                                                role.available = 1 ? 1 : 0;
                                                            }
                                                        });
                                                        this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                                                        this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                                                        this.waitingToRoute = false;
                                                        this.router.navigate(['./control/EATL-suggest'], {
                                                            queryParams: {
                                                                orgId: invitation.requesterOrgId
                                                            }
                                                        });
                                                    } else if (roleId === 10) {
                                                        this.standardUserRole.map((role, i) => {
                                                            if (role.roleID === 2) {
                                                                role.available = 1 ? 1 : 0;
                                                            }
                                                        });
                                                        this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                                                        this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                                                        this.waitingToRoute = false;
                                                        this.router.navigate(['./control/funder'], {
                                                            queryParams: {
                                                                orgId: invitation.requesterOrgId
                                                            }
                                                        });
                                                    } else if (roleId === 12) {
                                                        this.standardUserRole.map((role, i) => {
                                                            if (role.roleID === 5) {
                                                                role.available = 1 ? 1 : 0;
                                                            }
                                                        });
                                                        this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                                                        this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                                                        this.waitingToRoute = false;
                                                        this.router.navigate(['./control/analytic'], {
                                                            queryParams: {
                                                                orgId: invitation.requesterOrgId
                                                            }
                                                        });
                                                    }
                                                }, 1000);
                                            }
                                        });
                                });
                            } else {
                                this.orgSrv.getOrgUserByOrgIdNUserRoleId(invitation.requesterOrgId, userrole['data'][0].userRoleId).subscribe(orgUser => {

                                    if (orgUser['result'] === 'failed') {
                                        this.orgSrv.addUserToOrgUser(
                                            userrole['data'][0].userRoleId,
                                            invitation.requesterOrgId, orgTypeId,
                                            '',
                                            '',
                                            this.currentUser.id).subscribe(data => {

                                                if (data['result'] === 'successful') {

                                                    const today = new Date;
                                                    const todayformated = formatDate(today, 'dd/MM/yyyy', 'en');
                                                    let todo = new TodoItem();
                                                    let assigner = invitation.content.slice(invitation.content.indexOf('am')
                                                        + 3, invitation.content.indexOf('&'));
                                                    let assignee = invitation.content.slice(invitation.content.indexOf('Hello')
                                                        + 6, invitation.content.indexOf(','));
                                                    todo.organizationId = invitation.requesterOrgId;
                                                    todo.assigner = assigner;
                                                    todo.assigneeName = assignee;
                                                    todo.assigneeEmail = '';
                                                    todo.assigneeUid = this.currentUser.id;

                                                    todo.description = 'Sucessfully invited ' + assignee + ' into the company on ' + todayformated + '.';
                                                    todo.dueDate = '-';
                                                    todo.urgency = '-';
                                                    todo.createdBy = this.currentUser.id;
                                                    todo.status = 2;
                                                    todo.userName = assigner;

                                                    this.todoSrv.insert(todo).subscribe(NewData => { });
                                                    localStorage.setItem('currentOrg', JSON.stringify(organization));
                                                    setTimeout(() => {
                                                        if (roleId === 9) {
                                                            this.standardUserRole.map((role, i) => {
                                                                if (role.roleID === 7) {
                                                                    role.available = 1 ? 1 : 0;
                                                                }
                                                            });
                                                            this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                                                            this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                                                            this.waitingToRoute = false;
                                                            this.router.navigate(['./control/EATL-suggest'], {
                                                                queryParams: {
                                                                    orgId: invitation.requesterOrgId
                                                                }
                                                            });
                                                        } else if (roleId === 10) {
                                                            this.standardUserRole.map((role, i) => {
                                                                if (role.roleID === 2) {
                                                                    role.available = 1 ? 1 : 0;
                                                                }
                                                            });
                                                            this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                                                            this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                                                            this.waitingToRoute = false;
                                                            this.router.navigate(['./control/funder'], {
                                                                queryParams: {
                                                                    orgId: invitation.requesterOrgId
                                                                }
                                                            });
                                                        } else if (roleId === 12) {
                                                            this.standardUserRole.map((role, i) => {
                                                                if (role.roleID === 5) {
                                                                    role.available = 1 ? 1 : 0;
                                                                }
                                                            });
                                                            this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                                                            this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                                                            this.waitingToRoute = false;
                                                            this.router.navigate(['./control/analytic'], {
                                                                queryParams: {
                                                                    orgId: invitation.requesterOrgId
                                                                }
                                                            });
                                                        }
                                                    }, 1000);
                                                }
                                            });
                                    } else {
                                        this.toasterSrv.showToast('You are already a member of this organization!', '', this.toasterSrv.iconClasses.error);
                                    }
                                });
                            }
                        });

                    });

                    // 1 invited as a company
                } else if (invitation.invitationType === '1' || invitation.invitationType === '5') {
                    this.orgRelationshipSrv.checkIfOrganizationRelationshipExist(invitation.requesterOrgId,
                        invitation.invitedOrgId).subscribe(res => {

                            if (res['result'] === 'failed') {
                                this.orgRelationshipSrv.insert('',
                                    invitation.requesterOrgId,
                                    '',
                                    invitation.invitedOrgId,
                                    '',
                                    this.currentUser.id).subscribe(result => {
                                        if (result['result'] === 'successful') {
                                        }
                                    },
                                        err => {
                                            console.error("checkIfOrganizationRelationshipExist", err);
                                        }
                                    );
                            } else {

                            }
                        });
                } else if (invitation.invitationType === '2') { // invite company to batch
                    this.orgSrv.getOrgAndOrgTypeByOrgId(invitation.invitedOrgId).subscribe(data => {
                        if (data['result'] === 'successful') {
                            this.insSrv.getDataBybatchIdNmatchedOrgId(invitation.batchId, invitation.invitedOrgId).subscribe(res => {
                                if (res['result'] === 'failed') {
                                    // tslint:disable-next-line:max-line-length
                                    this.insSrv.addOrgBatchRelationship('', invitation.requesterOrgId, '', invitation.invitedOrgId, '', this.currentUser.id, invitation.batchId, data['data'][0].type).subscribe(
                                        result => {
                                            if (result['result'] === 'successful') {
                                                this.widgetSrv.getWidgetByBatchId(invitation.batchId).subscribe(batchWidget => {
                                                    if (batchWidget['result'] === 'successful') {
                                                        const organizationWidget = {
                                                            organizationId: invitation.invitedOrgId,
                                                            widgetId: batchWidget['data'][0].id,
                                                            organizationTypeId: data['data'][0].organizationTypeId,
                                                            userId: invitation.receiverUid
                                                        };
                                                        this.widgetSetSrv.createOrganizationWidget(organizationWidget).subscribe(
                                                            response => console.log('createOrganizationWidget', response),
                                                            error => console.error(error),
                                                            () => { }
                                                        );
                                                    }
                                                });
                                            }
                                        },
                                        error => console.error(error),
                                        () => { }
                                    );
                                }
                            });
                        }
                    });
                } else if (invitation.invitationType === '3') {
                    let userRoleId = '';
                    let orgTypeId = '';
                    this.roleSrv.getUserRoleWUidnRoleName(this.currentUser.id, '3').subscribe(data => {

                        userRoleId = data[0].userRoleId;
                        this.orgSrv.getOrgById(invitation.requesterOrgId).subscribe(Orgdata => {
                            orgTypeId = Orgdata['data'].organizationTypeId;

                            // add user into organization
                            this.orgSrv.addUserToOrgUser(userRoleId,
                                invitation.requesterOrgId,
                                orgTypeId,
                                '',
                                '',
                                this.currentUser.id).subscribe();
                        });
                    });
                } else if (invitation.invitationType === '4' || invitation.invitationType === '6') { //invite user to batch as one of the organizing committee
                    let organizationTypeId = '';
                    this.orgSrv.getOrgAndOrgTypeByOrgId(invitation.invitedOrgId).subscribe(data => {
                        if (data['result'] === 'successful') {
                            organizationTypeId = data['data'][0].organizationTypeId;
                            let roleId = '';
                            if (invitation.invitationType === '4') {
                                roleId = '13'
                            } else if (invitation.invitationType === '6') {
                                // participant
                                roleId = '15'
                            }
                            const params = {
                                batchId: invitation.batchId,
                                organizationId: invitation.invitedOrgId,
                                organizationTypeId: organizationTypeId,
                                roleID: roleId,
                                userId: this.currentUser.id
                            };
                            this.insSrv.addUserToBatch(params).subscribe(
                                value => {
                                    if (value['result'] === 'successful' && invitation.invitationType === '6') {
                                        this.widgetSrv.getWidgetByBatchId(invitation.batchId).subscribe(batchWidget => {
                                            if (batchWidget['result'] === 'successful') {
                                                const userWidget = {
                                                    uid: this.currentUser.id,
                                                    widgetId: batchWidget['data'][0].id,
                                                };
                                                this.widgetSrv.createUserWidget(userWidget).subscribe(
                                                    response => console.log('createUserWidget', response),
                                                    error => console.error(error),
                                                    () => { }
                                                );
                                            }
                                        });
                                    }
                                },
                                error => console.error("addUserToBatch failed", error),
                                () => {

                                }
                            );
                        }
                    });
                } else {
                    let userRoleId = '';
                    let orgTypeId = '';
                    this.roleSrv.getUserRoleWUidnRoleName(this.currentUser.id, '5').subscribe(data => {

                        userRoleId = data[0].userRoleId;
                        this.orgSrv.getOrgById(invitation.requesterOrgId).subscribe(Orgdata => {
                            orgTypeId = Orgdata['data'].organizationTypeId;

                            // add user into organization
                            this.orgSrv.addUserToOrgUser(userRoleId,
                                invitation.requesterOrgId,
                                orgTypeId,
                                '',
                                '',
                                this.currentUser.id).subscribe();
                        });
                    });
                }
            } else {
                // this.ShowMsg('Organization ID and/or User ID cannot be empty!', 1);
            }
            this.IsExecuting = false;
        } catch (err) {
            this.IsExecuting = false;
            // this.ShowMsg('Something went wrong!', 1);
        }
    }

    isToday(date) {
        var today = new Date().setHours(0, 0, 0, 0);
        var thatDay = new Date(date).setHours(0, 0, 0, 0);

        return today === thatDay;
    }

    timeSince(date) {
        const currentUnixTime = new Date().getTime();
        var seconds = Math.floor((currentUnixTime - date) / 1000);
        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + ' years';
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + ' months';
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + ' days';
        }
        interval = seconds / 3600;
        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' hour';
            } else {
                return Math.floor(interval) + ' hours';
            }
        }
        interval = seconds / 60;
        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' minute';
            } else {
                return Math.floor(interval) + ' minutes';
            }
        }
        return Math.floor(seconds) + ' seconds';
    }

    getTag(value) {
        if (value === 1) {
            return 'System'
        } else if (value === 2) {
            return 'Task'
        } else if (value === 3) {
            return 'Community'
        } else if (value === 4) {
            return 'Program'
        } else if (value === 5) {
            return 'System'
        }
    }

    getBackgroundColor(value) {
        switch (value) {
            case 'System':
                return '#A2A2A2';
            case 'Task':
                return '#FFAC4B';
            case 'Community':
                return '#bba1ff';
            case 'Program':
                return '#13BC6B';
        }
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

}
