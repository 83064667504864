import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';

import {
  FormControl,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';

import { environment } from 'environments/environment';

import {
  InstitutionService,
  ConfirmDialogService,
  EatToasterService,
  OrganizationService,
  NotificationService,
  UtilityService,
  ConfigService,
  UserService
} from "./../../services";
import { programNotification } from './../../models';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-progressItem',
  templateUrl: './progressItem.component.html',
  styleUrls: ['./progressItem.component.css']
})
export class ProgressItemComponent implements OnInit {
  public tags = [];
  @Input() id: string;
  @Input() itemType: string = "1"; //1:project ,2:expert,3: project manager
  @Input() projectTeamIndex: number;
  @Input() modalid: string;
  @Input() currentUser: any;
  @Input() IsMenu: boolean = true;
  @Input() IsShowShare: boolean = false;
  @Input() IsComment: boolean = false;
  @Input() IsRating: boolean = false;

  public _projectTeam: any;
  @Input() set projectTeam(object) {

    if (!!object.comment) {
      object["comment"] = "";
    }

    if (object) {
      this._projectTeam = object;
      console.log('this._projectTeam', this._projectTeam)
    }

    if (this._projectTeam.deliverable) {
      console.log('this._projectTeam.deliverable', this._projectTeam.deliverable)
      this.getPastedRating(this._projectTeam.deliverable, this._projectTeam.orgId);
    }
  }

  @Input() filterTeamProgressData: any;
  @Input() deliverableDetails: any;
  @Input() ratingType = [];
  @Input() deliverables: any;
  @Input() programs: any;
  @Input() isOpen = true;

  toggleSections: Array<string> = ['out', 'out', 'out', 'out', 'out', 'out', 'out'];
  fileUploadErrorMsg: string = "";

  // for Project team
  @Input() submissionInfo: any[] = [];
  @Input() completedInfo: any[] = [];

  @Output() ViewProfileEvent = new EventEmitter<{ id: string, name: string }>();
  callParent(): void {
    this.ViewProfileEvent.next({ id: this.id, name: this._projectTeam });
  }

  @Output() setNotificationEvent = new EventEmitter<{ projectTeam: any }>();
  @Output() setPivotEvent = new EventEmitter<{ projectTeam: any, deliverableData: any }>();
  @Output() setRestartDataEvent = new EventEmitter<{ projectTeam: any, deliverableData: any }>();
  @Output() setDropOutDataEvent = new EventEmitter<{ projectTeam: any }>();
  @Output() openDetailEvent = new EventEmitter<{ deliverableDetails: any }>();
  callDetailEvent(projectTeam, deliverable, deliverableType): void {
    //deliverableType 1: deliverable 2: task  3:pasted deliverable
    // 4: submission 5: rating
    let deliverableDetails = {
      projectTeam: projectTeam,
      deliverable: deliverable,
      deliverableType: deliverableType,
    };

    this.openDetailEvent.next({ deliverableDetails: deliverableDetails });
  }

  prefix = this.configSrv.defaultAssetUrl;
  currentOrgId = '';

  constructor(
    private toasterSrv: EatToasterService,
    private confirmDialogService: ConfirmDialogService,
    private cdr: ChangeDetectorRef,
    private institutionSrv: InstitutionService,
    private utility: UtilityService,
    private configSrv: ConfigService,
    private orgSrv: OrganizationService,
    private notiSrv: NotificationService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.currentOrgId = params['orgId'];

    });
  }

  ngOnInit() {
    console.log('itemType', this.itemType)
  }

  GetCompanyLogo() {
    let _logoPath = this.configSrv.defaultNoCompanyLogo;
    if (this._projectTeam) {
      if (!this.utility.IsNullOrEmpty(this._projectTeam.compLogo)) {
        if (this._projectTeam.compLogo.indexOf('asset') > -1) {
          _logoPath = environment.assetUrl
            + this._projectTeam.compLogo;
        }
      }
    }
    return _logoPath;

  }

  Open() {
    this.isOpen = !this.isOpen;
  }

  onToggleSection(event) {
    this.toggleSections[event.id] = event.action;
    this.cdr.detectChanges();
  }

  setPivotData(projectTeam, submissionDeliverable) {
    let _deliverableData = submissionDeliverable;
    if (!this.utility.IsNullOrEmpty(_deliverableData.length)) {
      let _afterFilterDeliverableData = _deliverableData.filter(value => value.type !== 0);
      this.setPivotEvent.next({ projectTeam: projectTeam, deliverableData: _afterFilterDeliverableData });
    } else {
      this.setPivotEvent.next({ projectTeam: projectTeam, deliverableData: submissionDeliverable });
    }
  }

  setRestartData(projectTeam, submissionDeliverable) {
    this.setRestartDataEvent.next({ projectTeam: projectTeam, deliverableData: submissionDeliverable });
  }

  setDropOutData(projectTeam) {
    this.setDropOutDataEvent.next({ projectTeam: projectTeam });
  }

  createComment(projectTeamId, programId, index) {
    const params = {
      organizationId: projectTeamId,
      programId: programId,
      ratingId: 'null',
      comment: this.filterTeamProgressData[index].comment,
      type: this.filterTeamProgressData[index].commentType ? 1 : 0,
      source: 1,
      status: 'null',
      userId: this.currentUser.id
    };

    this.institutionSrv.createComment(params).subscribe(
      value => {
        if (value['result'] === 'successful') {
          if (this.filterTeamProgressData[index].commentType !== 1 && this.currentOrgId !== projectTeamId) {
            this.orgSrv.getOrgAndOwnerById(projectTeamId).subscribe(res => {
              if (res['result'] === 'successful') {
                let email = res['ownerInfo']['email'];
                let name = res['ownerInfo']['fullname'];
                let newNotification = new programNotification();
                let title = this.currentUser.fullname + ' added a comment to a deliverable check it out now!';
                let content = this.currentUser.fullname + ' added a comment to a deliverable check it out now!';
                newNotification.commentId = value['commentId'];
                newNotification.uid = res['data']['owner'];
                newNotification.title = title;
                newNotification.content = content;
                newNotification.type = 0;
                this.notiSrv.sendProgramCommentNotification(newNotification, this.currentUser.id, email, name).subscribe(notiRes => {
                }, (error) => {
                  console.error("sendProgramCommentNotification :", error);
                });
              } else {

              }
            }, (error) => {
              console.error("getOrgAndOwnerById :", error);
            });
          }
          this.toasterSrv.showToast('comment', 'successfully', 'success');
        } else {
          this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
        }
        this.filterTeamProgressData[index].comment = '';
        this.filterTeamProgressData[index].commentType = 0;

      },
      error => {
        this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
        console.error("createComment", error)
      },
      () => {
        const programIds = this.programs.map(value => value.id);
        this.getComment(programIds);
      }
    );
  }

  getComment(programIds) {
    this.institutionSrv.getCommentData({ programIds: programIds }).subscribe(
      value => {
        this.filterTeamProgressData.map(value => {
          value['comments'] = [];
          value['frozen'] = false;
        });
        value.data.filter(value => value.ratingId === 'null').map(value => {
          this.filterTeamProgressData.map((team, index) => {
            if (value.organizationId === team.orgId && value.programId === team.id) {
              value.comment = value.comment.replace(/(?:\r\n|\r|\n)/g, '<br>');
              team['comments'] = team['comments'].concat(value);
              team['comments'] = team['comments'].sort((a, b) => a.createdAt - b.createdAt);
              if (value.status === 'dropout') {
                this.filterTeamProgressData.map(startup => {
                  if (startup.orgId === team.orgId) {
                    startup['frozen'] = true;
                  }
                })
              }
            }
          });
        });
      },
      error => {
        this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
        console.error('getComment', error)
      }
    );
  }

  updateRating(event, projectTeamId, deliverable, batchId) {

    const rating = {
      organizationId: projectTeamId,
      submission: deliverable.submission ? deliverable.submission : null,
      deliverableId: deliverable.id,
      ratingTypeId: event.target.value,
      userId: this.currentUser.id,
      batchId: batchId
    }

    this.institutionSrv.updateRating(rating).subscribe(
      response => {
        if (response['result'] == 'successful') {
          this.toasterSrv.showToast('updateRating', 'successfully', 'success');
        } else {
          this.toasterSrv.showToast('updateRating', 'Failed', 'error');
        }
      },
      error => {
        this.toasterSrv.showToast('updateRating', 'Failed', 'error');
        console.error('updateRating', error)
      },
      () => this.getRating(this.deliverables)
    );
  }

  // pasted deliverable
  getPastedRating(deliverables, organizationId) {
    try {

      let ids: string[] = [];
      deliverables.forEach(deliverable => {
        ids.push(deliverable.id);
      })
      this.institutionSrv.getPastedRatingData(ids, organizationId).subscribe(res => {
        if (res["result"] === "successful") {
          this._projectTeam.pastedDeliverable = res["data"];
          console.log(this._projectTeam.pastedDeliverable)
          this._projectTeam.pastedDeliverable.map((deliverable, index) => {
            //itemType 1: project team so project team cannot see private comment.
            if (this.itemType !== "1") {
              deliverable['commentCount'] = deliverable.PublicCount;
            } else {
              deliverable['commentCount'] = deliverable.PublicCount + deliverable.PrivateCount;
            }
          })
        }
      }, error => {
        console.error('getPastedRating', error)
      })
    } catch (error) {
      console.error('getPastedRating', error)
    }
  }

  getRating(deliverables) {
    this.institutionSrv.getRatingData({ deliverables: deliverables }).subscribe(
      res => {
        if (res['result'] == "successful") {
          if (!this.utility.IsNullOrEmpty(res) &&
            !this.utility.IsNullOrEmpty(res.data)) {
            res.data.map(value =>
              this.filterTeamProgressData.map(team => {
                if (value.organizationId === team.orgId) {
                  if (team['pastedDeliverable'] === undefined && team.type === 0) {
                    team['pastedDeliverable'] = [];
                  }
                  team.submissionDeliverable.map((deliverable, index) => {
                    if (deliverable.id === value.deliverableId) {
                      if (this.itemType !== "1") {
                        deliverable['commentCount'] = value.PublicCount;
                      } else {
                        deliverable['commentCount'] = value.PublicCount + value.PrivateCount;
                      }

                      deliverable['ratingId'] = value.id;
                      deliverable['ratingTypeId'] = value.ratingTypeId;
                      deliverable['submission'] = value.submission;
                      deliverable['fileName'] = value.fileName;
                      if (this.utility.IsNullOrEmpty(value.ratingTypeId)) {
                        deliverable['rating'] = null;
                      } else {
                        deliverable['rating'] = this.ratingType.filter(type => type['id'] === value.ratingTypeId)[0]['name'];
                      }
                    }
                  });

                  team.ratingDeliverable.map(deliverable => {
                    if (deliverable.id === value.deliverableId) {
                      if (this.itemType !== "1") {
                        deliverable['commentCount'] = value.PublicCount;
                      } else {
                        deliverable['commentCount'] = value.PublicCount + value.PrivateCount;
                        //let commentCount = _projectTeam.comments
                      }
                      deliverable['ratingId'] = value.id;
                      deliverable['ratingTypeId'] = value.ratingTypeId;
                      deliverable['submission'] = value.submission;
                      deliverable['fileName'] = value.fileName;

                      if (this.utility.IsNullOrEmpty(value.ratingTypeId)) {
                        deliverable['rating'] = null;
                      } else {
                        deliverable['rating'] = this.ratingType.filter(type => type['id'] === value.ratingTypeId)[0]['name'];
                      }
                    }
                  });
                }
              })
            );

          }
        } else {
          console.error("getRatingData failed", res);
        }
      },
      error => console.error("getRatingData failed", error),
      () => {
        this.initInfo(this.filterTeamProgressData);
        const programIds = this.programs.map(value => value.id);
        this.getComment(programIds);
      }
    );
  }

  // setCommentType(index) {
  //   if (this.utility.IsNullOrEmpty(this.filterTeamProgressData[index].commentType)) {
  //     this.filterTeamProgressData[index].commentType = 1;
  //   } else {
  //     this.filterTeamProgressData[index].commentType = !this.filterTeamProgressData[index].commentType;
  //   }
  // }

  isPrivate(value) {
    if (value != "1") {
      return true;
    }
    else {
      return false;
    }
  }

  // for project team
  initInfo(ProgressData) {
    if (!this.utility.IsNullOrEmpty(ProgressData)) {
      ProgressData.map(team => {
        const toGroupsSubmission = team.submissionDeliverable.map(entity => {
          return new FormGroup({
            submission: new FormControl(entity.submission === 'null' ? '' : entity.submission, Validators.required)
          });
        });
        let _controls = new FormArray(toGroupsSubmission);
        this.submissionInfo.push(_controls);
      });
    }
  }

  updateSubmission(deliverable, event, organizationId, batchId) {
    const submission = event.target.checked ? 'true' : 'false';
    const params = {
      organizationId: organizationId,
      submission: submission,
      ratingTypeId: deliverable.ratingTypeId,
      deliverableId: deliverable.id,
      userId: this.currentUser.id,
      batchId: batchId
    };

    this.institutionSrv.updateRating(params).subscribe(
      response => {
        if (response['result'] == 'successful') {
          deliverable.submission = submission;
          this.toasterSrv.showToast('updateRating', 'successfully', 'success');
        } else {
          this.toasterSrv.showToast('updateRating', 'Failed', 'error');
        }
      },
      error => {
        this.toasterSrv.showToast('updateRating', 'Failed', 'error');
        console.error("updateField ", error);
      }
    );
  }

  getControl(i: number, index: number, field: string, type: number) {
    try {
      if (type === 0) {
        return this.submissionInfo[i].at(index).get(field) as FormControl;
      } else if (type === 1) {
        return this.completedInfo[i].at(index).get(field) as FormControl;
      }
    } catch (error) {
      console.error("getControl error", error);
    }
  }

  updateField(i: number, index: number, field: string, type: number,
    organizationId: string, batchId: string) {
    const control = this.getControl(i, index, field, type);
    let ratingTypeId = this.filterTeamProgressData[i].submissionDeliverable[index].ratingTypeId;
    if (type === 0) {
      this.filterTeamProgressData[i].submissionDeliverable = this.filterTeamProgressData[i].submissionDeliverable.map((e, i) => {
        if (index === i && control) {
          return {
            ...e,
            [field]: control.value
          };
        }
        return e;
      });

      let submission = "";
      if (!this.utility.IsNullOrEmpty(this.filterTeamProgressData[i].submissionDeliverable[index].submission)) {
        submission = this.filterTeamProgressData[i].submissionDeliverable[index].submission.replace(/\'/g, '\\\'');
      }

      const params = {
        organizationId: organizationId,
        submission: submission,
        ratingTypeId: this.filterTeamProgressData[i].submissionDeliverable[index].ratingTypeId,
        deliverableId: this.filterTeamProgressData[i].submissionDeliverable[index].id,
        userId: this.currentUser.id,
        batchId: batchId
      };

      this.institutionSrv.updateRating(params).subscribe(
        response => {
          if (response['result'] == 'successful') {
            this.toasterSrv.showToast('Upload', 'successfully', 'success');
          } else {
            this.toasterSrv.showToast('Upload', 'Failed', 'error');
          }
        },
        error => {
          this.toasterSrv.showToast('Upload', 'Failed', 'error');
          console.error("updateField ", error);
        },
        () => this.getRating(this.deliverables)
      );
    } else if (type === 1) {
      // study wether remove 
      this.filterTeamProgressData[i].completedDeliverable = this.filterTeamProgressData[i].completedDeliverable.map((e, i) => {
        if (index === i && control) {
          return {
            ...e,
            [field]: control.value
          };
        }
        return e;
      });
      const submission = this.filterTeamProgressData[i].completedDeliverable[index].submission.replace(/\'/g, '\\\'');

      const params = {
        organizationId: organizationId,
        submission: submission,
        ratingTypeId: this.filterTeamProgressData[i].completedDeliverable[index].ratingTypeId,
        deliverableId: this.filterTeamProgressData[i].completedDeliverable[index].id,
        userId: this.currentUser.id,
        batchId: batchId
      };

      this.institutionSrv.updateRating(params).subscribe(
        response => {
          if (response['result'] == 'successful') {
            this.toasterSrv.showToast('Upload', 'successfully', 'success');
          } else {
            this.toasterSrv.showToast('Upload', 'Failed', 'error');
          }
        },
        error => {
          this.toasterSrv.showToast('Upload', 'Failed', 'error');
          console.error("updateField ", error);
        },
        () => this.getRating(this.deliverables)
      );
    }
  }

  fileUpload(files: FileList, deliverable, organizationId) {
    let fileToUpload: any = files.item(0);

    if (fileToUpload.size !== 0) {
      let formData = new FormData();
      formData.append('file', fileToUpload);
      formData.append('organizationId', organizationId);
      formData.append('submission', fileToUpload.name);
      formData.append('ratingTypeId', deliverable.ratingTypeId);
      formData.append('deliverableId', deliverable.id);
      formData.append('userId', this.currentUser.id);
      formData.append('batchId', this._projectTeam.batchId);

      this.institutionSrv.updateFileRating(formData).subscribe(
        response => {
          if (response['result'] == 'successful') {
            deliverable.submission = fileToUpload.name;

            deliverable.fileName = response["data"];
            this.toasterSrv.showToast('Upload', 'upload successfully', 'success');
          } else {
            this.toasterSrv.showToast('Upload', 'upload Failed', 'error');
          }
        },
        error => {
          this.toasterSrv.showToast('Upload', 'Failed!', 'error');
          console.error("fileUpload failed", error);
        }
      );

    }
  }


  deleteRatingFile(event, deliverable, organizationId) {
    try {
      this.confirmDialogService.confirmThis('Are you sure you want to delete this?', () => {
        // Yes clicked
        this.institutionSrv.deleteRatingFile(organizationId, deliverable.id, this.currentUser.id).subscribe(
          response => {
            if (response['result'] == 'successful') {
              deliverable.submission = null;
              deliverable.fileName = null;
              this.toasterSrv.showToast('Upload', 'successfully !', 'success');
            } else {
              this.toasterSrv.showToast('Upload', 'Failed!', 'error');
            }
          },
          error => {
            this.toasterSrv.showToast('Delete', 'Failed!', 'error');
            console.error("deleteRatingFile failed", error);
          }
        );
      }, () => {
        // No clicked
      });

    } catch (error) {
      console.error("deleteRatingFile failed", error);
    }
  }

  displayContent(content) {
    let _content = content;
    if (!this.utility.IsNullOrEmpty(_content)) {
      _content = this.utility.replaceAll(content, '<br>', '\r\n');
    }
    return _content;
  }

  onShare() {
    try {
      let selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = location.origin + '/control/expert-progress?batchId='
        + this._projectTeam.batchId + '&teamId=' + this._projectTeam.orgId
        + '&programId=' + this._projectTeam.id;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toasterSrv.showToast('Share', 'copied', 'success');
    } catch (error) {
      this.toasterSrv.showToast('Share', 'Failed to generate the shared link', 'error');
    }
  }

  setNotification(projectTeam) {
    this.setNotificationEvent.next({ projectTeam: projectTeam });
  }
}

