import {
    Component,
    Input,
    OnInit,

    ViewEncapsulation,
    ChangeDetectorRef,
    ElementRef,
    ViewChild,
    HostListener,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import {
    UtilityService,
    DataService,
    MenuService,
} from '@common/services';
import {
    AuthenticationService
} from '@modules/auth/services';
import { environment } from "environments/environment";
@Component({
    selector: 'lab-header',
    templateUrl: './header.component.html',
    styleUrls: ['header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    @ViewChild('search') search: ElementRef<HTMLElement>;
    @ViewChild('input') input: ElementRef<HTMLElement>;
    @ViewChild('header') headerRef: ElementRef<HTMLElement>;

    @Input() backgroundImage!: string;
    @Input() heading!: string;
    @Input() subHeading!: string;
    @Input() meta!: string;
    @Input() siteHeading = false;

    // click outside event
    @HostListener('document:click', ['$event'])
    clickedOut(event: any) {
        if (this.eRef.nativeElement.contains(event.target)) return;
        if (this.searchbarShow) return this.searchbarShow = !this.searchbarShow;
    }

    // check window size to hide dropdown menu of hamburger
    @HostListener('window:resize') onResize() {
        if (this.isShowHamburgerMenu) return this.isShowHamburgerMenu = !this.isShowHamburgerMenu;
    }

    searchbarShow: boolean = false;
    safeBackgroudImage!: SafeStyle;
    page: string = '';
    currentLink: string = '';
    currentType: string = '';
    currentUser;
    currentTab: string = '';
    totalNotif = 0;
    hasAvatar: boolean = false;
    userAvatar: string = '';
    menu = {
        projects: [],
        experts: [],
        batchs: []
    }
    isShowHamburgerMenu: boolean = false;

    haveOrg = true;

    constructor(
        private menuSrv: MenuService,
        private route: ActivatedRoute,
        private router: Router,
        private domSanitizer: DomSanitizer,
        private authSrv: AuthenticationService,
        private utilitySrv: UtilityService,
        private dataSrv: DataService,
        private cdf: ChangeDetectorRef,
        private eRef: ElementRef
    ) {

        this.currentUser = this.authSrv.getUserData();
        // 先檢查使用者是否存在，才做這件事，不然一直沒辦法看到畫面。
        if (this.currentUser && !this.utilitySrv.IsNullOrEmpty(this.currentUser.imageUrl)) {
            this.userAvatar = this.currentUser.imageUrl;
        }
        // if (this.currentUser) {
        //     if (!this.utilitySrv.IsNullOrEmpty(this.currentUser.imageUrl)) {
        //         this.currentUser.imageUrl = environment.assetUrl + this.currentUser.imageUrl;
        //         this.hasAvatar = true;
        //         this.userAvatar = this.currentUser.imageUrl;
        //     }
        // }
        this.route.queryParams.subscribe(params => {
            this.page = params["tab"];
        });

    }

    logout() {
        this.authSrv.logout();
        this.router.navigate(['/'], {
        });
    }

    ngOnInit() {
        this.safeBackgroudImage = this.domSanitizer.bypassSecurityTrustStyle(this.backgroundImage);

        // This is get the url to test the url is match the name about profile
        let path = this.router.url.split(/[/?&=]/);

        this.page = path.includes('profile')
            ? path.filter(text => text === 'startup' || text === 'consult' || text === 'lab' || text === 'batch').toString()
            // This is using split to get the name from index 2 of an array,
            // if not found, then back to use index 1 instead of undefined.
            : path[2] || path[1]

        this.currentLink = this.router['browserUrlTree'].queryParams.orgId;
        this.currentType = this.router['browserUrlTree'].queryParams.orgType;

        if (this.currentUser) {
            this.initialHeader();
        }
        this.dataSrv.refreshMenu.subscribe(res => {

            if (res) {
                this.initialHeader();
            }
        })
    }

    initialHeader() {
        this.getNumberOfNotifications();
        this.menuSrv.getMenusByUid(this.currentUser.id).then(res => {

            if (res['result'] == 'successful') {

                if (res['projects'] != null) {
                    this.menu.projects = res['projects'];
                }
                if (res['batchs'] != null) {
                    this.menu.batchs = res['batchs'];
                }
                if (res['experts'] != null) {
                    this.menu.experts = res['experts'];
                }
                this.cdf.detectChanges();

                if (res['projects'] == null && res['batchs'] == null && res['experts'] == null) {
                    this.haveOrg = false;
                } else {
                    this.haveOrg = true;
                }
                if (this.currentUser.IsAdmin) {
                    this.haveOrg = false;
                }

            }
        }).catch(error => {
            console.error("error", error);

        })
    }

    handleToggleSearch(e: any) {
        this.searchbarShow = !this.searchbarShow;
    }

    handleClear() {
        this.input.nativeElement.innerHTML = ''
    }

    getNumberOfNotifications() {

        this.totalNotif = 0;

        this.dataSrv.getNotificationByUid(this.currentUser.id).subscribe(res => {
            if (res['data'] !== undefined) {
                this.totalNotif += res['data'].length;
            }
        }, (error) => {
            console.error("getNotificationByUid :", error);
        });

        this.dataSrv.getToDoNotificationByUid(this.currentUser.id).subscribe(data => {
            if (data['result'] !== 'failed') {
                this.totalNotif += data['data'].length;
            }
        }, (error) => {
            console.error("getToDoNotificationByUid :", error);
        });

        this.dataSrv.getAllCalendarNotificationByUid(this.currentUser.id).subscribe(data => {
            if (data['result'] !== 'failed') {
                this.totalNotif += data['data'].length;
            }
        }, (error) => {
            console.error("getAllCalendarNotificationByUid :", error);
        })
    }

    onCheckRoute(path: string, type: string) {
        this.page = path;
        this.currentLink = '';
        this.currentType = '';
        this.router.navigate(['/w/' + path + '/' + type], {
            queryParams: {
                orgId: null,
                orgTypeId: null,
                orgType: null,
                tab: null
            }
        });
    }

    routeToHome(path: string) {
        this.page = path;
        this.currentLink = path;
        this.currentType = '';
        this.router.navigate(['/w'], {
            queryParams: {
                orgId: null,
                orgTypeId: null,
                orgType: null,
                tab: null
            }
        });
    }

    routeToSettings() {
        this.dataSrv.onChangeSettingTab(1);

        this.router.navigate(['/w/settings/account'], {
            queryParams: {
                orgId: null,
                orgTypeId: null,
                orgType: null,
                tab: null
            }
        });
    }

    routeToOrgDashboard(organization, type) {
        if (this.isShowHamburgerMenu) {
            this.isShowHamburgerMenu = !this.isShowHamburgerMenu;
            document.body.removeAttribute('style');
        }
        this.currentLink = organization?.organizationId || organization?.id;
        this.currentType = type;
        localStorage.removeItem('currentOrg');
        switch (type) {
            case 'project':
                localStorage.setItem('currentOrg', JSON.stringify(organization));
                this.router.navigate(['./w/startup/profile'], {
                    queryParams: {
                        orgId: organization.organizationId,
                        orgTypeId: organization.organizationTypeId,
                        orgType: type,
                        tab: type
                    }
                });
                break;
            case 'consult':
                localStorage.setItem('currentOrg', JSON.stringify(organization));
                localStorage.setItem('currentBatch', JSON.stringify(organization));
                this.router.navigate(['./w/consult/profile'], {
                    queryParams: {
                        orgId: organization.organizationId,
                        orgTypeId: organization.organizationTypeId,
                        orgType: 'consult',
                        tab: type
                    }
                });
                break;
            case 'lab':
                localStorage.setItem('currentOrg', JSON.stringify(organization));
                localStorage.setItem('currentBatch', JSON.stringify(organization.batch));
                this.router.navigate(['./w/lab/profile'], {
                    queryParams: {
                        orgId: organization.organizationId,
                        orgTypeId: organization.organizationTypeId,
                        orgType: 'lab',
                        tab: type
                    }
                });
                break;
        }
    }

    onShowMenu() {
        // alert('open hamburger menu');
        this.isShowHamburgerMenu = !this.isShowHamburgerMenu;
        if (this.isShowHamburgerMenu) return document.body.style.overflow = 'hidden';
        return document.body.removeAttribute('style');
    }

    clearLocalStorage() {
        localStorage.clear();
    }

}
