import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { User } from '@common/models';
import { UtilityService } from '@common/services';
import { environment } from "environments/environment";

@Component({
  selector: 'ui-sidebar-calendar',
  templateUrl: './sidebar-calendar.component.html',
  styleUrls: ['./sidebar-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarCalendarComponent implements OnInit {

  @Input() profile: boolean;
  @Input() avatar: boolean;
  @Input() eventData: string;
  currentUser: any;
  hasAvatar: boolean = false;
  userAvatar: string;
  assetUrl: string;

  constructor(
    private utilitySrv: UtilityService,
  ) {
    this.currentUser = new User();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    
    
  }

  ngOnInit() {

    if (this.currentUser && !this.utilitySrv.IsNullOrEmpty(this.currentUser?.imageUrl)) this.userAvatar = this.currentUser?.imageUrl;

  }

}
