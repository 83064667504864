import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {
  @Input() tipWord = [];
  @Input() placeholder = '';
  @Output() update = new EventEmitter<any>();
  @Input() keyupSearch = false;
  valueInSearchBar = '';

  onChangeValue(event) {
    if (event !== undefined && event.target.value !== undefined) {
      const keyWord = event.target.value;
      this.update.emit(keyWord);
    }
  }

  search(keyWord) {
    this.update.emit(keyWord);
  }
}
