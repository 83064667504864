import { Component, EventEmitter, OnInit, Output, Input, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ConfigService, DataService, EatToasterService, EmailService, UserService, UtilityService } from '@common/services';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Msg } from '@modules/_shared/models';
import { User } from '@common/models';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'lab-modal-user-list',
    templateUrl: './modal-user-list.component.html',
    styleUrls: ['./modal-user-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalUserListComponent implements OnInit, AfterViewInit {
    _currentOrg: any;

    @Input() set currentOrg(object) {

        if (object) {
            this._currentOrg = object;
            this.loading = true;
            this.users = [];
            this.getUsersWithlimit();
        }
    }
    @Input() type;
    @Output() onInviteUser = new EventEmitter<any>();
    currentUser: any;

    users: Array<any> = [];
    searchText;
    inviteByEmail = false;
    sortedCollection: any[];

    userEmails: Array<any> = [];
    invByEmailForm: FormGroup;
    emailTitle: string;
    invMessage = 'E-mail and notification sent to';
    msgType = '';

    emailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = true;

    typingTimer;
    toastRef;

    displaytext: string = '';

    loading: boolean = true;

    constructor(
        private userSrv: UserService,
        private fb: FormBuilder,
        private dataSrv: DataService,
        private emailSrv: EmailService,
        private toasterSrv: EatToasterService,
        private utility: UtilityService,
        public globals: ConfigService,
        private route: ActivatedRoute,
        private toastr: ToastrService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        /*    this.userSrv.getAllVerifiedUser().then((value: any) => { // the data is use to check if the invited user have an account on the platform
               if (value['result'] === 'successful' && value.data) {
                   this.userEmails = value.data.map(user => {
                       if (user.email !== undefined) {
                           return user.email.toLowerCase();
                       }
                   });
               }
           }, (error) => {
               console.error("getAllVerifiedUser :", error);
           }).finally(() => {
               this.loading = false;
           }); */
    }

    ngOnInit() {

        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        this.invByEmailForm = this.fb.group({
            newMembers: this.fb.array([
                this.fb.group({
                    email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
                })
            ])
        });
    }

    getNewMember(): FormArray {
        return this.invByEmailForm.get("newMembers") as FormArray
    }

    getInput(i) {
        return (<FormArray>this.invByEmailForm.get('newMembers')).controls[i];
    }

    newMember(): FormGroup {
        return this.fb.group({
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
        });
    }

    addMoreEmail() {
        this.getNewMember().push(this.newMember());
    }

    removeEmail(i: number) {
        this.getNewMember().removeAt(i);
    }

    ngAfterViewInit() {

    }

    getUsersWithlimit() { // to get a few users data to display to the user
        if (this._currentOrg?.id) {
            this.userSrv.getUsersWithlimit(this._currentOrg.organizationId, this._currentOrg.id).subscribe(data => {
                if (data['result'] === 'successful') {
                    this.users = data['data'].sort();
                } else {
                    this.users = [];

                }
            }, error => {
                console.error("Get all users failed :", error);
            }, () => {
                this.loading = false;
            });
        }
    }

    onSearchChange() {
        clearTimeout(this.typingTimer);
        if (this.searchText) { // check if the user typed something

            this.typingTimer = setTimeout(() => { // trigger function to search after 1 second
                this.doneTyping(this.searchText);
            }, 1000);
        } else { // if user didn't input any value return the default
            this.getUsersWithlimit();
            this.inviteByEmail = false;
        }
    }

    doneTyping(value) { // get data that contain value input by the user
        if (this._currentOrg?.id) {
            this.userSrv.searchUsers(value, this._currentOrg.organizationId, this._currentOrg.id).subscribe(res => {
                if (res['result'] === 'successful') {
                    this.users = res['data'];
                    this.inviteByEmail = false;
                } else {
                    this.users = [];
                    this.displaytext = `Sorry, we cannot find <span class="highlight">${this.searchText}</span> in our members list. Would you want to try to send an email to tell
                your friend`;
                    this.inviteByEmail = true;
                }
            }, error => {
                console.log(error);
            });
        }
    }

    onClose() {
        this.searchText = '';
        this.invByEmailForm.reset();
    }

    onFilterSelect($event) {

    }

    onAddByEmail() { // change to invite by email
        this.searchText = '';
        // this.users = [];
        if (this.inviteByEmail) {
            this.inviteByEmail = false;
            this.loading = true;
            this.getUsersWithlimit();
        } else {
            this.inviteByEmail = true;
            this.displaytext = 'Send an invitation to your friend and let them be part of your team';
        }
    }

    isInArray(val, arr) {
        return arr.indexOf(val) > -1;
    }

    validateEmail(event, index) { // check if the email input by user is in valid format

        const value = event.target.value;
        // tslint:disable-next-line:max-line-length
        // console.log(this.emailRegex.test(String(value).toLowerCase()));
        if (!this.emailRegex.test(String(value).toLowerCase())) {
            document.getElementById(index)!.style.border = "1px solid red";
            this.valid = false;
        } else {
            document.getElementById(index)!.style.border = "none";
            this.valid = true;
        }
    }

    onInviteByEmail() {
        for (let i = 0; i < this.invByEmailForm.value.newMembers.length; i++) { // check if all emails is in valid format

            if (!this.emailRegex.test(String(this.invByEmailForm.value.newMembers[i].email).toLowerCase())) {
                console.log('invalid', i);
                return
            }
        }
        if (this.invByEmailForm.invalid) {
            console.log('invalid');
            return;
        }

        for (let i = 0; i < this.invByEmailForm.value.newMembers.length; i++) {
            this.userSrv.getUserByEmail(this.invByEmailForm.value.newMembers[i].email.toLowerCase()).subscribe(res => {

                if (res['result'] === 'successful') { // if user have an account
                    this.invNoti(this.invByEmailForm.value.newMembers[i].email, 2);
                } else { // if user doesn't have an account
                    // console.log('doesnt exists');
                    let email = this.invByEmailForm.value.newMembers[i].email;
                    this.userSrv.createUser(
                        email,
                        email,
                        '',
                        true,
                        '',
                        '',
                        false,
                        'email',
                        this.currentUser.id
                    ).subscribe(res => {
                        if (res['result'] == 'successful') {
                            this.userSrv.getUserDataByEmailDB(email).subscribe(userData => {
                                const _noti = new Msg();
                                const currentUnixTime = new Date();

                                if (this.type === 'batch') {
                                    _noti.batchId = this._currentOrg.id;
                                } else {
                                    _noti.batchId = '';
                                }
                                _noti.requesterUid = this.currentUser.id;
                                _noti.senderUid = this.currentUser.id;
                                if (this.type === 'batch') {
                                    _noti.requesterOrgId = this._currentOrg.organizationId;
                                } else {
                                    _noti.requesterOrgId = this._currentOrg.id;
                                }
                                // _noti.invitedUid = '';
                                // _noti.receiverId = '';
                                _noti.invitedOrgId = '';
                                _noti.receiverName = '';
                                _noti.invitedUid = userData['data'].id;
                                _noti.receiverId = userData['data'].id;
                                _noti.requestDate = currentUnixTime.getTime().toString();
                                _noti.title = 'Invitation from ' + this._currentOrg.name + '.';
                                _noti.content = `Hello, I am ${this.currentUser.fullname} ` +
                                    ` & I would like to invite you to connect with our company ` +
                                    `${this._currentOrg.name} on the platform.`;
                                if (this.type === 'batch') {
                                    _noti.invitationType = '4';
                                } else {
                                    _noti.invitationType = '0';
                                }
                                _noti.createdAt = currentUnixTime.getTime().toString();
                                _noti.createdBy = this.currentUser.id;
                                _noti.updatedAt = currentUnixTime.getTime().toString();
                                _noti.updatedBy = this.currentUser.id;

                                this.dataSrv.createNewInv(_noti).subscribe(data => { // send notification to invited user

                                    if (data['result'] === 'successful') {
                                        this.emailTitle = `You are invited to join ${this._currentOrg.name} on Rescale Lab Platform!`;
                                        let link = window.location.origin + "/sign_up?token=";

                                        // this.emailSrv.sendInviteUserEmail( // send email to invited user
                                        //     this.emailTitle,
                                        //     this.invByEmailForm.value.newMembers[i].email,
                                        //     link,
                                        //     this.currentUser.id,
                                        //     this.currentUser.fullname,
                                        //     this._currentOrg.name,
                                        //     data['invitation'].notificationId).subscribe();
                                        let queryParam = {
                                            time: currentUnixTime.getTime(),
                                            org: _noti.requesterOrgId,
                                            email: email
                                        }

                                        this.emailSrv.sendEncryptedEmail(
                                            this.emailTitle,
                                            email,
                                            // 'arulananth.2008@gmail.com',
                                            link,
                                            this.currentUser.id,
                                            this.currentUser.fullname,
                                            this._currentOrg.name,
                                            queryParam,
                                            true
                                        ).subscribe();
                                        // this.toasterSrv.showToast('', `An e-mail has been sent to ${this.invByEmailForm.value.newMembers[i].email}.`, this.toasterSrv.iconClasses.success);
                                        this.showActionMsg('Success', `An e-mail has been sent to ${this.invByEmailForm.value.newMembers[i].email}.`);

                                        this.invByEmailForm.reset();
                                        // setTimeout(() => {
                                        //     this.invMessage = '';
                                        //     this.msgType = '';
                                        // }, 3000);
                                        this.onInviteUser.emit();
                                    } else if (data['error']) {
                                        console.log(data['error']);
                                    }
                                }, (error) => {
                                    console.error("createNewInv :", error);
                                });

                            }, (error) => {
                                console.error("getUserDataByEmailDB :", error);
                            });

                        } else {
                            this.showActionMsg(res['message'], 1);
                        }
                    });

                }
            });
        }
    }

    invNoti(email, type, index?) {
        const _noti = new Msg();

        if (type === 1) {
            this.users[index].inviteCount = 1;
        }
        if (email !== '') {
            this.userSrv.getUserDataByEmailDB(email).subscribe(res => { // retrieve user data

                let emailToName = res['data'].fullname;
                const currentUnixTime = new Date();
                if (this.type === 'batch') {
                    _noti.batchId = this._currentOrg.id;
                    _noti.requesterOrgId = this._currentOrg.organizationId;
                } else {
                    _noti.batchId = '';
                    _noti.requesterOrgId = this._currentOrg.id;

                }
                _noti.requesterUid = this.currentUser.id;
                _noti.senderUid = this.currentUser.id;
                // if (this.type === 'batch') {
                //     _noti.requesterOrgId = this._currentOrg.organizationId;
                // } else {
                //     _noti.requesterOrgId = this._currentOrg.id;
                // }
                _noti.invitedUid = res['data'].id;
                _noti.receiverId = res['data'].id;
                _noti.invitedOrgId = '';
                _noti.receiverName = emailToName;
                _noti.requestDate = currentUnixTime.getTime().toString();
                _noti.title = 'Invitation from ' + this._currentOrg.name + '.';
                _noti.content = `Hello ${_noti.receiverName},` +
                    ` I am ${this.currentUser.fullname} & I would like to invite you to connect with our company` +
                    ` ${this._currentOrg.name} on the platform.`;
                if (this.type === 'batch') {
                    _noti.invitationType = '4';
                } else {
                    _noti.invitationType = '0';
                }

                _noti.createdAt = currentUnixTime.getTime().toString();
                _noti.createdBy = this.currentUser.id;
                _noti.updatedAt = currentUnixTime.getTime().toString();
                _noti.updatedBy = this.currentUser.id;

                this.dataSrv.createNewInv(_noti).subscribe(data => { // send notification to invited user
                    if (data['result'] === 'successful') {
                        this.emailTitle = `You are invited to join ${this._currentOrg.name} on Rescale Lab Platform!`;
                        let link = window.location.origin + '/login?time=' + currentUnixTime.getTime();
                        this.emailSrv.sendEmail( // send email to invited user
                            this.emailTitle,
                            res['data'].email,
                            link,
                            this.currentUser.id,
                            this.currentUser.fullname,
                            this._currentOrg.name).subscribe();
                        // this.toasterSrv.showToast('', `E-mail and notification sent to ${email}.`, this.toasterSrv.iconClasses.success);
                        this.showActionMsg('Success', `E-mail and notification sent to ${email}.`);
                        this.invByEmailForm.reset();
                        this.onInviteUser.emit();

                    } else if (data['error']) {
                        console.log(data['error']);
                    }
                }, (error) => {
                    console.error("createNewInv :", error);
                });
            }, (error) => {
                console.error("getUserDataByEmailDB :", error);
            });
        } else {
            // this.ShowMsg('Please indicate role and/or the user', 1);
        }
    }



    onInvite(id) {

        this.users[id].inviteCount = 1;

        // document.getElementById(id)!.style.background = "linear-gradient(122deg, #00BA6B 0%, #008B80 100%)";
        // document.getElementById(id + '-btn')!.style.color = "white";
        // document.getElementById(id + '-btn')!.style.backgroundColor = "#2ABF6C";
        // document.getElementById(id + '-btn')!.style.border = "solid #2ABF6C";

        // document.getElementById(id + '-img')!.setAttribute('src', 'assets/icons/check.svg');
        // document.getElementById(id + '-img')!.style.filter = "brightness(0) invert(1)";
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

    showActionMsg(title, msg) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: 'green-title',
            closeButton: false,
            timeOut: 5000
        });
    }


}
