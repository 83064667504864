import { FileUpload } from "@common/models";
export class Project {
    id: string;
    companyStage = '';
    theWhat = '';
    theWho = '';
    theHow = '';
    sectorPertain = '';
    sectorPertainOthers = '';
    sectorPertainOthersText = '';
    appPertain = '';
    appPertainOthers = '';
    appPertainOthersText = '';
    solPertain = '';
    solPertainOthers = '';
    solPertainOthersText = '';
    revenueModel = '';
    revenueModelOthers = '';
    uniqueProposition = '';
    uniquePropositionOthers = '';
    uniquePropositionOthersText = '';
    targetMarket = '';
    haveValidatedData = '';
    haveValidatedDataOthers = '';
    haveValidatedDataOthersText = '';
    supportProgram = '';
    supportProgramOthers = '';
    supportProgramOthersText = '';
    productLaunchDate = '';
    hasWonAward = '';
    wonAward: any[];
    revenueNow = '';
    pitchDeck: FileUpload;
    affordRating = '';
    funcRating = '';
    accessRating = '';
    numOfCofounders = '';
    companySize = '';
    rolesFilled = '';
    rolesFilledOthers = '';
    rolesFilledOthersText = '';
    haveSpace: boolean;
    officeType = '';
    officeTypeOthers = '';
    fundingStage = '';
    fundingStageOthers = '';
    fundingStageOthersText = '';
    currentFunded = '';
    fundingEntities: any[];
    howRaiseFunds = '';
    howRaiseFundsOthers = '';
    howRaiseFundsOthersText = '';
    needFunds = '';
    raiseAmountFor = '';
    raiseAmountForOthers = '';
    raiseAmtForOthersText = '';
    fundMth = '';
    fundMthOthers = '';
    legal = '';
    structure = '';
    structureOthers = '';
    secretaryServiceProviders: any[];
    haveAny = '';
    partners = '';
    partnerships = '';
    partnershipsOthers = '';
    partnershipsOthersText = '';
    communities = '';
    communitiesOther = '';
    communitiesOthersText = '';
    needs = '';
    needsChallenges = '';
    needsChallengesOthers = '';
    reasonChallenges = '';
    skillSets = '';
    skillSetsOthers = '';
    expertise = '';
    expertiseOthers = '';
    expertiseOthersText = '';
    createStartupComp: boolean;
    startupCorp: any[] = [];
    createdAt = '';
    createdBy = '';
    updatedAt = '';
    updatedBy = '';
    uid = '';
    hasStartupForm = true;
    productStage = '';
    actions = '';
    validationIdea = '';
    traction = '';
    description = '';
    organizationId = '';
}
