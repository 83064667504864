import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    HostListener,
    ElementRef,
    Output,
    EventEmitter,
} from '@angular/core';

@Component({
    selector: 'lab-custom-popover',
    templateUrl: './custom-popover.component.html',
    styleUrls: ['./custom-popover.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomPopoverComponent implements OnInit {
    @Input() class?: string;
    @Input() isActive: boolean;

    @Output() close = new EventEmitter();

    constructor(private eRef: ElementRef) {}

    ngOnInit() {}

    @HostListener('document:click', ['$event'])
    clickedOut(event: any) {
        if (this.eRef.nativeElement.contains(event.target)) return;
        if (this.isActive) this.close.emit();
    }
}
