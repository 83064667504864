export class VbpPackage {
  id: string;
  vpbId: string;
  price: string;
  title: string;
  description: string;
  sequence: number;
  uid: string;
  pax: 1;
}
