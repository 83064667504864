import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmailService {

    constructor(private http: HttpClient) {
    }

    authenticateEmail(title, receiver, link, uid, name= '') {
        return this.http.post(`${environment.apiUrl}/email/authenticateMail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'name': name
            });
    }

    sendResetPasswordEmail(subject, receiverName, receiverEmail, link, uid) {
        return this.http.post(`${environment.apiUrl}/email/resetPassoword`,
            {
                'subject': subject,
                'receiverName': receiverName,
                'receiverEmail': receiverEmail,
                'link': link,
                'uid': uid
            });
    }

    sendInviteUserEmail(title, receiver, link, uid, requester, requesterOrg, invitationId) {
        return this.http.post(`${environment.apiUrl}/email/sendInviteUserEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'requester': requester,
                'requesterOrg': requesterOrg,
                'invitationId': invitationId
            });
    }

    sendEmail(title, receiver, link, uid, requester, requesterOrg) {
        return this.http.post(`${environment.apiUrl}/email/sendMail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'requester': requester,
                'requesterOrg': requesterOrg
            });
    }

    sendEncryptedEmail(title, receiver, link, uid, requester, requesterOrg, queryParam, isSignup) {
        return this.http.post(`${environment.apiUrl}/email/sendEncryptedEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'queryParams': queryParam,
                'requester': requester,
                'requesterOrg': requesterOrg,
                'isSignup': isSignup
            });
    }

    sendTaskCommentEmail(title, receiver, receiverName, content, commentAuthor, uid) {
        return this.http.post(`${environment.apiUrl}/email/sendTaskCommentEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'receiverName': receiverName,
                'content': content,
                'commentAuthor': commentAuthor,
                'uid': uid
            });
    }

    sendRatingEmail(title, receiver, receiverName, content, deliverableTitle, uid) {
        return this.http.post(`${environment.apiUrl}/email/sendRatingEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'receiverName': receiverName,
                'content': content,
                'deliverableTitle': deliverableTitle,
                'uid': uid
            });
    }

    sendSubmissionNotificationEmail(title, receiver, receiverName, projectTeam, content, deliverableTitle, uid) {
        return this.http.post(`${environment.apiUrl}/email/sendSubmissionNotificationEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'receiverName': receiverName,
                'projectTeam': projectTeam,
                'content': content,
                'deliverableTitle': deliverableTitle,
                'uid': uid
            });
    }

    sendNewTaskEmail(title, receiver, link, uid, requester, requesterOrg, dueDate, assigneeName, urgency, description) {
        return this.http.post(`${environment.apiUrl}/email/sendNewTaskMail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'requester': requester,
                'requesterOrg': requesterOrg,
                'dueDate': dueDate,
                'assigneeName': assigneeName,
                'urgency': urgency,
                'description': description
            });
    }

    updateTaskEmail(title, receiver, link, uid, requester, requesterOrg, dueDate, assigneeName, urgency, description) {
        return this.http.post(`${environment.apiUrl}/email/updateTaskEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'requester': requester,
                'requesterOrg': requesterOrg,
                'dueDate': dueDate,
                'assigneeName': assigneeName,
                'urgency': urgency,
                'description': description
            });
    }

    sendNewTaskEmailv2(title, receiver, link, uid, requester, requesterOrg, dueDate, assigneeName, urgency, description, _startDate, _endDate) {
        return this.http.post(`${environment.apiUrl}/email/sendNewTaskMail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'requester': requester,
                'requesterOrg': requesterOrg,
                'dueDate': dueDate,
                'assigneeName': assigneeName,
                'urgency': urgency,
                'description': description,
                'startDate': _startDate,
                'endDate': _endDate
            });
    }

    sendBatchInviteEmail(title, receiver, link, uid, requester, requesterOrg) {
        return this.http.post(`${environment.apiUrl}/email/sendBatchInviteEmail`,
            {
                'subject': title,
                'receiver': receiver,
                'link': link,
                'uid': uid,
                'requester': requester,
                'requesterOrg': requesterOrg
            });
    }

    sendContactUsEmail(name, email, msg, service) {
        return this.http.post(`${environment.apiUrl}/email/sendContactUsEmail`,
            {
                'name': name,
                'email': email,
                'msg': msg,
                'service': service
            });
    }

    sendWelcomeEmail(name, receiver) {
        return this.http.post(`${environment.apiUrl}/email/sendWelcomeEmail`,
            {
                'name': name,
                'receiver': receiver
            });
    }

    bookScheduleEmail(value) {
        return this.http.post(`${environment.apiUrl}/email/bookScheduleMail`, value);
    }

    cancelScheduleEmail(value) {
        return this.http.post(`${environment.apiUrl}/email/cancelScheduleMail`, value);
    }

    sendCalendarEmail(receiver, hostName, hostEmail, summary, description, date, attendanceId, content) {
        return this.http.post(`${environment.apiUrl}/email/sendCalendarEmail`,
            {
                'receiver': receiver,
                'hostName': hostName,
                'hostEmail': hostEmail,
                'summary': summary,
                'description': description,
                'date': date,
                'attendanceId': attendanceId,
                'file': content
            });
    }

    sendPostCommentEmail(receiver, receiverName, title, content) {
        return this.http.post(`${environment.apiUrl}/email/sendPostEmail`,
            {

                'receiver': receiver,
                'receiverName': receiverName,
                'subject': title,
                'content': content
            });
    }

    sendPaymentEmail(receiver, receiverName, title, content) {
        return this.http.post(`${environment.apiUrl}/email/sendPaymentNotificationEmail`,
            {

                'receiver': receiver,
                'receiverName': receiverName,
                'subject': title,
                'content': content
            });
    }

    getAll() {
        return this.http.get(`${environment.apiUrl}/delegatingEmail/getAll`,
            {

            });
    }

    deleteDelegatingEmail(id) {
        return this.http.post(`${environment.apiUrl}/delegatingEmail/deleteDelegatingEmail`,
            {
                id: id
            });
    }

    createDelegatingEmail(email, delegatingEmail, uid) {
        return this.http.post(`${environment.apiUrl}/delegatingEmail/createDelegatingEmail`,
            {
                email: email,
                delegatingEmail: delegatingEmail,
                uid: uid
            });
    }

}
