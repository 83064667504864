import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';

import moment from 'moment';
import { Observable, BehaviorSubject } from 'rxjs';
const CACHE_SIZE = 1;
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
export class ProfileCacheService {

  readonly CACHE_DURATION_IN_MINUTES = 3;

  private cache: {
    expires: Date,
    batchId: string,
    value: Observable<any[]>
  } = null as any;
  constructor(
  ) {
  }

  getValue(batchId): Observable<any[]> {
    if (!this.cache) {
      return null as any;
    }

    if (!this.cache.batchId != batchId) {
      return null as any;
    }

    if (moment(new Date()).isAfter(this.cache.expires)) {
      return null as any;
    }

    return this.cache.value;
  }

  setValue(value: Observable<any[]>, batchId: string) {
    this.cache = {
      value,
      batchId,
      expires: moment(new Date()).add(this.CACHE_DURATION_IN_MINUTES, 'minutes').toDate()
    };

  }

  clearCache() {
    this.cache = null as any;
  }
}
@Injectable({
  providedIn: 'root'
})
export class InstitutionService {
  projectsName = '';
  expertName = '';

  constructor(private http: HttpClient,
    private profileCacheSrv: ProfileCacheService,
    private teamDeliverableScoresChartCacheSrv: ProfileCacheService) { }

  getEventByStartupId(orgId) {
    return this.http.get<any>(`${environment.apiUrl}/institutionschedule/startup/${orgId}`);
  }

  getEventByExpertId(expertId) {
    return this.http.get<any>(`${environment.apiUrl}/institutionschedule/expert/${expertId}`);
  }

  getEventByProgramId(programId) {
    return this.http.get<any>(`${environment.apiUrl}/institutionschedule/program/${programId}`);
  }

  getProgramById(batchId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramById/${batchId}`);
  }

  bulkInsertOrgWidget(batchId, widgetIds, sequences, uid) {
    return this.http.post(`${environment.apiUrl}/widget/bulkInsertOrganizationBatchWidget`,
      {
        'batchId': batchId,
        'widgetIds': widgetIds,
        'sequences': sequences,
        'uid': uid
      }).subscribe(data => {
      }, error => {
        console.error('bulkInsertOrgWidget error', error);
      });
  }

  getAllOrganizationBatchWidget() {
    return this.http.get<any>(`${environment.apiUrl}/institution/getAllOrganizationBatchWidget`);
  }

  getOrganizationBatchById(batchId: string) {
    return this.http.post(`${environment.apiUrl}/institution/getOrganizationBatchById`,
      { batchId: batchId }
    );
  }

  getDataBybatchIdNmatchedOrgId(batchId: string, invitedOrgId: string) {
    const params = new HttpParams().set('batchId', batchId).set('invitedOrgId', invitedOrgId);
    return this.http.get(`${environment.apiUrl}/v2/institution/getDataBybatchIdNmatchedOrgId`,
      { params: params }
    );
  }

  addOrgBatchRelationship(matchingId: string, requesterOrgId: string, requesterOrgTypeId: string,
    matchedOrgId: string, matchedOrgTypeId: string, uid: string,
    batchId: string, orgType: string) {
    const res = this.http.post(`${environment.apiUrl}/institution/addOrgBatchRelationship`, {
      matchingId: matchingId,
      requesterOrgId: requesterOrgId,
      requesterOrgTypeId: requesterOrgTypeId,
      matchedOrgId: matchedOrgId,
      matchedOrgTypeId: matchedOrgTypeId,
      deleted: 0,
      uid: uid,
      batchId: batchId,
      orgType: orgType,
      status: status
    });

    return res;
  }

  addOrgRelationship(matchingId: string, requesterOrgId: string, requesterOrgTypeId: string,
    matchedOrgId: string, matchedOrgTypeId: string, uid: string,
    batchId: string, orgType: string,
    status: string, deleted: string, matchedOrgType: string) {
    const res = this.http.post(`${environment.apiUrl}/v2/institution/addOrgRelationship`, {
      matchingId: matchingId,
      requesterOrgId: requesterOrgId,
      requesterOrgTypeId: requesterOrgTypeId,
      matchedOrgId: matchedOrgId,
      matchedOrgTypeId: matchedOrgTypeId,
      deleted: deleted,
      uid: uid,
      batchId: batchId,
      orgType: orgType,
      status: status,
      matchedOrgType: matchedOrgType
    });

    return res;
  }

  removeRelationship(id: string, uid: string) {
    return this.http.delete<any>(`${environment.apiUrl}/v2/institution/relationship/${id}/${uid}`, {

    });
  }

  addOrgToBatch(matchingId: string, requesterOrgId: string, requesterOrgTypeId: string,
    matchedOrgId: string, matchedOrgTypeId: string, uid: string,
    batchId: string, orgType: string) {
    const res = this.http.post(`${environment.apiUrl}/institution/addOrgToBatch`, {
      matchingId,
      requesterOrgId,
      requesterOrgTypeId,
      matchedOrgId,
      matchedOrgTypeId,
      deleted: 0,
      uid,
      batchId,
      orgType
    });

    return res;
  }
  updateOrgBatchRelationship(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateOrgBatchRelationship`, value);
  }

  updateOrgRelationshipStatus(value) {
    return this.http.put<any>(`${environment.apiUrl}/v2/institution/updateOrgRelationshipStatus`, value);
  }

  updateOrgRelationshipStatusV2(value) {
    return this.http.put<any>(`${environment.apiUrl}/v2/institution/updateOrgRelationshipStatusV2`, value);
  }

  /*   getInvitedOrg(batchId) {
      return this.http.post(`${environment.apiUrl}/institution/getDataByBatchIdSorted`, { batchId: batchId });
    } */
  getInvitedOrg(batchId) {
    const params = new HttpParams().set('batchId', batchId)
    return this.http.get(`${environment.apiUrl}/v2/institution/getDataByBatchIdSorted`, { params: params });
  }

  getProjectTeamByBatchId(batchId) {
    return this.http.post(`${environment.apiUrl}/institution/getProjectTeamByBatchId`, { batchId: batchId });
  }

  getInvitedOrgByBatchId(batchId) {
    return this.http.post(`${environment.apiUrl}/institution/getDataByBatchId`, { batchId: batchId });
  }

  getInvitedOrgByBatchIdWithType(batchId) {
    return this.http.post(`${environment.apiUrl}/institution/getDataByBatchIdwithType`, { batchId: batchId });
  }

  editInvitedOrg(batchRelationshipId) {
    return this.http.post(`${environment.apiUrl}/institution/editInvitedOrg`,
      { batchRelationshipId: batchRelationshipId });
  }

  // Duplicated function after removing InDB in the function name. Url is different in the function. Please check.
  // createProgram(value) {
  //   return this.http.post<any>(`${environment.apiUrl}/institution/createProgram`, value);
  // }

  getProgramByBatchId(batchId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramByBatchId/${batchId}`);
  }
  // Duplicated function after removing InDB in the function name. Url is different in the function. Please check.
  // updateProgram(value) {
  //   return this.http.post<any>(`${environment.apiUrl}/institution/updateProgram`, value);
  // }

  //Duplicated function after removing InDB in the function name. Url is different in the function. Please check.
  // createDeliverable(value) {
  //   return this.http.post<any>(`${environment.apiUrl}/institution/createDeliverable`, value);
  // }

  //Duplicated function after removing InDB in the function name. Url is different in the function. Please check.
  // updateDeliverable(value) {
  //   return this.http.post<any>(`${environment.apiUrl}/institution/updateDeliverable`, value);
  // }

  createSchedule(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createSchedule`, value);
  }

  getAllScheduleByBatchId(batchId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getAllScheduleByBatchId/${batchId}`);
  }

  getAllScheduleGradeByBatchId(batchId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getAllScheduleGradeByBatchId/${batchId}`);
  }

  updateSchedule(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateSchedule`, value);
  }

  getAllGradeByBatchIdAndStartupId(batchId, startupId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getAllGradeByBatchIdAndStartupId/${batchId}/${startupId}`);
  }

  updateGrade(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateGrade`, value);
  }

  removeUserFromBatchById(organizationuserId, batchId, userId) {
    return this.http.post<any>(`${environment.apiUrl}/institution/removeUserFromBatch`, {
      organizationuserId: organizationuserId,
      batchId: batchId,
      userId: userId
    });
  }
  removePendingUserFromBatchById(batchId, userId) {
    return this.http.post<any>(`${environment.apiUrl}/institution/removePendingUserFromBatch`, {
      batchId: batchId,
      userId: userId
    });
  }

  addUserToBatch(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/addUserToBatch`, value);
  }

  addUserToBatchByEmail(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/addUserToBatchByEmail`, value);
  }


  getBatchUserByBatchId(batchId, key) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getBatchUser/${batchId}/${key}`);
  }

  async getBatcUserInfoByBatchId(batchId, key) {
    return await this.http.get<any>(`${environment.apiUrl}/institution/batchUserInfo/${batchId}/${key}`).toPromise();
  }

  updadteBatchUser(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateBatchUser`, value);
  }

  authorizeGoogleCalendar() {
    return this.http.get<any>(`${environment.apiUrl}/institution/authorizeGoogleCalendar`);
  }

  getGoogleApiToken(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/googleApiToken`, value);
  }

  insertCalendar(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/insertCalendar`, value);
  }

  updateUserGoogleCalendar(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateUserGoogleCalendar`, value);
  }

  listCalendar(userId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/listCalendar/${userId}`);
  }

  updateOrganizationBatchById(value) {
    return this.http.post(`${environment.apiUrl}/institution/updateOrganizationBatchById`, value);
  }

  async getInstitutionType() {
    return await this.http.get<any>(`${environment.apiUrl}/institution/getInstitutionType`).toPromise();
  }

  // for presentation block
  createProgram(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createProgramNew`, value);
  }

  // for first time to initial program for team and individual
  createPrograms(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createProgramsNew`, value);
  }

  updateProgram(value) {
    return this.http.post<any>(`${environment.apiUrl}/v2/institution/updateProgramV2`, value);
  }

  updateProgramPublish(data) {
    return this.http.post(`${environment.apiUrl}/v2/institution/updatePublishStatus`,
      { data: data }
    );
  }

  createDeliverable(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createDeliverableV2`, value);
  }

  updateDeliverable(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateDeliverableV2`, value);
  }

  deleteDeliverable(id, handoutresourceid, othersourceresourceid) {
    let value = { id, handoutresourceid, othersourceresourceid }
    return this.http.delete<any>(`${environment.apiUrl}/institution/deliverable`, { params: value });
  }

  getProgramDeliverable(batchId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramDeliverable/${batchId}`);
  }

  async getDeliverableSetByProgramId(programId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/batch/deliverableset/${programId}`).toPromise();
  }

  async getPresentationBlockByProgramId(programId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/batch/presentationblock/${programId}`).toPromise();
  }

  async getTeamDeliverableScoresChart(organizationId) {
    //   return this.http.get<any>(`${environment.apiUrl}/institution/getTeamDeliverableScoresChart/${organizationId}`);


    let scoresChart$ = this.teamDeliverableScoresChartCacheSrv.getValue(organizationId);
    if (!scoresChart$) {
      scoresChart$ = await this.http.get(`${environment.apiUrl}/institution/getTeamDeliverableScoresChart/${organizationId}`,
        {}).pipe(
          map((response: any) => response), shareReplay(1)).toPromise();
      this.teamDeliverableScoresChartCacheSrv.setValue(scoresChart$, organizationId);
    }
    return scoresChart$;
  }
  // organizationId is batchId, orgId is orgId
  initialProgressData(batchId, orgId) {
    return this.http.get<any>(`${environment.apiUrl}/v2/institution/initialProgressData/${batchId}/${orgId}`);
  }

  // get the progress data of a project team 
  projectTeamProgressData(batchId, projectTeamId) {
    return this.http.get<any>(`${environment.apiUrl}/v2/institution/projectTeamProgressData/${batchId}/${projectTeamId}`).toPromise();
  }

  // WIP remove if not use in program progress
  initialProgressDataV4(batchId, orgId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/initialProgressDataV4/${batchId}/${orgId}`);
  }

  async initialProgromProgressData(batchId, orgId, programId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/initialProgramProgressData/${batchId}/${orgId}/${programId}`).toPromise();
  }



  async initialIndividualProgressData(batchId, userId) {

    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/initialIndividualProgressData/${batchId}/${userId}`).toPromise();
  }

  getRatingData(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/getRatingData`, value);
  }

  updateRating(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateRating`, value);
  }

  //todo
  deleteRating(id) {
    return this.http.delete<any>(`${environment.apiUrl}/v2/institution/rating/${id}`, {});
  }

  updateFileRating(formData) {
    return this.http.post(`${environment.apiUrl}/institution/updateFileRating`, formData);
  }

  updateindividualRating(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateindividualRating`, value);
  }

  updateIndividualFileRating(formData) {
    return this.http.post(`${environment.apiUrl}/institution/updateIndividualFileRating`, formData);
  }



  getProgressData(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/getProgressData`, value);
  }

  createComment(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createComment`, value);
  }

  deleteComment(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/deleteComment`, value);
  }

  createIndividualComment(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createIndividualComment`, value);
  }

  getCommentData(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/getCommentData`, value);
  }

  async getDeliverableCommentData(deliverableId, ratingId) {
    return await this.http.get<any>(`${environment.apiUrl}/comment/batch/deliverable/commentData/${deliverableId}/${ratingId}`).toPromise();
  }

  async getCommentsbyDeliverableIdANDOrgId(deliverableId, orgId) {
    return await this.http.get<any>(`${environment.apiUrl}/comment/batch/deliverable/commentDataV2/${deliverableId}/${orgId}`).toPromise();
  }

  // updateCommentData(value, id) {
  //   return this.http.put<any>(`${environment.apiUrl}/comment/comment/${id}`, value);
  // }

  async updateCommentData(formData: FormData) {
    return await this.http.put<any>(`${environment.apiUrl}/comment/comment`,
      formData).toPromise();
  }

  getPastedCommentData(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/getPastedCommentData`, value);
  }
  /* 
    getDeliverableCommentData(value) {
      return this.http.post<any>(`${environment.apiUrl}/institution/getDeliverableCommentData`, value);
    } */

  async getNumOfCommentByRatingId(ratingId) {
    return await this.http.get<any>(`${environment.apiUrl}/institution/getNumOfCommentByRatingId/${ratingId}`).toPromise();
  }

  async getBatchProgramByUserId(uid) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/batch/programs/${uid}`).toPromise();
  }

  async getBatchByUserid(uid) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/getBatchByUserid/${uid}`).toPromise();
  }

  async getBatchByOrgId(orgId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/getBatchByOrgId/${orgId}`).toPromise();
  }

  async pauseBatch(batchId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/pauseBatch/${batchId}`).toPromise();
  }

  async batchPayment(batchId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/batchPayment/${batchId}`).toPromise();
  }

  async launchBatch(batchId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/launchBatch/${batchId}`).toPromise();
  }

  async hideBatch(batchId) {
    return await this.http.get<any>(`${environment.apiUrl}/v2/institution/hideBatch/${batchId}`).toPromise();
  }

  getBatchByOrgIdAndUid(orgId, uid) {
    return this.http.get<any>(`${environment.apiUrl}/v2/institution/getBatchByOrgIdAndUid/${orgId}/${uid}`);
  }

  getInstitutionOwnerByUserId(id) {
    return this.http.get<any>(`${environment.apiUrl}/v2/institution/getInstitutionOwnerbyUserId/${id}`);
  }

  createProgramCalendar(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/createProgramCalendar`, value);
  }

  getProgramCalendarDataById(id) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramCalendarById/${id}`);
  }

  getProgramCalendarData(organizationId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramCalendar/${organizationId}`);
  }

  getProgramCalendarByInstitutionId(organizationId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramCalendarByInstitutionId/${organizationId}`);
  }

  updateProgramCalendar(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/updateProgramCalendar`, value);
  }

  deleteProgram(id, slidesresourceid, spreadsheetresourceid) {
    let value = { id, slidesresourceid, spreadsheetresourceid }
    return this.http.delete<any>(`${environment.apiUrl}/institution/program`, { params: value });
  }

  programRestart(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/restart`, value);
  }

  deliverablePivot(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/pivot`, value);
  }

  removeStartupBatchWidget(value) {
    return this.http.post<any>(`${environment.apiUrl}/institution/removeStartupBatchWidget`, value);
  }

  addMentorManagement(value) {
    return this.http.post<any>(`${environment.apiUrl}/mentorManagement/addMentorManagement`, value);
  }

  getMentorManagement(value) {
    return this.http.post<any>(`${environment.apiUrl}/mentorManagement/getMentorManagement`, value);
  }

  async getMentors(batchId: string,
    clientId: string) {
    const params = new HttpParams().set('batchId', batchId).set('clientId', clientId);
    return await this.http.get<any>(`${environment.apiUrl}/mentorManagement/mentors`, { params: params }).toPromise();
  }

  // discord in v4
  getMentorManagementByMentorId(batchId, mentorId, clientType) {
    return this.http.get<any>(`${environment.apiUrl}/mentorManagement/getMentorManagementByMentorId/${batchId}/${mentorId}`);
  }

  async getMenteesByMentorId(batchId, mentorId, clientType) {
    const params = new HttpParams().set('batchId', batchId).set('mentorId', mentorId).set('clientType', clientType);
    return await this.http.get<any>(`${environment.apiUrl}/mentorManagement/mentees`, { params: params }).toPromise();
  }

  // delete record in organization relationship
  removeMentoring(batchId, masterId, clientId, uid) {
    return this.http.post<any>(`${environment.apiUrl}/institution/removeMentoring`, {
      batchId,
      masterId,
      clientId,
      uid
    });
  }

  // removeMentorManagement(value) {
  //   return this.http.post<any>(`${environment.apiUrl}/mentorManagement/removeMentorManagement`, value);
  // }

  removeMentorManagementByBatchIdAndMentorId(batchId, mentorId) {
    return this.http.post<any>(`${environment.apiUrl}/mentorManagement/removeMentorManagementByBatchIdAndMentorId`, {
      batchId,
      mentorId
    })
  }

  removeMentorManagement(id, uid) {
    return this.http.delete<any>(`${environment.apiUrl}/mentorManagement/${id}/${uid}`);
  }

  getProgramCalendarCompany(batchId) {
    return this.http.get<any>(`${environment.apiUrl}/institution/getProgramCalendarCompany/${batchId}`);
  }

  deleteRatingFile(organizationId, deliverableId, uid) {
    return this.http.post<any>(`${environment.apiUrl}/institution/deleteRatingFile`, {
      uid,
      deliverableId,
      organizationId
    });
  }

  deleteIndividulaRatingFile(userId, deliverableId, uid) {
    return this.http.post<any>(`${environment.apiUrl}/institution/deleteRatingFile`, {
      uid,
      deliverableId,
      userId
    });
  }

  getPastedRatingData(deliverableIds, organizationId) {
    return this.http.post<any>(`${environment.apiUrl}/institution/getPastedRatingData`, {
      deliverableIds,
      organizationId
    });
  }

  sendBookingEmail(subject,
    content,
    receiver,
    receiverName,
    startTime,
    endTime,
    startUpName,
    consultantName,
    programName,
    link,
    description,
    orgId,
    orgTypeId,
    uid,
    roles,
    isConvertTime = 0,
    icsStartDate,
    icsEndDate
  ) {

    return this.http.post<any>(`${environment.apiUrl}/institution/sendBookingEmail`, {
      subject,
      content,
      receiver,
      receiverName,
      startTime,
      endTime,
      startUpName,
      consultantName,
      programName,
      link,
      description,
      orgId,
      orgTypeId,
      uid,
      roles,
      isConvertTime,
      icsStartDate,
      icsEndDate
    });
  }

  sendMentorBookingEmail(subject,
    content,
    receiver,
    receiverName,
    startTime,
    endTime,
    startUpName,
    consultantName,
    programName,
    link,
    description,
    orgId,
    orgTypeId,
    uid,
    roles,
    isConvertTime,
    icsStartDate,
    icsEndDate) {
    return this.http.post<any>(`${environment.apiUrl}/institution/sendMentorBookingEmail`, {
      subject,
      content,
      receiver,
      receiverName,
      startTime,
      endTime,
      startUpName,
      consultantName,
      programName,
      link,
      description,
      orgId,
      orgTypeId,
      uid,
      roles,
      isConvertTime,
      icsStartDate,
      icsEndDate
    });
  }

  cancelBookingEmail(programCalendarId, uid, notiData) {
    return this.http.post<any>(`${environment.apiUrl}/institution/cancelBookingEmail/${programCalendarId}/${uid}`, {
      data: notiData, uid: uid
    });
  }


  updateChartDropout(batchId, organizationId, uid) {
    return this.http.post<any>(
      `${environment.apiUrl}/institution/updateChartDropout`,
      {
        batchId,
        organizationId,
        uid
      }
    );
  }

  // PM : program manager 
  // PT : project team  
  // PA : participant  ()
  // Mentor :mentor
  //  PT and PA will not exist at the same request. 
  setNotification(batchId, projectOrgId, participantId,
    title, content, isEmail, isNotificiation,
    isPM, isPT, isMentor, isPA, uid) {
    return this.http.post<any>(`${environment.apiUrl}/institution/sendBatchNotification`, {
      batchId, projectOrgId, participantId, title,
      content, isEmail, isNotificiation,
      isPM, isPT, isMentor, isPA, uid
    });
  }

  async getProjectProgressData(batchId: string, projectTeamId: string) {
    return await this.http.get(`${environment.apiUrl}/institution/project/progressData/${batchId}/${projectTeamId}`, {

    }).toPromise();
  }

  async getProjectProgramProgressData(batchId: string,
    projectTeamId: string, programId: string) {
    return await this.http.get(`${environment.apiUrl}/v2/institution/project/programProgressData/${batchId}/${projectTeamId}/${programId}`, {

    }).toPromise();
  }

  createPresentationBlock(params) {
    return this.http.post<any>(
      `${environment.apiUrl}/v2/institution/presentationBlock`,
      params
    );
  }

  createProgramV2(params) {
    return this.http.post<any>(
      `${environment.apiUrl}/v2/institution/createProgramV2`,
      params
    );
  }

  async getBatchProfile(batchId: string) {


    let profile$ = this.profileCacheSrv.getValue(batchId);

    if (!profile$) {
      profile$ = await this.http.get(`${environment.apiUrl}/v2/organization/batch/profile/${batchId}`,
        {}).pipe(
          map((response: any) => response), shareReplay(1)).toPromise();
      this.profileCacheSrv.setValue(profile$, batchId);
    }

    return profile$;
  }

  async getMentorList(batchId: string) {
    return await this.http.get(`${environment.apiUrl}/v2/organization/batch/mentors/${batchId}`, {

    }).toPromise();
  }

  async getStartupList(batchId: string) {
    return await this.http.get(`${environment.apiUrl}/v2/organization/batch/startups/${batchId}`, {

    }).toPromise();
  }

  async getParticipantsList(batchId: string) {
    return await this.http.get(`${environment.apiUrl}/batch_user/user_profile/${batchId}`, {

    }).toPromise();
  }

  //invitation
  async getOrgByType(batchId: string, type: string) {
    return await this.http.get(`${environment.apiUrl}/v2/organization/batch/type/${batchId}/${type}`, {

    }).toPromise();
  }

  removeParticipant(id, batchId) {
    return this.http.delete<any>(`${environment.apiUrl}/batch_user/delete_participant/${batchId}/${id}`);
  }
}

