import { Injectable } from '@angular/core';
import { BehaviorSubject, AsyncSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DataService {
    private launchModal = new BehaviorSubject(false);
    currentLaunchModal = this.launchModal.asObservable();

    private showSideBar = new BehaviorSubject(true);
    currentShowSideBar = this.showSideBar.asObservable();

    private showHeader = new BehaviorSubject(true);
    currentShowHeader = this.showHeader.asObservable();

    private showMilestone = new BehaviorSubject(true);
    currentShowMilestone = this.showMilestone.asObservable();

    private showAdminSideBar = new BehaviorSubject(true);
    currentShowAdminSideBar = this.showAdminSideBar.asObservable();

    private UserImageUrl = new BehaviorSubject<string>('../../assets/userupload.png');
    currentUserImageUrl = this.UserImageUrl.asObservable();

    private change = new BehaviorSubject(false);
    status = this.change.asObservable();

    private milestoneStatus = new BehaviorSubject(false);
    mStatus = this.milestoneStatus.asObservable();

    private todo = new BehaviorSubject([]);
    todolist = this.todo.asObservable();
    private fetchToDoStatus = new BehaviorSubject(false);
    toDoStatus = this.fetchToDoStatus.asObservable();

    private eduDelete = new BehaviorSubject('');
    eduKey = this.eduDelete.asObservable();

    private eduEdit = new BehaviorSubject([]);
    eduContent = this.eduEdit.asObservable();

    private items = new BehaviorSubject([]);
    checkoutContent = this.items.asObservable();

    private toDoChange = new BehaviorSubject(false);
    numberOfnoti = this.toDoChange.asObservable();

    private changOrgInfo = new BehaviorSubject(false);
    orgInfo = this.changOrgInfo.asObservable();

    orgTypeAsyncSubject = new AsyncSubject<{}>();
    widgetAsyncSubject = new AsyncSubject<{}>();

    private widgets = new BehaviorSubject([]);
    widgetsList = this.widgets.asObservable();

    institutionTypeAsyncSubject = new AsyncSubject<{}>();

    private _bizreviewSubject = new Subject<any>();
    bizReview = this._bizreviewSubject.asObservable();

    private onGetCurrentUser = new BehaviorSubject(false);
    onGetuser = this.onGetCurrentUser.asObservable();

    private projectTeams = new BehaviorSubject([]);
    projectTeamslist = this.projectTeams.asObservable();

    private experts = new BehaviorSubject([]);
    expertslist = this.experts.asObservable();

    private batchs = new BehaviorSubject([]);
    batchslist = this.batchs.asObservable();

    private _refreshMenu = new BehaviorSubject(false);
    refreshMenu = this._refreshMenu.asObservable();

    private _openDialog = new BehaviorSubject([]);
    openDialog = this._openDialog.asObservable();

    private _deleteDialog = new BehaviorSubject([]);
    deleteDialog = this._deleteDialog.asObservable();

    private _getPhaseData = new BehaviorSubject(false);
    getPhaseData = this._getPhaseData.asObservable();

    private _openAddCriteriaDialog = new BehaviorSubject([]);
    openAddCriteriaDialog = this._openAddCriteriaDialog.asObservable();

    private _openAddSubmissionDialog = new BehaviorSubject([]);
    openAddSubmissionDialog = this._openAddSubmissionDialog.asObservable();

    private _openTaskDialog = new BehaviorSubject([]);
    openTaskDialog = this._openTaskDialog.asObservable();

    private _openEditTaskDialog = new BehaviorSubject([]);
    openEditTaskDialog = this._openEditTaskDialog.asObservable();

    private _getResourceData = new BehaviorSubject(false);
    getResourceData = this._getResourceData.asObservable();

    private _scrollToSubmission = new BehaviorSubject([]);
    scrollToSubmission = this._scrollToSubmission.asObservable();

    private _assignBatch = new BehaviorSubject({});
    assignBatch = this._assignBatch.asObservable();

    private _updateBatchStatus = new BehaviorSubject({});
    updateBatchStatus = this._updateBatchStatus.asObservable();

    private _updateStripeSubscription = new BehaviorSubject({});
    updateStripeSubscription = this._updateStripeSubscription.asObservable();

    private _currentTab = new BehaviorSubject<number>(1);
    currentTab = this._currentTab.asObservable();

    private _settingsTab = new BehaviorSubject(1);
    settingsTab = this._settingsTab.asObservable();

    private _billingOrgId = new BehaviorSubject('');
    billingOrgId = this._billingOrgId.asObservable();

    private collapseProgramBtn = new BehaviorSubject(true);
    currentCollapseProgramBtn = this.collapseProgramBtn.asObservable();

    onChangeCollapseProgramBtn(value) {
        this.collapseProgramBtn.next(value);
    }

    getCurrentCollapseProgramBtn() {
        return this.currentCollapseProgramBtn;
    }

    onChangeLaunchModalStatus(value) {
        this.launchModal.next(value);
    }

    getLaunchModalStatus() {
        return this.currentLaunchModal;
    }

    onChangeBillingOrgId(value) {
        this._billingOrgId.next(value);
    }

    getBillingOrgId() {
        return this.billingOrgId;
    }

    onChangeSettingTab(value) {
        this._settingsTab.next(value);
    }

    getSettingsTab() {
        return this.settingsTab;
    }

    onUpdateCurrentTab(value) {
        this._currentTab.next(value);
    }

    getCurrentTab() {
        return this.currentTab;
    }

    onUpdateStripeSubscription(value) {
        this._updateStripeSubscription.next(value);
    }

    getUpdateStripeSubscription() {
        return this.updateStripeSubscription;
    }

    onUpdateBatchStatus(value) {
        this._updateBatchStatus.next(value);
    }

    getUpdatedBatchStatus() {
        return this.updateBatchStatus;
    }

    onAssignBatch(value) {
        this._assignBatch.next(value);
    }

    getAssignBatch() {
        return this.assignBatch;
    }

    openTaskModalDialog(value) {
        this._openTaskDialog.next(value);
    }

    openEditTaskModalDialog(value) {
        this._openEditTaskDialog.next(value);
    }

    getOpenEditTaskModalDialog() {
        return this.openEditTaskDialog;
    }
    //--------------------------
    openPhaseDialog(value) {
        this._openDialog.next(value);
    }

    openSubmissionDialog(value) {
        this._openAddSubmissionDialog.next(value);
    }

    openCriteriaDialog(value) {
        this._openAddCriteriaDialog.next(value);
    }

    openDeleteDialog(value) {
        this._deleteDialog.next(value);
    }

    getPhase(value) {
        this._getPhaseData.next(value);
    }

    getResource(value) {
        this._getResourceData.next(value);
    }

    onScroll(value) {
        this._scrollToSubmission.next(value);
    }

    updateHeaderMenu(status) {
        this._refreshMenu.next(status);
    }

    bizreviewEvent(event) {
        this._bizreviewSubject.next(event);
    }

    constructor(private http: HttpClient) { }

    //true : refresh  false: no
    setRefreshPage(value) {
        this._refreshMenu.next(value);
    }

    setProjectTeams(projectTeams) {
        this.projectTeams.next(projectTeams);
    }

    setExperts(experts) {
        this.experts.next(experts);
    }

    setBatchs(batchs) {
        this.batchs.next(batchs);
    }

    setToDo(todo) {
        this.todo.next(todo);
        this.updateMileStones(true);
    }

    setWidgets(widgets) {
        this.widgets.next(widgets);
    }

    updateMileStones(status) {
        this.milestoneStatus.next(status);
    }

    editUrl(newUrl) {
        this.UserImageUrl.next(newUrl);
        // this.defaultProfilePic.next(newUrl)
        this.update(true);
    }

    update(status) {
        this.change.next(status);
    }

    updateOrg(status) {
        this.changOrgInfo.next(status);
    }

    updateNumberOfNotifcation(status) {
        this.toDoChange.next(status);
    }

    updateUser(status) {
        this.onGetCurrentUser.next(status);
    }

    deleteEdu(eduContentKey) {
        this.eduDelete.next(eduContentKey);
    }


    editEdu(eduContent) {
        this.eduEdit.next(eduContent);
    }

    checkout(data) {
        this.items.next(data);
    }

    changeSideBar(value: boolean) {
        this.showSideBar.next(value);
    }

    changeHeader(value: boolean) {
        this.showHeader.next(value);
    }

    changeMilestone(value: boolean) {
        this.showMilestone.next(value);
    }

    changeAdminSideBar(value: boolean) {
        this.showAdminSideBar.next(value);
    }

    getNotificationById(id: string) {
        return this.http.get(`${environment.apiUrl}/notification/DataById/${id}`);
    }

    getNotificationByUid(uid) {
        return this.http.post<any>(`${environment.apiUrl}/notification/getDataByUid`,
            {
                uid: uid
            });
    }

    getAllToDoNotificationByUid(uid) {
        return this.http.post(`${environment.apiUrl}/notification/getAllToDoNotificationByUid`,
            {
                uid: uid
            });
    }

    getAllCalendarNotificationByUid(uid) {
        return this.http.post(`${environment.apiUrl}/notification/getAllCalendarNotificationByUid`,
            {
                uid: uid
            });
    }

    getAllRestartNotificationByUid(uid) {
        return this.http.post(`${environment.apiUrl}/notification/getAllRestartNotificationByUid`,
            {
                uid: uid
            });
    }

    getToDoNotificationByUid(uid) {

        return this.http.post<any>(`${environment.apiUrl}/notification/getToDoNotificationByUid`,
            {
                uid: uid
            });
    }

    getToDoNotificationById(id) {

        return this.http.post(`${environment.apiUrl}/notification/getToDoNotificationById`,
            {
                id: id
            });
    }

    createNewInv(data) {
        return this.http.post(`${environment.apiUrl}/notification/createNewInv`,
            {
                invitation: data
            });
    }

    createNewToDoNotification(todo) {
        return this.http.post(`${environment.apiUrl}/notification/createNewToDoNotif`,
            {
                todo: todo
            });
    }

    createNewScheduleNotif(event) {
        return this.http.post(`${environment.apiUrl}/notification/createNewScheduleNotif`,
            {
                event: event
            });
    }

    updateNotificationStatus(data) {
        return this.http.post(`${environment.apiUrl}/notification/updateNotificationStatus`,
            {
                data: data
            });
    }

    rejectNotification(data) {
        return this.http.post(`${environment.apiUrl}/notification/rejectInvite`,
            {
                data: data
            });
    }

    updateToDoNotificationStatus(todo) {
        return this.http.post(`${environment.apiUrl}/notification/updateToDoNotificationStatus`,
            {
                data: todo
            });
    }

    updateCalendarNotificationStatus(todo) {
        return this.http.post(`${environment.apiUrl}/notification/updateCalendarNotificationStatus`,
            {
                data: todo
            });
    }

    private subject = new Subject<any>();

    setTag(tag) {
        this.subject.next(tag);
    }
    getTag(): Observable<any> {
        return this.subject.asObservable();
    }

    triggerGetAllToDo(status) {
        this.fetchToDoStatus.next(status);
    }

    getAsyncSubject() {
        return this.orgTypeAsyncSubject.asObservable();
    }

    getWidgetAsyncSubject() {
        return this.widgetAsyncSubject.asObservable();
    }

    getInstitutionTypeAsyncSubject() {
        return this.institutionTypeAsyncSubject.asObservable();
    }

}
