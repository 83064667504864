import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'ui-dialog',
  animations: [
    trigger('openDialog', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('hide => show', [
        animate('0.3s')
      ]),
      transition('show => hide', [
        animate('0.15s')
      ])
    ])
  ],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})


export class DialogComponent implements OnInit {
  @Input() class: string;
  @Input() show: boolean;
  @Input() type: string;
  @Input() fullscreen: string;
  @Output() close = new EventEmitter();

  constructor() { }

  ngOnInit() {

  }

  closeDialog() {
    this.close.emit();
  }

}
