import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  public open = new BehaviorSubject<boolean>(false);
  public checkoutArray = new BehaviorSubject<any[]>([]);
  public creditAmount = new BehaviorSubject<Number>(0);

  constructor() { }

  changeCheckoutStatus(value: boolean) {
    this.open.next(value);
  }

  getCheckoutStatus(): Observable<boolean> {
    return this.open.asObservable();
  }

  changeCheckoutArray(value) {
    this.checkoutArray.next(value);
  }

  getCheckoutArray(): Observable<any[]> {
    return this.checkoutArray.asObservable();
  }

  changeCreditAmount(value) {
    this.creditAmount.next(value);
  }

  getCreditAmount(): Observable<Number> {
    return this.creditAmount.asObservable();
  }
}
