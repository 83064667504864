import { User } from "@common/models";
import { FileUpload } from "@common/models";
export class ProfessionalServices {
    id: string;
    mainContact = false;
    professionalServicesName = '';
    professionalServicesContact = '';
    professionalServicesEmail = '';
    identify = '';
    capacity = '';
    capacityOthers = '';
    servFunc = '';
    servFuncOthers = '';
    servProvided = false;
    uniqueCompetitive = '';
    uniqueCompetitiveOthers = '';
    pricingModel = '';
    pricingModelOthers = '';
    priceSchedule: FileUpload;
    serveRegion = '';
    serveBase = '';
    serveBaseOthers = '';
    corpDeck: FileUpload;
    advisorType = '';
    advisorTypeOthers = '';
    advisorIndustryFocus = '';
    advisorIndustryFocusOthers = '';
    fieldExperience = '';
    adviseOutside = '';
    wkConsultation = '';
    consultation = '';
    consultationsMode = '';
    consultationsModeOthers = '';
    advisorySuccess = '';
    advisorySuccessOthers = '';
    compensation = '';
    compensationOthers = '';
    acceptingFactors = '';
    mentor = '';
    mentorOthers = '';
    createVendorComp: boolean;
    hasCompanyProfile = false;
    createdAt: string;
    createdBy: User;
    updatedAt: string;
    updatedBy: User;
    uid: string;
    hasServiceProviderForm = true;
}
