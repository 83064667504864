import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ui-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectFilterComponent implements OnInit {
  @Input() class?: string;
  @Input() data!: any[];
  @Input() filterText?: string;
  @Input() selectId: any;
  @Input() avatarType?: string;
  @Input() isEditable?: boolean = true;
  @Input() backgroundcolor?: string;

  @HostListener('document:click', ['$event'])
  clickedOut(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) return
    if (this.isExpanded) return this.isExpanded = !this.isExpanded;
  }

  @Output() select = new EventEmitter();

  isExpanded: boolean = false;
  selected: any;

  options: any[] = [];

  constructor(
    private eRef: ElementRef,
    private router: Router) { }

  ngOnInit() {
    if (this.data) {
      this.options = [...this.data];
      this.selected = this.options.filter(a => a.id === this.selectId)[0]

      if (this.router.url.includes('/program') && this.selected.length > 0) {
        this.selected = this.selected[0];
      }
      // this.selected = this.selected[0];
    }
  }

  onClick() {
    if (this.isEditable)
      this.isExpanded = !this.isExpanded;
  }

  onSelect(id: any) {
    this.selected = this.options.filter(obj => obj.id === id)[0];
    this.select.emit(id);
    this.onClick();
  }

}
