import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { User } from '@common/models';
import { Router } from '@angular/router';
import { ConfigService, UtilityService } from '@common/services';

@Component({
    selector: 'members-card',
    templateUrl: './members-card.html',
    styleUrls: ['members-card.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MembersCardComponent implements OnInit {
    @Input() id;
    @Input() name;
    @Input() type;
    @Input() logo;
    @Input() personality;
    @Input() hipster;
    @Input() hacker;
    @Input() hustler;
    @Input() owner;
    @Input() uid;
    @Input() orgType;
    @Input() isDeleted: boolean = false;
    @Input() hideDeleteBtn;

    @Input() isSliderPersonalityCard: boolean = false;

    @Output() removeUser = new EventEmitter<any>();
    @Output() remove = new EventEmitter<any>();
    @Output() swap = new EventEmitter<any>();

    currentUser;
    isOpenProfile: boolean = false;

    constructor(
        private router: Router,
        public utility: UtilityService,
        public globals: ConfigService,
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
    }

    onRemove(event: any) {
        event.stopPropagation();
        this.removeUser.emit();
    }

    onBatchRemove() {
        this.removeUser.emit();
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

    getmBIndicator(index: string) {
        let _mBIndicator = "";
        switch (index) {
            case "1":
                _mBIndicator = "ISTJ";
                break;
            case "2":
                _mBIndicator = "ISTP";
                break;
            case "3":
                _mBIndicator = "ISFP";
                break;
            case "4":
                _mBIndicator = "ISFJ";
                break;
            case "5":
                _mBIndicator = "INFJ";
                break;
            case "6":
                _mBIndicator = "INTJ";
                break;
            case "7":
                _mBIndicator = "INFP";
                break;
            case "8":
                _mBIndicator = "INTP";
                break;
            case "9":
                _mBIndicator = "ESTP";
                break;
            case "10":
                _mBIndicator = "ESTJ";
                break;
            case "11":
                _mBIndicator = "ESFJ";
                break;
            case "12":
                _mBIndicator = "ESFP";
                break;
            case "13":
                _mBIndicator = "ENFP";
                break;
            case "14":
                _mBIndicator = "ENTP";
                break;
            case "15":
                _mBIndicator = "ENFJ";
                break;
            case "16":
                _mBIndicator = "ENTJ";
                break;
        }
        return _mBIndicator;
    }

    onProfile() {
        if (this.orgType === 'consult') {
            this.router.navigate(['/w/mentor-profile/' + this.id]);
            return
        }
        if (this.orgType === 'project') {
            this.router.navigate(['/w/organization-profile/' + this.id]);
        }
        if (this.orgType !== 'consult' || this.orgType !== 'project') {
            this.router.navigate(['/w/profile/' + this.id]);
        }
    }
    onProfileSlider() {
        this.isOpenProfile = true
    }

    notAllowTargetScrollable(element: any, condition: boolean) {
        if (condition) {
            element.style.overflow = 'hidden';
        } else {
            element.removeAttribute('style');
        }
    }


    onToggleProfile(event, type) {
        this.isOpenProfile = !this.isOpenProfile;
        this.notAllowTargetScrollable(document.body, this.isOpenProfile);
    }

    onSwap() {
        this.swap.emit();
    }

}