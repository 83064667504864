export class AccessRole {
    id: String;
    roleName: string;
    orgId: string;
    batchId: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}
