export class Organization {
    id: string;
    organizationId: string;
    organizationTypeId: string;
    owner: string;
    title: string;
    titleOthers: string;
    address1: string;
    address2: string;
    postalCode: string;
    uen: string;
    name: string;
    website: string;
    approve: number;
    country: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    deleted: string;
    compLogo: string;
    key: string;
    roleId: number;
    organizationType: string;
    companyNumber: string;
    type: number; // 0 = startup || 1 = funder || 2 = institution || 3 = professional services
    companyEmail: string;
    linkedin: string;
    facebook: string;
    instagram: string;
    companystructureLogo = '';
    batch: any;
    countryIncorp: string;
    startup: any = [];
    bio: string;
    market: string;
    twitter: string;
    medium: string;
}

// MySQL
export class NewOrganization {
    id: string;
    organizationId: string;
    organizationTypeId: string;
    title: string;
    titleOthers: string;
    address1: string;
    address2: string;
    postalCode: string;
    uen: string;
    name: string;
    website: string;
    companyEmail: string;
    companyNumber: string;
    linkedin: string;
    facebook: string;
    instagram: string;
    approve = 1;
    country: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    type: number; // 0 = startup || 1 = funder || 2 = institution || 3 = professional services
    deleted = 0;
    compLogo = '';
    roleId: number;
    industry: string;
    industryOthers: string;
    companySize: number;
    tagline: string;
    description: string;
    uenVerified = false;
    contactPerson: string;
    contactPersonNumber: string;
    pastOrg: string;
    pastPosition: string;
    yearOfExp: string;
    bio: string;
    markets: string;
    twitter: string;
    medium: string;
    locations: string;
    companystructureLogo = '';
    owner: string;
    mentoring = true;
}

export class OrganizationType {
    Id: string;
    organizationType: string;
    available: string;
}


