import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DataService, OrganizationService } from '@common/services';
import { StripePaymentService } from '@common/services/stripePayment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import moment from 'moment';

@Component({
  selector: 'lab-dialog-add-batch-plan',
  templateUrl: './dialog-add-batch-plan.component.html',
  styleUrls: ['./dialog-add-batch-plan.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogAddBatchPlanComponent implements OnInit {
  // @Input() batches;
  @Input() show;
  @Input() orgId;
  @Input() selectedPlan;
  @Input() selectedPlanType;
  toastRef;
  step = 1;
  batches = [];
  searchText;
  batchStatusForm: FormGroup;
  selected;
  type = 'medium';
  extendEndDate;
  @Output() close = new EventEmitter();
  @Output() updateBatchStatus = new EventEmitter();
  @Output() updatePlanTable = new EventEmitter();


  constructor(
    private orgSrv: OrganizationService,
    private paymentService: StripePaymentService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private dataSrv: DataService
  ) { }

  ngOnInit(): void {
    // this.orgSrv.getOrganizationBatch(this.orgId).subscribe(res => {
    //   console.log(res);
    //   this.batches = res['data'];
    // })

    this.batchStatusForm = this.fb.group({
      batchStatus: ['', Validators.required]
    });

    this.paymentService.getOrganizationBatch(this.orgId).subscribe(res => {
      this.batches = res['data'];
    })
  }

  get f() {
    return this.batchStatusForm.controls;
  }


  onCancel() {
    this.close.emit();
  }

  onNext() {
    if (this.selectedPlanType === 'batchCreditPack') {
      // let startDate = this.selected.startDate;
      // let endDate = this.selected.demoDate;

      // let startDateMoment = moment(Number(startDate));
      // let endDateMoment = moment(Number(endDate));

      // let diff = Math.ceil(endDateMoment.diff(startDateMoment, 'month', true));

      if (this.selected.batchStatus === 2 && this.selected.plan_id.includes('pi_')) {
        this.step = 1.5;
        this.extendEndDate = moment().add(this.selectedPlan.duration, 'months').format("D MMMM YYYY");
      } else {
        this.step = 2;
      }
       
    } else {
      this.step = 2;
    }
  }

  onNext2() {
    this.step = 2;
  }

  onPrev() {
    this.step = 1 ;
  }

  onConfirm() {
    if (this.f.batchStatus.status === 'INVALID') {
      return;
    }

    const totalCredit = this.selectedPlan.total_credit;
    const creditUsed = this.selectedPlan.credit_used;
    const creditId = this.selectedPlan.credit_id;
    const batchId = this.selected.id;

    if (this.f.batchStatus.status === 'VALID') {
      if (creditUsed < totalCredit) {
        if (this.selectedPlanType === 'subscription') {
          const subscriptionId = this.selectedPlan?.stripe_subscription_id;

          this.paymentService.addBatchToSubscription(subscriptionId, batchId, creditId).subscribe(res => {
            if (res['result'] === 'successful') {
              this.step = 3;

              // Add batch card in the subscription array in billing page
              this.updatePlanTable.emit({
                planId: subscriptionId,
                batch: this.selected,
                selectedPlanType: this.selectedPlanType
              });

              if (this.f.batchStatus.value === 1) {
                this.paymentService.launchBatch(this.selected.id, creditId, subscriptionId).subscribe(res => {
                  if (res['result'] === 'successful') {
                    this.updateBatchStatus.emit({
                      planId: subscriptionId,
                      batch: this.selected,
                      selectedPlanType: this.selectedPlanType
                    })
                    // Call data service to updatebatch status button
                    this.dataSrv.onUpdateBatchStatus({
                      batchId: this.selected.id,
                      status: 'running',
                      planId: subscriptionId
                    });
                  }
                })
              } else {
                // Call data service to updatebatch status button
                this.dataSrv.onUpdateBatchStatus({
                  batchId: this.selected.id,
                  status: 'notYetRunning',
                  planId: subscriptionId
                });
              }
              this.showActionMsg('Added Batch Successful', `${this.selected.name} has been added successfully into the plan`);
            }
          })
        }

        if (this.selectedPlanType === 'batchCreditPack') {
          const planId = this.selectedPlan?.stripe_plan_id;

          this.paymentService.addBatchToCreditPack(planId, batchId, creditId).subscribe(res => {
            if (res['result'] === 'successful') {
              this.step = 3;

              // Add batch card in the batch credit packs array in billing page
              this.updatePlanTable.emit({
                planId: creditId,
                batch: this.selected,
                selectedPlanType: this.selectedPlanType
              });

              if (this.f.batchStatus.value === 1) {
                this.paymentService.launchBatch(this.selected.id, creditId, planId).subscribe(res => {
                  if (res['result'] === 'successful') {
                    this.updateBatchStatus.emit({
                      planId: creditId,
                      batch: this.selected,
                      selectedPlanType: this.selectedPlanType
                    });

                    // Call data service to updatebatch status button
                    this.dataSrv.onUpdateBatchStatus({
                      batchId: this.selected.id,
                      status: 'running',
                      planId: creditId
                    });
                  }
                })
              } else {
                this.dataSrv.onUpdateBatchStatus({
                  batchId: this.selected.id,
                  status: 'notYetRunning',
                  planId: creditId
                });
              }

              this.showActionMsg('Added Batch Successful', `${this.selected.name} has been added successfully into the plan`);
            }
          })
        }
      } else {
        this.showActionMsg('Fail', 'There are no more credits, please order more credits.');
      }
    }

  }

  //TODO function  redirect user to program page

  onSearchChange(event) {
    // console.log(event)
    // this.searchText = event.target.value;
  }

  onKeydown(event: any) {
    const key = event.key || event.which
    // press 'Enter' to do research with filter option
    if (key === 13 || key === 'Enter') return console.log({ keywords: this.searchText });
  }

  onSelect(team) {
    this.selected = team;
  }

  redirectToProgram() {
    this.router.navigate(["/w/batch/profile"], {
      queryParams: {
        id: this.selected['id'],
        batchId: this.selected['id'],
        orgId: this.orgId,
        orgType: 'batch'
      }
    })
  }

  showActionMsg(title, msg) {
    this.toastRef = this.toastr.show(msg, title, {
      tapToDismiss: true,
      toastClass: 'toastpayment ',
      titleClass: 'green-title',
      closeButton: false,
      timeOut: 5000
    });
  }
}
