import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionHeaderComponent implements OnInit {

  @Input() class?: string;
  @Input() title: string;
  constructor() { }

  ngOnInit() {
  }

}
