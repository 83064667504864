import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthUtilsService, AuthenticationService } from '@modules/auth/services';
import { NavigationService } from '@modules/navigation/services';
import { Subscription } from 'rxjs';
import {

    ConfigService,
} from '@common/services';

@Component({
    selector: 'lab-top-nav',
    templateUrl: './lab-top-nav.component.html',
    styleUrls: ['lab-top-nav.component.css', './../../styles/styles.css'],
    encapsulation: ViewEncapsulation.None
})
export class LabTopNavComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    isLoggedIn = false;
    isOnPost = false;
    isMenuCollapsed = true;
    currentTab = '';
    isMobileNav = false;
    currentUser: any;
    haveCurrentUser = false;

    constructor(
        private configSrv: ConfigService,
        private navigationService: NavigationService,
        private authUtilsService: AuthUtilsService,
        private authenticationSrv: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router
    ) { }
    ngOnInit() {
        this.subscription.add(
            this.navigationService.currentComponent$().subscribe(currentComponentName => {
                this.isOnPost = currentComponentName === 'PostComponent';
            })
        );
        this.subscription.add(
            this.authUtilsService.isLoggedIn$().subscribe(isLoggedIn => {
                this.isLoggedIn = isLoggedIn;
                this.currentUser = this.authenticationSrv.getUserData();
            })
        );

        this.authUtilsService.checkToken();
        this.currentUser = this.authenticationSrv.getUserData();

    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    editPost() {
        this.router.navigateByUrl(`/edit/${this.route.snapshot.params.post}`);
    }
    changeTab(tab, url) {
        this.router.navigate([url], {
            queryParams: {
                tab: tab
            }
        });
    }
    checkRoute() {
        if (this.currentUser) {
            /*  this.router.navigate(["." + this.configSrv.defaultFirstPage], {
                 queryParams: {}
             }); */
            let element = document.getElementById('workspace')
            if (element) {
                element.click();
            }
        } else if (!this.currentUser) {
            this.router.navigate(['./'], {
                queryParams: {}
            });
        }
    }

    toggleMobileNav() {
        this.isMobileNav = !this.isMobileNav;
    }
}
