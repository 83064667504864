import { FooterComponent } from './footer/footer.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { LabTopNavComponent } from './lab-top-nav/lab-top-nav.component';
import { AdminSidebarComponent } from './admin_sidebar/admin_sidebar.component';

export const containers = [
    TopNavComponent,
    FooterComponent,
    LabTopNavComponent,
    AdminSidebarComponent
];

export * from './top-nav/top-nav.component';
export * from './footer/footer.component';
export * from './lab-top-nav/lab-top-nav.component';
export * from './admin_sidebar/admin_sidebar.component';