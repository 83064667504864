import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {

    uploadFileSizeLimit = 10485760;
    uploadImageSizeLimit = 5242880;
    joke;

    countries;
    industries;
    // countries = this.http.get('./../../assets/countries.json');
    // industries = this.http.get('./../../assets/industries.json');

    /*   CountryOptions: [];*/
    constructor(private http: HttpClient) { }

    public init(): Promise<any> {

        const _countries = new Promise((resolve, reject) => {
            this.http
                .get('./../../assets/countries.json')
                .subscribe(response => {
                    this.countries = response;

                    resolve(true);
                })
        });

        const _industries = new Promise((resolve, reject) => {
            this.http
                .get('./../../assets/industries.json')
                .subscribe(response => {
                    this.industries = response;

                    resolve(true);
                })
        });

        return Promise.all([_countries, _industries]);

    }

    public countryOptions() {
        return this.countries;
    }

    public industryOptions() {
        return this.industries;
    }
    public getCountryOptions(): Observable<any> {
        return this.http.get('./../../assets/countries.json');;
    }

    public getIndustryOptions(): Observable<any> {
        return this.http.get('./../../assets/industries.json');
    }
}
