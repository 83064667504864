export class Purchase {
    id: string;
    stripePaymentId: string;
    productId: string;
    itemName: string;
    itemPrice: number;
    itemIconPath: string;
    quantity: number;
    total: number;
    type: string;
    widgetId: string;
    status: string;
    orgId: string;
    orgName: string;
    orgTypeId: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
}
