import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabsService {

  tabs = [
    {
      id: 1,
      text: 'Deliverable',
      selected: true,
    },
    {
      id: 2,
      text: 'Completed',
      selected: false,
    }
  ]
  
  constructor() { }

  getData() {
    return this.tabs
  }

  selected(id: any) {
    this.tabs.forEach( (tab ) => {
      const status = id === tab.id
      tab.selected = status
    })
  }
}
