import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionService } from '@common/services';

@Component({
    selector: 'batch-banner',
    templateUrl: './batch-banner.component.html',
    styleUrls: ['batch-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BatchBannerComponent implements OnInit {
    @Input() name: string = "";
    @Input() description: string = "";
    @Input() startDate: string = "";
    @Input() demoDate: string = "";

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private institutionSrv: InstitutionService
    ) {

    }

    ngOnInit() {

    }

    onResetPage() {
       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //    this.router.onSameUrlNavigation = 'reload';
       this.router.navigate(['./'], {
        relativeTo: this.route,
        queryParamsHandling: "merge"
       })
    }
}