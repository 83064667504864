import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AdminService {

    constructor(
        private http: HttpClient
    ) {
    }

    getQuoteOfTheDay() {
        return this.http.get(`${environment.apiUrl}/admin/getQuoteOfTheDay`,
            {

            });
    }

    createQuoteOfTheDay(data) {
        return this.http.post(`${environment.apiUrl}/admin/addQuote`,
            {
                data: data
            });
    }

    updateQuoteOfTheDay(data) {
        return this.http.post(`${environment.apiUrl}/admin/updateQuote`,
            {
                data: data
            });
    }

    async getAllEvents() {
        return await this.http.get(`${environment.apiUrl}/admin/getAllEvents`,
            {

            }).toPromise();
    }

    async getUpcomingEvent() {
      return await this.http.get(`${environment.apiUrl}/admin/getUpcomingEvent`,
        {

        }).toPromise();
    }

    async addNewEvent(data) {
        return await this.http.post(`${environment.apiUrl}/admin/addEvent`,
            {
                data: data
            }).toPromise();
    }

    async updateEvent(data) {
        return await this.http.post(`${environment.apiUrl}/admin/updateEvent`,
            {
                data: data
            }).toPromise();
    }

    deleteEvent(eventId, uid) {
        return this.http.post(`${environment.apiUrl}/admin/deleteEvent`,
            {
                eventId: eventId, uid: uid
            });
    }

    async getAllPromotions() {
        return await this.http.get(`${environment.apiUrl}/admin/getAllPromotions`,
            {

            }).toPromise();
    }

    async addNewPromotion(data) {
        return await this.http.post(`${environment.apiUrl}/admin/addPromotion`,
            {
                data: data
            }).toPromise();
    }

    async updatePromotion(data) {
        return await this.http.post(`${environment.apiUrl}/admin/updatePromotion`,
            {
                data: data
            }).toPromise();
    }

    deletePromotion(promotionId, uid) {
        return this.http.post(`${environment.apiUrl}/admin/deletePromotion`,
            {
                promotionId: promotionId, uid: uid
            });
    }

    async getAllShoutouts() {
        return await this.http.get(`${environment.apiUrl}/admin/getAllShoutouts`,
            {

            }).toPromise();
    }

    async addNewShoutout(data) {
        return await this.http.post(`${environment.apiUrl}/admin/addShoutout`,
            {
                data: data
            }).toPromise();
    }

    async updateShoutout(data) {
        return await this.http.post(`${environment.apiUrl}/admin/updateShoutout`,
            {
                data: data
            }).toPromise();
    }

    deleteShoutout(shoutoutId, uid) {
        return this.http.post(`${environment.apiUrl}/admin/deleteShoutout`,
            {
                shoutoutId: shoutoutId, uid: uid
            });
    }

    async addVbpButton(uid,title,description,backgroundColour,link) {
        return await this.http.post(`${environment.apiUrl}/vbpButton/addVbpButton`,
            {
                uid: uid, title : title, description : description, backgroundColour : backgroundColour, link : link
            }).toPromise();
    }
}
