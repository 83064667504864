import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ToDoListService {

  items: Observable<any[]>;
  admins: string[] = [];
  // private status = new BehaviorSubject<string>('');
  private status = new BehaviorSubject<any>('');

  constructor(private http: HttpClient) {
  }

  getToDoByOrgId(orgId: string) {
    return this.http.post(`${environment.apiUrl}/toDo/getDataByOrgIdOrderByDueDate`,
      {
        orgId: orgId
      });
  }

  getDataByOrgIdOrderByUpdatedAt(orgId: string) {
    return this.http.post(`${environment.apiUrl}/toDo/getDataByOrgIdOrderByUpdatedAt`,
      {
        orgId: orgId
      });
  }

  getAllToDo(orgId: string, batchId: string) {
    return this.http.post(`${environment.apiUrl}/toDo/getAllToDo`,
      {
        orgId: orgId, batchId: batchId
      });
  }

  getSingleToDoByTodoId(todoId) {
    return this.http.post(`${environment.apiUrl}/toDo/getSingleToDoByTodoId`,
      {
        todoId: todoId
      });
  }

  insert(params) {
    return this.http.post<any>(`${environment.apiUrl}/toDo/insert`,
      params
      );
  }

  // update(data, userName) {
  //   return this.http.post(`${environment.apiUrl}/toDo/update`,
  //     {
  //       todo: data, userName: userName
  //     });
  // }

   update(params) {
    return this.http.post(`${environment.apiUrl}/toDo/update`,
      params
      );
  }

  

  delete(todoId) {
    return this.http.post(`${environment.apiUrl}/toDo/deleteToDo`,
      {
        todoId: todoId
      });
  }

  getCommentByToDoId(toDoId) {
    return this.http.post(`${environment.apiUrl}/toDo/getCommentByToDoId`,
      {
        toDoId: toDoId
      });
  }

  addToDoComment(params) {
    return this.http.post(`${environment.apiUrl}/toDo/addToDoComment`,
      params
      );
  }

  deleteTodoComment(value) {
    return this.http.post<any>(`${environment.apiUrl}/toDo/deleteTodoCommentById`, value);
    // return this.http.post<any>(`${environment.apiUrl}/toDo/deleteTodoComment`, value);
  }

  updateTodoComment(value) {
    return this.http.post<any>(`${environment.apiUrl}/toDo/updateTodoCommentById`, value);
    // return this.http.post<any>(`${environment.apiUrl}/toDo/updateTodoComment/${id}`, value);
  }

  updateMilestoneStatus(data) {
    return this.http.post(`${environment.apiUrl}/toDo/updateMilestoneStatus`,
      {
        todo: data
      });
  }

  getIndividualToDoByUid(uid) {
    return this.http.post(`${environment.apiUrl}/toDo/getIndividualToDoByUid`,
      {
        uid: uid
      });
  }

  // get todo comment status
  getStatus(): Observable<any> {
    return this.status.asObservable();
  }

  // update todo comment status
  updateStatus({status, todoId}) {
    return this.status.next({
      status,
      todoId
    });
    
  }
}
