import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lab-latest-benefits',
  templateUrl: './latest-benefits.component.html',
  styleUrls: ['./latest-benefits.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LatestBenefitsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
