export class InvitationCode {
    id: string;
    code = '';
    organizationId: string;
    organizationbatchId: string;
    type: string;
    quota = 0;
    used: boolean;
    email: string;
    uid: string;
    createdAt: string;
    createdBy: string;
}
