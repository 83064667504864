import { 
  Component, 
  OnInit, 
  Input, 
  Output, 
  EventEmitter, 
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'ui-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit, AfterViewInit {

  @Input() checked?: boolean;
  @Output() click = new EventEmitter();
  
  isChecked: boolean;

  @ViewChild('switch') switch: ElementRef; 
  
  constructor() { }

  ngOnInit() {
    this.isChecked = this.checked || false;
  }

  ngAfterViewInit() {
  }

  handleToggle(e: any) {
    e.preventDefault();
    e.stopPropagation(); 
    this.isChecked = !this.isChecked;
    this.click.emit(this.isChecked);
  }
}
