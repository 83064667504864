import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UtilityService } from '@common/services';

@Component({
  selector: 'ui-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanCardComponent implements OnInit {
  @Input() class?: string;
  @Input() planType: string;
  @Input() totalCredit: number;
  @Input() creditUsed: number;
  @Input() duration;
  @Input() creditId: string;
  @Input() planId;
  @Input() selected: boolean;

  @Output() handleSelected = new EventEmitter();

  constructor(
    public utilitySrv: UtilityService,

  ) { }

  ngOnInit() {
    
  }

  onSelected(event: any) {
    event.stopPropagation();
    // this.handleSelected.emit(this.creditId);
    this.handleSelected.emit({
      creditId: this.creditId,
      duration: this.duration,
      planId: this.planId,
      totalCredit:this.totalCredit,
      creditUsed: this.creditUsed
    })
  }
 
}
