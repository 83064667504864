
export class WidgetGroup {
    widgetGroupId: string;
    roleId: string;
    groupName: string;
    notes: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    deleted: boolean;
}
