import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { User } from '@common/models';
import { ConfigService, UtilityService } from '@common/services';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'ui-profile-banner',
    templateUrl: './profile-banner.html',
    styleUrls: ['profile-banner.scss'],
})
export class ProfileBannerComponent implements OnInit {
    @Input() logo;
    @Input() name;
    @Input() tagline;
    @Input() styles: any = {};
    @Input() type;

    @Input() currentOrg;

    currentOrgId: string;

    currentUser;

    // @Input() currentOrg: {name: ' ', tagline: string, website: string, facebook: string, linkedin: string, twitter: string, instagram: string};

    constructor(
        public utility: UtilityService,
        public globals: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private toastrSrv: ToastrService,
    ) {
        this.route.queryParams.subscribe(params => {
            this.currentOrgId = params['orgId'];
        });

        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit(): void {

    }

    copyText(name) {
        if (this.type === 'user') {
            if (!this.utility.IsNullOrEmpty(name)) {
                let nameId = name.toLowerCase().split(" ").join("-");
                let selBox = document.createElement("textarea");
                selBox.style.position = "fixed";
                selBox.style.left = "0";
                selBox.style.top = "0";
                selBox.style.opacity = "0";
                selBox.value = location.origin + "/profile/" + this.currentUser.id;
                document.body.appendChild(selBox);
                selBox.focus();
                selBox.select();
                document.execCommand("copy");
                document.body.removeChild(selBox);
                this.toastrSrv.show('copied', 'Share', {
                    disableTimeOut: false,
                    tapToDismiss: true,
                    toastClass: 'toast border-green',
                    closeButton: false,
                });
            } else {
                this.toastrSrv.show('Failed to generate the shared link', 'Share', {
                    disableTimeOut: false,
                    tapToDismiss: true,
                    toastClass: 'toast border-red',
                    closeButton: false,
                });
            }
        }
    }

    onEdit() {

        if (this.type === 'user') {
            this.router.navigate(["./w/edit"], {
                queryParams: {

                },
            });
        } else if (this.type === 'startup') {
            this.router.navigate(["./w/form/startup"], {
                queryParams: {
                    orgId: this.currentOrg.id
                },
            });
        } else if (this.type === 'consult') {
            this.router.navigate(["./w/form/consult"], {
                queryParams: {
                    orgId: this.currentOrg.id
                },
            });
        }
        // else if (this.type === 'org') {
        //     this.router.navigate(["./w/edit-business"], {
        //         queryParams: {
        //             orgId: this.currentOrgId
        //         },
        //     });
        // }
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

}