import {
    Component,
    OnInit,
    Output,
    Input,
    ViewEncapsulation,
    ViewChild,
    ElementRef,
    EventEmitter,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { User } from '@common/models';
import {
    InstitutionService,
    ConfigService,
    UtilityService
} from '@common/services';

@Component({
    selector: 'lab-sidebar-list-avatar',
    templateUrl: './sidebar-list-avatar.component.html',
    styleUrls: ['./sidebar-list-avatar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarListAvatarComponent implements OnInit, OnChanges {
    @Input() class?: string;
    _options: any[] = [];
    @Input() page;
    @Input() set options(value) {
        if (value) {
            this._options = value;
            if (this._options.length > 0 && this.page !== 'booking') {
                this.onSelect(this._options[0]);
            }
        }
    };
    // @Input() page;
    @Input() batchList?: any;
    @Input() displayDropdown;
    @Input() displaySearch;
    @Input() displayList;
    @Input() title;

    @Output() click = new EventEmitter();
    @Output() onSelectTeam = new EventEmitter();

    @ViewChild('searchInput') searchInputRef: ElementRef;


    // for search
    searchText: string = '';
    list: any[] = [];

    selected: string = "";

    currentUser;

    projectTeamList: Array<any> = [];
    loading = false;

    constructor(
        private utilitySrv: UtilityService,
        private changeDetectorRef: ChangeDetectorRef,
        private configSrv: ConfigService,
        private institutionSrv: InstitutionService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

    }

    // for select company
    onSelected(team) {
        // console.log(team);
        this.selected = team;
        this.click.emit(team);
    }

    onSelect(team) {
        // console.log(team);
        this.selected = team;
        this.onSelectTeam.emit(team);
    }

    // for search event
    onSearchChange(event: any) {
        this.searchText = event.target.value;
    }

    onKeydown(event: any) {
        const key = event.key || event.which
        // press 'Enter' to do research with filter option
        if (key === 13 || key === 'Enter') return console.log({ keywords: this.searchText });
    }

    // for clear search event
    onClearSearchText() {
        this.searchText = '';
        this.searchInputRef.nativeElement.focus();
    }

    onGetBatchProjectTeam(batchId) {

        this.loading = true;

        this.institutionSrv.getProjectTeamByBatchId(batchId).subscribe(res => {

            if (res['result'] === 'successful') {
                this.loading = false;
                this.projectTeamList = res['data'];

            } else {
                this.loading = false;

            }
        });
    }

    onSelectedWidget(event) {

        this.projectTeamList = [];
        if (event.organizationType === "project") {

            this.projectTeamList.push({
                name: event.name,
                id: event.id,
                batchId: event.batchId,
                organizationType: event.organizationType,
                compLogo: event.compLogo
            })
            this.selected = this.projectTeamList[0];
            this.click.emit(this.selected);

        } else {
            this.initProjectsDeliverable(event.batchId, event.id);
        }
    }

    initProjectsDeliverable(batchId, orgId) {
        this.institutionSrv.initialProgressData(batchId, orgId).subscribe(
            res => {
                if (res['result'] == "successful") {
                    if (res.projectTeam.length > 0) {

                        this.projectTeamList = res.projectTeam.map(projectTeam => {
                            return {
                                id: projectTeam.orgId,
                                name: projectTeam.orgName,
                                compLogo: projectTeam.compLogo ? this.configSrv.defaultAssetUrl + projectTeam.compLogo : this.configSrv.defaultNoCompanyLogo
                            };
                        });

                        //this.initDeliverable(batchId, this.projectTeamList[0].id)
                        // this.selectedOrgId = this.projectTeamList[0].id;
                    }
                }
            },
            error => console.error("initialProgressData ", error),
            () => {

            }
        );
    }

    // select program
    onSelectedOption(event) {
        this.loading = true;

        this.list = [];
        let matchedList = this.batchList.filter(batch => batch.batchId == event.id)
        matchedList.map(batch => {
            this.list.push({
                name: batch.organizationName,
                id: batch.organizationId,
                batchId: event.id,
                organizationType: batch.organizationType,
                compLogo: this.utilitySrv.IsNullOrEmpty(batch.compLogo) ? this.configSrv.defaultNoCompanyLogo : this.configSrv.defaultAssetUrl + batch.compLogo
            })
        });

        if (this.list.length > 0) {
            this.selected = this.list[0];
            this.click.emit(this.selected);
        }
    }

}
