import { AccessRole } from './accessControl';

import { CoFounder } from './cofounder';
import { Comment } from './comment';
import { DiscussionWidget } from './dicussionWidget';
import { Discussion } from './discussion';
import { Educate } from './educate';
import { Event } from './event';
import { FunderForm } from './funderForm';
import { Group } from './group';
import { Individual } from './individual';
import { Institution } from './institution';
import { Investment } from './investment';
import { Investor } from './investor';
import { InvitationCode } from './invitationCode';
import { Matching } from './matching';
import { MatchingSftQns } from './matchingSftQns';
import { Menu } from './menu';
import { Msg } from './msg';
import { Notification } from './notification';
import { Organization } from './organization';
import { OrganizationBatch } from './organizationBatch.model';
import { OrganizationInfo } from './organizationInfo';
import { Partner } from './partner.model';
import { Paypal } from './paypal';
import { PersonalTraits } from './personal-traits.model';
import { PersonalInvestor } from './personalInvestor';
import { PersonalPersonality } from './personalPersonality';
import { PersonalQuizzes } from './personalQuizzes';
import { PersonalStartup } from './personalStartup';
import { PersonalValue } from './personalValue';
import { PostNotification } from './postNotification';
import { ProfessionalServices } from './professionalServices';
import { ProgramNotification } from './programNotification';
import { Project } from './project';
import { Promotion } from './promotion';
import { Purchase } from './purchase';
import { Quote } from './quoteOfTheDay'
import { Recommendation } from './recommendation';
import { Request } from './request';
import { Review } from './review';
import { Role } from './role';
import { StartUp } from './startup';
import { TimelineOption } from './timelineOption';
import { TodoItem } from './todoItem';
import { UserTour } from './userTour';
import { Vbp } from './vbp';
import { VbpButton } from './vbpButton';
import { VbpPackage } from './vbpPackage';

import { WidgetAccess } from './widgetAccess';


export const models = [
    AccessRole,
    CoFounder,
    Comment,
    DiscussionWidget,
    Discussion,
    Educate,
    Event,
    FunderForm,
    Group,
    Individual,
    Institution,
    Investment,
    Investor,
    InvitationCode,
    Matching,
    MatchingSftQns,
    Menu,
    Msg,
    Notification,
    Organization,
    OrganizationBatch,
    OrganizationInfo,
    Partner,
    Paypal,
    PersonalTraits,
    PersonalInvestor,
    PersonalPersonality,
    PersonalQuizzes,
    PersonalStartup,
    PersonalValue,
    PostNotification,
    ProfessionalServices,
    ProgramNotification,
    Project,
    Promotion,
    Purchase,
    Quote,
    Recommendation,
    Request,
    Review,
    Role,

    StartUp,
    TimelineOption,
    TodoItem,
    UserTour,
    Vbp,
    VbpButton,
    VbpPackage,
    WidgetAccess
];

export * from './accessControl';
export * from './cofounder';
export * from './comment';
export * from './dicussionWidget';
export * from './discussion';
export * from './educate';
export * from './event';
export * from './funderForm';
export * from './group';
export * from './individual';
export * from './institution';
export * from './investment';
export * from './investor';
export * from './invitationCode';
export * from './matching';
export * from './matchingSftQns';
export * from './menu';
export * from './msg';
export * from './notification';
export * from './organization';
export * from './organizationBatch.model';
export * from './organizationInfo';
export * from './partner.model';
export * from './paypal';
export * from './personal-traits.model';
export * from './personalInvestor';
export * from './personalPersonality';
export * from './personalQuizzes';
export * from './personalStartup';
export * from './personalValue';
export * from './postNotification';
export * from './professionalServices';
export * from './programNotification';
export * from './project';
export * from './promotion';
export * from './purchase';
export * from './quoteOfTheDay';
export * from './recommendation';
export * from './request';
export * from './review';
export * from './role';

export * from './startup';
export * from './timelineOption';
export * from './todoItem';
export * from './userTour';
export * from './vbp';
export * from './vbpButton';
export * from './vbpPackage';
export * from './widgetAccess';

