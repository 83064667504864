import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  InstitutionService,
  UtilityService,
  ConfigService
} from '@common/services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-modal-program-deliverable-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        right: '0px'
      })),
      state('close', style({
        opacity: 0,
        right: '-50px'
      })),
      transition('open => close', [
        animate('.3s')
      ]),
      transition('close => open', [
        animate('.3s')
      ])
    ])
  ],
  templateUrl: './modal-program-deliverable-list.component.html',
  styleUrls: ['./modal-program-deliverable-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalProgramDeliverableListComponent implements OnInit {
  @Input() open: boolean;
  @Input() currentUser: any;
  //type: projectTeam, mentor, programmer 
  // noted projectTeam cannot rating
  @Input() type: string;
  public _program: any;

  @Input() set program(object) {

    this._program = object;
    this.projectTeamList = [];
    if (this._program) {
      this.onResetFilter();
      if (this._program.organizationType === "project") {

        this.initDeliverable(this._program.batchId,
          this._program.id, this._program.id);
        this.team = this._program;
      } else {

        this.initProjectsDeliverable(this._program.batchId,
          this._program.id, this._program.id);
      }
    }
  }
  @Output() close = new EventEmitter();
  @Output() openComments = new EventEmitter();


  @ViewChild('filterList') filterList: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;

  public isOpenDialog: boolean = false;
  public isOpenDialogAttach: boolean = false;
  public isOpenDialogLink: boolean = false;
  public isOpenDialogComment: boolean = false;

  loading: boolean = true;
  animation = 'pulse';
  toastRef;
  count: number = 0;
  searchText: string = '';
  filterType: string = 'all';

  comments: any[] = [];
  isShowPivot: boolean = false;

  projectTeamList: any[] = [
  ]

  ratingTypeList: any[] = [];

  //submission
  programs: any;
  deliverable: any;
  selectedDeliverable: any;
  // moentor
  selectedOrgId: string = "";
  displayProgrames: any[] = [];

  team: any;

  constructor(
    private toastr: ToastrService,
    private institutionSrv: InstitutionService,
    private utilitySrv: UtilityService,
    private cdf: ChangeDetectorRef,
    private configSrv: ConfigService
  ) { }

  ngOnInit() {
    // get ratingType
    this.institutionSrv.getInstitutionType().then(
      res => {
        if (res.result === 'successful') {
          if (res["ratingType"]) {
            this.ratingTypeList = res["ratingType"];
          }
        }
      }
    ).catch(error => {
      console.log('getInstitutionType Error: ', error)
    });
  }

  initProjectsDeliverable(batchId, orgId, programId) {
    this.institutionSrv.initialProgromProgressData(batchId, orgId, programId).then(
      res => {

        if (res['result'] == "successful") {
          if (res.projectTeam.length > 0) {

            this.projectTeamList = res.projectTeam.map(projectTeam => {
              return {
                id: projectTeam.orgId,
                name: projectTeam.orgName,
                compLogo: projectTeam.compLogo ? this.configSrv.defaultAssetUrl + projectTeam.compLogo : this.configSrv.defaultNoCompanyLogo
              };
            });

            if (this._program.batchType == "1") {
              this.initIndividualDeliverable(this._program.batchId, this.currentUser.id);
            } else {
              this.initDeliverable(batchId, this.projectTeamList[0].id, this._program.id);
            }
            this.selectedOrgId = this.projectTeamList[0].id;
            this.team = this.projectTeamList[0];
          }
        }
      }
    ).catch(error => console.error("initialProgressData ", error));
  }

  initIndividualDeliverable(batchId, userId) {

    this.institutionSrv.initialIndividualProgressData(batchId, userId).then((res) => {
      if (res['result'] == 'successful') {
        this.programs = res['programs'];
        this.programs.map(program => {
          program['totaldeliverables'] = this.deliverableTotal(program.deliverables);
        });

        this.loading = false;
        this.displayProgrames = this.programs;
        localStorage.setItem(this.currentUser.id, JSON.stringify(this.programs))
      }

      this.cdf.detectChanges();
    }).catch(error => {
      console.error("initDeliverable", error);
    }).finally(() => {
      this.loading = false;
    })
  }

  initDeliverable(batchId, projectTeamId, programId) {

    this.institutionSrv.getProjectProgramProgressData(batchId,
      projectTeamId, programId).then((res) => {

        if (res['result'] == 'successful') {
          this.programs = res['programs'];
          let deliverables = res['deliverables'];
          this.programs.map(program => {
            program['deliverables'] = deliverables[program.name];
            program['totaldeliverables'] = this.deliverableTotal(program.deliverables);
          });

          this.displayProgrames = this.programs;
          localStorage.setItem(this.currentUser.id, JSON.stringify(this.programs));

        }

        this.cdf.detectChanges();
      }).catch(error => {
        console.error("initDeliverable", error);
      }).finally(() => {
        this.loading = false;
        console.log(this.loading);
      })
  }

  // close modal batch list
  onCloseModal() {
    if (this.isOpenDialog) this.onToggleDialogWarning();
    if (this.isOpenDialogAttach) this.onToggleDialogAttach();
    if (!this.isOpenDialog) {
      this._program = null;
      this.searchText = "";
      this.projectTeamList = [];
    }
    this.close.emit();
  }

  // Attention
  onToggleSubmitDialog(event, deliverable) {
    this.selectedDeliverable = deliverable;
    this.deliverable = event;
    switch (event.deliverableTypeKey) {
      case "link":
        this.isOpenDialogLink = !this.isOpenDialogLink;
        break;
      case "comment":
        this.isOpenDialogComment = !this.isOpenDialogComment;
        break;
      case "checkbox":
        this.isOpenDialog = !this.isOpenDialog;
        break;
      case "file":
        this.isOpenDialogAttach = !this.isOpenDialogAttach;
        break;
      case "textbox":
        this.isOpenDialogComment = !this.isOpenDialogComment;
        break;
      //textbox
    }
  }

  // Attention
  onToggleDialogWarning() {
    this.isOpenDialog = !this.isOpenDialog;
  }

  // Attach
  onToggleDialogAttach() {
    this.isOpenDialogAttach = !this.isOpenDialogAttach;
  }

  // Link
  onToggleDialogLink() {
    this.isOpenDialogLink = !this.isOpenDialogLink;
  }

  // Comment
  onToggleDialogComment() {
    this.isOpenDialogComment = !this.isOpenDialogComment;
  }

  onToggleComments(lecture: string) {
    this.openComments.emit(lecture);
  }

  // lecture update
  onLectureSubmit(checked: boolean, deliverable: any) {
    // this.count += 1;

    let _checked = !checked;
    const rating = {
      organizationId: this.team.id,
      submission: true,
      deliverableId: deliverable.id,
      ratingId: deliverable.ratingId,
      userId: this.currentUser.id,
      batchId: this._program.batchId
    }

    this.institutionSrv.updateRating(rating).subscribe(
      response => {

        if (response['result'] == 'successful') {

          this.showActionMsg(this.selectedDeliverable.description,
            "Successfully");
          let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
          let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
          _deliverable.submission = true;
        } else {
          this.showActionMsg(this.selectedDeliverable.description, "Failed");
        }
      },
      error => {
        this.showActionMsg(this.selectedDeliverable.description, "Failed");
        console.error('updateRating', error)
      }
    );
  }

  // attach upload
  onAttachSubmit(event: any, deliverable: any) {
    if (event) {
      let formData = new FormData();
      formData.append('file', event);
      formData.append('organizationId', this.team.id);
      formData.append('submission', event.name);
      formData.append('ratingTypeId', deliverable.ratingTypeId);
      formData.append('deliverableId', deliverable.id);
      formData.append('userId', this.currentUser.id);
      formData.append('batchId', this._program.batchId);
      let _file = event;
      this.institutionSrv.updateFileRating(formData).subscribe(
        response => {
          if (response['result'] == 'successful') {
            this.showActionMsg(this.selectedDeliverable.description, "Successfully");
            let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
            let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
            _deliverable.submission = _file.name;

          } else {
            this.showActionMsg(this.selectedDeliverable.description, "Failed");
          }
        },
        error => {
          this.showActionMsg(this.selectedDeliverable.description, "Failed");
          console.error("fileUpload failed", error);
        }
      );

    }

  }

  // link update
  onLinkSubmit(url: string, deliverable: any) {
    const rating = {
      organizationId: this.team.id,
      submission: url ? url : null,
      deliverableId: deliverable.id,
      ratingId: deliverable.ratingId,
      userId: this.currentUser.id,
      batchId: this._program.batchId
    }

    let _url = url;
    this.institutionSrv.updateRating(rating).subscribe(
      response => {
        if (response['result'] == 'successful') {

          this.showActionMsg(this.selectedDeliverable.description, "Successfully");
          let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
          let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
          _deliverable.submission = _url;
        } else {
          this.showActionMsg(this.selectedDeliverable.description, "Failed");
        }
      },
      error => {
        this.showActionMsg(this.selectedDeliverable.description, "Failed");
        console.error('updateRating', error)
      }
    );
  }

  showActionMsg(title, msg) {
    this.toastRef = this.toastr.show(msg, title, {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-blue',
      closeButton: false,
    });
  }

  onCommentSubmit(msg: string) {
    this.toastRef = this.toastr.show(msg, 'Comment', {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-green',
      closeButton: false,
    });
  }

  onResetFilter() {
    const { childNodes } = this.filterList.nativeElement
    const list = [...childNodes]
    list.forEach(item => {
      item.classList.remove('selected');
    })
  }

  onFilterSelect(event: any) {
    this.searchText = "";
    const { childNodes } = this.filterList.nativeElement;
    const list = [...childNodes]
    list.forEach(item => {
      if (item.dataset.option === event.target.dataset.option && item.classList.contains('selected')) return;
      item.classList.remove('selected');
      if (item.dataset.option === event.target.dataset.option) return item.classList.add('selected');
    })
    // Store filter type for search using
    if (this.filterType !== event.target.dataset.option) this.filterType = event.target.dataset.option;


    this.displayProgrames = JSON.parse(localStorage.getItem(this.currentUser.id) || '{}');
    if (!this.utilitySrv.IsNullOrEmpty(this.filterType) && this.filterType != "all") {

      this.displayProgrames.map(program => {
        if (program.deliverables != null) {
          program.deliverables = program.deliverables.filter(deliverable => {

            if (this.filterType == 'completed' && deliverable.score > 0) {
              return true;
            } else if (this.filterType == 'uncompleted' && deliverable.score == null) {
              return true;
            } else if (this.filterType == 'file' && deliverable.deliverableTypeKey == 'file') {
              return true;
            } else if (this.filterType == 'link' && deliverable.deliverableTypeKey == 'link') {
              return true;
            }

          })
          program['totaldeliverables'] = this.deliverableTotal(program.deliverables);
        } else {
          program['totaldeliverables'] = 0;
        }

      });

    } else {
      this.displayProgrames = this.programs;
    }

  }

  onSearchChange(event: any) {
    this.searchText = event.target.value;
    /*    if (this.searchText != null && this.searchText != "") {
         let _results = this.programs.map(program => {
           program.deliverables.filter(deliverable => { return deliverable.name.include(this.searchText) })
         });
         this.displayProgrames = _results;
       } else {
         this.displayProgrames = this.programs;
       }
       this.cdf.detectChanges(); */

  }

  onKeydown(event: any) {
    const key = event.key || event.which
    // press 'Enter' to do research with filter option
    if (key === 13 || key === 'Enter') return console.log('search ===========', { keywords: this.searchText, type: this.filterType });
  }

  // for clear search event
  onClearSearchText() {
    this.searchText = '';
    this.searchInputRef.nativeElement.focus();
  }

  onBatchExpand(event: any) {
    const target = event.target;
    target.parentNode.classList.toggle('collapse');

    const isExpaned = target.parentNode.classList.contains('collapse')
    target.parentNode.setAttribute('aria-expanded', !isExpaned);
    target.nextSibling.setAttribute('aria-hidden', isExpaned);

  }

  onOpenComments(lecture: any) {
    /*     let _comments: any[] = [];
        if (this.comments.length > 0) {
          _comments = this.comments.filter(comment => { return comment.ratingId == lecture.ratingId })
        } */
    console.log('modal program', lecture);
    lecture['projectTeamId'] = this._program.organizationId;
    //this.openComments.emit({ lecture: lecture, comments: _comments });
    this.openComments.emit({ lecture: lecture });
  }

  deliverableTotal(deliverables) {
    if (!deliverables) {
      return 0;
    }
    else {
      let avaiableDels = deliverables.filter(del => del.deliverableTypeKey != 'na')
      return avaiableDels.length;
    }
  }

  onToggleShowPivot() {
    this.isShowPivot = !this.isShowPivot;
  }

  isSubmitted(value) {
    if (value === "true") {
      return true;
    } else if (value === "false") {
      return false;
    }
    return !this.utilitySrv.IsNullOrEmpty(value);
  }

  onCleanSubmission(event, programId) {
    let _programId = programId;
    let _ratingId = event;
    this.institutionSrv.deleteRating(event).subscribe(
      response => {
        if (response['result'] == 'successful') {

          this.showActionMsg(this.selectedDeliverable.description, "Successfully");
          this.programs.map(program => {
            if (program.id == _programId) {
              program.deliverables.map(deliverable => {
                if (deliverable.ratingId == _ratingId) {
                  deliverable.submission = "";
                  deliverable.fileName = "";
                }
              })
            }
          });
          this.displayProgrames = this.programs;
        } else {
          this.showActionMsg(this.selectedDeliverable.description, "Failed");
        }
        this.cdf.detectChanges();
      },
      error => {
        this.showActionMsg(this.selectedDeliverable.description, "Failed");
        console.error('updateRating', error)
      }
    );
  }

  onSelectedTeam(team) {
    this.programs = [];

    this.displayProgrames = this.programs;
    this.selectedOrgId = team.id;
    this.team = team;
    this.cdf.detectChanges();
    if (this._program.batchType == "1") {
      this.initIndividualDeliverable(this._program.batchId, this.currentUser.id);
    } else {
      this.initDeliverable(this._program.batchId, team.id, this._program.id);
    }

    this.selectedOrgId = team.id;
  }

  onSubmitRating(event, deliverable) {
    this.selectedDeliverable = deliverable;
    let _ratingType = this.ratingTypeList.filter(ratingType => ratingType.score == event);
    if (_ratingType.length > 0) {
      // organizationId must be selectedOrgId because mentor or programmer will rate selected organization
      const rating = {
        organizationId: this.selectedOrgId,
        deliverableId: deliverable.id,
        ratingTypeId: _ratingType[0].id,
        userId: this.currentUser.id,
        batchId: this._program.batchId
      }

      let _score = event;

      this.institutionSrv.updateRating(rating).subscribe(
        response => {
          if (response['result'] == 'successful') {
            this.showActionMsg(this.selectedDeliverable.description, "Successfully");
            let _program = this.programs.find(program => program.id = this.selectedDeliverable.programId);
            let _deliverable = _program.deliverables.find(deliverable => deliverable.id = this.selectedDeliverable.id);
            _deliverable.score = _score;
            this.displayProgrames = this.programs;
          } else {
            this.showActionMsg(this.selectedDeliverable.description, "Failed");
          }
        },
        error => {
          this.showActionMsg(this.selectedDeliverable.description, "Failed");
          console.error('updateRating', error)
        }
      );
    } else {
      this.showActionMsg(this.selectedDeliverable.description, "Failed");
    }
  }
}
