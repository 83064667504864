import { Component, Input, OnInit, ViewEncapsulation, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { ConfigService, UtilityService } from '@common/services';
import { environment } from 'environments/environment';

@Component({
  selector: 'ui-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent implements OnInit {
  @Input() class?: string;
  @Input() img?: string;
  @Input() alt: string = '';
  @Input() type: string;
  @Input() name?: string;

  constructor(
    public utility: UtilityService,
    public globals: ConfigService,
  ) { }

  ngOnInit() {
  }

  onImgError(event) {
    // event.target.src = this.globals.defaultUserLogo;
    // if (this.img) {
    //   // for development using only
    //   if (this.img.match(/http/) || this.img.match('assets/images')) return event.target.src = this.img;

    //   event.target.src = this.type?.match('member|logo')
    //     ? '../../../../assets/images/workspace/program-default-avatar.png'
    //     : '../../../../assets/images/default-avatar.png';

    //   return;
    // } 

    event.target.src = this.type?.match('member|logo')
    ? './assets/images/workspace/program-default-avatar.png'
    : './assets/images/default-avatar.png';
    
  }

  displayImg(img: string) {
    // includes or test is check the string if match 'http' 
    // console.log(img, /^http/.test(img), img.includes('http'));
    if (this.type === 'logo' || this.type === 'member') return this.utility.GetOrganizationLogo(img);
    if (/^http/.test(img)) return img;
    return environment.assetUrl + img;
  }

}
