export class PersonalPersonality {
    id = '';
    userId = '';
    mBIndicator = '';
    criticism = '';
    mistake = '';
    personnalItem = '';
    successFactor1 = '';
    successFactor2 = '';
    successFactor3 = '';
    effectiveLeader = '';
    phraseLeader = '';
    emotionalIntelligence = '';
    personalityProblem = '';
    createdAt = '';
    createdBy = '';
    updatedAt = '';
    updatedBy = '';
}
