import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ui-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class TabsComponent implements OnInit {
  current = '';
  tabs: any[] = [];
  @Input() changeCurrent;
  @Input() class?: string;
  @Input() data: any[];
  @Output() click = new EventEmitter();

  
  constructor() { }

  ngOnInit() {
    this.tabs = this.data;
    
    if (this.changeCurrent === true) {
      this.current = this.tabs[1].text;
    } else {
      this.current = this.tabs[0].text;
    }
  }

  onClick(event: any, tab: object) {
    event.stopPropagation();
    this.click.emit(tab['id'] || tab['text']);
    this.current = tab['text'];
  }
}
