export class FunderForm {
    id: string;
    investingSetup: string;
    investingSetupOthers: string;
    investedIn: string;
    rolePlay: string;
    rolePlayOthers: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}
