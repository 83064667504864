import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@common/models';
import { DataService, InstitutionService, OrganizationService } from '@common/services';
import { StripePaymentService } from '@common/services/stripePayment.service';
import { FLATPICKR_CONTROL_VALUE_ACCESSOR } from 'angularx-flatpickr/flatpickr.directive';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'lab-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {
  @ViewChild('menuDialog') menuDialog: ElementRef<HTMLElement>;
  organizationArray: any[] = [];
  subscriptions: any[] = [];
  batchCreditPacks: any[] = [];
  selectedSubscription: any;
  selectedPlan: any;
  selectedPlanType;
  subscriptionsLoaded: boolean = false;
  batchCreditPacksLoaded: boolean = false;
  selectedBatchPack: any;
  currentUser;
  currentOrg;
  selectedOrgId;
  orgId: string;
  customerId;
  isOpenCreditDialog: boolean = false;
  isOpenBatchDialog: boolean = false;
  isOpenLaunchDialog: boolean = false;
  isOpenConfirmDialog: boolean = false;
  isOpenCancelDialog: boolean = false;
  isOpenCancelCreditDialog: boolean = false;
  isStopBatch: boolean = false;
  assignBatchSub;
  assignedBatch;
  deletingSubscription;
  batches: any[] = [];
  selectedBatch;
  // cardExpMonth = 11;
  // cardExpYear = 2025;
  // brand = 'Visa'
  // last4 = '0046';
  licenceId: string;
  licenceExpiry;
  licenceReceipt;
  licenceReceiptPdfList; //
  showLicencePdfList: boolean = false;
  licenceLoaded: boolean = false;
  licenceAutoRenewal: any = 0;
  licenceAutoRenewalChecked: boolean;
  toastRef;
  hideRuleContent: boolean[] = [];
  hideRuleContentPack: boolean[] = [];
  replaceElement;
  getBillingOrgIdSubscription$;
  getBillingOrgIdSubscription;
  @ViewChild('receiptList') receiptList: ElementRef
  // Testing
  licence$;

  constructor(
    private paymentService: StripePaymentService,
    private orgSrv: OrganizationService,
    private toastr: ToastrService,
    private router: Router,
    private institutionSrv: InstitutionService,
    private eRef: ElementRef,
    private dataSrv: DataService,
    private renderer: Renderer2
  ) {

    this.currentUser = new User();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.customerId = this.currentUser.stripeCustomerId;

    // this.assignBatchSub = this.dataSrv.getAssignBatch().subscribe(res => {
    //   this.assignedBatch = res
    // })

    // if (Object.keys(this.assignedBatch).length > 0) {
    //   console.log(this.assignedBatch);
    //   if (this.assignedBatch.duration === 0) {
    //     this.showActionMsg('Batch launched successfully', `${this.assignedBatch.batchName} is running now and subscription plan reduced by 1 credit.`)
    //   } else {
    //     this.showActionMsg('Batch launched successfully', `${this.assignedBatch.batchName} is running now and batch credits reduced by 1 credit.`)
    //   }
    // }

    // this.renderer.listen('window', 'click', (e: Event) => {
    //   // console.log(e)
    //   // console.log(this.receiptList)
    //   if (this.showLicencePdfList && e.target !== this.receiptList.nativeElement) {
    //     this.showLicencePdfList = false;this.plan
    //   }
    // });

    // Testing, using pipe, map, switchMap is much cleaner/better than calling subscribe methods within another subscribe method, callback hell
    // this.licence$ = this.institutionSrv.getInstitutionOwnerByUserId(this.currentUser.id).pipe(
    //   filter((res) => res['result'] === 'successful'),
    //   tap(res => {
    //     this.organizationArray = res['data'];
    //     this.currentOrg = this.organizationArray[0];
    //     this.getBillingOrgIdSubscription$ = this.dataSrv.getBillingOrgId();
    //     this.getBillingOrgIdSubscription = this.getBillingOrgIdSubscription$.subscribe(res => {
    //       this.selectedOrgId = res ? res : this.currentOrg?.id;
    //     });
    //   }),
    //   switchMap(res => {
    //     return this.paymentService.retrieveUserLicenceExpiry(this.currentUser.id, this.selectedOrgId)
    //   })).pipe(
    //     tap((res) => {
    //       if (res['result'] === 'successful') {
    //         this.licenceReceipt = res['data'][0]['receipt'];
    //         this.licenceAutoRenewal = res['data'][0]['auto_renewal'];
    //         this.licenceId = res['data'][0]['subscription_id'];
    //         this.licenceAutoRenewalChecked = this.licenceAutoRenewal ? true : false;
    //       } else {
    //         this.licenceExpiry = '-';
    //         this.licenceReceipt = '';
    //         this.licenceAutoRenewal = false;
    //       }
    //     }),
    //     switchMap(res => {
    //       return this.paymentService.getSubscriptionFromStripe(this.licenceId);
    //     }),
    //     tap((res) => {
    //       if (res['result'] === 'successful') {
    //         let licenceExpiry = res['subscription']['current_period_end'];
    //         licenceExpiry = new Date(licenceExpiry * 1000)
    //         this.licenceExpiry = licenceExpiry.toLocaleDateString('en-GB');
    //       }
    //     }),
    //     catchError(err => {
    //       return throwError('This is a custom error!');
    //     })
    //   )
    //   .subscribe(res => console.log(res),
    //     err => console.log('Error', err),
    //     () => this.licenceLoaded = true);
    //================================================

    this.institutionSrv.getInstitutionOwnerByUserId(this.currentUser.id).pipe(take(1)).subscribe(res => {
      if (res['result'] === 'successful') {
        this.organizationArray = res['data'];
        this.currentOrg = this.organizationArray[0];

        // if user clicks on 'plan settings button' from institution profile page
        this.getBillingOrgIdSubscription$ = this.dataSrv.getBillingOrgId();
        this.getBillingOrgIdSubscription = this.getBillingOrgIdSubscription$.subscribe(res => {
          this.selectedOrgId = res ? res : this.currentOrg.id;
        })


        this.paymentService.retrieveUserLicenceExpiry(this.currentUser.id, this.selectedOrgId).pipe(take(1)).subscribe(res => {
          if (res['result'] === 'successful') {

            this.licenceReceipt = res['data'][0]['receipt'];
            this.licenceAutoRenewal = res['data'][0]['auto_renewal'];
            this.licenceId = res['data'][0]['subscription_id'];

            this.paymentService.getSubscriptionFromStripe(this.licenceId).subscribe(res => {
              if (res['result'] === 'successful') {
                let licenceExpiry = res['subscription']['current_period_end'];
                licenceExpiry = new Date(licenceExpiry * 1000)
                this.licenceExpiry = licenceExpiry.toLocaleDateString('en-GB');
              }
            })

            if (this.licenceAutoRenewal === 1) {
              this.licenceAutoRenewalChecked = true;
            } else {
              this.licenceAutoRenewalChecked = false;
            }
          } else {
            this.licenceExpiry = '-';
            this.licenceReceipt = '';
            this.licenceAutoRenewal = false;
          }
        }, (err) => {
          // console.log(err)
        }, () => {
          this.licenceLoaded = true;
        });

        this.paymentService.getSubscriptionsByOrgId(this.selectedOrgId).then(res => {
          if (res['result'] === 'successful') {
            this.subscriptions = res['data'];
            // Run a for loop to list down the batch assigned to plan
            this.subscriptions.forEach((subscription, index) => {
              subscription['toggleMenu'] = false;
              // Call API to get batch assigned to plan
              this.paymentService.getAssignedBatchBySubscriptionId(subscription.stripe_subscription_id).pipe(take(1)).subscribe(res => {
                if (res['result'] === 'successful') {
                  subscription['batches'] = res['data'];
                } else {
                  subscription['batches'] = [];
                }

                if (subscription['credit_used'] < subscription['total_credit']) {
                  const remainingCredit = subscription['total_credit'] - subscription['credit_used'];

                  for (let i = 1; i <= remainingCredit; i++) {
                    subscription['batches'].push({})
                  }
                }

                subscription['batches'].forEach((batch, index) => {
                  batch['index'] = index;
                })
                // subscription['toggleMenu'] = false;
                // if (Object.keys(this.assignedBatch).length > 0) {
                //   if (subscription['stripe_subscription_id'] === this.assignedBatch.subscription) {
                //     this.toggleExpand(index);
                //   }
                // }
              })

              this.paymentService.getSubscriptionFromStripe(subscription.stripe_subscription_id).pipe(take(1)).subscribe(res => {
                if (res['result'] === 'successful') {
                  const subscriptionData = res['subscription']
                  subscription['next_billing_date'] = this.convertDate(subscriptionData['current_period_end']);
                  // if (subscriptionData['cancel_at_period_end'] === false) {
                  //   subscription['next_billing_date'] = this.convertDate(subscriptionData['current_period_end']);
                  // }
                  // if (subscriptionData['cancel_at_period_end'] === true) {
                  //   subscription['next_billing_date'] = '-';
                  // }
                }
              })
            })
            this.toggleExpand(0);
          }
        }).finally(() => {
          this.subscriptionsLoaded = true;
        })
      }

      this.paymentService.getBatchCreditPacks(this.selectedOrgId).pipe(take(1)).subscribe(res => {
        if (res['result'] === 'successful') {
          this.batchCreditPacks = res['data'];
          
          this.batchCreditPacks.forEach((creditPack, index) => {
            creditPack['toggleReceipt'] = false;
            this.paymentService.getAssignedBatchByBatchCreditPack(creditPack.credit_id).pipe(take(1)).subscribe(res => {
              if (res['result'] === 'successful') {
                creditPack['batches'] = res['data'];
              } else {
                creditPack['batches'] = [];
              }

              if (creditPack['credit_used'] < creditPack['total_credit']) {
                const remainingCredit = creditPack['total_credit'] - creditPack['credit_used'];

                for (let i = 1; i <= remainingCredit; i++) {
                  creditPack['batches'].push({})
                }
              }

              creditPack['batches'].forEach((batch, index) => {
                batch['index'] = index;
              })
            }, (err) => {
              creditPack['batches'] = [];
            }, () => {
              this.batchCreditPacksLoaded = true;

            })
          })
          this.toggleExpandPack(0);
        } else {
          this.batchCreditPacks = [];
          this.batchCreditPacksLoaded = true;
        }
      })

      this.paymentService.getLicenceInvoices(this.selectedOrgId).then(res => {
        if (res['result'] === 'successful') {
          this.licenceReceiptPdfList = res['data'];
        } else {
          // console.log("no data");
        }
      })

    })
  }

  ngOnInit(): void {
    // Testing purpose
    // this.getData();
  }

  ngOnDestroy(): void {
    // this.dataSrv.onAssignBatch({})
    // this.assignBatchSub.unsubscribe();
    this.getBillingOrgIdSubscription?.unsubscribe();

    this.dataSrv.onChangeBillingOrgId('');
  }

  // @HostListener('click', ['$event'])
  // clickedOut(event: any) {
  //   if (this.eRef.nativeElement.contains(event.target)) return;

  // }

  // retrieveCustomerPaymentMethod() {

  //   this.paymentService.retrieveCustomerPaymentMethod(this.customerId).subscribe(res => {
  //     // console.log(res)
  //   })
  // }

  //Testing purpose
  // getData() {
  //   this.institutionSrv.getInstitutionOwnerByUserId(this.currentUser.id)
  //     .pipe(
  //       take(1),
  //       switchMap(

  //       )
  //     )
  //     .subscribe((value) => console.log(value))
  // }

  //====================================================
  cancelSubscription(subscription) {
    if (subscription.active === 0) {
      return;
    } else {
      this.paymentService.cancelSubscription(subscription.stripe_subscription_id).subscribe(res => {
        if (res['result'] === 'successful') {
          this.subscriptions.forEach(plan => {
            if (plan.stripe_subscription_id === subscription.stripe_subscription_id) {
              this.removePlanWithId(this.subscriptions, subscription.stripe_subscription_id);
            }
          });
          this.showActionMsg('Canceled Plan Successful', `For ${subscription.type} subscription has been canceled successfully.`)
        } else {
          this.showActionMsg('Canceled Plan Fail', ``)
        }

        this.isOpenCancelDialog = false;
      })
    }
  }

  deleteCredit(event) {
    let batchId = event.batch.id
    let subscriptionId = event.subscription.stripe_subscription_id;
    // Call API to remove batch from plan
    this.paymentService.removeBatchFromSubscription(batchId).pipe(take(1)).subscribe(res => {
      if (res['result'] === 'successful') {
        // Remove from batches array in frontend;
        this.subscriptions.forEach(subscription => {
          if (subscription.stripe_subscription_id === subscriptionId) {
            this.removeObjectWithId(subscription['batches'], batchId);
          }
        });

        this.dataSrv.onUpdateBatchStatus({
          batchId: batchId,
          status: 'notYetRunning',
          planId: '0'
      });

        this.subscriptions = this.subscriptions.map(subscription => (subscription.stripe_subscription_id
          === subscriptionId ? {
          ...subscription, total_credit: (subscription.total_credit - 1), credit_used: (subscription.credit_used - 1)
        } : subscription));

        this.paymentService.removeCredits(subscriptionId).pipe(take(1)).subscribe(res => {
          if (res['result'] === 'successful') {
            let credits = res['data'][0]['total_credit'];

            this.paymentService.removeCreditsInStripe(subscriptionId, credits).pipe(take(1)).subscribe(res => {
              // console.log(res);
            })
          }
        });

        this.showActionMsg('Deleted 1 credit successfully', 'Credit has been removed from the subscription plan');
        this.isOpenCancelCreditDialog = false;
      }

    })
  }

  removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
  }

  removePlanWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.stripe_subscription_id === id);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
  }

  // toggle switch
  handleChecked(event) {
    this.paymentService.updateAutoRenewal(this.licenceId, !event).subscribe(res => {
      if (res['result'] === 'successful') {
        if (res['subscription']['cancel_at_period_end'] === false) {
          this.showActionMsg('Auto-renewal update Successful', 'Your licence auto-renewal status has been enabled');
        } else if (res['subscription']['cancel_at_period_end'] === true) {
          this.showActionMsg('Auto-renewal update Successful', 'Your licence auto-renewal status has been cancelled')
        }
      }
    });
  }

  handleSubscriptionToggle(event, subscription) {
    if (subscription.active === 0) {
      return;
    } else {
      this.paymentService.updateAutoRenewalSubscription(subscription.stripe_subscription_id, !event).subscribe(res => {
        if (res['result'] === 'successful') {
          this.subscriptions.forEach(plan => {
            if (plan.stripe_subscription_id === subscription.stripe_subscription_id) {
              plan['next_billing_date'] = this.convertDate(res['subscription']['current_period_end']);

              if (res['subscription']['cancel_at_period_end'] === false) {
                this.showActionMsg('Auto-renewal update Successful', 'Your subscription auto-renewal status has been enabled');
                plan['auto_renewal'] = 1;
              } else if (res['subscription']['cancel_at_period_end'] === true) {
                this.showActionMsg('Auto-renewal update Successful', 'Your subscription auto-renewal status has been cancelled');
                plan['auto_renewal'] = 0;
              }
            }
          });
        }
      });
    }
  }

  toggleMenu(plan) {
    this.deletingSubscription = plan;
    this.subscriptions = this.subscriptions.map(subscription => (subscription.stripe_subscription_id
      === plan.stripe_subscription_id ? {
      ...subscription, toggleMenu: !subscription.toggleMenu
    } : subscription));
  }

  toggleReceipt(plan) {
    this.batchCreditPacks = this.batchCreditPacks.map(creditPack => (creditPack.credit_id
      === plan.credit_id ? {
      ...creditPack, toggleReceipt: !creditPack.toggleReceipt
    } : creditPack));
  }

  toggleExpand(index) {
    this.hideRuleContent[index] = !this.hideRuleContent[index];
  }

  toggleExpandPack(index) {
    this.hideRuleContentPack[index] = !this.hideRuleContentPack[index];
  }

  redirectToPlan(planType) {
    if (planType === 'credit') {
      this.dataSrv.onUpdateCurrentTab(2);
    }
    if (planType === 'subscription') {
      this.dataSrv.onUpdateCurrentTab(1);
    }

    this.router.navigate(["/w/subscription-plans"], {
      // Add the current viewed org details
      queryParams: {
        orgId: this.selectedOrgId,
        orgTypeId: '5c5b6ca4-da99-11e9-b68a-bb4d51af784e',
        orgType: 'lab',
        tab: 'lab'
      }
    })
  }

  redirectToLicence() {
    this.paymentService.selectedPlan('licence');
    this.paymentService.setTotalAmount(2994);

    this.router.navigate(["/w/payments"], {
      // Add the current viewed org details
      queryParams: {
        orgId: this.selectedOrgId,
        orgTypeId: '5c5b6ca4-da99-11e9-b68a-bb4d51af784e',
        orgType: 'lab',
        tab: 'lab'
      }
    })
  }


  // redirectToPayment(sub) {
  //   // Get current subscription
  //   console.log(sub);
  //   console.log(this.licenceId)
  //   // Check whether subscription has licence or not. so in payment page, this.selectedPlan is of correct type.
  //   if (this.licenceId && sub['type'] === 'monthly') {
  //     this.paymentService.selectedPlan('monthly with licence');
  //     this.paymentService.setNumberOfCohorts(1);
  //     this.paymentService.setTotalAmount(499.5);
  //   }
  //   if (this.licenceId && sub['type'] === 'yearly') {
  //     this.paymentService.selectedPlan('yearly with licence');
  //     this.paymentService.setNumberOfCohorts(1);
  //     this.paymentService.setTotalAmount(5494.5);
  //   }
  //   if (!this.licenceId && sub['type'] === 'monthly') {
  //     this.paymentService.selectedPlan('monthly');
  //     this.paymentService.setNumberOfCohorts(1);
  //     this.paymentService.setTotalAmount(999);
  //   }
  //   if (!this.licenceId && sub['type'] === 'yearly') {
  //     this.paymentService.selectedPlan('yearly');
  //     this.paymentService.setNumberOfCohorts(1);
  //     this.paymentService.setTotalAmount(10989);
  //   }

  //   // this.dataSrv.onUpdateStripeSubscription(sub.stripe_subscription_id);
  //   this.dataSrv.onUpdateStripeSubscription({
  //     subscriptionId: sub.stripe_subscription_id,
  //     creditId: sub.credit_id
  //   });

  //   this.router.navigate(["/w/payments"], {
  //     // Add the current viewed org details
  //     queryParams: {
  //       orgId: this.selectedOrgId,
  //       orgTypeId: '5c5b6ca4-da99-11e9-b68a-bb4d51af784e',
  //       orgType: 'lab',
  //       tab: 'lab'
  //     }
  //   })
  // }

  viewLicence() {
    window.open(this.licenceReceipt, '_blank');
  }

  showLicenceList() {
    this.showLicencePdfList = true;
  }

  viewSubscriptionInvoice(subscription) {
    window.open(subscription.invoice_url, '_blank');
  }

  showActionMsg(title, msg) {
    this.toastRef = this.toastr.show(msg, title, {
      tapToDismiss: true,
      toastClass: 'toastpayment ',
      titleClass: 'green-title',
      closeButton: false,
      timeOut: 5000
    });
  }

  onSelectOrg(event) {
    this.selectedOrgId = event;

    this.subscriptionsLoaded = false;
    // Retrieve Licence for organization
    this.paymentService.retrieveUserLicenceExpiry(this.currentUser.id, event).pipe(take(1)).subscribe(res => {
      if (res['result'] === 'successful') {

        this.licenceReceipt = res['data'][0]['receipt'];
        this.licenceAutoRenewal = res['data'][0]['auto_renewal'];
        this.licenceId = res['data'][0]['subscription_id'];

        this.paymentService.getSubscriptionFromStripe(this.licenceId).pipe(take(1)).subscribe(res => {
          if (res['result'] === 'successful') {
            let licenceExpiry = res['subscription']['current_period_end'];
            licenceExpiry = new Date(licenceExpiry * 1000)
            this.licenceExpiry = licenceExpiry.toLocaleDateString('en-GB');
          }
        })

        if (this.licenceAutoRenewal === 1) {
          this.licenceAutoRenewalChecked = true;
        } else {
          this.licenceAutoRenewalChecked = false;
        }
      } else {
        this.licenceExpiry = '-';
        this.licenceReceipt = '';
        this.licenceAutoRenewal = false;
        this.licenceId = ''
      }
    }, (err) => {
      // console.log(err)
    }, () => {
      this.licenceLoaded = true;
    });

    this.paymentService.getLicenceInvoices(this.selectedOrgId).then(res => {
      if (res['result'] === 'successful') {
        this.licenceReceiptPdfList = res['data'];
      } else {
        // console.log("no data");
      }
    })

    // Retrieve Subscriptions for organization
    this.paymentService.getSubscriptionsByOrgId(this.selectedOrgId).then(res => {
      if (res['result'] === 'successful') {
        this.subscriptions = res['data'];

        this.subscriptions.forEach((subscription) => {
          // Call API to get batch assigned to plan
          this.paymentService.getAssignedBatchBySubscriptionId(subscription.stripe_subscription_id).pipe(take(1)).subscribe(res => {
            if (res['result'] === 'successful') {
              subscription['batches'] = res['data'];
            } else {
              subscription['batches'] = [];
            }
            subscription['toggleMenu'] = false;

            if (subscription['credit_used'] < subscription['total_credit']) {
              const remainingCredit = subscription['total_credit'] - subscription['credit_used'];

              for (let i = 1; i <= remainingCredit; i++) {
                subscription['batches'].push({})
              }
            }

            subscription['batches'].forEach((batch, index) => {
              batch['index'] = index;
            })
          });

          this.paymentService.getSubscriptionFromStripe(subscription.stripe_subscription_id).pipe(take(1)).subscribe(res => {
            if (res['result'] === 'successful') {
              const subscriptionData = res['subscription'];
              subscription['next_billing_date'] = this.convertDate(subscriptionData['current_period_end']);
              // if (subscriptionData['cancel_at_period_end'] === false) {
              //   subscription['next_billing_date'] = this.convertDate(subscriptionData['current_period_end']);
              // }
              // if (subscriptionData['cancel_at_period_end'] === true) {
              //   subscription['next_billing_date'] = '-';
              // }
            }
          })
        });
      } else {
        this.subscriptions = [];
      }
    }).finally(() => {
      this.subscriptionsLoaded = true;

    })

    // Retrieve Batch Credit Pack for organization
    this.paymentService.getBatchCreditPacks(this.selectedOrgId).pipe(take(1)).subscribe(res => {

      if (res['result'] === 'successful') {
        this.batchCreditPacks = res['data'];

        this.batchCreditPacks.forEach((creditPack, index) => {
          this.paymentService.getAssignedBatchByBatchCreditPack(creditPack.credit_id).pipe(take(1)).subscribe(res => {
            if (res['result'] === 'successful') {
              creditPack['batches'] = res['data'];
            } else {
              creditPack['batches'] = [];
            }

            if (creditPack['credit_used'] < creditPack['total_credit']) {
              const remainingCredit = creditPack['total_credit'] - creditPack['credit_used'];

              for (let i = 1; i <= remainingCredit; i++) {
                creditPack['batches'].push({})
              }

            }

            creditPack['batches'].forEach((batch, index) => {
              batch['index'] = index;
            })
          }, (err) => {
            creditPack['batches'] = [];
          }, () => {
            this.batchCreditPacksLoaded = true;
          })
        })
        this.toggleExpandPack(0);

      } else {
        this.batchCreditPacks = [];
      }
    })
  }

  openBatchDialog(event, orgId, plan, batch) {
    this.replaceElement = batch['index'];
    event.stopPropagation();
    if (plan.active === 0) {
      return;
    }
    // this.selectedSubscription = plan;
    // this.selectedBatchPack = plan;
    this.selectedPlan = plan;
    if (this.selectedPlan.hasOwnProperty('stripe_subscription_id')) {
      this.selectedPlanType = 'subscription'
    } else {
      this.selectedPlanType = 'batchCreditPack'
    }
    this.isOpenBatchDialog = true;

    // this.notAllowTargetScrollable(document.body, this.isOpenBatchDialog);
  }

  updatePlan(event) {
    const planId = event.planId;
    const batch = event.batch;
    batch.credit_id = planId;
    
    if (event.selectedPlanType === 'subscription') {

      // Run a for loop to list down the batch assigned to plan and update credits used
      this.subscriptions = this.subscriptions.map(subscription => (subscription.stripe_subscription_id
        === planId ? {
        ...subscription,
        credit_used: (subscription.credit_used + 1)
      } : subscription));

      this.subscriptions.forEach(subscription => {
        if (subscription.stripe_subscription_id
          === planId) {
          subscription.batches.splice(this.replaceElement, 1, batch);
        }
      })
    }

    if (event.selectedPlanType === 'batchCreditPack') {
      this.batchCreditPacks = this.batchCreditPacks.map(plan => (plan.credit_id
        === planId ? {
        ...plan,
        credit_used: (plan.credit_used + 1)
      } : plan));

      this.batchCreditPacks.forEach(plan => {
        if (plan.credit_id
          === planId) {
          plan.batches.splice(this.replaceElement, 1, batch);
        }
      })
    }
  }

  onAddBatch() {
    const batchId = this.selectedBatch.id;
    const creditId = this.selectedPlan.credit_id;
    const totalCredit = this.selectedPlan.total_credit;
    const creditUsed = this.selectedPlan.credit_used;

    if (creditUsed < totalCredit) {
      if (this.selectedPlanType === 'subscription') {
        const subscriptionId = this.selectedPlan.stripe_subscription_id;
        this.paymentService.addBatchToSubscription(subscriptionId, batchId, creditId).subscribe(res => {
          if (res['result'] === 'successful') {
            this.subscriptions = this.subscriptions.map(subscription => (subscription.stripe_subscription_id
              === subscriptionId ? {
              ...subscription, credit_used: (subscription.credit_used + 1)
            } : subscription));

            // Run a for loop to list down the batch assigned to plan
            this.subscriptions = this.subscriptions.map(subscription => (subscription.stripe_subscription_id
              === subscriptionId ? {
              ...subscription, batches: [this.selectedBatch, ...subscription.batches]
            } : subscription));

            this.showActionMsg('Success', 'Batch added');
          }
        }, (err) => {
          // console.log(err)
        }, () => {
          this.isOpenBatchDialog = false;
          this.isOpenConfirmDialog = false;
        });
      }
      if (this.selectedPlanType === 'batchCreditPack') {
        const planId = this.selectedPlan.stripe_plan_id;
        this.paymentService.addBatchToCreditPack(planId, batchId, creditId).subscribe(res => {
          if (res['result'] === 'successful') {
            this.batchCreditPacks = this.batchCreditPacks.map(plan => (plan.stripe_plan_id
              === planId ? {
              ...plan, credit_used: (plan.credit_used + 1)
            } : plan));

            // Run a for loop to list down the batch assigned to plan
            this.batchCreditPacks = this.batchCreditPacks.map(plan => (plan.stripe_plan_id
              === planId ? {
              ...plan, batches: [this.selectedBatch, ...plan.batches]
            } : plan));

            this.showActionMsg('Success', 'Batch added');
          }
        }, (err) => {
          // console.log(err)
        }, () => {
          this.isOpenBatchDialog = false;
          this.isOpenConfirmDialog = false;
        })
      }
    } else {
      this.showActionMsg('Fail', 'There are no more credits, please order more credits.');

      window.setTimeout(() => {
        this.isOpenBatchDialog = false;
        this.isOpenConfirmDialog = false;
      }, 2000)
    }
  }

  launchBatch() {
    if (this.selectedPlan?.stripe_subscription_id) {
      this.paymentService.launchBatch(this.selectedBatch.id, this.selectedPlan.credit_id, this.selectedPlan.stripe_subscription_id).subscribe(res => {
        if (res['result'] === 'successful') {
          if (this.selectedPlanType === 'subscription') {
            for (let i = 0; i < this.subscriptions.length; i++) {
              if (this.subscriptions[i]['stripe_subscription_id'] === this.selectedPlan['stripe_subscription_id']) {
                for (let j = 0; j < this.subscriptions[i]['batches'].length; j++) {
                  if (this.subscriptions[i]['batches'][j]['id'] === this.selectedBatch.id) {
                    this.subscriptions[i]['batches'][j]['batchStatus'] = 1;
  
                    // Call data service to updatebatch status button
                    this.dataSrv.onUpdateBatchStatus({
                      batchId: this.selectedBatch.id,
                      status: 'running',
                      planId: this.selectedPlan['stripe_subscription_id'],
                      creditId: this.selectedPlan['credit_id']
                    });
                  }
                }
              }
            }
          }
  
          this.isOpenLaunchDialog = false;
          this.showActionMsg('Batch Launched Successfully', `${this.selectedBatch.name} has started running.`)
        }
      })
    } else if (this.selectedPlan?.stripe_plan_id) {
      this.paymentService.launchBatch(this.selectedBatch.id, this.selectedPlan.credit_id, this.selectedPlan.stripe_plan_id).subscribe(res => {
        if (res['result'] === 'successful') {
          if (this.selectedPlanType === 'batchCreditPack') {
            for (let i = 0; i < this.batchCreditPacks.length; i++) {
              if (this.batchCreditPacks[i]['credit_id'] === this.selectedPlan['credit_id']) {
                for (let j = 0; j < this.batchCreditPacks[i]['batches'].length; j++) {
                  if (this.batchCreditPacks[i]['batches'][j]['id'] === this.selectedBatch.id) {
                    this.batchCreditPacks[i]['batches'][j]['batchStatus'] = 1;
  
                    // Call data service to updatebatch status button
                    this.dataSrv.onUpdateBatchStatus({
                      batchId: this.selectedBatch.id,
                      status: 'running',
                      planId: this.selectedPlan['credit_id'],
                      creditId: this.selectedPlan['credit_id']
                    });
                  }
                }
              }
            }
          }
  
          this.isOpenLaunchDialog = false;
          this.showActionMsg('Batch Launched Successfully', `${this.selectedBatch.name} has started running.`)
        }
      })
    }
  }

  stopBatch() {
    this.paymentService.stopBatch(this.selectedBatch.id, this.selectedBatch.plan_id).subscribe(res => {
      if (res['result'] === 'successful') {
        if (this.selectedPlanType === 'subscription') {
          for (let i = 0; i < this.subscriptions.length; i++) {
            if (this.subscriptions[i]['stripe_subscription_id'] === this.selectedPlan['stripe_subscription_id']) {
              for (let j = 0; j < this.subscriptions[i]['batches'].length; j++) {
                if (this.subscriptions[i]['batches'][j]['id'] === this.selectedBatch.id) {
                  this.subscriptions[i]['batches'][j]['batchStatus'] = 0;
                  this.subscriptions[i]['batches'][j]['credit_id'] = '0';
                }
              }
            }
          }
          this.subscriptions = this.subscriptions.map(subscription => (subscription.stripe_subscription_id
            === this.selectedPlan['stripe_subscription_id'] ? {
            ...subscription, total_credit: (subscription.total_credit - 1), credit_used: (subscription.credit_used - 1)
          } : subscription));
        }

       

        if (this.selectedPlanType === 'batchCreditPack') {
          for (let i = 0; i < this.batchCreditPacks.length; i++) {
            if (this.batchCreditPacks[i]['credit_id'] === this.selectedPlan['credit_id']) {
              for (let j = 0; j < this.batchCreditPacks[i]['batches'].length; j++) {
                if (this.batchCreditPacks[i]['batches'][j]['id'] === this.selectedBatch.id) {
                  this.batchCreditPacks[i]['batches'][j]['batchStatus'] = 0;
                  this.batchCreditPacks[i]['batches'][j]['credit_id'] = '0';
                  // this.batchCreditPacks[i]['batches'].splice(j, 1)
                }
              }
            }
          }
        }

        this.showActionMsg('Batch Stopped Successfully', `${this.selectedBatch.name} has stopped running.`);

        this.dataSrv.onUpdateBatchStatus({
          batchId: this.selectedBatch.id,
          status: 'notYetRunning',
          planId: '0',
          creditId: '0'
        });

        this.isStopBatch = false;

      }
    })
  }

  closeLaunchDialog() {
    this.isOpenLaunchDialog = false;
  }

  closeStopDialog() {
    this.isStopBatch = false;
  }

  closeBatchDialog() {
    this.isOpenBatchDialog = false;
  }

  closeReceiptList() {

    this.showLicencePdfList = false
  }

  openTestDialog(event) {
    if (event.status === 0) {

    } else {

    }
  }

  updateBatchStatus(event) {
    const planId = event.planId;
    const batch = event.batch;
    const selectedPlanType = event.selectedPlanType;

    if (selectedPlanType === 'subscription') {
      for (let i = 0; i < this.subscriptions.length; i++) {
        if (this.subscriptions[i]['stripe_subscription_id'] === planId) {
          for (let j = 0; j < this.subscriptions[i]['batches'].length; j++) {
            if (this.subscriptions[i]['batches'][j]['id'] === batch.id) {
              this.subscriptions[i]['batches'][j]['batchStatus'] = 1;
              this.subscriptions[i]['batches'][j]['credit_id'] = planId;
            }
          }
        }
      }
    }

    if (selectedPlanType === 'batchCreditPack') {
      for (let i = 0; i < this.batchCreditPacks.length; i++) {
        if (this.batchCreditPacks[i]['credit_id'] === planId) {
          for (let j = 0; j < this.batchCreditPacks[i]['batches'].length; j++) {
            if (this.batchCreditPacks[i]['batches'][j]['id'] === batch.id) {
              this.batchCreditPacks[i]['batches'][j]['batchStatus'] = 1;
              this.batchCreditPacks[i]['batches'][j]['credit_id'] = planId;
            }
          }
        }
      }
    }
  }

  openConfirmDialog(event) {
    this.isOpenConfirmDialog = true;
    this.selectedBatch = event;
  }

  closeConfirmDialog() {
    this.isOpenConfirmDialog = false;
  }

  openCancelDialog(sub) {
    this.deletingSubscription = sub;
    this.isOpenCancelDialog = true;
  }

  closeCancelDialog() {
    this.isOpenCancelDialog = false;
    // this.toggleMenu(this.deletingSubscription);
  }

  openCancelCreditDialog(event, subscription, batch) {
    event.stopPropagation();
    this.selectedPlan = subscription;
    this.selectedBatch = batch;
    this.isOpenCancelCreditDialog = true;
  }

  openLaunchDialog(event, plan, batch) {
    event.stopPropagation();
    this.selectedPlan = plan;
    this.selectedBatch = batch;
  
    if (this.selectedPlan.hasOwnProperty('stripe_subscription_id')) {
      this.selectedPlanType = 'subscription'
    } else {
      this.selectedPlanType = 'batchCreditPack'
    }
    if ((batch['batchStatus'] === 0 || batch['batchStatus'] === 2 ) && batch['credit_id'] !== '0') {
      this.isOpenLaunchDialog = true;
    }

    // if (batch['batchStatus'] === 0 && batch['credit_id'] === '0') {
    //   this.showActionMsg('The batch has already expired', '14 days grace period in effect')
    // }
    if (batch['batchStatus'] === 1) {
      this.isStopBatch = true
    }
    // if (batch['batchStatus'] === 2) {
    //   this.isOpenLaunchDialog = true;
    //   // if (this.selectedBatch['expired'] != '0' && this.selectedPlanType === 'batchCreditPack') {
    //   //   this.showActionMsg('The batch has already expired under the credit pack', '');
    //   // } else {
    //   //   this.isOpenLaunchDialog = true;
    //   // }
    // }
  }

  redirectToBatch(event, batch) {
    event.stopPropagation();

    this.router.navigate(["/w/batch/profile"], {
      // Add the current viewed org details
      queryParams: {
        id: batch['id'],
        batchId: batch['id'],
        orgId: this.selectedOrgId,
        orgType: 'batch'
      }
    })
  }

  closeCancelCreditDialog() {
    this.isOpenCancelCreditDialog = false;
  }

  notAllowTargetScrollable(element: any, condition: boolean) {
    element.style.overflow = condition ? "hidden" : 'auto';
  }

  viewInvoice(url) {
    window.open(url, '_blank');
  }

  convertDate(timeStamp) {
    const unixTimeStamp = timeStamp * 1000;
    const dateVal = timeStamp ? new Date(unixTimeStamp).toLocaleDateString('en-GB') : '-';

    return dateVal;
  }

}
