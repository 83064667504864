export class TodoItem {
  id: string;
  dueDate: string;
  assigner: string;
  assignee: string;
  urgency: string; // 0: widget, 1: product
  taskName: string;
  description: string;
  status = 0; // 0: New 1: WIP 2: Pending 3: Fullfiled
  widget: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  milestonesStatus = 0; // 0: display at Detailed 1: display at Summary
  date: string;
  dMore: boolean;
  sMore: boolean;
  organizationId: string = '';
  assigneeName: string;
  assigneeOrgName: string;
  assigneeEmail: string;
  assigneeUid: string;
  assignerUid: string;
  assignees: any;
  title: string;
  content: string;
  userName: string;
  toDoId: string;
  filepath = '';
  assigneeOrg: string;
  dueDateDescription: string;
  statusDescription: string;
  comment: string;
  batchId: string;
  filename = '';
}
