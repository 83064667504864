import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@modules/auth/guards';

const routes: Routes = [
    {
        path: 'version',
        loadChildren: () =>
            import('modules/app-common/app-common-routing.module').then(
                m => m.AppCommonRoutingModule
            ),
    },
    {
        path: '',
        loadChildren: () =>
            import('@modules/landingpages/landingpage-routing.module').then(m => m.LandingPageRoutingModule),
    },
    {
        path: 'admin',
        loadChildren: () =>
            import('modules/admin/admin-routing.module').then(m => m.AdminRoutingModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'w',
        loadChildren: () =>
            import('modules/workspace/workspace-routing.module').then(m => m.WorkSpaceRoutingModule),
        canActivate: [AuthGuard],
    },

    {
        path: 'auth',
        loadChildren: () =>
            import('modules/auth/auth-routing.module').then(m => m.AuthRoutingModule),
    },
    {
        path: 'error',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('modules/error/error-routing.module').then(m => m.ErrorRoutingModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { 
            relativeLinkResolution: 'legacy', 
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload'
        }),
    ],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
