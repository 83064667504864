import { Component, OnInit, Input, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { ConfigService, DataService, EatToasterService, EmailService, InstitutionService, OrganizationService, UserService, UtilityService } from '@common/services';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Msg } from '@modules/_shared/models';
import { User } from '@common/models';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
    selector: 'lab-modal-student-registration',
    templateUrl: './modal-student-registration.component.html',
    styleUrls: ['./modal-student-registration.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class ModalStudentRegistrationComponent implements OnInit, AfterViewInit {
    _currentOrg: any;
    _batchOrg: any;

    @Input() set currentOrg(object) {

        if (object) {
            this._currentOrg = object;
            this.loading = false;
            this.users = [];
        }
    }
    @Input() type;

    currentUser: any;
    users: Array<any> = [];

    inviteByEmail = false;
    sortedCollection: any[];

    userEmails: Array<any> = [];
    invByEmailForm: FormGroup;
    emailTitle: string;
    projectTeam: string;
    msgType = '';

    emailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = true;

    typingTimer;
    toastRef;

    displaytext: string = '';

    loading: boolean = true;
    lastEmail: string = '';

    constructor(
        private userSrv: UserService,
        private fb: FormBuilder,
        private dataSrv: DataService,
        private emailSrv: EmailService,
        private toasterSrv: EatToasterService,
        private utility: UtilityService,
        public globals: ConfigService,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private institutionSrv: InstitutionService,
        private orgSrv: OrganizationService,
        private router: Router
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {

        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        this.invByEmailForm = this.fb.group({
            newMembers: this.fb.array([
                this.fb.group({
                    email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
                })
            ])
        });
    }

    getNewMember(): FormArray {
        return this.invByEmailForm.get("newMembers") as FormArray
    }

    getInput(i) {
        return (<FormArray>this.invByEmailForm.get('newMembers')).controls[i];
    }

    newMember(): FormGroup {
        return this.fb.group({
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
        });
    }

    addMoreEmail() {
        this.getNewMember().push(this.newMember());
    }

    removeEmail(i: number) {
        this.getNewMember().removeAt(i);
    }

    ngAfterViewInit() {

    }

    onClose() {
        this.invByEmailForm.reset();
    }

    isInArray(val, arr) {
        return arr.indexOf(val) > -1;
    }

    validateEmail(event, index) { // check if the email input by user is in valid format

        const value = event.target.value;
        if (!this.emailRegex.test(String(value).toLowerCase())) {
            document.getElementById(index)!.style.border = "1px solid red";
            this.valid = false;
        } else {
            document.getElementById(index)!.style.border = "none";
            this.valid = true;
        }
    }

    onInviteByEmail() {
        for (let i = 0; i < this.invByEmailForm.value.newMembers.length; i++) { // check if all emails is in valid format

            if (!this.emailRegex.test(String(this.invByEmailForm.value.newMembers[i].email).toLowerCase())) {
                console.log('invalid', i);
                return
            }
        }
        if (this.invByEmailForm.invalid) {
            console.log('invalid');
            return;
        }

        this.loading = true;
        for (let i = 0; i < this.invByEmailForm.value.newMembers.length; i++) {
            this.userSrv.getUserByEmail(this.invByEmailForm.value.newMembers[i].email.toLowerCase()).subscribe(res => {

                if (res['result'] === 'successful') { // if user have an account
                    this.invNoti(this.invByEmailForm.value.newMembers[i].email, 2, false);
                } else { // if user doesn't have an account
                    this.addNewStudentAndInvite(this.invByEmailForm.value.newMembers[i].email);
                }

                if (i == this.invByEmailForm.value.newMembers.length - 1)
                    this.lastEmail = this.invByEmailForm.value.newMembers[i].email;
            });
        }
    }

    disableLoader(email: string) {
        if (this.lastEmail === email)
            this.loading = false;
    }

    invNoti(email, type, isNewStudent, index?) {
        const _noti = new Msg();

        if (type === 1) {
            this.users[index].inviteCount = 1;
        }
        if (email !== '') {
            this.userSrv.getUserDataByEmailDB(email).subscribe(res => { // retrieve user data

                let emailToName = res['data'].fullname;
                const currentUnixTime = new Date();
                _noti.batchId = this._currentOrg.id;
                _noti.requesterOrgId = this._currentOrg.organizationId;

                _noti.requesterUid = this.currentUser.id;
                _noti.senderUid = this.currentUser.id;
                _noti.invitedUid = res['data'].id;
                _noti.receiverId = res['data'].id;
                _noti.invitedOrgId = '';
                _noti.receiverName = emailToName;
                _noti.requestDate = currentUnixTime.getTime().toString();
                _noti.title = 'Invitation from ' + this._currentOrg.name + '.';
                _noti.content = `Hello ${_noti.receiverName},` +
                    ` I am ${this.currentUser.fullname} & I would like to invite you to connect with our company` +
                    ` ${this._currentOrg.name} on the platform.`;

                _noti.invitationType = '6';
                _noti.createdAt = currentUnixTime.getTime().toString();
                _noti.createdBy = this.currentUser.id;
                _noti.updatedAt = currentUnixTime.getTime().toString();
                _noti.updatedBy = this.currentUser.id;
                this.addOrgBatchRelationship(_noti.requesterOrgId, this._currentOrg.organizationTypeId,
                    res['data'].id, this.currentUser.id, this._currentOrg.id, '', ''
                ).subscribe(
                    (result) => {
                        if (result['result'] === 'successful') {
                            this.sendEmailNotification(_noti, currentUnixTime, res, email, isNewStudent);
                            this.router.navigate(['./w/batch/profile'],
                                {
                                    queryParams: {
                                        id: this.currentUser.id,
                                        batchId: _noti.batchId,
                                        orgId: this._currentOrg?.id,
                                        orgType: 'batch',
                                    }
                                });
                        }
                        else if (result['result'] === 'user_exist') {
                            let exist_string = 'This Users already exist in this batch. ' + emailToName;
                            this.showActionMsg("User Exist", exist_string, "red-title");
                            this.disableLoader(email);
                        }
                    },
                    (error) => console.log(error),
                    () => { }
                );

            }, (error) => {
                console.error("getUserDataByEmailDB :", error);
                this.disableLoader(email);
            });
        } else {
            // this.ShowMsg('Please indicate role and/or the user', 1);
        }
    }

    addOrgBatchRelationship(
        requesterOrgId,
        requesterOrgTypeId,
        invitedOrgId,
        uid,
        batchId,
        organizationTypeId,
        _matchedOrgType
    ) {
        return this.institutionSrv
            .addOrgRelationship(
                '',
                requesterOrgId,
                requesterOrgTypeId,
                invitedOrgId,
                organizationTypeId,
                uid,
                batchId,
                _matchedOrgType,
                'inviting',
                '0',
                _matchedOrgType
            )
        // .subscribe(
        //     (result) => {
        //         if (result['result'] === 'successful') {

        //         }
        //         else if (result['result'] === 'user_exist') {
        //             let exist_string = 'This Users already exist in this batch.';
        //             this.showActionMsg("User Exist", exist_string, "red-title");
        //         }
        //     },
        //     (error) => console.log(error),
        //     () => { }
        // );
    }


    private sendEmailNotification(_noti: Msg, currentUnixTime: Date, res: Object, email: any, isNewStudent: boolean) {
        this.dataSrv.createNewInv(_noti).subscribe(data => {
            if (data['result'] === 'successful') {
                this.emailTitle = `You are invited to join ${this._currentOrg.name}!`;
                let link = window.location.origin + (isNewStudent ? '/sign_up?token=' : '/login?token=');

                let queryParam = {
                    time: currentUnixTime.getTime(),
                    org: _noti.requesterOrgId,
                    email: email
                }

                this.emailSrv.sendEncryptedEmail(
                    this.emailTitle,
                    res['data'].email,
                    // 'arulananth.2008@gmail.com',
                    link,
                    this.currentUser.id,
                    this.currentUser.fullname,
                    this._currentOrg.name,
                    queryParam,
                    isNewStudent
                ).subscribe();
                this.showActionMsg('Success', `E-mail and notification sent to ${email}.`, "");
                this.invByEmailForm.reset();
            } else if (data['error']) {
                console.log(data['error']);
            }

            this.disableLoader(email);
        }, (error) => {
            console.error("createNewInv :", error);
            this.disableLoader(email);
        });
    }

    addNewStudentAndInvite(email: string) {
        this.userSrv.createUser(
            email,
            email,
            '',
            true,
            '',
            '',
            false,
            'email',
            this.currentUser.id
        ).subscribe(res => {
            if (res['result'] == 'successful') {
                this.invNoti(email, 2, true);
            } else {
                this.showActionMsg(res['message'], 1, "");
                this.disableLoader(email);
            }
        });
    }

    onInvite(id) {
        this.users[id].inviteCount = 1;
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

    showActionMsg(title, msg, color) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: color || 'green-title',
            closeButton: false,
            timeOut: 5000
        });
    }
}
