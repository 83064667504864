export class Partner {
    id: string;
    title: string;
    website: string;
    description: string;
    sequence: string;
    tags: string;
    sequnce: string;
    available: string;
    imageUrl: string;
}
