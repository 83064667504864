import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'lab-dialog-swapOwnership',
  templateUrl: './dialog-swapOwnership.component.html',
  styleUrls: ['./dialog-swapOwnership.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogSwapOwnerShipComponent implements OnInit {
  @Input() class?: string;
  @Input() show: string;
  @Input() members: Array<any> = [];
  @Input() type;
  @Input() name: string = "";

  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter<any>();

  selectedMemberId: string = "";
  selectedMemberName: string = "";
  selectedMemberError: boolean;
  confirmOwner: boolean = false;
  removeAsMember: boolean = false;
  loader: boolean = false;

  constructor() { }

  ngOnInit() {

    console.log(this.name)
  }

  onClick() {
    this.loader = false;
    this.confirmOwner = false;
    this.selectedMemberId = "";
    this.selectedMemberName = "";
    this.removeAsMember = false;
    this.selectedMemberError = false;
    this.close.emit();
  }

  onSubmit() {
    if(!this.confirmOwner) {
      if(!this.selectedMemberId) {
        this.selectedMemberError = true;
        return
      }
      this.selectedMemberError = false;
      
      this.members.forEach( (x) => {
        if(x.userId === this.selectedMemberId) {
          this.selectedMemberName = x.fullname;
        }
      })


      this.confirmOwner = true;
      return;
    }
    this.submit.emit({new_owner_uid: this.selectedMemberId, is_delete: this.removeAsMember});
    this.confirmOwner = false;
    this.loader = true;
  }

}
