import {
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { User } from '@common/models';
import { DataService } from '@common/services';

@Component({
    selector: 'ui-settings-nav',
    templateUrl: './settings-nav.component.html',
    styleUrls: ['./settings-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SettingsNavComponent implements OnInit, OnDestroy {
    currentUser;
    isStripeCustomer: boolean = false;
    tab;
    tabSubscription;
    constructor(
        private dataSrv: DataService
    ) { 
        this.tabSubscription = this.dataSrv.getSettingsTab().subscribe(res => {
            this.tab = res;
        });

        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        if (this.currentUser.stripeCustomerId) {
            this.isStripeCustomer = true;
        }
    }

    ngOnDestroy(): void {
        this.dataSrv.onChangeSettingTab(1);
        this.tabSubscription.unsubscribe();
    }

    onClick(value){
        this.tab = value;
        this.dataSrv.onChangeSettingTab(value);
    }

}
