import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { UtilityService } from '@common/services';

@Component({
  selector: 'lab-dialog-comment',
  templateUrl: './dialog-comment.component.html',
  styleUrls: ['./dialog-comment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogCommentComponent implements OnInit {
  @Input() msg: any;
  @Input() show: string;
  @Input() mode;

  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();

  public Editor = ClassicEditor;
  public model = {
    editorData: ''
  }
  public config = {
    placeholder: 'Add a comment here!',
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://www'
    }
  };

  constructor(
    public utilitySrv: UtilityService,
  ) { }

  ngOnInit() {
    // this.model.editorData = this.msg;
    if (!this.utilitySrv.IsNullOrEmpty(this.msg)) {
      this.model.editorData = this.msg;
    }
  }

  onClick() {
    this.close.emit();
  }

  onSubmit(event: any) {
    this.submit.emit(this.model.editorData);
  }

  onChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.model.editorData = data;
  }

}
