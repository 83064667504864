import { Organization } from './organization.model';

export class Menu {
    project: boolean;
    startup: boolean; // abandoned
    professionalService: boolean; // abandoned
    expert: boolean;
    funder: boolean;
    institution: boolean;
    organization: boolean;
    company: boolean;
    startupOrg: Organization[];
    advisorOrg: Organization[];
    investorOrg: Organization[];
    serviceProviderOrg: Organization[];
    uid: string;
    investor: boolean;
    advisor: boolean;
    serviceProvider: boolean;

}
