import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-toggleIcon',
  templateUrl: './toggleIcon.component.html',
  styleUrls: ['./toggleIcon.component.css'],

})
export class ToggleIconComponent implements OnInit {

  toggleIconAction: string;
  @Input() id: any;
  @Input() title: any;
  @Input() set defaultAction(value) {
    this.toggleIconAction = value;
  }
  @Output() ClickToggleIconEmt: EventEmitter<{ id: string, action: string }> = new EventEmitter<{ id: string, action: string }>();
  constructor(
  ) {
  }

  ngOnInit() { }

  onToggleIconAction() {
    this.toggleIconAction = this.toggleIconAction === 'out' ? 'in' : 'out';
    this.ClickToggleIconEmt.emit({ id: this.id, action: this.toggleIconAction });
  }


}
