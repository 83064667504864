import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lab-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @Input() path;
  @Input() type;
  @Input() isProfile;
  @Input() avatar: boolean;
  @Input() org;
  @Input() calendarEvents;

  hideWidgets = true; // remove it when start working on widgets

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    // this.getType(this.router.url);
  }

  ngAfterViewInit() {
  }

  getType(url: string) {

    const [empty, w, type, path, id] = url.split('/');
    this.isProfile = path === 'profile'
    this.type = type
    this.path = path;
  }

}
