import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinnerEdu',
  templateUrl: './spinnerEdu.component.html',
  styleUrls: ['./spinnerEdu.component.scss']
})
export class SpinnerEduComponent implements OnInit {

  @Input() imgPath;

  constructor() { }

  ngOnInit() {

  }

}
