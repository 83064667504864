import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'lab-dialog-remove',
  templateUrl: './dialog-remove.component.html',
  styleUrls: ['./dialog-remove.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogRemoveComponent implements OnInit {
  @Input() class?: string;
  @Input() show: string;
  @Input() content: string;
  @Input() subcontent: string;
  @Input() yesText: string = 'Yes';
  @Input() noText: string = 'No';
  @Input() type;

  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.close.emit();
  }

  onSubmit() {
    this.submit.emit();
  }

}
