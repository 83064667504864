import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'lab-dialog-delete-associated',
    templateUrl: './dialog-delete-associated.component.html',
    styleUrls: ['./dialog-delete-associated.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogDeleteAssociatedComponent implements OnInit {
    @Input() class?: string;
    @Input() show: string;
    @Input() content: string;
    @Input() type;
    @Input() title: string;
    @Input() data;

    @Output() close = new EventEmitter();
    @Output() deleteData = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onClick() {
        this.close.emit();
    }

    onSubmit() {
        this.deleteData.emit();
    }

    onGetType(value) {
        if (value === 'handout') {
            return 'Handout'
        } else if (value === 'othersource' || value === 'spreadsheet') {
            return 'Other source'
        } else if (value === 'slides') {
            return 'Template resource'
        }
    }

}
