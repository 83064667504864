import {
  Component,
  Input,
  Output,
  OnInit,
  ViewEncapsulation,
  EventEmitter
} from '@angular/core';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'ui-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToggleSwitchComponent implements OnInit {
  // properties 
  @Input() select: string;
  @Input() data: any[];

  // events
  @Output() toggleBtn = new EventEmitter();

  // variables
  defaultChoice: string;
  choice: string;
  options: any[] = [];

  constructor() { }

  ngOnInit() {
    this.options = [...this.data]
    this.defaultChoice = this.select ? this.select : this.options[0];
  }

  onChecked(value: any) {
    this.defaultChoice = value;
    this.toggleBtn.emit(value);
  }

}
