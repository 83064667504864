import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.css']
})
export class PercentageBarComponent implements OnInit {
  // To represent percentage values in the bar graph, pass in a string number with % affixed to the back. e.g. "5%"
  @Input() value: string;
  @Input() label: string;

  isValueAbsolute = false;
  absoluteValue: number;

  constructor() { }

  ngOnInit() {
    if (this.value) {
      if (!(this.value).includes('%')) {
        this.isValueAbsolute = true;
        var intValue = Number(this.value);

        // If showing absolute values (e.g. num of industries instead of % of industries, i multiply by 5% to represent the width of the bar)
        this.absoluteValue = intValue;
        this.value = (intValue * 5).toString() + '%';
      }
    }
  }

}
