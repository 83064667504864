import { User } from "@common/models";
import { Organization } from './organization';

export class Request {
    id: string;
    requestOrg: Organization;
    date: string;
    requestedRoles: string;
    status = 0; // 0 = new  |  1 = admin viewed it  |  2 = admin already suggest company
    type: number; // type of notification 0 = request 1 = invite
    requestBy: User;
}
