import { Organization } from './organization';
export class Review {
    id: string;
    userId: string;
    orgId: string;
    userId_orgId: string;
    reviewerName: string;
    createdAt: string;
    createdByUid: string;
    createdByUserName: string;
    updatedAt: string;
    updatedByUid: string;
    createdbyOrg: Organization;
    createdByOrgName: string;
    createdByOrgLogo: string;
    review: string;
    rating: number;
    // 0 = new || 1 = edited
    status = 0;
    reviewType: number;
    createdByAdmin: number;
    reviewedOrgId: string;
    createdByOrgId: string;
}
