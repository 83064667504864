import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProductService {

    constructor(
        private http: HttpClient) {
    }

    getAllProduct() {
        return this.http.get(`${environment.apiUrl}/product/getAllProduct`,
            {

            });
    }

    async getProductById(id) {
        return await this.http.post(`${environment.apiUrl}/product/getProductById`, {
            id: id
        }).toPromise();
    }

    addNewProduct(productName, productDescription, price, uid) {
        return this.http.post(`${environment.apiUrl}/product/addNewProduct`,
            {
                productName: productName,
                productDescription: productDescription,
                price: price,
                uid: uid,
            });
    }

    updateProduct(productName, productDescription, price, uid, id) {
        return this.http.post(`${environment.apiUrl}/product/updateProduct`,
            {
                productName: productName,
                productDescription: productDescription,
                price: price,
                uid: uid,
                id: id
            });
    }

}
