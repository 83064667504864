import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  projectsName = '';
  expertName = '';

  constructor(private http: HttpClient) { }

  createCalendar(value) {
    return this.http.post<any>(`${environment.apiUrl}/calendar/createCalendar`, value);
  }

  getCalendarByOrganizationId(organizationId) {
    return this.http.get<any>(`${environment.apiUrl}/calendar/getCalendarByOrganizationId/${organizationId}`);
  }

  updateCalendar(value) {
    return this.http.post<any>(`${environment.apiUrl}/calendar/updateCalendarByOrganizationId`, value);
  }

  deleteCalendar(id) {
    return this.http.get<any>(`${environment.apiUrl}/calendar/deleteCalendar/${id}`);
  }

  getEventByStartupId(id) {
      return this.http.get<any>(`${environment.apiUrl}/calendar/startup/${id}`)
  }

  getEventByExpertId(id) {
    return this.http.get<any>(`${environment.apiUrl}/calendar/expert/${id}`)
  }

  
  getEventByOrganizationId(id) {
    return this.http.get<any>(`${environment.apiUrl}/program/organization/${id}`)
  }

  getEventByEventId(id) {
    return this.http.get<any>(`${environment.apiUrl}/calendar/getEventByEventId/${id}`)
  }

  getCalendarEventByUserId(userId) {
    return this.http.get<any>(`${environment.apiUrl}/calendar/batch/events/${userId}`)
  }

}
