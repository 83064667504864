import { Component, Input, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService, ResourcesService, UtilityService } from '@common/services';
import { AuthenticationService } from '@modules/auth/services';

@Component({
    selector: 'lab-resource-info',
    templateUrl: './resource-info.component.html',
    styleUrls: ['./resource-info.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceInfoComponent implements OnInit {
    @Input() class?: string;
    @Input() data: any;
    @Input() page;
    @Output() onDisplay = new EventEmitter<{ id: any, type: any }>();

    currentUser: any;

    prefix: string = "";

    associated: any[] = [];
    loading = false;
    fileType: string;
    urlSafe: any;

    constructor(
        private authSrv: AuthenticationService,
        private configSrv: ConfigService,
        public utilitySrv: UtilityService,
        private resourcesSrv: ResourcesService,
        private cdf: ChangeDetectorRef,
        public sanitizer: DomSanitizer
    ) {
        this.prefix = this.configSrv.defaultAssetUrl;
        this.currentUser = this.authSrv.getUserData();
    }

    ngOnInit() {
        if (this.page === 'designer') { // check if user access component from program designer or program progress page
            this.onGetAssociated();
        } else if (this.page === 'progress') {
            this.onGetAssociatedProgress();
        }

        // this.prefix = 'https://host3.rescalelab.com'
        const file = this.prefix + this.data.filepath;

        if (this.utilitySrv.IsNullOrEmpty(this.data.link)) {
            if (this.utilitySrv.isSlide(file)) this.fileType = 'slide';
            if (this.utilitySrv.isImage(file)) this.fileType = 'image';
            if (this.utilitySrv.isVideo(file)) this.fileType = 'video';
        }

        if (this.utilitySrv.IsNullOrEmpty(this.data.filepath) ) {
            if (this.utilitySrv.isYoutube(this.data.link)) {
                this.fileType = 'media';
                const url = 'https://www.youtube.com/embed/' + this.data.link.split('/')[3];
                this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
            } 
        }
        
    }

    navigateExternalRoute() {
        let url: string = '';
        if (!/^http[s]?:\/\//.test(this.data.link)) {
            url += 'http://';

            url += this.data.link;
            window.open(url, '_blank');
        } else {
            window.open(this.data.link, '_blank');
        }
    }

    onGetAssociated() {
        this.loading = true;
        this.resourcesSrv.getAssociatedByResourceId(this.data.id).subscribe(res => {
            if (res['result'] === 'successful') {
                this.associated = res['data'];
                this.loading = false;
                this.cdf.detectChanges();
            } else {
                this.loading = false;
                this.associated = [];
                this.cdf.detectChanges();
            }
        }, (error) => {
            this.loading = false;
            console.error(error);
        });
    }

    onGetAssociatedProgress() { // only get published phase
        this.loading = true;
        this.resourcesSrv.getAssociatedByResourceIdProgress(this.data.id).subscribe(res => {
            if (res['result'] === 'successful') {
                this.associated = res['data'];
                this.loading = false;
                this.cdf.detectChanges();
            } else {
                this.loading = false;
                this.associated = [];
                this.cdf.detectChanges();
            }
        }, (error) => {
            this.loading = false;
            console.error(error);
        });
    }

    displayAssociated(id, type) {
        this.onDisplay.emit({id, type});
    }

    onGetType(value){
        if (value === 'handout') {
            return 'Handout'
        } else if (value === 'othersource' || value === 'spreadsheet') {
            return 'Other source'
        } else if (value === 'slides') {
            return 'Template resource'
        }
    }

    getFilePath(path) {
        let filename = path.slice(this.data.filepath.indexOf('resourceFile'));
        return filename
    }

}
