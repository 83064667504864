import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class OrganizationBatchService {

    constructor(
        private http: HttpClient
    ) {

    }

    getBatches() {
        return this.http.get(`${environment.apiUrl}/organizationBatch/batches`, {});
    }

    async getConsultantAndProjectTeamById(userId, batchId) {
        const params = new HttpParams().set('uid', userId).set('batch_id', batchId);
        return await this.http.get<any>(`${environment.apiUrl}/get_details/project_consultant`,
            { params: params }).toPromise();
    }

    async getParticipantByProjectAndBatch(orgId, batchId) {
        const params = new HttpParams().set('orgId', orgId).set('batchId', batchId);
        return await this.http.get<any>(`${environment.apiUrl}/get_details/org_users`,
            { params: params }).toPromise();
    }
    async getParticipantByBatch(orgId, batchId) {
        const params = new HttpParams().set('consultantId', orgId).set('batchId', batchId);
        return await this.http.get<any>(`${environment.apiUrl}/consultant/get_students`,
            { params: params }).toPromise();
    }
    async getTaggedUsersByBatch(orgId, batchId) {
        const params = new HttpParams().set('consultantId', orgId).set('batchId', batchId);
        return await this.http.get<any>(`${environment.apiUrl}/consultant/users`,
            { params: params }).toPromise();
    }
}
