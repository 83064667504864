import { User } from "@common/models";

export class Paypal {
    create_time: string;
    id: string;
    intent: string;
    links: any;
    payer: any;
    purchase_units: any;
    status: string;
    updated_time: string;
    transactionDoneByUserId: string;
    purchaseBy: User;
    itemAmount: number;
    itemDescription: string;
    // forV2Db
    orderDateTime: string;
    itemPrice: string;
    // itemDescription: string;
    payerEmail: string;
    payerLastName: string;
    payerFirstName: string;
    payerId: string;
    purchasedByUid: string;
    // status: string;
}
