import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UtilityService, ConfigService } from '@common/services';
import { AuthenticationService } from '@modules/auth/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ui-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FileInfoComponent implements OnInit {
  @Input() createdByName: string;
  @Input() createdBy: string;
  @Input() fileName: string;
  @Input() fileType: string;
  @Input() lastModified: any;
  @Input() fileSize: any;
  @Input() type: string;
  @Input() selected: string;
  @Input() filepath: string;
  @Input() link: string;
  @Input() action: string; // to define if user is selecting a resource or editing a resource
  @Input() associatedCount: number;

  @Output() select = new EventEmitter();
  @Output() view = new EventEmitter();
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();

  icon: string;
  prefix: string = "";
  docType: string;

  currentUser: any;

  disableclick = false;

  constructor(
    public utilitySrv: UtilityService,
    private configSrv: ConfigService,
    private toastrSrv: ToastrService,
    private authSrv: AuthenticationService,
  ) {
    this.prefix = this.configSrv.defaultAssetUrl;
    this.currentUser = this.authSrv.getUserData();
  }

  ngOnInit() {
    this.onCheckFileType(this.fileType);
  }

  addClassName({type, icon}) {
    const path = '../assets/icons';
    this.icon = `${path}/${icon}.svg`;
    this.docType = type;
  }

  onCheckFileType(type: string) {
    
    if (type.match(/pdf|ppt/)) return this.addClassName({type: 'slides', icon: 'slides'});
    if (type.match(/doc|txt/)) return this.addClassName({type: 'doc', icon: 'slide-template'});
    if (type.match(/img|jpg|gif|png|avif|webp|apng|svg|bmp/) ) return this.addClassName({type: 'img', icon: 'img'});
    if (type.match(/xls|csv/)) return this.addClassName({type: 'sheets', icon: 'sheets'});
    if (type.match(/video|mp4|ogg|mov|wmv|avi|flv|webm/)) return this.addClassName({type: 'video', icon: 'video'});
    if (type === 'link') return this.addClassName({type, icon: type});
  }

  onClick(event: any, name: string) {
    // if (this.type === 'view-file') return false;
    // this.select.emit(name);
    if (!this.disableclick) {
      if (this.action === 'select') {
        this.select.emit();
      } else {
        this.view.emit();
      }
      // else if (this.action === 'edit') {
      //   this.view.emit();
      // }
    } else {
      this.disableclick = false;
    }
  }

  onEdit() {
    this.disableclick = true;
    this.edit.emit();
  }

  navigateExternalRoute() {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(this.link)) {
      url += 'http://';

      url += this.link;
      window.open(url, '_blank');
    } else {
      window.open(this.link, '_blank');
    }
  }

  copyLink() {
    this.disableclick = true;
    let link;
    if (this.utilitySrv.IsNullOrEmpty(this.filepath) && this.link) {
      link = this.link;
    } else if (!this.utilitySrv.IsNullOrEmpty(this.filepath)) {
      link = this.prefix + this.filepath;
    }
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    this.toastrSrv.show('copied', 'Share', {
      disableTimeOut: false,
      tapToDismiss: true,
      toastClass: 'toast border-green',
      closeButton: false,
    });
  }

  onDelete() {
    this.disableclick = true;
    this.delete.emit();
  }

  formatBytes(bytes, decimals = 2) { // if link dont need to display 0 bytes
    if (this.fileType === 'link') {
      return '-'
    }
    if (bytes === 0 || this.utilitySrv.IsNullOrEmpty(bytes)) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
