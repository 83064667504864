import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService, ResourcesService } from '@common/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-dialog-resources',
  templateUrl: './dialog-resources.component.html',
  styleUrls: ['./dialog-resources.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogResourcesComponent implements OnInit {
  @Input() class?: string;
  @Input() show: boolean;
  @Input() type: string;
  @Input() currentRoleType;
  @Input() action;
  @Input() page;
  @Input() fileType;
  @Input() selectedSlidesResource;
  @Input() selectedSpreadResource;
  @Input() selectedHandoutRes;
  @Input() selectedOtherRes;


  @Output() openUpload = new EventEmitter<{ openUpload: any, resourceData: any }>();
  @Output() confirmResource = new EventEmitter();
  @Output() routeToAssociated = new EventEmitter();
  @Output() close = new EventEmitter();

  @ViewChild('searchInput') searchInputRef: ElementRef;

  selectedData;

  searchText: string = '';
  filterType: string = 'all';
  filesArray: any[];
  sortBy: string = 'file-name';
  sortDown: boolean = false;
  isSelected: boolean = false;

  selectFile: string = '';

  currentBatchId: string = '';
  loading = true;
  resources: any[] = [];
  displayData: any[] = [];

  isOpenDeleteDialog = false;
  isOpenDeleteAssociatedDialog = false;
  selectedResource;

  pageNumber: number = 1;
  numberOfPage = 1;
  startingIndex = 0;
  numberOfItemPerPage = 10;

  isOpenResourceModal: boolean = false;

  currentResource;

  // typingTimer;

  associated: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private resourcesSrv: ResourcesService,
    private cdf: ChangeDetectorRef,
    private toastr: ToastrService,
    private dataSrv: DataService,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.currentBatchId = params['batchId'];
    });

    this.dataSrv.getResourceData.subscribe(res => {
      if (res) {
        this.getResources();
      }
    });
    if (this.page === 'designer') {
      this.getResources();
    } else if (this.page === 'progress') {
      this.getResourcesProgress();
    }


  }

  getResources() {
    this.resourcesSrv.getResourcesByBatchId(this.currentBatchId).subscribe(res => {
      // console.log(res);
      if (res['result'] === 'successful' && res['data'] && res['data'].length > 0) {
        this.resources = res['data'];
        this.loading = false;
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.numberOfPage = Math.ceil(this.resources.length / this.numberOfItemPerPage);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
        if (this.fileType === 'slides' && this.selectedSlidesResource) {
          this.selectedData = this.selectedSlidesResource;
        } else if (this.fileType === 'spreadsheet' && this.selectedSpreadResource) {
          this.selectedData = this.selectedSpreadResource;
        } else if (this.fileType === 'handout' && this.selectedHandoutRes) {
          this.selectedData = this.selectedHandoutRes;
        } else if (this.fileType === 'othersource' && this.selectedOtherRes) {
          this.selectedData = this.selectedOtherRes;
        }
        this.cdf.detectChanges();
      } else {
        this.resources = [];
        this.displayData = [];
        this.loading = false;
        this.cdf.detectChanges();
      }
    });
  }

  getResourcesProgress() {
    this.resourcesSrv.getResourcesByBatchIdProgress(this.currentBatchId).subscribe(res => {
      if (res['result'] === 'successful' && res['data'] && res['data'].length > 0) {
        this.resources = res['data'];
        this.loading = false;
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.numberOfPage = Math.ceil(this.resources.length / this.numberOfItemPerPage);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
        this.cdf.detectChanges();
      } else {
        this.resources = [];
        this.loading = false;
        this.cdf.detectChanges();
      }
    });
  }

  onClose(event) {
    this.openUpload.emit({ openUpload: event, resourceData: null });
  }

  onSearchChange() {
    // clearTimeout(this.typingTimer);
    if (this.searchText) { // check if the user typed something
      this.doneTyping(this.searchText);
      // this.typingTimer = setTimeout(() => { // trigger function to search after 1 second

      // }, 1000);
    } else {
      this.pageNumber = 1;
      this.startingIndex = 0;
      this.numberOfPage = Math.ceil(this.resources.length / this.numberOfItemPerPage);
      this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
    }
  }

  doneTyping(value) {
    let filteredData = this.resources.filter(option => {
      if (option.filename) {
        if ((option.title.toLowerCase().includes(value.toLowerCase())) || (option.filename.toLowerCase().includes(value.toLowerCase()))) {
          return true
        }
      } else if (option.link) {
        if ((option.title.toLowerCase().includes(value.toLowerCase())) || option.link.toLowerCase().includes(value.toLowerCase())) {
          return true
        }
      } else if (option.title.toLowerCase().includes(value.toLowerCase())) {
        return true
      }
    });
    this.pageNumber = 1;
    this.startingIndex = 0;
    this.numberOfPage = Math.ceil(filteredData.length / this.numberOfItemPerPage);
    this.displayData = filteredData.slice(this.startingIndex, this.numberOfItemPerPage);
  }

  // for clear search event
  onClearSearchText() {
    this.searchText = '';
    this.searchInputRef.nativeElement.focus();
    this.numberOfPage = Math.ceil(this.resources.length / this.numberOfItemPerPage);
    this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
  }

  onFileSelected(event) {
    this.filesArray = [];
    let files = event.target.files;

    for (const element of files) {
      this.filesArray.push(element);
    }

    // if (file) {

    //     const formData = new FormData();

    //     formData.append("thumbnail", file);

    //     const upload$ = this.http.post("/api/thumbnail-upload", formData);

    //     upload$.subscribe();
    // }
  }

  onSort(value: string) {
    this.sortBy = value;
    this.sortDown = !this.sortDown;
    if (this.sortBy === 'file-name') {
      if (this.sortDown) {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      } else {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => b.title.toLowerCase() > a.title.toLowerCase() ? 1 : -1);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      }
    } else if (this.sortBy === 'associated') {

    } else if (this.sortBy === 'created') {
      if (this.sortDown) {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources = this.resources.sort((a, b) => a.fullname.toLowerCase() > b.fullname.toLowerCase() ? 1 : -1);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      } else {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => b.fullname.toLowerCase() > a.fullname.toLowerCase() ? 1 : -1);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      }
    } else if (this.sortBy === 'modified') {
      if (this.sortDown) {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => a.updatedAt - b.updatedAt);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      } else {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => b.updatedAt - a.updatedAt);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      }
    } else if (this.sortBy === 'size') {
      console.log('size');
      if (this.sortDown) {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => a.filesize - b.filesize);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      } else {
        this.pageNumber = 1;
        this.startingIndex = 0;
        this.resources.sort((a, b) => b.filesize - a.filesize);
        this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      }
      // if (this.sortDown) {
      //   console.log('g')
      //   this.pageNumber = 1;
      //   this.startingIndex = 0;
      //   this.resources.sort((a, b) => {
      //     console.log('a', a)
      //     console.log('b', b)
      //     if (a.filesize > b.filesize) {
      //       return 1
      //     } else {
      //       return 0
      //     }
      //   });
      //   this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      // } else {
      //   console.log('h')
      //   this.pageNumber = 1;
      //   this.startingIndex = 0;
      //   // this.resources.sort((a, b) => b.filesize > a.filesize ? 1 : -1);
      //   this.resources.sort((a, b) => {
      //     console.log('a', a)
      //     console.log('b', b)
      //     if (b.filesize > a.filesize) {
      //       return 1
      //     }
      //     if (b.filesize < a.filesize) {
      //       return -1
      //     }
      //     return 0
      //   });
      //   this.displayData = this.resources.slice(this.startingIndex, this.numberOfItemPerPage);
      // }
    }

  }

  onSelect(fileName: string) {
    // cancel selected and dipslay selected
    this.selectFile = fileName === this.selectFile ? '' : fileName;
  }

  onEdit(value) {
    this.openUpload.emit({ openUpload: true, resourceData: value });
  }

  onGetFileType(value) {
    if (value) {
      let startingPoint = value.indexOf('.') + 1;
      return value.slice(startingPoint, value.length)
    } else {
      return 'link'
    }
  }

  onDeleteDialog(data, action) {
    this.selectedResource = data;
    if (action === 'close') {
      this.isOpenDeleteAssociatedDialog = false;
      this.isOpenDeleteDialog = false;
    }
    if (action !== 'close') {
      this.onGetAssociated(this.selectedResource.id);
    }
  }

  onDeleteData() {
    this.resourcesSrv.deleteResource(this.selectedResource.id).subscribe(res => {
      // console.log(res);
      if (res['result'] === 'successful') {
        this.isOpenDeleteAssociatedDialog = false;
        this.isOpenDeleteDialog = false;
        this.getResources();
        this.dataSrv.getPhase(true);
        this.toastr.show('Resource deleted successfully!', '', {
          disableTimeOut: false,
          tapToDismiss: true,
          toastClass: 'toast border-green',
          closeButton: false,
        });
      }
    });
  }

  onBack() {
    if (!this.pageNumber) {
      this.pageNumber = 1;
    }
    if (this.pageNumber > 1) {
      this.pageNumber -= 1;
      this.startingIndex -= this.numberOfItemPerPage;
      this.displayData = this.resources.slice(this.startingIndex, (this.numberOfItemPerPage * this.pageNumber));
    }
  }

  onNext() {
    if (!this.pageNumber) {
      this.pageNumber = 1;
    }
    if (this.pageNumber < this.numberOfPage) {
      this.pageNumber += 1;
      this.startingIndex += this.numberOfItemPerPage;
      this.displayData = this.resources.slice(this.startingIndex, (this.numberOfItemPerPage * this.pageNumber));
    }
  }

  onSelectResource(data) {
    this.selectedData = data;
    // if (this.selectedData && data.id === this.selectedData.id) {
    //   this.selectedData = null;
    // } else {
    //   this.selectedData = data;
    // }
  }

  onConfirmResource() {
    this.confirmResource.emit(this.selectedData);
  }

  onView(value) {
    this.currentResource = value;
    this.isOpenResourceModal = true;
  }

  onCloseResourceModal() {
    this.isOpenResourceModal = false;
  }

  onCloseDialog(value) {
    this.isOpenResourceModal = false;
    this.routeToAssociated.emit(value);
  }

  onGetAssociated(id) {
    this.resourcesSrv.getAssociatedByResourceId(id).subscribe(res => {
      // console.log(res);
      if (res['result'] === 'successful') {
        this.associated = res['data'];
        this.isOpenDeleteAssociatedDialog = !this.isOpenDeleteAssociatedDialog;
        this.cdf.detectChanges();
      } else {
        this.associated = [];
        this.isOpenDeleteDialog = !this.isOpenDeleteDialog;
        this.cdf.detectChanges();
      }
    }, (error) => {
      this.loading = false;
      console.error(error);
    });
  }

  onEnter() {
    if (!this.pageNumber) {
      this.pageNumber = 1;
    }
    if (this.pageNumber != 0 && this.pageNumber <= this.numberOfPage) {
      this.startingIndex = (this.numberOfItemPerPage * this.pageNumber) - 7
      this.displayData = this.resources.slice(this.startingIndex, (this.numberOfItemPerPage * this.pageNumber));
    }
  }

  onCloseModal() {
    this.close.emit();
  }

}
