export class Matching {
    date: string;
    requestedRoles: string;
    requesterId: string;
    requesterOrgId: string;
    status1 = 0;
    status2 = 0;
    status3 = 0;
    Org1: string;
    Org2: string;
    Org3: string;
    Org1Name: string;
    Org2Name: string;
    Org3Name: string;
    key: string;

}
