import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
    ConfigService,
    DataService,
    EatToasterService,
    InstitutionService,
    MenuService,
    OrganizationRelationshipService,
    OrganizationService,
    RoleService,
    ToDoListService,
    UtilityService,
    WidgetService,
    WidgetSetService
} from '@common/services';
import { formatDate } from '@angular/common';
import { User, TodoItem } from '@common/models';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'lab-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./../../styles.scss', './notification.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
    @Input() notification: any;
    @Input() notifType: any; //1: Invitation, 2: Todo (Task), 3: Community Post Comment, 4: program notification, 5: Invitation, 6: Calendar
    @Input() when: any;
    @Input() index: any;
    @Output() dismissNotif = new EventEmitter();
    @Output() rejectNotify = new EventEmitter();
    @Input() logo;
    @Input() name;

    currentUser: User;
    funderId;
    expertId;
    institutionId;
    userRole: Array<any> = [];
    standardUserRole: Array<any> = [];
    waitingToRoute: boolean = false;
    toastRef;

    constructor(
        private toasterSrv: EatToasterService,
        private toastr: ToastrService,
        private dataSrv: DataService,
        private orgSrv: OrganizationService,
        private roleSrv: RoleService,
        private orgRelationshipSrv: OrganizationRelationshipService,
        private insSrv: InstitutionService,
        private widgetSrv: WidgetService,
        private widgetSetSrv: WidgetSetService,
        private todoSrv: ToDoListService,
        private router: Router,
        public utility: UtilityService,
        public globals: ConfigService,
        private menuSrv: MenuService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.dataSrv.getAsyncSubject().subscribe(
            (value: any) => {
                this.funderId = value.funderId;
                this.expertId = value.expertId;
                this.institutionId = value.institutionId;
            },
            (error) => console.log(error),
            () => { }
        );
    }

    ngOnInit() {
    }

    onDismissNotif() { // remove notification
        this.dismissNotif.emit({ notifType: this.notifType, when: this.when, index: this.index, notification: this.notification });
    }

    // reject function goes here 2022/7/19
    reject(invitation) {
        this.waitingToRoute = true;
        this.dataSrv.rejectNotification(invitation).subscribe(data => {
            if (data['result'] === 'successful') {
                this.rejectNotify.emit();
            } else {

            }
        }, (error) => {
            console.error("updateNotificationStatus :", error);
        })
    }

    // join function goes here
    // invitationType : 
    //   1 or 5 : build relastionship between organizations 
    //   2 : joined batch
    //   3 : joined organization
    //   4 : invite user to join batch as one of the organizing committee
    //   6 : invite user to join batch as participtant
    join(invitation) {
        this.waitingToRoute = true;

        // update invitation status - 0 new - 1 an action has been done
        if (invitation.status === 0) {
            invitation.status = 1;
            this.dataSrv.updateNotificationStatus(invitation).subscribe(data => {
                if (data['result'] === 'successful') {
                    this.dataSrv.updateNumberOfNotifcation(true);
                } else {

                }
            }, (error) => {
                console.error("updateNotificationStatus :", error);
            });
        }

        try {
            if (invitation.requesterOrgId !== '' && this.currentUser.id !== '' && invitation.invitationType !== '') {
                // 0 invited as a team member
                if (invitation.invitationType === '0') {
                    let orgTypeId = '';
                    let organization: any;
                    this.orgSrv.getOrgAndOrgTypeByOrgId(invitation.requesterOrgId).subscribe(orgData => {

                        orgTypeId = orgData['data'][0].organizationTypeId;

                        organization = orgData['data'][0];

                        let roleId = 9; // projectstaff
                        if (orgTypeId === '569e3670-da99-11e9-b68a-bb4d51af784e') {
                            roleId = 10; // funderstaff
                        } else if (orgTypeId === '5c5b6ca4-da99-11e9-b68a-bb4d51af784e') {
                            roleId = 11; // institutionstaff
                        } else if (orgTypeId === '6695c71e-da99-11e9-b68a-bb4d51af784e') {
                            roleId = 12; // expertstaff
                        }

                        // get invited user userrole
                        this.roleSrv.getUserRoleWUidnRoleName(this.currentUser.id, roleId.toString()).subscribe(userrole => { // check if user have this role

                            if (userrole['result'] === 'failed') {
                                this.userRole.push({
                                    userRoleId: '',
                                    userId: this.currentUser.id,
                                    roleID: roleId,
                                    available: 0
                                });

                                this.roleSrv.updateUserRoleByUid2(this.userRole).subscribe(userRoleRes => { // add user role if the user doesn't have it

                                    if (userRoleRes['result'] === 'successful') {
                                        this.userRole[0].userRoleId = userRoleRes['userRoleId'];

                                        this.onAddUserToOrg(this.userRole[0].userRoleId, invitation.requesterOrgId, orgTypeId, invitation.content, organization, roleId);
                                    }
                                }, (error) => {
                                    console.error("updateUserRoleByUid2 :", error);
                                });
                            } else {
                                this.onAddUserToOrg(userrole['data'][0].userRoleId, invitation.requesterOrgId, orgTypeId, invitation.content, organization, roleId);
                            }
                        }, (error) => {
                            console.error("getUserRoleWUidnRoleName :", error);
                        });
                    }, (error) => {
                        console.error("getOrgAndOrgTypeByOrgId :", error);
                    });

                    // 1 invited as a company
                } else if (invitation.invitationType === '1' || invitation.invitationType === '5') {

                    this.orgRelationshipSrv.checkIfOrganizationRelationshipExist(invitation.requesterOrgId,
                        invitation.invitedOrgId).subscribe(res => {

                            if (res['result'] === 'failed') {
                                this.orgRelationshipSrv.insert('',
                                    invitation.requesterOrgId,
                                    '',
                                    invitation.invitedOrgId,
                                    '',
                                    this.currentUser.id).subscribe(result => {
                                        if (result['result'] === 'successful') {
                                        }
                                    },
                                        err => {
                                            console.error("checkIfOrganizationRelationshipExist", err);
                                        }
                                    );
                            } else {

                            }
                        }, (error) => {
                            console.error("checkIfOrganizationRelationshipExist :", error);
                        });
                } else if (invitation.invitationType === '2') { // invite company to batch

                    this.orgSrv.getOrgAndOrgTypeByOrgId(invitation.invitedOrgId).subscribe(data => {
                        if (data['result'] === 'successful') {

                            this.updateOrgRelationship(invitation.batchId, invitation.invitedOrgId);
                        }
                    }, (error) => {
                        console.log("OrgAndOrgTypeByOrgId :", error);
                    });
                } else if (invitation.invitationType === '3') {

                    let userRoleId = '';
                    let orgTypeId = '';
                    this.roleSrv.getUserRoleWUidnRoleName(this.currentUser.id, '3').subscribe(data => {

                        userRoleId = data[0].userRoleId;
                        this.orgSrv.getOrgById(invitation.requesterOrgId).subscribe(Orgdata => {
                            orgTypeId = Orgdata['data'].organizationTypeId;

                            // add user into organization
                            this.orgSrv.addUserToOrgUser(userRoleId,
                                invitation.requesterOrgId,
                                orgTypeId,
                                '',
                                '',
                                this.currentUser.id).subscribe();
                        }, (error) => {
                            console.error("getOrgById :", error);
                        });
                    }, (error) => {
                        console.error("getUserRoleWUidnRoleName :", error);
                    });
                } else if (invitation.invitationType === '4' || invitation.invitationType === '6') { //invite user to batch as one of the organizing committee
                    let organizationTypeId = '';
                    this.orgSrv.getOrgAndOrgTypeByOrgId(invitation.requesterOrgId).subscribe(data => {
                        if (data['result'] === 'successful') {
                            organizationTypeId = data['data'][0].organizationTypeId;
                            let roleId = '';
                            if (invitation.invitationType === '4') {
                                roleId = '13'
                                // WIP
                            } else if (invitation.invitationType === '6') {
                                // participant
                                roleId = '15'
                            }
                            const params = {
                                batchId: invitation.batchId,
                                organizationId: invitation.requesterOrgId,
                                organizationTypeId: organizationTypeId,
                                roleID: roleId,
                                userId: this.currentUser.id
                            };
                            this.insSrv.addUserToBatch(params).subscribe(
                                value => {
                                    this.dataSrv.updateHeaderMenu(true);
                                    if (value['result'] === 'successful' && invitation.invitationType === '6') {
                                        this.updateOrgRelationship(invitation.batchId, this.currentUser.id);
                                        this.widgetSrv.getWidgetByBatchId(invitation.batchId).subscribe(batchWidget => {
                                            if (batchWidget['result'] === 'successful') {
                                                const userWidget = {
                                                    uid: this.currentUser.id,
                                                    widgetId: batchWidget['data'][0].id,
                                                };
                                                this.widgetSrv.createUserWidget(userWidget).subscribe(
                                                    response => console.log('createUserWidget', response),
                                                    error => console.error(error),
                                                    () => { }
                                                );
                                            }
                                        }, (error) => {
                                            console.error("getWidgetByBatchId :", error);
                                        });
                                    }
                                },
                                error => console.error("addUserToBatch failed", error),
                                () => {

                                }
                            );
                        }
                    }, (error) => {
                        console.error("getOrgAndOrgTypeByOrgId :", error);
                    });
                } else {
                    let userRoleId = '';
                    let orgTypeId = '';
                    this.roleSrv.getUserRoleWUidnRoleName(this.currentUser.id, '5').subscribe(data => {

                        userRoleId = data[0].userRoleId;
                        this.orgSrv.getOrgById(invitation.requesterOrgId).subscribe(Orgdata => {
                            orgTypeId = Orgdata['data'].organizationTypeId;

                            // add user into organization
                            this.orgSrv.addUserToOrgUser(userRoleId,
                                invitation.requesterOrgId,
                                orgTypeId,
                                '',
                                '',
                                this.currentUser.id).subscribe();
                        }, (error) => {
                            console.error("getOrgById :", error);
                        });
                    }, (error) => {
                        console.error("getUserRoleWUidnRoleName :", error);
                    });
                }
            } else {
                // this.ShowMsg('Organization ID and/or User ID cannot be empty!', 1);
            }
        } catch (err) {
            // this.ShowMsg('Something went wrong!', 1);
        }
    }

    updateOrgRelationship(batchId, invitedOrgId) {
        this.insSrv.getDataBybatchIdNmatchedOrgId(batchId, invitedOrgId).subscribe(res => {
            if (res['result'] === 'failed') {

            } else {

                let _relationship = res['data'];
                const params = {
                    id: _relationship[0].id,
                    userId: this.currentUser.id,
                    deleted: "0",
                    status: "joined",
                };

                this.insSrv.updateOrgRelationshipStatus(params).subscribe(res => {

                    if (res["stutus"] == 'successful') {

                    }

                }, (error) => {
                    console.log("OrgRelationshipStatus :", error);
                });
            }
        }, (error) => {
            console.log("batchIdNmatchedOrgId :", error);
        });
    }

    onAddUserToOrg(userRoleId, requesterOrgId, orgTypeId, content, organization, roleId) { // add user into organization
        this.orgSrv.addUserToOrgUser(
            userRoleId,
            requesterOrgId,
            orgTypeId,
            '',
            '',
            this.currentUser.id
        ).subscribe(NewUser => {
            if (NewUser['result'] === 'successful') {
                const today = new Date;
                const todayformated = formatDate(today, 'dd/MM/yyyy', 'en');
                let todo = new TodoItem();
                let assigner = content.slice(content.indexOf('am')
                    + 3, content.indexOf('&'));
                let assignee = content.slice(content.indexOf('Hello')
                    + 6, content.indexOf(','));
                todo.organizationId = requesterOrgId;
                todo.assigner = assigner;
                todo.assigneeName = assignee;
                todo.assigneeEmail = '';
                todo.assigneeUid = this.currentUser.id;

                todo.description = 'Sucessfully invited ' + assignee + ' into the company on ' + todayformated + '.';
                todo.dueDate = '-';
                todo.urgency = '-';
                todo.createdBy = this.currentUser.id;
                todo.status = 2;
                todo.userName = assigner;

                this.todoSrv.insert(todo).subscribe(NewData => { }); // add a record of when this user join the company
                localStorage.setItem('currentOrg', JSON.stringify(organization));
                setTimeout(() => {
                    if (roleId === 9) {
                        this.standardUserRole.map((role, i) => {
                            if (role.roleID === 7) {
                                role.available = 1 ? 1 : 0;
                            }
                        });
                        this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                        // this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                        this.showActionMsg('Success', 'Joined organization!');
                        this.waitingToRoute = false;
                        this.router.navigate(['./w/startup/profile'], { // route to the business profile page
                            queryParams: {
                                orgId: requesterOrgId,
                                orgTypeId: orgTypeId,
                                orgType: 'project'
                            }
                        });
                        this.dataSrv.updateHeaderMenu(true); // to refresh the header so user can access the business profile from the header
                    } else if (roleId === 12) {
                        this.standardUserRole.map((role, i) => {
                            if (role.roleID === 5) {
                                role.available = 1 ? 1 : 0;
                            }
                        });
                        this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                        // this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                        this.showActionMsg('Success', 'Joined organization!');
                        this.waitingToRoute = false;
                        this.router.navigate(['./w/consult/profile'], { // route to the business profile page
                            queryParams: {
                                orgId: requesterOrgId,
                                orgTypeId: orgTypeId,
                                orgType: 'consult'
                            }
                        });
                        this.dataSrv.updateHeaderMenu(true); // to refresh the header so user can access the business profile from the header
                    } else if (roleId === 11) {
                        this.standardUserRole.map((role, i) => {
                            if (role.roleID === 11) {
                                role.available = 1 ? 1 : 0;
                            }
                        });
                        this.roleSrv.updateUserRoleByUid(this.standardUserRole);
                        // this.toasterSrv.showToast('Joined organization!', '', this.toasterSrv.iconClasses.success);
                        this.showActionMsg('Success', 'Joined organization!');
                        this.waitingToRoute = false;
                        this.router.navigate(['./w/lab/profile'], { // route to the business profile page
                            queryParams: {
                                orgId: requesterOrgId,
                                orgTypeId: orgTypeId,
                                orgType: 'lab'
                            }
                        });
                        this.dataSrv.updateHeaderMenu(true); // to refresh the header so user can access the business profile from the header
                    }
                }, 1000);
            }
        }, (error) => {
            console.error("addUserToOrgUser :", error);
        });
    }

    isToday(date) {
        var today = new Date().setHours(0, 0, 0, 0);
        var thatDay = new Date(date).setHours(0, 0, 0, 0);

        return today === thatDay;
    }

    timeSince(date) {
        const currentUnixTime = new Date().getTime();
        var seconds = Math.floor((currentUnixTime - date) / 1000);
        var interval = seconds / 31536000;

        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' year';
            } else {
                return Math.floor(interval) + ' years';
            }
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' month';
            } else {
                return Math.floor(interval) + ' months';
            }
        }
        interval = seconds / 86400;
        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' day';
            } else {
                return Math.floor(interval) + ' days';
            }
        }
        interval = seconds / 3600;
        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' hour';
            } else {
                return Math.floor(interval) + ' hours';
            }
        }
        interval = seconds / 60;
        if (interval > 1) {
            if (Math.floor(interval) === 1) {
                return Math.floor(interval) + ' minute';
            } else {
                return Math.floor(interval) + ' minutes';
            }
        }
        return Math.floor(seconds) + ' seconds';
    }

    getTag(value) {
        if (value === 1) {
            return 'System'
        } else if (value === 2) {
            return 'Task'
        } else if (value === 3) {
            return 'Community'
        } else if (value === 4) {
            return 'Program'
        } else if (value === 5 || value === 6) {
            return 'System'
        }
    }

    getBackgroundColor(value) {
        switch (value) {
            case 'System':
                return '#A2A2A2';
            case 'Task':
                return '#FFAC4B';
            case 'Community':
                return '#bba1ff';
            case 'Program':
                return '#13BC6B';
        }
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }

    showActionMsg(title, msg) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: 'green-title',
            closeButton: false,
            timeOut: 5000
        });
    }

}
