import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ui-profile-nav',
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileNavComponent implements OnInit {
  @Input() type: string;
  @Input() path: string;
  @Input() batchId: string;
  @Input() org: any;

  orgId;
  orgType;
  orgTypeId;
  page: string = '';
  selectedItem = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.page = this.router.url.match('profile') ? this.router.url.split('/')[3] : this.router.url.split('/')[2];

    this.route.queryParams.subscribe((params) => {
      this.orgId = params['orgId'];
      this.orgType = params['orgType'];
      this.orgTypeId = params['orgTypeId'];
      this.batchId = params['batchId'];
    });
  }

  onLoadSchedule() {
    this.router.navigate(['/w/calendar'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  onLoadBanner() {
    this.router.navigate(['/w/batch/banner'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
  }

  onRoute(page) {

    this.page = page;
    this.path = page;
    if (this.org) {
      if (page == 'programs') {
        this.router.navigate(['./w/programs'], {
          queryParams: {
            orgId: this.orgId,
            orgTypeId: this.orgTypeId,
            orgType: this.type,
            tab: this.type,
            batchId: this.batchId
          }
        });
      } else {
        this.router.navigate(['./w/' + this.type + '/' + page],
          {
            relativeTo: this.route, queryParamsHandling: 'preserve',
            queryParams: {
              batchId: this.batchId
            }
          });
      }
    } else {
      if (page == 'programs') {
        this.router.navigate(['./w/programs'], {
          queryParams: {
            orgId: this.orgId,
            orgTypeId: this.orgTypeId,
            orgType: this.type,
            tab: this.type,
            batchId: this.batchId
          }
        });
      } else {
        this.router.navigate(['./w/' + this.type + '/' + page], {
          relativeTo: this.route, queryParamsHandling: 'preserve',
          queryParams: {
            batchId: this.batchId
          }
        });
      }
    }
  }
}
