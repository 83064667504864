import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ViewChild,
    ElementRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppSettingsService } from '@common/services';

@Component({
    selector: 'lab-dialog-edit',
    templateUrl: './dialog-edit-business-profile.component.html',
    styleUrls: ['./dialog-edit-business-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DialogEditComponent implements OnInit {
    @Input() show: string;
    @Input() currentOrg;

    @Output() close = new EventEmitter();
    @Output() submit = new EventEmitter();

    editOrgInfoForm: FormGroup;

    countriesOptions: any[] = [];
    countriesDisplayOptions: any[] = [];

    @ViewChild('selectCountry') selectCountry: ElementRef;

    constructor(
        private fb: FormBuilder,
        private appSettingsSrv: AppSettingsService
    ) {
        // this.appSettingsSrv.getCountryOptions().subscribe((data) => {
        //     this.countriesOptions = data;
        //     this.countriesDisplayOptions = data;
        // });
        this.countriesOptions = this.appSettingsSrv.countryOptions();
        this.countriesDisplayOptions = this.appSettingsSrv.countryOptions();
    }

    ngOnInit() {

        this.editOrgInfoForm = this.fb.group({
            companyName: [""],
            country: [""],
            postalcode: [""],
            compLogo: [""],
            add1: [""],
            add2: [""],
            website: [""],
            companyNumber: [""],
            companyEmail: [""],
            companyLinkedin: [""],
            companyFacebook: [""],
            companyTwitter: [""],
            companyInsta: [""],
            companyStage: [""],
            markets: [""],
            tagline: [""],
            description: [""],
            locations: [""],
            companysize: ["0"],
            industry: [""],
        });

    }

    onClick() {
        this.close.emit();
    }

    onSubmit() {
        this.submit.emit(this.currentOrg);
    }

    onDisplayOptions(event: any) {
        this.selectCountry.nativeElement.classList.toggle('expanded');
    }

    onCountryChange(value) {
        this.countriesDisplayOptions = this.countriesOptions.filter(option => option.text.toLowerCase().startsWith(value.toLowerCase()));
    }

}
