import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ExploreService {

    constructor(private http: HttpClient) { }

    handleError(err) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `Error: ${err.message}`;
        } else {
            errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;

            return throwError(errorMessage);
        }

    }

    updateAvailableOfExhibitorganization(id, available, uid) {
        return this.http.post(`${environment.apiUrl}/explore/updateAvailableOfExhibitorganization`,
            { id, available, uid });
    }

    updateExhibitorganization(id, organizationId,
        organizationTypeId,
        sequence,
        available,
        uid) {
        return this.http.post(`${environment.apiUrl}/explore/updateExhibitorganization`,
            {
                id, organizationId,
                organizationTypeId,
                sequence,
                available,
                uid
            });
    }

    insertExhibitorganization(organizationId,
        organizationTypeId,
        sequence,
        available,
        uid) {
        return this.http.post(`${environment.apiUrl}/explore/insertExhibitorganization`,
            {
                organizationId,
                organizationTypeId,
                sequence,
                available,
                uid
            });
    }
    deleteExhibitorganization(id, uid) {
        return this.http.post(`${environment.apiUrl}/explore/deleteExhibitorganization`,
            { id, uid });
    }

    async  getUserByRoleName(rolename: string) {
        return await this.http.get<any>(`${environment.apiUrl}/explore/getUserByRoleName/${rolename}`
            , {  }).toPromise();

    }

    async  getStartup() {
        return await this.http.get<any>(`${environment.apiUrl}/explore/startup`
            , {  }).toPromise();

    }
}
