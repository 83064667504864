import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, forwardRef } from '@angular/core';
import {
  FormControl,
  FormBuilder,
  Validators,
  FormGroup
} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-dialog-pivot',
  templateUrl: './dialog-pivot.component.html',
  styleUrls: ['./dialog-pivot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DialogPivotComponent implements OnInit {
  @Input() class?: string;
  @Input() submissions: any[];
  @Input() show: boolean;
  @Input() title;
  @Input() data;
  @Input() allowCancel: boolean;
  @Input() role;

  @Output() close = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() onPivot = new EventEmitter();

  public Editor = ClassicEditor;
  pivotForm: FormGroup;
  submitted: boolean = false;
  showItemAmount:number=2;

  qns1 = '';
  qns2 = '';
  qns3 = '';

  // list: any[] = [
  //   {
  //     id: 1,
  //     name: 'A1. Problem Statement'
  //   },
  //   {
  //     id: 2,
  //     name: 'A2. Supply chain chart'
  //   },
  //   {
  //     id: 3,
  //     name: 'A3. Customer persona/journey'
  //   },
  //   {
  //     id: 4,
  //     name: 'A4. Value Proposition Canvas'
  //   },
  // ];

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.pivotForm = this.formBuilder.group({
      description: ['', Validators.required],
      allAgreeChecked: false,
      mentorsAgreeChecked: false,
      selfAgreeChecked: false,
      submitAgreeChecked: false,
    });
  }

  ngOnInit() {
    this.submitted = false;
    if (this.role === 'project') {
      this.qns1 = 'All members of the team have agreed to this pivot';
      this.qns2 = 'Consultants have been consulted and agreed the best way forward is to pivot the project';
      this.qns3 = 'By choosing to move forward, you agree to urgently redo all the deliverables listed above in order to stay on track';
    } else if (this.role === 'professionalservice') {
      this.qns1 = 'As a Consultant designated to this Project Team, you agree to move forward with this pivot';
      this.qns2 = 'All Project Team members and Program Managers will be notified, and will need to agree to this pivot';
      this.qns3 = 'By choosing to move forward, the chosen Project Team will need to redo ALL the deliverables listed above and you will need to reassess them as soon as possible';
    } else if (this.role === 'batch') {
      this.qns1 = 'You would like to trigger a pivot for this project team';
      this.qns2 = 'All Project Team members and Consultants will be notified and will need to agree to this pivot';
      this.qns3 = 'By choosing to move forward, the chosen Project Team will need to redo ALL the deliverables listed above and Consultants will need to reassess them';
    }
  }

  get f() { return this.pivotForm.controls; }

  onClose() {
    this.close.emit();
  }

  onReject() {
    this.submitted = true;
    if (this.pivotForm.invalid) {
      return
    }
    this.reject.emit(this.pivotForm.value);
  }

  onSubmit() {
    // console.log('pviot => get values =========', this.pivotForm.value);
    this.submitted = true;
    if (this.pivotForm.invalid
      || !this.pivotForm.value.allAgreeChecked
      || !this.pivotForm.value.mentorsAgreeChecked
      || !this.pivotForm.value.selfAgreeChecked
      || !this.pivotForm.value.submitAgreeChecked
    ) {
      this.toastr.show('Please check all checkbox and fill in the reason for restart!', '', {
        disableTimeOut: false,
        tapToDismiss: true,
        toastClass: 'toast border-red',
        closeButton: false,
      });
      return
    }

    this.submitted = false;
    this.onPivot.emit(this.pivotForm.value);
    this.pivotForm.reset();
  }

  onLessOrMore(event){
    if(this.showItemAmount == this.data.selectedPhase.length){
      this.showItemAmount =2;
    } else{
    this.showItemAmount = this.data.selectedPhase.length;
    }
  }

}
