import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from '@angular/core';

import { environment } from 'environments/environment';

import {
  InstitutionService,
  UtilityService,
  ConfigService
} from '../../services';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-progressItem-detail',
  templateUrl: './progressItemDetail.component.html',
  styleUrls: ['./progressItemDetail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressItemDetailComponent implements OnInit{
  public tags = [];
  @Input() deliverableDetails: any;
  @Input() currentUser: any;
  @Input() ratingType: any[] = [];
  @Input() filterTeamProgressData: any[] = [];
  @Input() deliverables: any;
  @Input() programs: any;
  _canComment: boolean = true;
  public _projectTeam: any;
  @Input() set canComment(object) {
    this._canComment = !object;
  }
  toggleSections: Array<string> = ['out'];
  prefix = this.configSrv.defaultAssetUrl;
  editedComment;
  commentForm: FormGroup;
  submitted = false;
  isFailed = false;

  @Input() itemType: string = "1"; //1:project ,2:expert,3: project manager 4: individual
  constructor(
    private cdr: ChangeDetectorRef,
    private institutionSrv: InstitutionService,
    private utility: UtilityService,
    private configSrv: ConfigService,
    private fb: FormBuilder
  ) {
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
      isPrivate: [0, Validators.required]
    });
  }
  @Output() openDetailEvent = new EventEmitter<boolean>();

  ngOnInit() {

  }

  callCloseEvent(): void {
    this.openDetailEvent.next(true);
  }


  isNullOrEmpty(value) {
    return !this.utility.IsNullOrEmpty(value);
  }

  get f() { return this.commentForm.controls; }

  inValid() {
    return this.commentForm.invalid;
  }
  //
  // ra
  // 
  deliverableComment() {
    const values = this.commentForm.value;
    // whether have rating data or not. if not, we have to create rating data first
    if (this.deliverableDetails['deliverable'].ratingId === undefined) {
      const rating = {
        organizationId: this.deliverableDetails['projectTeam'].orgId,
        submission: 'null',
        deliverableId: this.deliverableDetails["deliverable"].id,
        ratingTypeId: 'null',
        userId: this.currentUser.id
      }

      this.institutionSrv.updateRating(rating).subscribe(
        value => this.deliverableDetails['deliverable'].ratingId = value.ratingId,
        error => console.log(error),
        () => {
          this.getRating(this.deliverables);
          this.createDeliverableComment(this.deliverableDetails['projectTeam'].orgId,
            this.deliverableDetails['projectTeam'].id,
            this.deliverableDetails['deliverable'].ratingId,
            values.comment,
            values.isPrivate,
            this.currentUser.id);
        }
      );
    } else {
      this.createDeliverableComment(this.deliverableDetails['projectTeam'].orgId,
        this.deliverableDetails['projectTeam'].id,
        this.deliverableDetails['deliverable'].ratingId,
        values.comment,
        values.isPrivate,
        this.currentUser.id);
    }
  }

  createDeliverableComment(organizationId, programId, ratingId, comment, commentType, uid) {
    this.isFailed = false;
    this.submitted = true;
    const params = {
      organizationId: organizationId,
      programId: programId,
      ratingId: ratingId,
      comment: comment,
      type: commentType ? 1 : 0,
      source: 1,
      status: 'null',
      userId: uid
    };

    this.institutionSrv.createComment(params).subscribe(
      res => {
        if (res['result'] == 'successful') {
          this.editedComment = null;
          this.commentForm.controls['comment'].setValue('');
          this.commentForm.controls['isPrivate'].setValue(0);
          const programIds = this.programs.map(value => value.id);
          this.getDeliverableComment(programIds);
        }
        else {
          this.isFailed = true;
        }
      },
      error => {
        console.error(error);
        this.isFailed = true;
      },
      () => {

      }
    );
  }

  getRating(deliverables) {


    this.institutionSrv.getRatingData({ deliverables: deliverables }).subscribe(
      res => {
        if (!this.utility.IsNullOrEmpty(res) &&
          !this.utility.IsNullOrEmpty(res.data)) {
          res.data.map(value =>
            this.filterTeamProgressData.map(team => {
              if (value.organizationId === team.orgId) {


                team.submissionDeliverable.map((deliverable, index) => {
                  if (deliverable.id === value.deliverableId) {
                    // deliverable['commentCount'] = value.count;
                    deliverable['ratingId'] = value.id;
                    deliverable['ratingTypeId'] = value.ratingTypeId;
                    deliverable['submission'] = value.submission;
                    deliverable['fileName'] = value.fileName;

                    if (this.utility.IsNullOrEmpty(value.ratingTypeId)) {
                      deliverable['rating'] = null;
                    } else {
                      deliverable['rating'] = this.ratingType.filter(type => type.id === value.ratingTypeId)[0].name;
                    }


                  }
                });

                team.ratingDeliverable.map(deliverable => {
                  if (deliverable.id === value.deliverableId) {
                    deliverable['ratingId'] = value.id;
                    deliverable['ratingTypeId'] = value.ratingTypeId;
                    deliverable['submission'] = value.submission;
                    deliverable['fileName'] = value.fileName;

                    if (this.utility.IsNullOrEmpty(value.ratingTypeId)) {
                      deliverable['rating'] = null;
                    } else {
                      deliverable['rating'] = this.ratingType.filter(type => type.id === value.ratingTypeId)[0].name;
                    }
                  }
                });
              }
            })
          );
        }
      },
      error => console.log(error),
      () => {
        const programIds = this.programs.map(value => value.id);
        this.getComment(programIds);
      }
    );
  }

  getDeliverableComment(programIds) {

    this.institutionSrv.getCommentData({ programIds: programIds }).subscribe(
      value => {
        this.deliverableDetails['deliverable'].comments = value.data.filter(comment => comment.ratingId === this.deliverableDetails['deliverable'].ratingId).sort((a, b) => a.createdAt - b.createdAt)
      },
      error => console.log(error),
      () => { }
    );
  }

  getComment(programIds) {
    this.institutionSrv.getCommentData({ programIds: programIds }).subscribe(
      value => {
        this.filterTeamProgressData.map(value => {
          value['comments'] = [];
          value['frozen'] = false;
        });
        value.data.filter(value => value.ratingId === 'null').map(value => {
          this.filterTeamProgressData.map((team, index) => {
            if (value.organizationId === team.orgId && value.programId === team.id) {
              value.comment = value.comment.replace(/(?:\r\n|\r|\n)/g, '<br>');
              team['comments'] = team['comments'].concat(value);
              team['comments'] = team['comments'].sort((a, b) => a.createdAt - b.createdAt);
              if (value.status === 'dropout') {
                this.filterTeamProgressData.map(startup => {
                  if (startup.orgId === team.orgId) {
                    startup['frozen'] = true;
                  }
                })
              }
            }
          });
        });
      },
      error => console.log(error),
      () => {
        // this.teamProgressData = JSON.parse(JSON.stringify(this.filterTeamProgressData));
        // this.isLoading = false;
      }
    );
  }

  onToggleSection(event) {
    this.toggleSections[event.id] = event.action;
    this.cdr.detectChanges();
  }

  updateRating(event, projectTeamId, deliverable) {
    const rating = {
      organizationId: projectTeamId,
      submission: deliverable.submission ? deliverable.submission : null,
      deliverableId: deliverable.id,
      ratingTypeId: event.target.value,
      userId: this.currentUser.id
    }

    this.institutionSrv.updateRating(rating).subscribe(
      value => { },
      error => console.log(error),
      () => this.getRating(this.deliverables)
    );
  }
  isPrivate(value) {
    if (value != "1") {
      return true;
    }
    else {
      return false;
    }

  }

  displayContent(content) {

    let _content = content;
    if (!this.utility.IsNullOrEmpty(content)) {
      _content = this.utility.replaceAll(content, '<br>', '\r\n');
    }
    return _content;
  }
  onEditComment(comment) {
    this.submitted = false;
    if (this.editedComment == null) {
      this.editedComment = comment;
      this.commentForm.controls['comment'].setValue(comment.comment);
      this.commentForm.controls['isPrivate'].setValue(comment.type);
    } else {
      this.editedComment = null;
      this.commentForm.controls['comment'].setValue('');
      this.commentForm.controls['isPrivate'].setValue(0);

    }
    this.cdr.detectChanges();
  }

  onUpdateComment() {
    this.submitted = true;
    // const params = {
    //   comment: this.commentForm.value.comment,
    //   type: this.commentForm.value.isPrivate ? 1 : 0,
    //   uid: this.currentUser.id
    // };

    let formData = new FormData();
    formData.append("comment", this.commentForm.value.comment);
    if (this.commentForm.value.isPrivate) {
      formData.append("type", '1');
    } 

    if (!this.commentForm.value.isPrivate) {
      formData.append("type", '0');
    }
    formData.append("uid", this.currentUser.id);
    formData.append('id', this.editedComment['id'])

    this.institutionSrv.updateCommentData(formData).then(
      res => {
        if (res['result'] == 'successful') {
          this.editedComment = null;
          this.commentForm.controls['comment'].setValue('');
          this.commentForm.controls['isPrivate'].setValue(0);
          const programIds = this.programs.map(value => value.id);
          this.getDeliverableComment(programIds);
        } else {
          this.isFailed = true;
        }
      },
      error => {
        console.error(error);
        this.isFailed = true;
      }
    );
  }
}

