export class Investor {
  id: string;
  residence = '';
  wealthSource = '';
  wealthSourceOthers = '';
  wealthSourceOthersText = '';
  investmentType = '';
  successfulPortfolio = '';
  failedPortfolio = '';
  failure = '';
  failureOthers = '';
  currentSetup = '';
  currentSetupOthers = '';
  currentSetupOthersText = '';
  investorIndustryFocus = '';
  investorIndustryFocusOthers = '';
  investorIndustryFocusText = '';
  targetSector = '';
  targetSectorOthers = '';
  targetSectorOthersText = '';
  stageInvested = '';
  stageInvestedOthers = '';
  stageInvestedOthersText = '';
  startupsInvested = '';
  capitalForStartups = '';
  roleKind = '';
  roleKindOthers = '';
  roleKindOthersText = '';
  valuationSize = '';
  ebitdaX = '';
  ebitdaXOthers = '';
  actAs = '';
  actAsOthers = '';
  actAsOthersText = '';
  investStage = '';
  investStageOthers = '';
  investStageOthersText = '';
  minInvestAmt = '';
  maxInvestAmt = '';
  startupRegion = '';
  roleKindWould = '';
  roleKindWouldOthers = '';
  roleKindWouldOthersText = '';
  investCommunity = '';
  investCommunityList: any[];
  engageTHL = '';
  engageTHLOthers = '';
  engageTHLOthersText = '';
  hearOfTHL = '';
  hearOfTHLOthers = '';
  hearOfTHLOthersText = '';
  joiningAs: boolean;
  investedCorp: any[] = [];
  createdInvestorAt = '';
  createdInvestorBy = '';
  updatedInvestorAt = '';
  updatedInvestorBy = '';
  uid = '';
  hasInvestorForm = true;
}
