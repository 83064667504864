import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: [
    './modal.component.css',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {

  @Input() modalid: string;
  @ViewChild('footerContent') footerContent;
  @ViewChild('headerContent') headerContent;
  @Output() ActionEvent = new EventEmitter<{ element: object, action: string }>();
  callParent(_action): void {
    this.ActionEvent.next({ element: this.el, action: _action });
  }
  constructor(private el: ElementRef) {

  }


  ngOnInit() {

    // we added this so that when the backdrop is clicked the modal is closed.
    this.el.nativeElement.addEventListener('click', (element) => {
      if (this.el.nativeElement.children.length > 0) {
        if (!this.el.nativeElement.children[0].classList.contains('show')) {
          this.callParent('closed');
        }
      }
    });

  }

  close() {
    this.el.nativeElement.classList.remove('sshow');
    this.el.nativeElement.classList.add('hhidden');
  }

}
