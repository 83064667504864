import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutService } from '@common/services';
import { StripePaymentService } from '@common/services/stripePayment.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lab-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckoutComponent implements OnInit, OnDestroy, OnChanges {
  checkoutStatus: Subscription;
  checkoutArray;
  checkoutTotal = 0;
  checkoutTotalText;
  checkoutAmount;
  checkoutAmountSub;
  checkoutArraySubs: Subscription;
  @Input() addToTotal;
  @Input() checkoutArrayLength;
  @Output() checkoutLength = new EventEmitter<any>();

  constructor(
    private checkoutSrv: CheckoutService,
    private router: Router,
    private paymentService: StripePaymentService
  ) { }

  ngOnInit(): void {
    this.checkoutArraySubs = this.checkoutSrv.getCheckoutArray().subscribe(res => {
      this.checkoutArray = res;
    });

    this.checkoutAmountSub = this.checkoutSrv.getCreditAmount().subscribe(res => {
      this.checkoutAmount = res;
      this.checkoutTotalText = this.checkoutAmount.toLocaleString();
    })

  }

  onClose() {
    this.checkoutSrv.changeCheckoutStatus(false)
  }

  ngOnDestroy() {
    this.checkoutArraySubs.unsubscribe();
    this.checkoutAmountSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  removeItem(data) {
    const indexOfObject = this.checkoutArray.findIndex(item => {
      return item.id === data.id;
    });

    var newCheckoutArr = [...this.checkoutArray];
    // newCheckoutArr[indexOfObject]['quantity'] = 1;
      this.checkoutSrv.changeCheckoutArray(newCheckoutArr);

    this.checkoutArray.splice(indexOfObject, 1);
    this.checkoutLength.emit(this.checkoutArray.length);
    this.calculateTotal();
  }

  /*
  * calculation of pricing for inputs changes events
  */
  updateValue(event, item) {
    item.total = item.price * event.target.value;
    item.quantity = Number(event.target.value);
    this.calculateTotal();
  }

  decreaseValue(item) {
    if (item.quantity > 1) {
      item.quantity = --item.quantity;
      item.total = item.price * item.quantity;
      this.calculateTotal();
    }
  }

  increaseValue(item) {
    item.quantity = ++item.quantity;
    item.total = item.price * item.quantity;
    this.calculateTotal();
  }

  calculateTotal() {
    this.checkoutTotal = 0;
    for (let i = 0; i < this.checkoutArray.length; i++) {
      this.checkoutTotal = this.checkoutArray[i]['total'] + this.checkoutTotal;
    }

    this.checkoutSrv.changeCreditAmount(this.checkoutTotal);
    this.checkoutTotalText = this.checkoutTotal.toLocaleString();
  }

  redirectToPayment() {
    if (this.checkoutAmount === 0) {
      return;
    }
    // Add to local storage
    localStorage.setItem('checkoutArray', JSON.stringify(this.checkoutArray));
    localStorage.setItem('checkoutTotal', JSON.stringify(this.checkoutAmount));
    this.paymentService.selectedPlan('credits');
    this.paymentService.setTotalAmount(this.checkoutAmount);

    this.checkoutSrv.changeCheckoutArray([]);
    this.checkoutSrv.changeCreditAmount(0);
    // if (this.checkoutArraySubs) this.checkoutArraySubs.unsubscribe();
    // if (this.checkoutAmountSub) this.checkoutAmountSub.unsubscribe();
    this.router.navigate(["/w/payments"], {
      queryParamsHandling: 'preserve'
    })
    this.onClose();
  }
}
