import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  AfterViewChecked,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  InstitutionService,
  OrganizationService,
  NotificationService,
  UtilityService,
  UserService
} from '@common/services';
import { programNotification } from '@common/models';
import { environment } from '../../../../environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'lab-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  disableScrollDown = false
  _rating: any;
  loading: boolean = true;
  @Input() set rating(object) {

    this._rating = object;
    if (this._rating) {
      this.initComment(this._rating.id, this._rating.ratingId);
      if (this.batchId) {
        this.initalCompany(this.batchId);
        this.getProgramManagersInfo(this.batchId);
      }
    }
  };
  @Input() showComments: any;
  commentData: any[] = [];
  @Input() title: string;
  // only mentors and programmer can do private comment.
  @Input() isSupportPrivate: boolean = false;
  @Input() currentUser: any;
  @Input() program: any;
  @Input() currentTeamStatus;

  @Output() close = new EventEmitter();
  @Output() addCount = new EventEmitter();
  @Output() subtractCount = new EventEmitter();

  message: string = '';
  isChecked: boolean = false;
  sendDisabeld: boolean = true;
  orgType: string = ''
  consultantId: string = '0';
  editMode: boolean = false;
  editCommentId: string = '';
  updatedBy: string = '';
  mentors: any[] = [];
  mentorManagement: any[] = [];
  batchCompany: any;
  batchId: string;
  batchUser: any[] = [];
  currentType: string;
  currentBatch;

  public Editor = ClassicEditor;
  public config = {
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://'
    }
  };

  constructor(
    private institutionSrv: InstitutionService,
    private cdr: ChangeDetectorRef,
    private orgSrv: OrganizationService,
    private route: ActivatedRoute,
    private notiSrv: NotificationService,
    private userService: UserService,
    private toastr: ToastrService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.currentType = params['orgType'];
      this.batchId = params['batchId'];
    });

    
  }

  ngOnInit() {
    this.scrollToBottom();
    this.currentBatch = JSON.parse(localStorage.getItem('currentBatch') || '{}');
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngAfterViewInit() {

  }

  public onScroll() {
    let element = this.myScrollContainer.nativeElement
    let atBottom = element.scrollHeight - element.scrollTop === element.clientHeight
    if (this.disableScrollDown && atBottom) {
      this.disableScrollDown = false
    } else {
      this.disableScrollDown = true
    }
  }


  private scrollToBottom(): void {
    if (this.disableScrollDown) {
      return
    }
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  onChange(event: any) {
    this.message = event.target.textContent;
    this.sendDisabeld = event.target.textContent.length > 0;
  }

  onToggleChecked() {
    this.isChecked = !this.isChecked;
  }

  onClose() {
    this.close.emit();
  }

  // check editMode is true or false
  onHandleSubmit(evnet: Event, mode: boolean) {
    // Cannot proceed if batch already expired.
    if (this.currentBatch['batchStatus'] === 2) {
      this.toastr.show('Batch has already expired. Sending comments has been disabled', '', {
        disableTimeOut: false,
        tapToDismiss: true,
        toastClass: 'toast border-red',
        closeButton: false,
      });
      return;
    }
    // call onUpdateComment or onComment
    mode ? this.onUpdateComment() : this.onComment();
  }

  onComment() {
    const params = {
      organizationId: this._rating.projectTeamId,
      programId: this._rating.programId,
      deliverableId: this._rating.id,
      ratingId: this._rating.ratingId,
      comment: this.message,
      type: this.isChecked ? 1 : 0,
      source: 1,
      status: 'null',
      userId: this.currentUser.id,
      createdByOrgType: 'null'
    };

    let res;

    this.institutionSrv.createComment(params).subscribe(
      comRes => {
        res = comRes;
        if (comRes['result'] === 'successful') {
          params['fullname'] = this.currentUser.fullname;
          params['imageUrl'] = this.currentUser.imageUrl;
          params['createdAt'] = comRes['data'][8];
          params['createdBy'] = comRes['data'][9];
          this.addCount.emit();

          if (this.isChecked !== true) {

            this.orgSrv.getOrgAndOwnerById(this._rating.projectTeamId).subscribe(res => {

              if (res['result'] === 'successful') {

                let email = res['ownerInfo']['email'];
                let name = res['ownerInfo']['fullname'];
                let newNotification = new programNotification();
                let title = this.currentUser.fullname + ' added a comment to a deliverable. Check it out now!';
                let content = this.currentUser.fullname + ' added a comment to a deliverable. Check it out now!';
                newNotification.commentId = comRes['commentId'];
                newNotification.uid = res['data']['owner'];
                newNotification.title = title;
                newNotification.content = content;
                newNotification.message = params['comment'];
                newNotification.commentAuthor = this.currentUser.fullname;
                newNotification.type = 0;

                if (res['ownerInfo']['deliverableComments'] === true) {
                  this.notiSrv.sendProgramCommentNotification(newNotification,
                    this.currentUser.id,
                    email, name).subscribe(notiRes => {
                    }, (error) => {
                      console.error("sendProgramCommentNotification :", error);
                    });
                }

              } else {

              }
            }, (error) => {
              console.error("getOrgAndOwnerById :", error);
            });
            // this.commentData.push(params);
            this.initComment(this._rating.id, this._rating.ratingId);
          } else {
            this.initComment(this._rating.id, this._rating.ratingId);
          }
          //  this.toasterSrv.showToast('comment', 'successfully', 'success');
        } else {

          //   this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
        }

      },
      error => {
        /*   this.toasterSrv.showToast('comment', 'Comment Failed', 'error');
          console.error("createComment", error) */
      },
      () => {
        /*   const programIds = this.programs.map(value => value.id);
          this.getComment(programIds); */
        if (this.mentorManagement.length > 0) {
          this.notifyMentor(res, params);
        }

        if (this.batchUser.length > 0) {
          this.notifyProgramManagers(res, params);
        }

        this.message = '';
        this.isChecked = false;
        this.cdr.detectChanges();

      }
    );
  }

  onDeleteComment(commentId) {
    if (this.currentBatch['batchStatus'] === 2) {
      this.toastr.show('Batch has already expired. Deleting comments has been disabled', '', {
        disableTimeOut: false,
        tapToDismiss: true,
        toastClass: 'toast border-red',
        closeButton: false,
      });
      return;
    }

    this.message = '';
    this.isChecked = false;
    this.editMode = false;

    const params = {
      id: commentId
    }

    this.institutionSrv.deleteComment(params).subscribe(res => {
      if (res['result'] === 'successful') {
        if (this.commentData.length > 0) {
          this.subtractCount.emit();
        }
        
        this.initComment(this._rating.id, this._rating.ratingId);
      }
    })
  }

  onEditComment(value) {
    this.editMode = true;
    this.message = value.text;
    this.editCommentId = value.commentId;
    this.updatedBy = value.uid;

    if (value['private'] === true) {
      this.isChecked = true;
    }
  }

  onUpdateComment() {
    let formData = new FormData();

    formData.append("comment", this.message);
    formData.append("type", this.isChecked ? '1' : '0');
    // if (this.isChecked) {
    //   formData.append("type", '1');
    // }

    // if (!this.isChecked) {
    //   formData.append("type", '0');
    // }

    formData.append("uid", this.updatedBy);
    formData.append('id', this.editCommentId)

    this.institutionSrv.updateCommentData(formData).then(res => {

      if (res['result'] === 'successful') {
        this.initComment(this._rating.id, this._rating.ratingId);
      }

    })

    this.message = '';
    this.isChecked = false;
    this.editMode = false;
  }

  initComment(deliverableId, ratingId) {
    try {
      this.loading = true;
      // getCommentsbyDeliverableIdANDOrgId
      this.institutionSrv.getDeliverableCommentData(deliverableId, ratingId).then(res => {
        if (res["result"] == "successful") {
          this.commentData = res["data"];

        } else {
          this.commentData = [];
        }
      }).catch((error) => {
        console.log("error", error);
        this.commentData = [];
      }).finally(() => {
        this.loading = false;
        this.cdr.detectChanges();
      })
    } catch (error) {

    }
  }

  initComment2(deliverableId, orgId) {
    try {
      this.loading = true;
      // getCommentsbyDeliverableIdANDOrgId
      this.institutionSrv.getCommentsbyDeliverableIdANDOrgId(deliverableId, orgId).then(res => {

        if (res["result"] == "successful") {
          this.commentData = res["data"];
        } else {
          this.commentData = [];
        }
      }).catch((error) => {
        console.log("error", error);
        this.commentData = [];
      }).finally(() => {
        this.loading = false;
        this.cdr.detectChanges();
      })
    } catch (error) {

    }
  }

  initCommentAfterNewComment(deliverableId, ratingId) {
    try {

      this.loading = true;
      // getCommentsbyDeliverableIdANDOrgId
      this.institutionSrv.getDeliverableCommentData(deliverableId, ratingId).then(res => {

        if (res["result"] == "successful") {
          this.commentData = res["data"];

        } else {
          this.commentData = [];
        }
      }).catch((error) => {
        console.log("error", error);
        this.commentData = [];
      }).finally(() => {
        this.scrollToBottom();
        this.loading = false;
        this.cdr.detectChanges();
      })
    } catch (error) {

    }
  }

  getImg(comment) {
    if (comment['imageUrl'] != null) {
      return environment.assetUrl + comment['imageUrl'];
    } else {
      // Change below image 
      return '/assets/images/workspace/event-hoster1.jpg';
    }
  }

  formatDateTime(dateString) {
    const parsed = moment(new Date(parseInt(dateString)))

    if (!parsed.isValid()) {
      return dateString
    }

    return parsed.format('MMM D, YYYY, HH:mmA')
  }

  //WIP
  getMentorManagement(batchId, clientId, clientType) {
    const params = {
      batchId: batchId,
      clientId: clientId,
      clientType: clientType
    };

    this.institutionSrv.getMentorManagement(params).subscribe(
      (res) => {

        if (res["result"] === "successful") {
          res.data.map((management) => {
            management["name"] = this.mentors.filter(
              (mentor) => mentor['id'] === management.masterId
            )[0]['name'];
            management["compLogo"] = this.mentors.filter(
              (mentor) => mentor['id'] === management.masterId
            )[0]['compLogo'];
          });
          if (res.data.length > 0) {
            this.mentorManagement = res.data.sort((a, b) => {
              return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
            });
          } else {
            this.mentorManagement = [];
          }
        }
      },
      (error) => console.log(error),
      () => { }
    );
  }

  initalCompany(batchId) {
    this.institutionSrv.getInvitedOrg(batchId).subscribe(
      (res) => {
        if (res["result"] !== "failed") {
          this.batchCompany = [
            ...res["projects"],
            ...res["funders"],
            ...res["experts"],
          ];

          this.mentors = res["experts"];
          this.getMentorManagement(this.batchId, this._rating.projectTeamId, 'organization');
        }
      },
      (error) => console.error("getInvitedOrg :", error)

    );
  }


  notifyMentor(comRes, params) {
    this.mentorManagement.forEach(mentor => {

      this.orgSrv.getOrgAndOwnerById(mentor['masterId']).subscribe(res => {
        if (res['result'] === 'successful') {

          let ownerInfo = res['ownerInfo'];

          if (ownerInfo['deliverable'] === true) {
            // Call API to send email
            let email = res['ownerInfo']['email'];
            let name = res['ownerInfo']['fullname'];
            let newNotification = new programNotification();
            let title = this.currentUser.fullname + ' added a comment to a deliverable. Check it out now!';
            let content = this.currentUser.fullname + ' added a comment to a deliverable. Check it out now!';
            newNotification.commentId = comRes['commentId'];
            newNotification.uid = res['data']['owner'];
            newNotification.title = title;
            newNotification.content = content;
            newNotification.message = params['comment'];
            newNotification.commentAuthor = this.currentUser.fullname;
            newNotification.type = 0;

            if (res['ownerInfo']['deliverableComments'] === true) {
              this.notiSrv.sendProgramCommentNotification(newNotification,
                this.currentUser.id,
                email, name).subscribe(notiRes => {
                }, (error) => {
                  console.error("sendProgramCommentNotification :", error);
                });
            }

          }

        }
      })
    });
  }

  getProgramManagersInfo(batchId) {

    if (batchId) {
      this.institutionSrv.getBatchProfile(batchId).then(res => {
        if (res['result'] === "successful") {
          if (res['users'] && res['users'].length > 0) {
            this.batchUser = res['users'].filter(user => {
              if (user.role === 'batchadmin' || user.role === 'batchstaff') {
                user.isDeleted = user.role === 'batchadmin' ? false : true;
                return true
              }
            })
          }
        }
      }).catch(error => {
        console.error("getBatchProfile :", error)
      }).finally(() => {
      });
    }
  }

  notifyProgramManagers(comRes, params) {

    this.batchUser.forEach(programManager => {
      this.userService.getUserDataByUid(programManager.userId).subscribe(res => {
        if (res['result'] === 'successful') {
          let programManagerInfo = res['data']
          // Call API to send email
          let email = programManagerInfo['email'];
          let name = programManagerInfo['fullname'];
          let newNotification = new programNotification();
          let title = this.currentUser.fullname + ' added a comment to a deliverable. Check it out now!';
          let content = this.currentUser.fullname + ' added a comment to a deliverable. Check it out now!';
          newNotification.commentId = comRes['commentId'];
          newNotification.uid = programManagerInfo['id'];
          newNotification.title = title;
          newNotification.content = content;
          newNotification.message = params['comment'];
          newNotification.commentAuthor = this.currentUser.fullname;
          newNotification.type = 0;

          if (programManagerInfo['deliverableComments'] === true) {
            this.notiSrv.sendProgramCommentNotification(newNotification,
              this.currentUser.id,
              email, name).subscribe(notiRes => {
              }, (error) => {
                console.error("sendProgramCommentNotification :", error);
              });
          }
        }
      })
    })
  }

}
