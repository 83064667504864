import { User } from "@common/models";

export class Institution {
    institution = '';
    institutionOthers = '';
    industryFocus = '';
    industryFocusOthers = '';
    targetSector = '';
    targetSectorOthers = '';
    noOfYearOperating = '';
    onboard = '';
    otherFocus = '';
    timeLimit = '';
    communicationMode = '';
    communicationModeOthers = '';
    memberSuccess = '';
    memberSuccessOthers = '';
    compensation = '';
    compensationOthers = '';
    factors4Members = '';
    institutionOperate = '';
    sourceOfFunding = '';
    sourceOfFundingOthers = '';
    successfulPortfolio = '';
    failedPortfolio = '';
    reason4failure = '';
    reason4failureOthers = '';
    stage2onboard = '';
    stage2onboardOthers = '';
    onboarded = '';
    capitalDeployed = '';
    rolesOnboarded = '';
    rolesOnboardedOthers = '';
    valuation = '';
    ebitdaX = '';
    ebitdaXOthers = '';
    noOfTeam = '';
    engage = '';
    engageOthers = '';
    createdInstitutionAt: string;
    createdInstitutionBy: User;
    updatedInstitutionAt: string;
    updatedInstitutionBy: User;
    uid: string;
}
