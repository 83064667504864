import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  ConfigService,
} from './config.service';

@Injectable()
export class UtilityService {
  parse: JSON['parse'];
  stringify: JSON['stringify'];
  localStorage: Storage;
  countriesOptions: any;

  constructor(private http: HttpClient,
    private configSrv: ConfigService) {
    this.parse = JSON.parse;
    this.stringify = JSON.stringify;
    this.localStorage = localStorage;

  }

  get version$(): Observable<string> {
    return this.http.get('/assets/version', { responseType: 'text' });
  }

  getStoredObject<T>(objectName: string): T | undefined {
    const objectString = this.localStorage.getItem(objectName);
    if (!objectString) {
      return undefined;
    }
    return this.parse(objectString) as T;
  }

  async getCountryOptions() {
    return await this.http.get('/assets/countries.json').toPromise();
  }

  storeObject(objectName: string, objectToStore: {}): void {
    this.localStorage.setItem(objectName, this.stringify(objectToStore));
  }

  IsNullOrEmpty(value) {
    try {
      if (
        !value ||
        value === "" ||
        value === " " ||
        value === null ||
        value === undefined ||
        value === "null" ||
        value === 'undefined' ||
        value === 'NULL'
      ) {
        return true;
      }
      return false;
    } catch (error) {
      console.warn("error", error);
    }
  }

  isJSONString(str) {
    if (
      /^[\],:{}\s]*$/.test(
        str
          .replace(/\\["\\\/bfnrtu]/g, "@")
          .replace(
            /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
            "]"
          )
          .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
      )
    ) {
      // the json is ok
      return true;
    } else {
      return false;
      // the json is not ok
    }
  }

  // to get the country name not short term
  // ex sg => Singapore
  GetCountryName(code: string) {
    try {
      if (this.IsNullOrEmpty(this.countriesOptions)) {
        return '';
      }
      let result = this.countriesOptions.filter(t => t.value == code);
      if (result.length > 0) {
        return result[0]['text'];
      } else {
        return ' ';
      }
    } catch (error) {
      console.error('Country Name Error : ' + error);
      return ' ';
    }
  }

  // to get full image path
  GetOrganizationLogo(path: string) {
    if (!this.IsNullOrEmpty(path)) {
      if (path.indexOf('asset') > -1) {
        return environment.assetUrl
          + path;
      }
    } else {
      return this.configSrv.defaultNoCompanyLogo;
    }
  }

  truncate(str, max = 70, suffix = '...') {
    return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${' ' + suffix}`;
  }

  isInArray(val, arr) {
    return arr.indexOf(val) > -1;
  }

  downloadFile(data, columns, filename = 'data') {
    let csvData = this.ConvertToCSV(data, columns);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  IsFieldDataNullOrEmpty(Data: object, field: string) {
    if (!this.IsNullOrEmpty(Data)) {
      return this.IsNullOrEmpty(Data[field]);
    } else {
      return true;
    }
  }

  // to uppercase the first letter of a string
  // ex project => Project
  FirstWordCapitalized(word: string) {
    if (this.IsNullOrEmpty(word)) {
      return '';
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  }


  isObject(obj) {
    return obj !== undefined && obj !== null && obj.constructor === Object;
  }

  cuttingWord(str, MaxLength) {
    if (!this.IsNullOrEmpty(str)) {
      if (str.length < MaxLength) {
        return str;
      } else {
        return str.substring(0, MaxLength) + '...';
      }
    }
    return str;
  }

  GetFieldData(Data: object, field: string, returnStr = '') {
    if (!this.IsNullOrEmpty(Data)) {
      if (!this.IsNullOrEmpty(Data[field])) {
        return Data[field];
      } else {
        return returnStr;
      }
    } else {
      return returnStr;
    }
  }

  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  timeSince(date) {
    try {

      const currentUnixTime = new Date().getTime();
      var seconds = Math.floor((currentUnixTime - date) / 1000);
      var interval = seconds / 31536000;

      if (date == 'null' || interval < 0.0000001) {
        return '1 seconds';
      }

      if (interval > 1) {
        return Math.floor(interval) + ' years';
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + ' months';
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + ' days';
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + ' hours';
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + ' minutes';
      }
      return Math.floor(seconds) + ' seconds';
    } catch (error) {
      return '1 seconds';
    }
  }

  splitArr(arr, size) {
    let newArr: any[] = [];
    for (let i = 0; i < arr.length; i += size) {
      newArr.push(arr.slice(i, i + size));
    }
    return newArr;
  }
  IsImageFilePath(value) {
    try {
      if (typeof value === 'string') {
        value = value.trim().toLowerCase();
        let extenionType = value.substring(value.lastIndexOf('.') + 1, value.length);
        if (extenionType === 'png' || extenionType === 'jpg' || extenionType === 'jpeg') {
          return true;
        }
      }
      return false;
    } catch (error) {

      console.warn('error', error);
      return false;
    }
  }

  checkFileType(url: string) {
    const types = new Map([
      ['jpg', 'image'],
      ['jpeg', 'image'],
      ['png', 'image'],
      ['svg', 'image'],
      ['gif', 'image'],
      ['webp', 'image'],
      ['avif', 'image'],
      ['apng', 'image'],
      ['bmp', 'image'],
      ['tiff', 'image'],
      ['mp4', 'video'],
      ['ogg', 'video'],
      ['webm', 'video'],
      ['avi', 'video'],
      ['mpeg', 'video'],
      ['wmv', 'video'],
      ['mov', 'video'],
      ['mp3', 'audio'],
      ['mp4', 'audio'],
      ['3gp', 'audio'],
      ['ogg', 'audio'],
      ['wav', 'audio'],
    ])

  }

  isYoutube(url: string) {
    if (url.match('youtu')) {
        return true
    }
    return false
  }
  
  isSlide(filename) {
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'pdf':
        case 'pptx':
          return true;
      }
      return false;
  }
  
  isImage(filename) {
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'svg':
        case 'gif':
        case 'bmp':
        case 'png':
          return true;
      }
      return false;
  }

  isVideo(filename) {
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'm4v':
        case 'ogg':
        case 'avi':
        case 'mpg':
        case 'mp4':
        case 'mov':
        case 'wmv':
        case 'flv':
          // etc
          return true;
      }
      return false;
  }

  isDoc(filename) {
    let ext = this.getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'docx':
      case 'doc':
      case 'txt':
      case 'xls':
        return true;
    }
    return false;
  }
  
  getExtension( url ) {
      return url.split(/[#?]/)[0].split('.').pop().trim();
  }

}
