import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { DataService } from './data.service';

@Injectable()
export class RoleService {
    private dbPath = '/roles';
    authState: any = null;

    constructor(
        private http: HttpClient,
        private dataSrv: DataService,
        private router: Router) {
    }

    async getAllRoles() {
        return await this.http.get<any>(`${environment.apiUrl}/role/getAllRoles`, {
        }).toPromise();
    }

    async getAllPermissions() {
        return await this.http.get<any>(`${environment.apiUrl}/role/getAllPermissions`,{})
        .toPromise();
    }

    async getRoleByUid(uid: string) {
        const params = new HttpParams().set('uid', uid);
        return await this.http.get<any>(`${environment.apiUrl}/userRole/getRoleByUid`, {
            params: params
        }).toPromise();
    }

    async getPermissionsByUid(uid: string) {
        const params = new HttpParams().set('uid', uid);
        return await this.http.get<any>(`${environment.apiUrl}/userRole/getPermissionsByUid`, {
            params: params
        }).toPromise();
    }

    getUserRoleWUidnRoleName(userId: string, roleName: string) {
        return this.http.post(`${environment.apiUrl}/userRole/getUserRoleByUserIdAndRoleName`,
            { userId: userId, roleName: roleName });
    }

    getUserRoleWRoleName(roleName: string) {
        return this.http.post(`${environment.apiUrl}/userRole/getRoleIdByRoleName`,
            { roleName: roleName });
    }

    getPermissionsByUidAndBatchId(uid: string, batchId: string) {
        return this.http.post(`${environment.apiUrl}/userRole/getPermissionsByUidAndBatchId`,
            {
                uid, 
                batchId
            });
    }

    getUserRoleIdByUidAndBatch(uid: string, batchId: string) {
        return this.http.post(`${environment.apiUrl}/userRole/getUserRoleIdByUidAndBatch`,
            {
                uid, 
                batchId
            });
    }

    getRoleIdByUidAndBatch(uid: string, batchId: string) {
        return this.http.post(`${environment.apiUrl}/userRole/getRoleIdByUidAndBatch`,
            {
                uid, 
                batchId
            });
    }

    changeRoleToBatchAdmin(userRoleId: string) {
        return this.http.post(`${environment.apiUrl}/userRole/changeRoleToBatchAdmin`,
            {
                userRoleId
            });
    }

    changeRoleToBatchStaff(userRoleId: string) {
        return this.http.post(`${environment.apiUrl}/userRole/changeRoleToBatchStaff`,
            {
                userRoleId
            });
    }

    deleteUserRoleByUserRoleId(userRoleId: string) {
        return this.http.post(`${environment.apiUrl}/userRole/deleteUserRoleByUserRoleId`,
            {
                userRoleId
            });
    }


    getRoleDefaultData(uid: string) {

        return [{
            'userRoleId': null,
            'userId': uid,
            'roleID': 3,
            'roleName': 'institution',
            'available': 0

        },
        {
            'userRoleId': null,
            'userId': uid,
            'roleID': 5,
            'roleName': 'professionalservice',
            'available': 0

        },
        {
            'userRoleId': null,
            'userId': uid,
            'roleID': 7,
            'roleName': 'startup',
            'available': 0
        },
        {
            'userRoleId': null,
            'userId': uid,
            'roleID': 2,
            'roleName': 'funder',
            'available': 0
        }];

    }

    updateUserRoleByUid(roles) {
        return this.http.post(
            `${environment.apiUrl}/userRole/insertUpdateRoleByRoleID`,
            { 'roles': roles }, { headers: new HttpHeaders().set('Content-Type', 'application/json') }
        ).subscribe(data => {
            this.dataSrv.update(true);
        }, error => {
            console.error(' updateUserRoleByUid error', error);
        });
    }

    updateUserRoleByUid2(roles) {
        return this.http.post(`${environment.apiUrl}/userRole/insertUpdateRoleByRoleID`,
            { roles: roles });
    }

    /** Error Handling method */

    errorHandler(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }



    getUserRoleIdByUidNroleID(uid, roleId) {
        return this.http.post(`${environment.apiUrl}/userRole/getDataByUidNRoleId`,
            { uid: uid, roleId: roleId });
    }

    getRoleIDByUserroleID(userroleID: string) {
        const params = new HttpParams().set('userroleID', userroleID);
        return this.http.get(`${environment.apiUrl}/userRole/getRoleIDByUserroleID`,
            { params: params }).toPromise();
    }
}
