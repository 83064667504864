export class Quote {
    id: String;
    quote: string;
    quoteColour: string;
    nameOfQuoter: string;
    nameOfQuoterColour: string;
    backgroundColour: string;
    fontWeight: string;
    fontSize: string;
    updatedBy: string;
    updatedAt: string;
}
