import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class NewActivityService {

  constructor(private http: HttpClient) { }

  newActivity(value) {
    return this.http.post<any>(`${environment.apiUrl}/newActivity/newActivity`, value);
  }

  newOrgUser(value) {
    return this.http.post<any>(`${environment.apiUrl}/newActivity/newOrgUser`, value);
  }

  activeUsers() {
    return this.http.get<any>(`${environment.apiUrl}/newActivity/activeUsers`);
  }

  activeOrgs() {
    return this.http.get<any>(`${environment.apiUrl}/newActivity/activeOrgs`);
  }

  activitiesCount() {
    return this.http.get<any>(`${environment.apiUrl}/newActivity/activitiesCount`);
  }

  typeActivityCount(type) {
    return this.http.get<any>(`${environment.apiUrl}/newActivity/typeActivityCount/${type}`);
  }
}
