
export class Educate {
  id: string;
  title: string;
  url: string;
  roles: string; // 0: startup 1: investor 2: advisor 3: vendors 4: individual
  type: number; // 0: widget, 1: product
  widgets: string;
  status: boolean; // 0: online 1: offline
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  filepath = '';
  discussioId: string;
  batchId: string;
  createdByOrgName: string;
}
