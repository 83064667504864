import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'batchFilter',
})
export class BatchFilterPipe implements PipeTransform {
    transform(items: any[], pending: boolean = false, assigned: boolean = false, notAssigned: boolean = false): any[] {
        if (!items) {
            return [];
        }

        return items.filter((user) => {
            let exists: boolean = false;
            if (pending && !user.invite_state) {
                exists = true;
            }
            if (assigned && user.org_name) {
                exists = true;
            }
            if (notAssigned && !user.org_name && user.invite_state) {
                exists = true;
            }

            return exists;
        });
    }
}
