import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
const CACHE_SIZE = 1;
import moment from 'moment';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';



export class UserCacheService {
    readonly CACHE_DURATION_IN_MINUTES = 3;

    private cache: {
        expires: Date;
        value: Observable<any[]>;
    } = null as any;

    constructor() {}

    getValue(): Observable<any[]> {
        if (!this.cache) {
            return null as any;
        }

        if (moment(new Date()).isAfter(this.cache.expires)) {
            return null as any;
        }

        return this.cache.value;
    }

    setValue(value: Observable<any[]>) {
        this.cache = {
            value,
            expires: moment(new Date()).add(this.CACHE_DURATION_IN_MINUTES, 'minutes').toDate(),
        };
    }

    clearCache() {
        this.cache = null as any;
    }
}

@Injectable()

export class UserService {
    admins: string[] = [];
    private subject: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private http: HttpClient, private userCacheSrv: UserCacheService) {}

    IsAdmin(uid: string) {
        return this.admins.indexOf(uid) > 0;
    }

    getAllUser() {
        return this.http.get(`${environment.apiUrl}/user/getAllUser`, {});
    }

    getUsersWithlimit(orgId, batchId) {
        return this.http.post(`${environment.apiUrl}/user/getUsersWithlimit`, {
            orgId: orgId,
            batchId: batchId
        });
    }

    searchUsers(text, orgId, batchId) {
        return this.http.post(`${environment.apiUrl}/user/searchUsers`, {
            text: text,
            orgId: orgId,
            batchId: batchId
        });
    }

    async getAllVerifiedUser() {
        let users$ = this.userCacheSrv.getValue();
        if (!users$) {
            users$ = await this.http
                .get(`${environment.apiUrl}/user/getAllVerifiedUser`, {})
                .pipe(
                    map((response: any) => response),
                    shareReplay(1)
                )
                .toPromise();
            this.userCacheSrv.setValue(users$);
        }
        return users$;
        // return this.http.get(`${environment.apiUrl}/user/getAllVerifiedUser`, {});
    }

    matchCofounderInfo() {
        return this.http.get(`${environment.apiUrl}/user/matchCofounderInfo`, {});
    }

    getAllUserRoles() {
        return this.http.get(`${environment.apiUrl}/userRole/getAllUserRole`, {});
    }

    SetNotFirstTime(uid: string) {
        return this.http.post(
            `${environment.apiUrl}/user/setNotFirstTime`,
            {
                uid: uid,
            },
            { headers: new HttpHeaders().set('Content-Type', 'application/json') }
        );
    }

    setFirstTime(uid: string, isFirstTime) {
        if (uid !== null || uid !== undefined) {
            return this.http.post(`${environment.apiUrl}/user/setFirstTime`, {
                uid: uid,
                isFirstTime,
            });
        }
    }

    setNotFirstTimeIndividual(uid: string) {
        return this.http.post(`${environment.apiUrl}/user/setNotFirstTime`, {
            uid: uid,
        });
    }

    getUserDataByEmailDB(email: string) {
        return this.http.post(`${environment.apiUrl}/user/getUserDataByEmail`, {
            email: email,
        });
    }

    getUserByEmail(email: string) {
        return this.http.post(`${environment.apiUrl}/user/getUserByEmail`, {
            email: email,
        });
    }
    /* 
        getUserDataByUidDB(id: string) {
            return this.http.post(`${environment.apiUrl}/user/getUserDataByUid`,
                {
                    uid: id
                });
        }
     */
    getUserDataByUid(uid: string) {
        const params = new HttpParams().set('uid', uid);
        return this.http.get<any>(`${environment.apiUrl}/user/getUserBasicInfoByUid`, {
            params: params,
        });
    }

    getUserPersonalityInfoByUid(uid: string) {
        const params = new HttpParams().set('uid', uid);
        return this.http.get<any>(`${environment.apiUrl}/user/getUserPersonalityInfoByUid`, {
            params: params,
        });
    }

    register(user) {
        const _user = this.http.post(`${environment.apiUrl}/users/register`, user);
        return _user;
    }

    update(data) {
        return this.http.post(`${environment.apiUrl}/user/updateUser`, {
            user: data,
        });
    }

    updateNotificationSettings(data) {
        return this.http.post(`${environment.apiUrl}/user/updateNotificationSetting`, data);
    }

    updateProfileImage(data) {
        return this.http.post(`${environment.apiUrl}/user/updateProfileImage`, {
            user: data,
        });
    }

    resetPassword(uid: string, email: string, password: string) {
        return this.http.post(`${environment.apiUrl}/authenticate/changePassword`, {
            uid: uid,
            email: email,
            password: password,
        });
    }

    updateIsAdminNDeleted(data: any) {
        return this.http.post(`${environment.apiUrl}/user/setAdminNDeleted`, data);
    }

    verifyDB(uid: any) {
        return this.http.post(`${environment.apiUrl}/user/setVerified`, { uid: uid });
    }

    async getUserList() {
        return this.http.get<any[]>(`${environment.apiUrl}/list/getUserList`);
    }

    // Form //

    async getPersonalpersonalityDataByUid(uid: string) {
        /*   return this.http.get<PersonalPersonality[]>(`${environment.apiUrl}/individual/getPersonalPersonalityByUid`);
         */
        const params = new HttpParams().set('uid', uid);
        const res = await this.http
            .get<any>(`${environment.apiUrl}/individual/getPersonalPersonalityByUid`, {
                params: params,
            })
            .toPromise();
        return res.data;
    }

    ConfirmVerifiedEmail(uid, status) {
        return this.http.post(`${environment.apiUrl}/user/setVerified`, {
            uid: uid,
            status: status,
        });
    }

    updateReqForgotPwTime(uid: string, forgotPwAt: string) {
        return this.http.post<any>(`${environment.apiUrl}/user/updateReqForgotPwTime`, {
            uid: uid,
            forgotPwAt: forgotPwAt,
        });
    }

    async IsExistingEmail(email) {
        const params = new HttpParams().set('email', email);
        const res = await this.http
            .get<any>(`${environment.apiUrl}/user/checkExistingEmail`, { params: params })
            .toPromise();
        return res;
    }

    getCurrentUser() {
        try {
            if (this.isJSONString(localStorage.getItem('currentUser'))) {
                return JSON.parse(localStorage.getItem('currentUser') || '{}');
            } else {
                return null;
            }
        } catch (error) {
            console.error('getCurrentUser failed :', error);
            return null;
        }
    }

    getNewSignUpsperDay() {
        return this.http.get(`${environment.apiUrl}/user/newSignUps`, {});
    }

    async getAllUserWithRoleName(roleName: string, pageLength = 10) {
        const params = new HttpParams()
            .set('roleName', roleName)
            .set('pageLength', pageLength.toString());
        const res = await this.http
            .get<any>(`${environment.apiUrl}/userRole/getAllUserWithRoleName`, { params: params })
            .toPromise();
        return res;
    }

    async getAllUserWithExtDetailByRoleName(roleName: string, keyword: string, pageLength = 10) {
        const params = new HttpParams()
            .set('roleName', roleName)
            .set('pageLength', pageLength.toString())
            .set('keyword', keyword);
        const res = await this.http
            .get<any>(`${environment.apiUrl}/userRole/getAllUserWithExtDetailByRoleName`, {
                params: params,
            })
            .toPromise();
        return res;
    }

    async getExpertForExternal() {
        const res = await this.http
            .get<any>(`${environment.apiUrl}/userRole/getExpertForExternal`)
            .toPromise();
        return res;
    }

    async getUserWithOrgByName(uid: string) {
        // getUserWithOrgByName
        const params = new HttpParams().set('uid', uid);
        const res = await this.http
            .get<any>(`${environment.apiUrl}/user/getUserWithOrgByNameV2`, { params: params })
            .toPromise();
        return res;
    }

    async getUserWithOrgById(id: string) {
        // getUserWithOrgByName
        return await this.http.get<any>(`${environment.apiUrl}/user/${id}`, {}).toPromise();
    }

    async getUserByRoleName(rolename: string) {
        return await this.http
            .get<any>(`${environment.apiUrl}/user/getUserByRoleName/${rolename}`, {})
            .toPromise();
    }

    async getUserSimpleDataByEmail(email: string) {
        const params = new HttpParams().set('email', email);
        const res = await this.http
            .get<any>(`${environment.apiUrl}/user/getUserSimpleDataByEmail`, { params: params })
            .toPromise();
        return res;
    }

    updateUser(value) {
        return this.http.post<any>(`${environment.apiUrl}/user/newUpdateUser`, value);
    }

    async insertUserRoleByRole(uid, role) {
        return await this.http
            .post<any>(`${environment.apiUrl}/userRole/insertUserRoleByRole`, {
                uid: uid,
                role: role,
            })
            .toPromise();
    }

    getAllUserDataByUid(user) {
        return this.http.post(`${environment.apiUrl}/user/getAllUserDataByUid`, {
            user: user,
        });
    }

    createUser(name, email, password, firstTime, gender, country, verified, provider, uid) {
        return this.http.post(`${environment.apiUrl}/user/createUser`, {
            fullname: name,
            email,
            password,
            firstTime,
            gender,
            country,
            verified,
            provider,
            uid,
        });
    }

    isJSONString(str) {
        if (
            /^[\],:{}\s]*$/.test(
                str
                    .replace(/\\["\\\/bfnrtu]/g, '@')
                    .replace(
                        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
                        ']'
                    )
                    .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
            )
        ) {
            // the json is ok
            return true;
        } else {
            return false;
            // the json is not ok
        }
    }
}
