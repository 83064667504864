import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@common/models';
import {
    EatToasterService,
    IndividualService,
    InstitutionService,
    OrganizationService,
    UtilityService,
} from '@common/services';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ModalUserListComponent } from '../modal-user-list/modal-user-list.component';

@Component({
    selector: 'business-profile-members',
    templateUrl: './business-profile-members.html',
    styleUrls: ['business-profile-members.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BusinessProfileMembersComponent implements OnInit {
    @Input() type;
    @Input() hideInviteBtn;
    @Input() currentUserRole;
    @Input() hideDeleteBtn: boolean = false;
    @Input() batchId;
    @Input() isSliderPersonalityCard: boolean = false;

    _currentOrg: any;

    @Input() set currentOrg(object) {
        if (object) {
            this._currentOrg = object;

            if (this.type === 'batch') {
                this.initalUser(this.batchId, 'default');
            } else {
                /* this.currentOrgId = params['orgId'];
                this.orgTypeId = params['orgTypeId']; */
                this.getStaffList();
            }
        }
    }

    @Output() viewUserUpdate = new EventEmitter<any>();



    currentUser;
    owner;
    canInvite: boolean;
    toastRef;

    orgType: string = '';
    loading = true;
    coFounders: Array<any> = [];
    members: Array<any> = [];
    batchUser: Array<any> = [];
    batchParticipants: Array<any> = [];
    showInviteBtn: boolean = true;

    isOpenModal: boolean = false;
    viewAll: boolean = true;
    expand: boolean = false;
    isOpenDialog: boolean = false;
    content;
    removeTarget;

    @ViewChild('container') containerRef: ElementRef;
    @ViewChild('modalcontainer', { read: ElementRef }) modalcontainer: ElementRef;
    @ViewChild('modalBody', { read: ElementRef }) modalBody: ElementRef;
    @ViewChild(ModalUserListComponent) MULComponent: ModalUserListComponent;

    @Output() onRemoveUser = new EventEmitter<any>();

    isSwapDialog: boolean = false;
    swapTarget;

    constructor(
        private route: ActivatedRoute,
        private orgSrv: OrganizationService,
        private individualSrv: IndividualService,
        private institutionSrv: InstitutionService,
        private toasterSrv: EatToasterService,
        private utilitySrv: UtilityService,
        private renderer: Renderer2,
        private toastr: ToastrService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.orgType = params['batchId'];
        });
    }

    changeWidth() {
        if (!this.expand) {
            const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-container'
            )[0];
            this.renderer.setStyle(childComponentOne, 'width', `100%`);
            const childComponentTwo = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-user-list-container'
            )[0];
            this.renderer.setStyle(childComponentTwo, 'grid-template-columns', `repeat(5, 1fr)`);
            this.expand = true;
        } else {
            const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-container'
            )[0];
            this.renderer.setStyle(childComponentOne, 'width', `665px`);

            const childComponentTwo = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-user-list-container'
            )[0];
            this.renderer.setStyle(childComponentTwo, 'grid-template-columns', `repeat(3, 1fr)`);
            this.expand = false;
        }
    }

    onToggleModal(value) {
        this.isOpenModal = value;
        // const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName("modal-container")[0];
        // this.renderer.setStyle(childComponentOne, "width", `665px`);
        // this.notAllowTargetScrollable(this.containerRef.nativeElement, this.isOpenModal);
        if (!this.isOpenModal) {
            this.MULComponent.onClose();
        }
    }

    notAllowTargetScrollable(element: any, condition: boolean) {
        const header = document.querySelector('#header');
        const height = header?.clientHeight;
        if (condition) {
            element.style.overflow = 'hidden';
            element.style.height = `calc(100vh - ${height}px)`;
        } else {
            element.removeAttribute('style');
        }
    }

    getStaffList(refresh = false) {
        // to get all the members in the current organization
        if (!refresh) this.loading = true;
        this.coFounders = [];
        this.members = [];
        if (this._currentOrg) {
            this.orgSrv
                .getOrgUserListWithValue(this._currentOrg.id, this._currentOrg.organizationTypeId)
                .then(
                    (staffList) => {
                        if (staffList['result'] === 'successful') {
                            if (staffList['data'].length > 0) {
                                let orguserlist = staffList['data'].filter(
                                    (user) =>
                                        user.roleID != '15' &&
                                        user.roleID != '16' &&
                                        user.roleID != '13' &&
                                        user.roleID != '14'
                                );

                                orguserlist = [
                                    ...new Map(
                                        orguserlist.map((item) => [item['userId'], item])
                                    ).values(),
                                ];

                                orguserlist.map((user) => {
                                    user['isDeleted'] = this.isAllowProjectDeleteUser(user);
                                    if (!this.utilitySrv.IsNullOrEmpty(user.personalQuizzes)) {
                                        user[
                                            'hipster'
                                        ] = this.individualSrv.calculate_Hipster_percentage(
                                            user.personalQuizzes[0]
                                        );
                                        user[
                                            'hacker'
                                        ] = this.individualSrv.calculate_Hacker_percentage(
                                            user.personalQuizzes[0]
                                        );
                                        user[
                                            'hustler'
                                        ] = this.individualSrv.calculate_Hustler_percentage(
                                            user.personalQuizzes[0]
                                        );
                                    }
                                    user.isPersonalPersonality = true;
                                    if (this.utilitySrv.IsNullOrEmpty(user.personalPersonality)) {
                                        user.isPersonalPersonality = false;
                                        // if(cofounder.personalPersonality[0].mBIndicator)
                                        // user.isPersonalPersonality = false;
                                    } else if (
                                        this.utilitySrv.IsNullOrEmpty(user.personalPersonality[0])
                                    ) {
                                        user.isPersonalPersonality = false;
                                    } else if (
                                        this.utilitySrv.IsNullOrEmpty(
                                            user.personalPersonality[0].mBIndicator
                                        )
                                    ) {
                                        user.isPersonalPersonality = false;
                                    }

                                    /*    if (!this.utilitySrv.IsNullOrEmpty(user.imageUrl)) {
                                   if (user.imageUrl.indexOf('asset') > -1) {
                                       user.imageUrl = environment.assetUrl + user.imageUrl;
                                   }
                               } */
                                });

                                this.coFounders = orguserlist;

                                this.owner = orguserlist[0]?.owner;

                                let currentUser = this.coFounders.filter((member) => {
                                    return member.userId === this.currentUser.id;
                                });

                                if (currentUser.length) {
                                    switch (currentUser[0]['roleID']) {
                                        case 3:
                                        case 5:
                                        case 7:
                                        case 14:
                                            this.canInvite = true;
                                            break;
                                        default:
                                            this.canInvite = false;
                                    }
                                }

                                if (this.currentUser.IsAdmin === 1) {
                                    this.canInvite = true;
                                }

                                if (!this.canInvite) {
                                    if (this.currentUser.roles) {

                                        let batchAdminRoles = this.currentUser.roles.filter((role) => {
                                            return role.roleName == 'batchadmin';
                                        });

                                        if (batchAdminRoles && batchAdminRoles.length > 0)
                                            this.canInvite = true;
                                    }
                                }

                                /*  this.coFounders = staffList['coFounder'];
                         for (let item in this.coFounders) { 
 
                             this.coFounders[item][0]['hipster'] = this.individualSrv.calculate_Hipster_percentage(this.coFounders[item][0].personalQuizzes);
                             this.coFounders[item][0]['hacker'] = this.individualSrv.calculate_Hacker_percentage(this.coFounders[item][0].personalQuizzes);
                             this.coFounders[item][0]['hustler'] = this.individualSrv.calculate_Hustler_percentage(this.coFounders[item][0].personalQuizzes);
                             let logo = this.coFounders[item][0].userinfo.imageUrl;
 
                             if (!this.utilitySrv.IsNullOrEmpty(logo)) {
                                 if (logo.indexOf('asset') > -1) {
                                     this.coFounders[item][0].userinfo.imageUrl = environment.assetUrl + logo;
                                 }
                             }
                         } */

                                // remove-duplicate
                                //  this.coFounders = [...new Map(this.coFounders.map(item => [item['id'], item])).values()]

                                // It is used to decide whether show remove user button
                                /*  let isCofounder = this.coFounders.filter(user => user[0].userinfo.id === this.currentUser.id);
                         if (isCofounder.length !== 0) {
                             this.currentUser['isCofounder'] = true;
                         }
                         else {
                             this.currentUser['isCofounder'] = false;
                         } */
                            }
                        } else if (staffList['error']) {
                            console.log(staffList['error']);
                        }
                        this.loading = false;
                    },
                    (error) => {
                        console.error('getOrgStaffList :', error);
                    }
                );
        }
    }

    initalUser(batchId, key) {
        this.institutionSrv.getBatchUserByBatchId(batchId, key).subscribe(
            (value) => {
                if (value.result === 'successful') {
                    if (value.data.length > 0) {
                        value.data.map((data) => {
                            if (data.roleID === 13 || data.roleID === 14) {
                                this.batchUser.push(data);
                            } else if (data.roleID === 15) {
                                this.batchParticipants.push(data);
                            }
                        });

                        if (this.batchUser instanceof Array) {
                            this.batchUser = this.batchUser.sort((a, b) => {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            });
                        }
                        if (this.batchParticipants instanceof Array) {
                            this.batchParticipants = this.batchParticipants.sort((a, b) => {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            });
                        }
                    }
                } else if (value['error']) {
                    console.error(value['error']);
                }
            },
            (error) => {
                console.error('getBatchUserByBatchId :', error);
            },
            () => {
                this.loading = false;
            }
        );
    }

    // userType: cofounder , member
    removeUser(orgId: string, orgTypeId: string, user, userType) {
        let _user = user;

        this.orgSrv
            .removeUserFromOrgUser(
                orgId,
                orgTypeId,
                _user.userRoleId,
                this.currentUser.id,
                _user.userId
            )
            .subscribe(
                (data) => {
                    if (data['result'] === 'successful') {
                        if (userType == 'cofounder') {
                            this.coFounders = this.coFounders.filter(
                                (user) => user.userId !== _user.userId
                            );
                        } else if (userType == 'member') {
                            this.members = this.members.filter((user) => {
                                return user.userId !== _user.userId;
                            });
                        }
                        // this.toasterSrv.showToast('', `${_user.fullname} is removed!`, 'success');
                        this.showActionMsg('Success', `${_user.fullname} is removed!`);
                    } else {
                        // this.toasterSrv.showToast('', `remove ${_user.fullname} failed!`, 'error');
                        this.showActionMsg('Failed', `${_user.fullname} is not removed!`);
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onOpenDialog(value) {
        this.onRemoveUser.emit(value);
    }

    showActionMsg(title, msg) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: 'green-title',
            closeButton: false,
            timeOut: 5000,
        });
    }

    /*   organization owner is not allow to be deleted
     current user is not allow to be deleted
     */
    isAllowDeleteUser(userInfo) {
        if (this.currentUser.IsAdmin === 1) {
            if (this._currentOrg.owner == userInfo.userId) {
                return false;
            } else {
                return true;
            }
        }

        if (this._currentOrg.owner == userInfo.userId) {
            return false;
        }
        if (this.currentUser.id != this._currentOrg.owner) {
            return false;
        }
        if (userInfo.userId == this.currentUser.id) {
            return false;
        }

        return true;
    }

    isAllowProjectDeleteUser(userInfo) {
        if (this._currentOrg.owner == userInfo.userId) {
            return false;
        }
        if (this.currentUser.id === userInfo.owner) {
            return true;
        }
        return false;
    }

    onRemove(org) {
        if (!this.isSliderPersonalityCard) {
            this.onOpenDialog([
                this._currentOrg.id,
                this._currentOrg.organizationTypeId,
                org,
                'cofounder',
            ]);
            return;
        }
        this.isOpenDialog = !this.isOpenDialog;
        this.content = `Are you sure you want to delete <b>${org.fullname}</b>? This action cannot be undone.`;
        this.removeTarget = { obj: org };
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
    }

    onCloseDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        document.body.removeAttribute('style');
    }

    onSubmit() {
        const { obj } = this.removeTarget;
        this.isOpenDialog = !this.isOpenDialog;
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
        this.removeUser(this._currentOrg.id, this._currentOrg.organizationTypeId, obj, 'cofounder');
    }

    openSwapDialog(org) {
        this.isSwapDialog = !this.isSwapDialog;
        this.removeTarget = { obj: org };
        this.notAllowTargetScrollable(document.body, this.isSwapDialog);
    }

    onCloseSwapDialog() {
        this.isSwapDialog = !this.isSwapDialog;
        document.body.removeAttribute('style');
    }

    getMembers() {
        return this.coFounders.filter((x) => x.userId !== this._currentOrg.owner);
    }

    swap(value: any) {
        const { new_owner_uid, is_delete } = value;
        const current_owner_uid = this.coFounders.find((x) => x.userId === this._currentOrg.owner)
            .userId;
        const payload = {
            org_id: this._currentOrg.id,
            current_owner_uid,
            new_owner_uid,
            is_delete,
        };
        const newOwnerName = this.coFounders.find((x) => x.userId === new_owner_uid).fullname;
        this.orgSrv.changeOwnerShip(payload).subscribe(
            (data) => {
                if (data['status'] === 'Success') {
                    // this.toasterSrv.showToast('', `${_user.fullname} is removed!`, 'success');
                    this.showActionMsg(
                        'Success',
                        `Ownership has been successfully transferred to ${newOwnerName}.`
                    );
                    this.onCloseSwapDialog();
                    this.viewUserUpdate.emit();
                    this.getStaffList(true);
                } else {
                    // this.toasterSrv.showToast('', `remove ${_user.fullname} failed!`, 'error');
                    this.onCloseSwapDialog();
                    this.showActionMsg(
                        'Failed',
                        `The transfer of Ownership to ${newOwnerName} is failed.`
                    );
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
}
