import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class VbpService {

  constructor(
    private http: HttpClient
  ) { }

  // FUNCTIONS FOR VBP INFO HERE
  getVbpInfo() {
    return this.http.get(`${environment.apiUrl}/vbp/getVbpInfo`,
      {

      });
  }

  getVbpById(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getVbpById`,
      {
        vbpId: vbpId,
      });
  }

  addVbpInfo(title, description, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/addVbpInfo`,
      {
        title: title,
        description: description,
        uid: uid,
      });
  }

  updateVbpInfo(id, title, description, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/updateVbpInfo`,
      {
        id: id,
        title: title,
        description: description,
        uid: uid,
      });
  }

  deleteVbpInfo(id) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteVbpinfo`,
      {
        id: id
      });
  }

  getVBPPackageByVBPId(id) {
    return this.http.post(`${environment.apiUrl}/vbp/getVBPPackageByVBPId`,
      {
        id: id
      });
  }

  // FUNCTIONS FOR VBP BUTTON HERE
  getVbpButton() {
    return this.http.get(`${environment.apiUrl}/vbp/getVbpButton`,
      {

      });
  }

  getVBPButtonByVBPId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getVBPButtonByVBPId`,
      {
        vbpId: vbpId
      });
  }

  addVbpButton(uid, vbpId, title, description, backgroundColour, link, link2, link3, link4, link5, vTitle2, vTitle3, vTitle4, vTitle5) {
    return this.http.post(`${environment.apiUrl}/vbp/addVbpButton`,
      {
        uid: uid,
        vbpId: vbpId,
        title: title,
        description: description,
        backgroundColour: backgroundColour,
        link: link,
        link2: link2,
        link3: link3,
        link4: link4,
        link5: link5,
        vTitle2: vTitle2,
        vTitle3: vTitle3,
        vTitle4: vTitle4,
        vTitle5: vTitle5
      });
  }

  updateVbpButtonWPic(data, file, uid) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('data', data.id);
    formData.append('data', data.vbpId);
    formData.append('data', data.title);
    formData.append('data', data.description);
    formData.append('data', data.backgroundColour);
    formData.append('data', data.link);
    formData.append('data', data.link2);
    formData.append('data', data.link3);
    formData.append('data', data.link4);
    formData.append('data', data.link5);
    formData.append('data', data.vTitle2);
    formData.append('data', data.vTitle3);
    formData.append('data', data.vTitle4);
    formData.append('data', data.vTitle5);
    formData.append('data', uid);
    return this.http.post(`${environment.apiUrl}/vbp/updateVbpButtonWPic`, formData);
  }

  updateVbpButton(data, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/updateVbpButton`,
      {
        data: data,
        uid: uid
      });
  }

  // FUNCTIONS FOR PACKAGE HERE
  getAllVbpPackages() {
    return this.http.get(`${environment.apiUrl}/vbp/getAllVbpPackages`,
      {

      });
  }

  getVBPPackageById(id) {
    return this.http.post(`${environment.apiUrl}/vbp/getVBPPackageById`, {
      id: id,
    });
  }

  createPackage(vbpId, price, title, description, sequence, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/createPackage`, {
      vbpId: vbpId,
      price: price,
      title: title,
      description: description,
      sequence: sequence,
      uid: uid,
    });
  }

  updatePackage(id, vbpId, price, title, description, sequence, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/updatePackage`, {
      id: id,
      vbpId: vbpId,
      price: price,
      title: title,
      description: description,
      sequence: sequence,
      uid: uid,
    });
  }

  deletePackage(id) {
    return this.http.post(`${environment.apiUrl}/vbp/deletePackage`, {
      id: id,
    });
  }



  // PHASE HERE
  getPhases() {
    return this.http.get(`${environment.apiUrl}/vbp/getPhases`,
      {

      });
  }

  addNewPhase(vbpId, title, description, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/addNewPhase`,
      {
        vbpId: vbpId,
        title: title,
        description: description,
        uid: uid,
      });
  }

  updateVBPPhase(id, vbpId, title, description, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/updateVBPPhase`,
      {
        id: id,
        vbpId: vbpId,
        title: title,
        description: description,
        uid: uid,
      });
  }

  setPhaseVisibility(phaseId, visible) {
    return this.http.post(`${environment.apiUrl}/vbp/setPhaseVisibility`,
    {
      phaseId: phaseId,
      visible: visible,
    });
  }

  getPhasesByVPBId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getPhasesByVPBId`,
      {
        vbpId: vbpId,
      });
  }

  getVisiblePhasesByVPBId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getVisiblePhasesByVPBId`,
    {
      vbpId: vbpId,
    });
  }

  deletePhaseById(id) {
    return this.http.post(`${environment.apiUrl}/vbp/deletePhase`,
      {
        id: id
      });
  }

  deleteProgramPhases(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteProgramPhases`,
      {
        vbpId: vbpId,
      });
  }

  getModules() {
    return this.http.get(`${environment.apiUrl}/vbp/getModules`,
      {

      });
  }

  addModuleWithBg(file: File, vbpId: string, phaseId: string, link: string, title: string, description: string, type: any, uid: string, visible: any) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('data', vbpId);
    formData.append('data', phaseId);
    formData.append('data', link);
    formData.append('data', title);
    formData.append('data', description);
    formData.append('data', type);
    formData.append('data', uid);
    formData.append('data', visible);

    return this.http.post(`${environment.apiUrl}/vbp/addModuleWithBg`, formData);
  }

  updateVBPModuleWithBg(file: File, id: string, vbpId: string, phaseId: string, link: string, title: string, description: string, visible: any, type: any, uid: string) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('data', id);
    formData.append('data', vbpId);
    formData.append('data', phaseId);
    formData.append('data', link);
    formData.append('data', title);
    formData.append('data', description);
    formData.append('data', type);
    formData.append('data', uid);
    formData.append('data', visible);

    return this.http.post(`${environment.apiUrl}/vbp/updateVBPModuleWithBg`, formData);
  }

  addNewModule(vbpId, vbpPhaseId, link, title, description, visible, type, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/addNewModule`,
      {
        vbpId: vbpId,
        vbpPhaseId: vbpPhaseId,
        link: link,
        title: title,
        description: description,
        visible: visible,
        type: type,
        uid: uid,
      });
  }

  updateVBPModule(id, vbpId, vbpPhaseId, link, title, bgImgOriginalName, bgImgName, bgImgPath, description, visible, type, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/updateVBPModule`,
      {
        id: id,
        vbpId: vbpId,
        vbpPhaseId: vbpPhaseId,
        link: link,
        title: title,
        bgImgOriginalName: bgImgOriginalName,
        bgImgName: bgImgName,
        bgImgPath: bgImgPath,
        description: description,
        visible: visible,
        type: type,
        uid: uid,
      });
  }

  getModulesByPhaseId(phaseId) {
    return this.http.post(`${environment.apiUrl}/vbp/getModuleByPhaseId`,
      {
        phaseId: phaseId,
      });
  }

  removeModuleBg(id) {
    return this.http.post(`${environment.apiUrl}/vbp/removeModuleBg`,
      {
        id: id
      });
  }

  setModuleVisibility(moduleId, visible) {
    return this.http.post(`${environment.apiUrl}/vbp/setModuleVisibility`,
      {
        moduleId: moduleId,
        visible: visible
      });
  }

  getVisibleModulesByVBPId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getVisibleModulesByVBPId`,
      {
        vbpId: vbpId
      });
  }

  getModuleByVBPId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getModuleByVBPId`,
      {
        vbpId: vbpId
      });
  }

  deleteModuleById(id) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteModule`,
      {
        id: id
      });
  }

  deletePhaseModules(vbpPhaseId) {
    return this.http.post(`${environment.apiUrl}/vbp/deletePhaseModules`,
      {
        vbpPhaseId: vbpPhaseId,
      });
  }

  // TOPIC
  getTopics() {
    return this.http.get(`${environment.apiUrl}/vbp/getTopics`,
      {
      });
  }

  getTopicNameById(id) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicNameById`,
      {
        id: id
      });
  }

  getTopicsByVbpId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicsByVbpId`,
      {
        vbpId: vbpId
      });
  }

  getVisibleTopicsByVbpId(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getVisibleTopicsByVbpId`,
    {
      vbpId: vbpId
    });
  }

  getTopicsByModuleId(moduleId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicsByModuleId`,
      {
        moduleId: moduleId,
      });
  }

  getTopicIdsByModuleId(moduleId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicIdsByModuleId`,
      {
        moduleId: moduleId,
      });
  }

  addNewTopic(vbpId, vbpPhaseId, vbpModuleId, link, title, description, uid, visible) {
    return this.http.post(`${environment.apiUrl}/vbp/addNewTopic`,
      {
        vbpId: vbpId,
        vbpPhaseId: vbpPhaseId,
        vbpModuleId: vbpModuleId,
        link: link,
        title: title,
        description: description,
        uid: uid,
        visible: visible
      });
  }

  updateVBPTopic(id, vbpId, vbpPhaseId, vbpModuleId, link, title, description, type, uid, visible) {
    return this.http.post(`${environment.apiUrl}/vbp/updateVBPTopic`,
      {
        id: id,
        vbpId: vbpId,
        vbpPhaseId: vbpPhaseId,
        vbpModuleId: vbpModuleId,
        link: link,
        title: title,
        description: description,
        type: type,
        uid: uid,
        visible: visible
      });
  }

  setTopicVisibility(topicId, visible) {
    return this.http.post(`${environment.apiUrl}/vbp/setTopicVisibility`,
      {
        topicId: topicId,
        visible: visible,
      });
  }

  deleteTopic(id) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteTopic`,
      {
        id: id,
      });
  }

  getTopicCount(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicCount`, {
      vbpId: vbpId,
    });
  }

  deleteModuleTopics(vbpModuleId) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteModuleTopics`,
      {
        vbpModuleId: vbpModuleId,
      });
  }

  // TOPIC WIDGET
  addNewTopicWidget(vbpTopicId, widgetId, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/addNewTopicWidget`,
      {
        vbpTopicId: vbpTopicId,
        widgetId: widgetId,
        uid: uid,
      });
  }

  deleteAllWidgetsInTopic(topicId) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteAllWidgetsInTopic`,
      {
        topicId: topicId,
      });
  }

  getWidgtsByTopicId(topicId) {
    return this.http.post(`${environment.apiUrl}/vbp/getWidgtsByTopicId`,
      {
        topicId: topicId,
      });
  }

  orgHasWidget(widgetId, orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/orgHasWidget`,
    {
      widgetId: widgetId,
      orgId: orgId
    });
  }

  //  TOPIC ACTIVITY
  addNewTopicActivity(vbpTopicId, title, description, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/addNewTopicActivity`,
      {
        vbpTopicId: vbpTopicId,
        title: title,
        description: description,
        uid: uid,
      });
  }

  deleteActivitiesByTopicId(topicId) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteActivitiesByTopicId`,
      {
        topicId: topicId,
      });
  }

  getActivityByTopicId(topicId) {
    return this.http.post(`${environment.apiUrl}/vbp/getActivityByTopicId`,
      {
        topicId: topicId,
      });
  }

  uploadFile(file: File, vbpId: string, topicId: string, activityId: string, link: string, uid: string, projTeam: string, orgId: string) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('data', vbpId);
    formData.append('data', topicId);
    formData.append('data', activityId);
    formData.append('data', link);
    formData.append('data', projTeam);
    formData.append('data', orgId);
    formData.append('data', uid);

    return this.http.post(`${environment.apiUrl}/vbp/uploadFile`, formData);
  }

  addDeliverableLink(vbpId, topicId, activityId, uid, projTeam, orgId, link) {
    return this.http.post(`${environment.apiUrl}/vbp/addDeliverableLink`,
      {
        vbpId: vbpId,
        topicId: topicId,
        activityId: activityId,
        projTeam: projTeam,
        orgId: orgId,
        uid: uid,
        link: link,
      });
  }

  getDeliverablesByVBP(vbpId) {
    return this.http.post(`${environment.apiUrl}/vbp/getDeliverablesByVBP`,
      {
        vbpId: vbpId,
      });
  }

  getDeliverablesByActivityandOrgId(activityId, orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/getDeliverablesByActivityandOrgId`,
      {
        activityId: activityId,
        orgId: orgId
      });
  }

  deleteDeliverablesByActivityandOrgId(activityId, orgId, type) {
    return this.http.post(`${environment.apiUrl}/vbp/deleteDeliverablesByActivityandOrgId`,
      {
        type: type,
        activityId: activityId,
        orgId: orgId
      });
  }

  getDeliverablesByTopicId(topicId) {
    return this.http.post(`${environment.apiUrl}/vbp/getDeliverablesByTopicId`,
      {
        topicId: topicId,
      });
  }

  getTopicProgByTopicId(topicId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicProgByTopicId`,
      {
        topicId: topicId,
      });
  }

  getTopicProgCountByOrgId(orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicProgCountByOrgId`,
      {
        orgId: orgId,
      });
  }

  getTopicProgByOrgId(orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicProgByOrgId`,
      {
        orgId: orgId,
      });
  }

  getTopicProgByTopicAndOrgId(topicId, orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/getTopicProgByTopicAndOrgId`,
      {
        topicId: topicId,
        orgId: orgId,
      });
  }
  getDoneAndUndoneTopics(moduleId, orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/getDoneAndUndoneTopics`,
      {
        moduleId: moduleId,
        orgId: orgId,
      });
  }

  addTopicProgress(topicId, moduleId, orgId, uid) {
    return this.http.post(`${environment.apiUrl}/vbp/addTopicProgress`,
      {
        topicId: topicId,
        moduleId: moduleId,
        orgId: orgId,
        uid: uid,
      });
  }

  removeTopicProgress(topicId, orgId) {
    return this.http.post(`${environment.apiUrl}/vbp/removeTopicProgress`,
      {
        topicId: topicId,
        orgId: orgId
      });
  }


  getVBPTeamDetails() {
    return this.http.get(`${environment.apiUrl}/vbp/getVBPTeamDetails`,
      {
      });
  }

}
