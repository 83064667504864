export class Investment {
    id: string;
    investorOrgId: string;
    investorOrgName: string;
    investedOrgId: string;
    investedOrgName: string;
    orgName: string;
    amount: number;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    valuation: number;
    valuationPercent: string;
}
