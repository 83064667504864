import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpParams
} from '@angular/common/http';

import { environment } from '../../../environments/environment';
import {
    User,
    Widget,
    ResResult,
    WidgetGroup
} from '../models';


@Injectable()
export class WidgetGroupService {
    widgetSets: Widget[] = [];


    constructor(private http: HttpClient,
    ) {

    }

    async getAllWidgetGroup() {
        const _resResult = await this.http.get<ResResult>(`${environment.apiUrl}/widgetGroup/getAllWidgetGroup`).toPromise();
        if (_resResult.result == 'successful') {
            return _resResult.data as WidgetGroup[];
        } else {
            return null;
        }
    }

    async deleteWidgetGroupListById(id: string) {

        return await this.http.post<ResResult>(`${environment.apiUrl}/widgetGroup/deleteWidgetGroupListByWidgetGroupId`, {
            'id': id
        }).toPromise();
    }

    async bulkInserWidgetGroupList(groupId: string, widgetIds: string, uid: string) {
        try {
            return this.http.post(`${environment.apiUrl}/widgetGroup/bulkInserWidgetGroupList`,
                { widgetGroupId: groupId, widgetIds: widgetIds, uid: uid })
                .toPromise();
        } catch (error) {
            console.error('add widget group failed', error);
            return null;
        }
    }


    getAllAdminWidget() {
        return this.http.get<ResResult>(`${environment.apiUrl}/widgetGroup/getAdminRolesList`);
    }

    // for admin plan
    addWidgetGroupList(groupId: string, widgetId: string, uid: string) {
        try {
            return this.http.post(`${environment.apiUrl}/widget/addWidgetGroupList`,
                { widgetGroupId: groupId, widgetId: widgetId, uid: uid })
                .subscribe(data => { });
        } catch (error) {
            console.error('add widget group failed', error);
            return null;
        }
    }

    getWidgetSetById(Id: string) {
        const _widgetSet = this.widgetSets.filter(widgetSet => {
            return widgetSet.id === Id;
        });
        if (_widgetSet.length > 0) {
            return _widgetSet;
        } else {
            return null;
        }

    }


    async getAllOrgWidgets() {
        const _resResult = await this.http.get<any>(`${environment.apiUrl}/widgetGroup/getAllOrgWidgets`,
            {}).toPromise();
        if (_resResult.result == 'successful') {
            return _resResult.data;
        } else {
            return null;
        }
    }

    async getOrgWidgetsByOrgId(orgId: string) {

        const params = new HttpParams().set('orgId', orgId);

        const _resResult = await this.http.get<any>(`${environment.apiUrl}/widgetGroup/getUserWidgetsByOrgId`,
            { params: params }).toPromise();
        if (_resResult.result == 'successful') {
            return _resResult.data;
        } else {
            return null;
        }
    }

    /*
     */
    async assignWidgetToOrganizationByRoleId(organizationId: string,
        roleId: string,
        organizationTypeId: string,
        userId: string) {

        return await this.http.post(`${environment.apiUrl}/widget/assignWidgetToOrganizationWidgetByRoleId`, {
            'organizationId': organizationId,
            'roleId': roleId,
            'organizationTypeId': organizationTypeId,
            'userId': userId
        }).subscribe(
            (response) => {
            },
            (error) => {
                console.error('assignWidgetToOrganizationByRoleId error', error);
            }
        );
    }
}
