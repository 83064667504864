/* tslint:disable: ordered-imports*/
import { NgModule, APP_INITIALIZER, ModuleWithProviders, SecurityContext } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';

import { Config } from '@common/models';
import * as authServices from '@modules/auth/services';
import * as directives from './directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Third Party */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxPermissionsModule } from 'ngx-permissions';

//https://swiperjs.com/angular
import { SwiperModule } from 'swiper/angular';
// https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/angular.html
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DataTablesModule } from 'angular-datatables';
import { TagInputModule } from '@vpetrusevici/ngx-chips';
// https://www.npmjs.com/package/ngx-toastr
import { ToastrModule } from 'ngx-toastr';
import { CustomToastComponent } from './components';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

const thirdParty = [
    DataTablesModule,
    NgbModule,
    NgApexchartsModule,
    CKEditorModule,
    TagInputModule,
    TooltipModule,
];


/* Containers */
import * as appCommonContainers from './containers';

/* Components */
import * as appCommonComponents from './components';


/* Services */
import * as appCommonServices from './services';

/* Interceptors */
import { interceptors } from './interceptors';

import * as Pipes from './pipe';

import { ProfileInvolvementsComponent } from '@modules/workspace/components';

import { ProfileBannerComponent } from '@modules/workspace/components';

import { ProfileAboutComponent } from '@modules/workspace/components';
import { HighlighterPipe } from './pipe/highlighter.pipe';
// import { TodoCommentsComponent } from './components/todo-comments/todo-comments.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PlanCardComponent } from './components/plan-card/plan-card.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...thirdParty,
        NgxPermissionsModule.forChild(),
        AngularSvgIconModule.forRoot(),
        ToastrModule.forRoot({
            toastComponent: CustomToastComponent,
        }),
        NgxDocViewerModule
    ],
    declarations: [...appCommonContainers.containers,
    ...appCommonComponents.components,
    ...Pipes.pipes,
    ...directives.directives,
        CustomToastComponent,
        ProfileInvolvementsComponent,
        ProfileBannerComponent,
        ProfileAboutComponent,
        HighlighterPipe,
        CheckoutComponent,
        PlanCardComponent,
        ClickOutsideDirective
    ],
    entryComponents: [CustomToastComponent],
    exports: [...appCommonContainers.containers,
    ...appCommonComponents.components,
    ...thirdParty,
    ...Pipes.pipes,
    ...directives.directives,
        ProfileInvolvementsComponent,
        ProfileBannerComponent,
        ProfileAboutComponent,
        NgxPermissionsModule
    ],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                ...appCommonServices.services,
                ...authServices.services,
                {
                    provide: APP_INITIALIZER,
                    useFactory: configServiceFactory,
                    multi: true,
                    deps: [appCommonServices.ConfigService],
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: interceptors.AuthInterceptor,
                    multi: true,
                }
            ],
        };
    }
}

export function configServiceFactory(
    configService: appCommonServices.ConfigService
): () => Promise<Config> {
    return () => configService.loadConfig();
}

export function authInterceptorFactory(
    router: Router,
    authUtilsService: authServices.AuthUtilsService
) {
    return new interceptors.AuthInterceptor(router, authUtilsService);
}

/* export function demoInterceptorFactory(
    utilityServuce: appCommonServices.UtilityService,
    configServuce: appCommonServices.ConfigService
) {
    return new interceptors.DemoInterceptor(utilityServuce, configServuce);
}
 */