import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lab-dialog-stop-batch',
  templateUrl: './dialog-stop-batch.component.html',
  styleUrls: ['./dialog-stop-batch.component.scss']
})
export class DialogStopBatchComponent implements OnInit {
  @Input() show: string;
  @Input() batch;
  @Input() desc: string;
  @Input() type: string;

  @Output() close = new EventEmitter();
  @Output() stopBatch = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.close.emit();
  }

  onSubmit() {
    this.stopBatch.emit();
  }

}
