import { Component, OnInit } from '@angular/core';
// import { DataService } from '../../_services';
// import { User } from '../../_models';
import { Router } from '@angular/router';

@Component({
    selector: 'lab-layout-admin',
    templateUrl: 'layout-admin.component.html',
    styleUrls: ['layout-admin.component.scss', './../../styles/styles.css']
})
export class LayoutAdminComponent implements OnInit {

    display = true;
    // currentUser: User;

    constructor(
        private router: Router,
        // private data: DataService,
    ) {
        // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // if (this.currentUser.IsAdmin === false) {
        //     this.router.navigate(['/home']);
        // }
    }

    ngOnInit() {
        // this.data.currentShowAdminSideBar.subscribe(value => (this.display = value));
    }
}
