import { Component, OnInit, Input } from '@angular/core';
import { ConfigService  } from '@common/services';
@Component({
  selector: 'app-mentor-card',
  templateUrl: './mentor-card.component.html',
  styleUrls: ['./mentor-card.component.css']
})
export class MentorCardComponent implements OnInit {
  @Input() mentor: any;

  constructor(
    private globals: ConfigService) { }

  ngOnInit() {
  }

  onImgError(event) {
    event.target.src = this.globals.defaultUserLogo;
  }

}
