import {
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  Renderer2,
  EventEmitter
} from '@angular/core';
import {
  ConfigService,
  DataService,
  EatToasterService,
  EmailService,
  UtilityService,
  InstitutionService,
} from '@common/services';
import { User } from '@common/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-modal-mentorship-list',
  templateUrl: './modal-mentorship-list.component.html',
  styleUrls: ['./modal-mentorship-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalMentorshipListComponent implements OnInit, AfterViewInit, OnChanges {
  // WIP Don't need currentOrg???? just need batchId?
  _currentOrg: any;
  _params: any;
  currentUser: User;
  searchText;
  toastRef;

  mentorManagement: Array<any> = [];
  mentorShip: Array<any> = [];

  expand: boolean = true;

  loading: boolean = true;

  @Input() set params(object) {
    if (object) {
      this._params = object;
      this.loading = true;

      if (!this.utility.IsNullOrEmpty(this._params.batchId) && typeof this._params.orgId === 'string') {
        if (this._params.type === "mentor") {
          this.getMenteeManagement(this._params.batchId,
            this._params.orgId, this._params.list,
            'organization');
        } else {
          this.getMentorManagement(this._params.batchId,
            this._params.orgId, this._params.list,
            'organization');
        }
      }
    }
  }

  @Input() set currentOrg(object) {
    if (object) {
      this._currentOrg = object;
    }
  }
  @Input() addMentorShip;

  @Output() onSelected = new EventEmitter<any>();
  @Output() onAdded = new EventEmitter<any>();

  @ViewChild("modalcontainer", { read: ElementRef }) modalcontainer: ElementRef;

  constructor(
    private toastr: ToastrService,
    private utility: UtilityService,
    public globals: ConfigService,
    private institutionSrv: InstitutionService,
    private renderer: Renderer2
  ) {
    this.currentUser = new User();
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');


  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) { }


  ngAfterViewInit() {
    this.searchText = "";
  }

  onClose() {
    this.searchText = '';
  }

  onKeydown($event) { }

  onImgError(event) {
    event.target.src = this.globals.defaultUserLogo;
  }

  //show mentor or mentee
  onSelect(id) {
    this.onSelected.emit(id);
  }

  // clientType : organization,  participant
  getMentorManagement(batchId, clientId, list, clientType) {
    let _list = list;
    this.institutionSrv.getMentors(batchId, clientId).then(
      (res) => {

        if (res["result"] === "successful") {
          if (res.data.length > 0) {
            this.mentorManagement = res.data.sort((a, b) => {
              return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
            });
            if (_list) {
              const _clientList = res.data.map(item => item.masterId);
              if (_clientList.length > 0) {
                this.mentorShip = _list.filter(mentee => !(_clientList.includes(mentee.orgId)));

              } else {
                this.mentorShip = _list;
              }
            }
          } else {
            this.mentorManagement = [];
            this.mentorShip = _list;
          }
        } else {
          this.mentorShip = _list;
        }

      }
    ).catch((error) => console.error(error)).finally(() => {
      this.loading = false;
    });;
  }

  getMenteeManagement(batchId, mentorId, list, clientType) {
    let _list = list;
    this.institutionSrv.getMenteesByMentorId(batchId,
      mentorId,
      clientType).then(
        (res) => {
          if (res['result'] == "successful") {
            if (res.data.length > 0) {
              this.mentorManagement = res.data.sort((a, b) => {
                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
              });
              if (_list) {
                const _clientList = res.data.map(item => item.clientId);
                if (_clientList.length > 0) {
                  this.mentorShip = _list.filter(mentee => !(_clientList.includes(mentee.orgId)));

                } else {
                  this.mentorShip = _list;
                }
              }
            } else {
              this.mentorManagement = [];
              this.mentorShip = _list;
            }

          } else {
            this.mentorShip = _list;
          }
        }
      ).catch((error) => console.error(error)).finally(() => {
        this.loading = false;
      });
  }

  add(organization) {
    // mentor's company
    // let orgId = this.InvForm.value.orgId;

    let params = {}
    let _mentorId = '';
    let _clientType = 'organization';

    //if (this._params.type == "addMentorToStartup") {
    if (this._params.type == "mentee") {
      // add mentor
      params = {
        batchId: this._params.batchId,
        masterId: organization.orgId,
        clientId: this._params.orgId,
        clientType: _clientType,
        userId: this.currentUser.id,
      };
      _mentorId = organization.orgId;
    } else if (this._params.type == "mentor") {
      // add startup
      params = {
        batchId: this._params.batchId,
        masterId: this._params.orgId,
        clientId: organization.orgId,
        clientType: _clientType,
        userId: this.currentUser.id,
      };
      _mentorId = organization.orgId;
    }

    this.institutionSrv.addMentorManagement(params).subscribe(
      (res) => {
        if (res['result'] == 'successful') {
          if (res.data) {
            if (this._params.type == "mentor") {
              this.mentorShip = this.mentorShip.filter(mentee => mentee.orgId != _mentorId);
              this.getMenteeManagement(this._params.batchId,
                this._params.orgId, null,
                'organization');
              this.onAdded.emit(organization);
              this.showActionMsg("Mentorship", "The mentee added successfully");
            } else {

              this.mentorShip = this.mentorShip.filter(mentee => mentee.orgId != _mentorId);
              this.getMentorManagement(this._params.batchId,
                this._params.orgId, null,
                'organization');
              this.onAdded.emit(organization);
              this.showActionMsg("Mentorship", "The mentor added successfully");
            }
          } else {

          }

        }
      }, (error) => console.log(error),
      () => { }
    );
  }

  remove(mentorManagementId) {
    this.institutionSrv.removeMentorManagement(mentorManagementId, this.currentUser.id).subscribe(
      (res) => {
        if (res["result"] == "successful") {
          if (this._params.type == "mentor") {
            this.showActionMsg("Mentorship", "The mentee removed successfully");
          } else {
            this.showActionMsg("Mentorship", "The mentor removed successfully");
          }
          this.mentorManagement = this.mentorManagement.filter(item =>
            item.id != mentorManagementId
          )
        }
      },
      (error) => console.error(error),
      () => {

      }
    );
  }

  showActionMsg(title, msg) {
    this.toastRef = this.toastr.show(msg, title, {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-blue',
      closeButton: false,
    });
  }


}
