import { Component, OnInit } from '@angular/core';
import { User } from '@common/models';
import { Router } from '@angular/router';
import { ConfigService } from '@common/services';

@Component({
    selector: 'app-admin-sidebar',
    templateUrl: './admin_sidebar.component.html',
    styleUrls: ['./admin_sidebar.component.css', './../../styles/styles.css']
})

export class AdminSidebarComponent implements OnInit {

    display = true;
    currentUser: User;
    url: String;
    check = true;

    constructor(
        private router: Router,
        public globals: ConfigService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        this.url = this.router.url;
    }

    navigate(path: string) {
        this.router.navigate(['/admin/' + path]
        );
    }

    isMenuActive(menu) {
        if (menu === 'widgetList') {
            return this.url === '/admin/' + menu;
        }
        if (menu === 'widgetGroupList') {
            return this.url === '/admin/widgetGroupList';
        }
        if (menu === 'rolesList') {
            return this.url === '/admin/rolesList';
        }
        if (menu === 'organizationWidgetList') {
            return this.url === '/admin/organizationWidgetList';
        }
    }

    redirect(path: string) {
        this.url = '/' + path;
        this.router.navigate([path]);
    }

    routeToDashboard() {
        this.router.navigate(['./w']);
    }
}
