import { Injectable } from '@angular/core';

import { BehaviorSubject, AsyncSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BatchesService {

  private selectedBatch = new BehaviorSubject('');
  currentSelectedBatch = this.selectedBatch.asObservable();

  batches = [

  ]

  constructor() { }

  getData() {
    return this.batches;
  }

  selected(batch) {
    this.selectedBatch.next(batch);
    /*  this.batches.forEach(batch => {
       const status = id === batch.id
       batch.current = status
     }) */
  }

}
