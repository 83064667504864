import { Injectable } from '@angular/core';
import {
  Menu,
  ResResult
} from '../models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { resourceUsage } from 'process';

@Injectable()
export class MenuService {

  // private subject: BehaviorSubject<Menu> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient) {
  }

  async getMenuByUid(uid: string): Promise<Menu[]> {
    const params = new HttpParams().set('userId', uid);
    const res = await this.http.get<ResResult>(`${environment.apiUrl}/menu/loadMenu`
      , { params: params }).toPromise();

    return res.data as Menu[];
  }

  async getMenusByUid(uid: string): Promise<any[]> {

    let res = await this.http.get<any>(`${environment.apiUrl}/menu/menus/${uid}`
      , {}).toPromise();
    if (res.result != 'successful') {
      return [];
    } else {
      return res;
    }


  }

  async getBatchMenuByUid(uid: string) {
    const params = new HttpParams().set('userId', uid);
    return await this.http.get<any>(`${environment.apiUrl}/menu/loadBatchMenu`
      , { params: params }).toPromise();

  }

  // public getSavedMenu(): BehaviorSubject<Menu> {
  //   return this.subject;
  // }

}
