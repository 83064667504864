import { 
  Component, 
  OnInit, 
  ViewEncapsulation, 
  ViewChild, ElementRef, 
  Input, 
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'ui-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CommentComponent implements OnInit {
  @Input() name: string;
  @Input() timestamp: string;
  @Input() private: boolean;
  @Input() text: string;
  @Input() avatar?: string;
  @Input() createdByUid: string;
  @Input() commentId: string;
  @Input() currentTeamStatus;
  @Output() toDelete = new EventEmitter<any>();
  @Output() close = new EventEmitter();
  @Output() edit = new EventEmitter();
  currentUser;
  public isOpenDialog: boolean = false;

  canEdit:boolean = false;

  @ViewChild('comment') commentRef: ElementRef;

  isExpanded: boolean = false;

  constructor() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  ngOnInit() {
    if (this.currentUser.id === this.createdByUid) {
      this.canEdit = true;
    }
  }

  onExpand() {
    this.commentRef.nativeElement.innerHTML = this.text;
    this.isExpanded = !this.isExpanded;
  }

  onDeleteComment() {
    this.toDelete.emit(this.commentId);
  }

  onClose() {
    this.isOpenDialog = false;
    this.close.emit();
  }

  onTriggerDialog() {
    this.isOpenDialog = !this.isOpenDialog;
  }

  editComment() {
    let value = {
      commentId: this.commentId,
      text: this.text,
      private: this.private,
      uid: this.currentUser.id
    }

    // this.edit.emit(this.commentId);
    this.edit.emit(value);
  }

}
