import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocViewerService {

  private doc = new BehaviorSubject<string>('');
  public open = new BehaviorSubject<boolean>(false);

  constructor() { }

  sendData(value: any) {
    this.doc.next(value);
  }

  docViewStatus(value: boolean) {
    this.open.next(value);
  }

  getData(): Observable<any> {
    return this.doc.asObservable();
  }

  getDocStatus(): Observable<boolean> {
    return this.open.asObservable();
  }

}


