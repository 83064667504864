import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '@common/models';
import { Router } from '@angular/router';
import { ConfigService, UserService, UtilityService } from '@common/services';
import { type } from 'jquery';

@Component({
    selector: 'profile-involvements',
    templateUrl: './profile-involvements.component.html',
    styleUrls: ['./profile-involvements.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProfileInvolvementsComponent implements OnInit {
    @Input() uid?;
    OrgsOfUser = [];

    constructor(
        private router: Router,
        private userSrv: UserService,
        private utility: UtilityService,
        public globals: ConfigService,
    ) {

    }

    ngOnInit() {

        this.userSrv.getUserWithOrgById(this.uid).then(res => {

            if (res['result'] === 'successful') {
                let _userOrgs = res['organizations'];
                if (!this.utility.IsNullOrEmpty(_userOrgs)) {
                    this.OrgsOfUser = _userOrgs;
                }
            } else {
                console.log("failed");
            }
        }, error => {
            console.log(error);
        });
    }

    setOrgTypeClass(value: string) {
        if (value === 'project') return 'startup';
        if (value === 'professionalservice') return 'consult';
        if (value === 'institution') return 'lab';
    }

    getOrgType(value: string) {
        if (value === 'project') return 'Start up';
        if (value === 'professionalservice') return 'Consultant';
        if (value === 'institution') return 'Venture lab';
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }


    //20220714 disable profile because access right issue
    onViewProfile(org) {

        return;
        if (org.organizationType == "institution") {
            this.router.navigate(['/w/consult/profile'], {
                queryParams: {
                    orgId: org.organizationId,
                    orgTypeId: org.organizationTypeId,
                    orgType: 'lab',
                    tab: 'lab'
                }
            });
        } else if (org.organizationType == "professionalservice") {
            // this.router.navigate(['/w/consult/profile'], {
            //     queryParams: {
            //         orgId: org.organizationId,
            //         orgTypeId: org.organizationTypeId,
            //         orgType: 'consult',
            //         tab: 'consult'
            //     }
            // });

            this.router.navigate(['/w/mentor-profile/' + org.organizationId], {
                queryParams: {
                    orgTypeId: org.organizationTypeId,
                    orgType: 'consult',
                    tab: 'consult'
                }
            });
        } else {
            this.router.navigate(['./w/organization-profile/' + org.organizationId], {
                queryParams: {
                    orgTypeId: org.organizationTypeId,
                    orgType: 'project',
                    tab: 'project'
                }
            });
        }
    }

}
