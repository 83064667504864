export class MatchingSftQns {
    id: string;
    goodMatch: string;
    asses: string;
    comfortable: string;
    factors: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;

}
