import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lab-dialog-process-payment',
  templateUrl: './dialog-process-payment.component.html',
  styleUrls: ['./dialog-process-payment.component.scss']
})
export class DialogProcessPaymentComponent implements OnInit {
  @Input() show: string;
  @Input() type: string;
  
  constructor() { }

  ngOnInit(): void {
  }

}
