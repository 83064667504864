import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {
  ConfigService, UtilityService
} from "@common/services"
@Component({
  selector: 'lab-dialog-attach',
  templateUrl: './dialog-attach.component.html',
  styleUrls: ['./dialog-attach.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogAttachComponent implements OnInit {
  @Input() show: boolean;
  @Input() deliverable: any;
  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter<any>();

  prefix: string = '';
  selectedFile: any;
  newUpload = false;
  fileUrl: string = '';
  fileName: string = '';

  constructor(
    private configSrv: ConfigService,
    public utilitySrv: UtilityService,
  ) {
    this.prefix = this.configSrv.defaultAssetUrl;
  }

  ngOnInit() {
    if (this.deliverable) {
      this.fileUrl = this.deliverable.fileName;
      this.fileName = this.deliverable.submission;
    }

  }

  onClick() {
    this.onRemoveFile();
    this.close.emit();
  }

  onSubmit() {
    if (!this.selectedFile) {
      if (this.deliverable.fileName && !this.fileUrl) { // remove file
        this.deliverable.fileName = '';
        this.deliverable.submission = '';
        this.submit.emit(this.selectedFile);
      } else {
        this.onClick();
      }
    } else {
      this.deliverable.fileName = this.selectedFile.name;
      this.deliverable.submission = this.selectedFile.name;
      this.submit.emit(this.selectedFile);
    }
  }

  // upload image
  handleUpload(event) {
    this.selectedFile = event.target.files[0];
    this.fileUrl = this.selectedFile.name;
    this.fileName = this.selectedFile.name;
    this.newUpload = true;
  }

  handleDragFile(event) {
    if (event) {
      this.selectedFile = event[0];
      this.deliverable.fileName = this.selectedFile.name;
      this.deliverable.submission = this.selectedFile.name;
    }
  }

  onRemoveFile() {
    this.selectedFile = null;
    this.newUpload = false;
    this.fileUrl = '';
    this.fileName = '';
  }

  checkLink(link) {
    if (!link) {
      return false;
    }
    if (link?.includes('batch')) {
      return true
    }
    return false
  }

}
