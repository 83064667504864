import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'batch-filters',
    templateUrl: './batch-filters.component.html',
    styleUrls: ['batch-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BatchFiltersComponent implements OnInit {
    @Input() filterArray : {name : string, key : string}[];
    @Output() searchTextChange = new EventEmitter<string>();
    @Output() onStatusChange = new EventEmitter<any>();
    ngOnInit(): void {}
    onSearchTextChange(val:any) {
        this.searchTextChange.emit(val.target.value);
    }
    onChange(key: string, value: boolean) {
        this.onStatusChange.emit({ key, value });
    }
}
