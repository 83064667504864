import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'lab-dialog-warning',
  templateUrl: './dialog-warning.component.html',
  styleUrls: ['./dialog-warning.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogWarningComponent implements OnInit {
  @Input() show: string;
  @Input() name: string;
  @Input() count: number;

  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onClick() {
    this.close.emit();
  }

  onSubmit(name: string) {
    this.submit.emit(name);
  }
}
