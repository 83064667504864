import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    ConfigService,
    EmailService,
    UtilityService
} from '@common/services';
import { User } from '@modules/auth/models';
import { from, Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthUtilsService } from './auth-utils.service';
import { environment } from '../../../environments/environment';
const AUTH_DATA = "user_data";
@Injectable()
export class AuthenticationService {
    private subject = new BehaviorSubject<any>(null);

    user$: Observable<any> = this.subject.asObservable();

    isLoggedIn$: Observable<boolean>;
    isLoggedOut$: Observable<boolean>;
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private authUtilsService: AuthUtilsService,
        private router: Router,
        private emailSrv: EmailService,
        private utilitySrv: UtilityService
    ) { }

    login$(loginPayload): Observable<boolean> {
        return this.http
            .post<any>(
                `${this.configService.config.sbCleanBlogNodeURL}/api/latest/auth/login`,
                loginPayload
            )
            .pipe(
                switchMap(
                    (loginResults): Observable<User> =>
                        this.authUtilsService.processToken$(loginResults.token)
                ),
                switchMap(user => {

                    return from(this.router.navigate(['/']));
                }),
                catchError((error: Error) => throwError(error))
            );
    }

    emailLogin(email: string, password: string) {
        return this.http
            .post<any>(`${environment.apiUrl}/authenticate/emailLogin`, {
                'email': email,
                'password': password
            });
    }

    googleLogin(id: string, name: string, email: string, token: string) {
        return this.http
            .post(`${environment.apiUrl}/authenticate/googleLogin`, {
                'id': id,
                'name': name,
                'email': email,
                'token': token,
                'provider': 'google'
            });
    }
    googleSignUp(id: string, name: string, email: string, token: string) {
        return this.http
            .post(`${environment.apiUrl}/authenticate/googleSignUp`, {
                'id': id,
                'name': name,
                'email': email,
                'token': token,
                'provider': 'google'
            });
    }

    sendAuthEmail(uid, email, role = '', name= '') {
        const domain = window.location.origin;
        const url = '/verification';
        const currentTime = new Date();
        const timeInMs = currentTime.getTime();
        const link = domain + url + '?uid=' + uid + '&time=' + timeInMs + '&role=' + role;
        this.emailSrv.authenticateEmail(
            'Welcome to the Rescale Lab platform!',
            email,
            link,
            uid,
            name).subscribe(res => {
                // console.log(res);
            });
    }


    emailSignUpWithRole(email, password, roleName) {
        return this.http.post(`${environment.apiUrl}/authenticate/emailSignUpWithRole`,
            {
                email: email, password: password, roleName: roleName
            });
    }

    emailSignUp(email: string, password: string) {
        return this.http
            .post(`${environment.apiUrl}/authenticate/emailSignUp`, {
                'email': email,
                'password': password
            });


    }

    // admin use this function
    resetPassword(uid, adminUid) {
        return this.http.post(`${environment.apiUrl}/user/ResetPassword`,
            {
                uid: uid, adminUid
            });
    }

    getUserData() {
        try {
            if (!this.utilitySrv.IsNullOrEmpty(localStorage.getItem(AUTH_DATA))) {
                if (this.utilitySrv.isJSONString(localStorage.getItem(AUTH_DATA))) {
                    return JSON.parse(localStorage.getItem(AUTH_DATA) || "{}");
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.error(`getUserData failed :`, error);
            return null;
        }
    }

    setUserData(userData) {
        try {
            localStorage.setItem(AUTH_DATA, JSON.stringify(userData));
            this.subject.next(userData);
            return true;
        } catch (error) {
            console.error(`setUserData failed : `, error);
            return false;
        }
    }

    logout() {
        this.subject.next(null);
        localStorage.removeItem(AUTH_DATA);

    }

    verifyToken(token) {
        return this.http.post(`${environment.apiUrl}/authenticate/verifyToken`,
            {
                token: token
            });
    }

    //// Helpers ////

    checkPassword(password, userInput) {
        return this.http.post(`${environment.apiUrl}/user/checkPassword`,
            {
                password: password, userInput: userInput
            });
    }

    updatePassword(newPassword, uid) {
        return this.http.post(`${environment.apiUrl}/user/changePassword`,
            {
                newPassword: newPassword, uid: uid
            });
    }

}
