import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { User } from '@common/models';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilityService, UserService, AppSettingsService, InstitutionService, OrganizationBatchService, IndividualService } from '@common/services';
import { CalendarService } from '@common/services/calendar.service';
@Component({
    selector: 'student-profile',
    templateUrl: './student-profile.component.html',
    styleUrls: ['./../../styles.scss', './student-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StudentProfileComponent implements OnInit {
    currentUser;
    user: any;
    viewUser: any;
    isProfile = true;
    countriesOptions: any;
    calendarEvents: any[];

    projectTeams: any[];
    mentors: any[];

    hipster: any;
    hacker: any;
    hustler: any;
    _orgId: string;
    @Input() set orgId(object) {
        this._orgId = object;
    }

    @Input() params;

    @Input() isCurrentUserIsManager: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private utilitySrv: UtilityService,
        private userSrv: UserService,
        private appSettingsSrv: AppSettingsService,
        private calendarSrv: CalendarService,
        private orgBatchSrv: OrganizationBatchService,
        private individualSrv: IndividualService,
        private institution: InstitutionService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.countriesOptions = this.appSettingsSrv.countryOptions();
    }

    fetchUserDetails(uid) {
        if (this.utilitySrv.IsNullOrEmpty(uid)) {
            this.viewUser = this.currentUser;
            if (this.viewUser.country) {
                this.onGetCountryName(this.viewUser.country);
            }
        } else {
            this.isProfile = false;
            this.userSrv.getUserPersonalityInfoByUid(uid).subscribe((res) => {
                if (res['result'] == 'successful') {
                    if (res['data'].length > 0) {
                        this.hipster = this.individualSrv.calculate_Hipster_percentage(res['data'][0]);
                        this.hacker = this.individualSrv.calculate_Hacker_percentage(res['data'][0]);
                        this.hustler = this.individualSrv.calculate_Hustler_percentage(res['data'][0]);
                    }
                }
            });
            this.userSrv.getUserDataByUid(uid).subscribe((res) => {
                if (res['result'] == 'successful') {
                    this.viewUser = res['data'];
                    if (this.viewUser.country) {
                        this.onGetCountryName(this.viewUser.country);
                    }
                }
            });
        }
    }

    fetchMentorAndProjectDetails() {
        this.orgBatchSrv.getConsultantAndProjectTeamById(this._orgId, this.params['batchId']).then((response) => {
            if (response['result'] === 'successful') {
                this.projectTeams = response['data'].filter((org) => {
                    return org.matchedOrgType === '1';
                });

                if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                    this.projectTeams = this.projectTeams.sort((a, b) => {
                        if (a.consultant_count != null && b.consultant_count == null) return -1;
                        if (a.consultant_count == null && b.consultant_count != null) return 1;
                        return 0;
                    });

                } else {
                    this.projectTeams = this.projectTeams.filter(startup => startup.consultant_count != null)
                }
                // console.log("TEAMS : PARTICIPANTS : TAGGED PROJECTS : ", this.projectTeams)


                this.mentors = response['data'].filter((org) => {
                    return org.matchedOrgType === '4';
                });
                if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                    this.mentors.sort((a, b) => {
                        // Status "joined" and project_count != null first
                        if (a.status === "joined" && a.project_count != null && !(b.status === "joined" && b.project_count != null)) return -1;
                        if (!(a.status === "joined" && a.project_count != null) && b.status === "joined" && b.project_count != null) return 1;

                        // Status "joined" and project_count == null second
                        if (a.status === "joined" && a.project_count == null && !(b.status === "joined" && b.project_count == null)) return -1;
                        if (!(a.status === "joined" && a.project_count == null) && b.status === "joined" && b.project_count == null) return 1;

                        // Status "inviting" third
                        if (a.status === "inviting" && b.status !== "inviting") return -1;
                        if (a.status !== "inviting" && b.status === "inviting") return 1;

                        return 0;
                    });
                }
                else {
                    this.mentors = this.mentors.filter(mentor => (mentor.status == "joined" && mentor.project_count != null));
                }
                // console.log("TEAMS : PARTICIPANTS : Tagged Consultants : ", this.mentors)
            }
        });
    }

    fetchIndividualMentors() {
        if (this.params.batchType == '1') {
            this.institution.getMentors(this.params['batchId'], this._orgId).then((res) => {
                if (res["result"] === "successful") {
                    this.mentors = res['data'].map((value) => {
                        return {
                            org_name: value.name,
                            org_id: value.clientId,
                            compLogo: value.imageUrl,
                            orguser_org_id: value.masterId,
                            participantsCount : value.participantsCount,
                            status : value.status,
                        }
                    });
                    // console.log("INDIVIDUALS : PATICIPANTS : TAGGED CONSULTANTS : ", this.mentors)

                    if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                        this.mentors.sort((a, b) => {
                            // Status "joined" and participantsCount != null first
                            if (a.status === "joined" && a.participantsCount != null && !(b.status === "joined" && b.participantsCount != null)) return -1;
                            if (!(a.status === "joined" && a.participantsCount != null) && b.status === "joined" && b.participantsCount != null) return 1;

                            // Status "joined" and participantsCount == null second
                            if (a.status === "joined" && a.participantsCount == null && !(b.status === "joined" && b.participantsCount == null)) return -1;
                            if (!(a.status === "joined" && a.participantsCount == null) && b.status === "joined" && b.participantsCount == null) return 1;

                            // Status "inviting" third
                            if (a.status === "inviting" && b.status !== "inviting") return -1;
                            if (a.status !== "inviting" && b.status === "inviting") return 1;

                            return 0;
                        });
                    } else {
                        this.mentors = this.mentors.filter(mentor => (mentor.status == "joined" && mentor.participantsCount != null))
                    }
                }
            })
        }

    }

    ngOnInit() {
        this.fetchUserDetails(this._orgId);
        this.fetchMentorAndProjectDetails();
        this.fetchIndividualMentors();
    }

    onGetCountryName(value) {
        if (this.viewUser.country && this.countriesOptions) {
            const found = this.countriesOptions.find((element) => element.value == value);
            if (found) {
                this.viewUser.country = found['text'];
            }
        }
    }

    initCalendar() {
        this.calendarSrv.getCalendarEventByUserId(this.currentUser.id).subscribe(
            (res) => {
                if (res['result'] === 'successful') {
                    this.calendarEvents = res['data'];
                } else {
                    this.calendarEvents = [];
                }
            },
            (error) => {
                console.error(error);
            },
            () => { }
        );
    }
}
