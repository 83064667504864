import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@common/models';
import { AuthenticationService } from '@modules/auth/services';
import { ToastrService } from 'ngx-toastr';
import { transition, style, animate, trigger } from '@angular/animations';

const enterTransition = transition(':enter', [
    style({
        opacity: 0
    }),
    animate('0.5s ease-in', style({
        opacity: 1
    }))
]);

const leaveTrans = transition(':leave', [
    style({
        opacity: 1
    }),
    animate('0.5s ease-out', style({
        opacity: 0
    }))
])

const fadeIn = trigger('fadeIn', [
    enterTransition
]);

const fadeOut = trigger('fadeOut', [
    leaveTrans
]);

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        fadeIn,
        fadeOut
    ]
})

export class ChangePasswordComponent implements OnInit {

    currentUser: User;
    changePasswordForm: FormGroup;
    submitted = false;
    notSame = false;
    wrongPw = false;
    show = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private authenticationSrv: AuthenticationService,
        private toasterSrv: ToastrService
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        this.changePasswordForm = this.fb.group({
            password: ['', [Validators.required]],
            newPassword: ['', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]],
            confirmPassword: ['', [Validators.required]]
        });
    }

    get f() {
        return this.changePasswordForm.controls;
    }

    changeInput(input: any): any {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    onUpdatePassword() {
        this.submitted = true;

        if (this.changePasswordForm.invalid) {
            return;
        }

        this.authenticationSrv.checkPassword(this.currentUser.password, this.changePasswordForm.value.password).subscribe(data => { // check if user entered the correct current password
            if (data['result'] === true) {
                this.wrongPw = false;
                if (this.changePasswordForm.value.newPassword !== this.changePasswordForm.value.confirmPassword) { // check if the new passwords is the same
                    this.notSame = true;
                    return;
                } else {
                    this.notSame = false;
                    this.authenticationSrv.updatePassword(this.changePasswordForm.value.newPassword, // update new password to database
                        this.currentUser.id).subscribe(updatePasswordRes => {
                            if (updatePasswordRes['result'] === 'successful') {
                                this.toasterSrv.show('Successfully changed password. Please log in again with your new password.', '', {
                                    disableTimeOut: false,
                                    tapToDismiss: true,
                                    toastClass: 'toast border-green',
                                    closeButton: false,
                                });
                                setTimeout(() => { this.router.navigate(['/login']); }, 1000);
                            } else {
                                this.toasterSrv.show('Failed to change password! Please inform the admin for help.', '', {
                                    disableTimeOut: false,
                                    tapToDismiss: true,
                                    toastClass: 'toast border-red',
                                    closeButton: false,
                                });
                            }
                        }, error => {
                            console.error('updatePassword error', error);
                        });
                }
            } else {
                this.wrongPw = true;
            }
        }, error => {
            console.error('checkPassword error', error);
        });
    }

    onValueChange(value) { // function to check if the user password meet the pattern required

        const lowerCaseLetters = /[a-z]/g;
        const upperCaseLetters = /[A-Z]/g;
        const numbers = /[0-9]/g;
        if (!lowerCaseLetters.test(value)) {
            this.show = true;
            return
        } else if (!upperCaseLetters.test(value)) {
            this.show = true;
            return
        } else if (!numbers.test(value)) {
            this.show = true;
            return
        } else if (value.length < 8) {
            this.show = true;
            return
        } else {
            this.show = false;
        }
    }



}
