import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config, SubmissionType } from '@common/models';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

let _config: Config;

@Injectable({ providedIn: 'root' })
export class ConfigService {
    constructor(private http: HttpClient) { }

    InstitutionTypes$: Observable<any>;

    loadConfig(): Promise<Config> {
        return this.http
            .get<Config>(`assets/config.json`)
            .pipe(
                tap(config => {
                    _config = config;
                })
            )
            .toPromise();
    }

    get config() {
        return _config;
    }

    defaultCompanyLogo: string = '././assets/header/EATLogo.png';
    defaultNoCompanyLogo: string = './assets/images/eatl/EATLCompany.png';
    defaultUpLoadLogo: string = '../../assets/images/eatl/uploadlogo.png';
    defaultUserLogo: string = './assets/images/EATL.png';
    defaultAvatar: string = './assets/images/default-avatar.png';
    defaultLogo: string = './assets/images/default-logo.png';
    noResultLogo: string = './assets/NoResults.png';
    nofoundLogo: string = './assets/images/eatl/no_results_found_text_eatl.png';
    errorLogo: string = "./assets/images/eatl/empty-state-eatl 1.png";
    defaultEatAdminLogo: string = '../../assets/EAT_logo.png';
    defaultArrow_up: string = '../../assets/arrow_up.png';
    defaultArrow_down: string = '../../assets/arrow_down.png';
    defaultOrgChartLogo: string = '../../assets/people.png';
    defaultTeamLog: string = '../../../assets/team.png';
    defaultAssetUrl = environment.assetUrl;
    defaultApiUrl = environment.apiUrl;
    defaultFirstPage = '/w';
    defaultImageUpload: string = './assets/images/photo-placeholder.jpeg'

    async getInstitutionTypes() {
        return await this.http.get<any>(`${environment.apiUrl}/institution/getInstitutionType`).toPromise();
    }

}
