import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppCommonModule } from '@common/app-common.module';
import { NavigationModule } from '@modules/navigation/navigation.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AppSettingsService } from '@common/services';
import { NgxPermissionsModule } from 'ngx-permissions';
export function appConfigFactory(provider: AppSettingsService) {

    return () => provider.init();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AppCommonModule.forRoot(),
        NavigationModule.forRoot(),
        NgbModule,
        BrowserAnimationsModule,
        NgxSkeletonLoaderModule,
        FormsModule,
        NgxPermissionsModule.forRoot()
    ], providers: [
        AppSettingsService,
        {
            provide: APP_INITIALIZER,
            useFactory: appConfigFactory,
            deps: [AppSettingsService],
            multi: true
        }],
    bootstrap: [AppComponent],

})
export class AppModule { }
