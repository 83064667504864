import {
    Component,
    OnInit,
    Input,
    AfterViewInit,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {
    ConfigService,
    DataService,
    EatToasterService,
    EmailService,
    OrganizationService,
    UserService,
    UtilityService,
} from '@common/services';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Msg } from '@modules/_shared/models';
import { User } from '@common/models';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'lab-modal-member-list',
    templateUrl: './modal-member-list.component.html',
    styleUrls: ['./modal-member-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ModalMemberListComponent implements OnInit, AfterViewInit, OnChanges {
    // WIP Don't need currentOrg???? just need batchId?
    @Input() currentOrg;
    @Input() invitedType;

    currentUser: User;

    users: Array<any> = [];
    filteredData = [
        { fullname: 'lee 1' },
        { fullname: 'lee 2' },
        { fullname: 'lee 3' },
        { fullname: 'lee 4' },
        { fullname: 'lee 5' },
    ];
    searchText;
    inviteByEmail = false;

    userEmails: Array<any> = [];
    invByEmailForm: FormGroup;
    emailTitle: string;
    invMessage = 'E-mail and notification sent to';
    msgType = '';

    emailRegex = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = true;

    typingTimer;

    displaytext = '';

    //WIP
    projects;
    experts;
    batchId;

    constructor(
        private userSrv: UserService,
        private fb: FormBuilder,
        private dataSrv: DataService,
        private emailSrv: EmailService,
        private toasterSrv: EatToasterService,
        private utility: UtilityService,
        public globals: ConfigService,
        private orgSrv: OrganizationService,
        private route: ActivatedRoute
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.userSrv.getAllVerifiedUser().then(
            (value: any) => {
                if (value['result'] === 'successful' && value.data) {
                    this.userEmails = value.data.map((user) => {
                        if (user.email !== undefined) {
                            return user.email.toLowerCase();
                        }
                    });
                }
            },
            (error) => {
                console.error('getAllVerifiedUser :', error);
            }
        );
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.batchId = params['batchId'];
        });

        this.getUsersWithlimit();
        this.invByEmailForm = this.fb.group({
            newMembers: this.fb.array([
                this.fb.group({
                    email: ['', Validators.required],
                }),
            ]),
        });
    }

    ngOnChanges(changes: SimpleChanges) { }

    getNewMember(): FormArray {
        return this.invByEmailForm.get('newMembers') as FormArray;
    }

    newMember(): FormGroup {
        return this.fb.group({
            email: ['', Validators.required],
        });
    }

    addMoreEmail() {
        this.getNewMember().push(this.newMember());
    }

    removeEmail(i: number) {
        this.getNewMember().removeAt(i);
    }

    ngAfterViewInit() { }

    getUsersWithlimit() {
        if (this.invitedType === 'batch') {
            this.userSrv.getUsersWithlimit(this.currentOrg.id, this.batchId).subscribe(
                (data) => {
                    if (data['result'] === 'successful') {
                        this.users = data['data'];
                    } else {
                        this.users = [];
                    }
                },
                (error) => {
                    console.error('Get all users failed :', error);
                },
                () => { }
            );
        }
        if (this.invitedType === 'mentor') {
            this.orgSrv
                .getAllOrgSortByRolesInvite(this.batchId, '6695c71e-da99-11e9-b68a-bb4d51af784e')
                .subscribe((res) => {
                    if (res['result'] == 'successful') {
                        this.experts = res['data'];
                        this.users = res['data'];
                    } else {
                        console.error('failed', res);
                    }
                });
        }
        if (this.invitedType === 'startup') {
            this.orgSrv
                .getAllOrgSortByRolesInvite(this.batchId, '715189cc-da99-11e9-b68a-bb4d51af784e')
                .subscribe((res) => {
                    if (res['result'] == 'successful') {
                        this.projects = res['data'];
                        this.users = res['data'];
                    } else {
                        console.error('failed', res);
                    }
                });
        }
    }

    onSearchChange() {
        clearTimeout(this.typingTimer);
        if (this.searchText) {
            this.typingTimer = setTimeout(() => {
                if (this.invitedType === 'batch') {
                    this.doneTyping(this.searchText);
                }
                if (this.invitedType === 'mentor') {
                    this.doneTypingMentor(this.searchText);
                }

                if (this.invitedType === 'startup') {
                    this.doneTypingStartup(this.searchText);
                }
            }, 2000);
        } else {
            //  this.getUsersWithlimit();
            this.inviteByEmail = false;
        }

        // this.filteredData = this.users.filter((content) => {
        //     if (content.fullname) {
        //         return content.fullname.toLowerCase().includes(this.searchText.toLowerCase())
        //     }
        // });
    }

    // WIP
    doneTypingStartup(value) {
        this.orgSrv
            .searchStartup(this.batchId, '715189cc-da99-11e9-b68a-bb4d51af784e', value)
            .subscribe((res) => {
                if (res['result'] === 'successful') {

                    this.users = res['data'];
                    this.inviteByEmail = false;
                } else {
                    this.users = [];
                    this.displaytext = `Sorry, we cannot find ${this.searchText} in our members list. Would you want to try to send an email to tell
          your friend`;
                    this.inviteByEmail = true;
                }
            });
    }

    doneTypingMentor(value) {
        this.orgSrv
            .searchMentor(this.batchId, '6695c71e-da99-11e9-b68a-bb4d51af784e', value)
            .subscribe((res) => {
                if (res['result'] === 'successful') {
                    this.users = res['data'];
                    this.inviteByEmail = false;
                } else {
                    this.users = [];
                    this.displaytext = `Sorry, we cannot find ${this.searchText} in our members list. Would you want to try to send an email to tell
                your friend`;
                    this.inviteByEmail = true;
                }
            });
    }

    doneTyping(value) {
        this.userSrv.searchUsers(value, this.currentOrg.id, this.batchId).subscribe((res) => {

            if (res['result'] === 'successful') {
                this.users = res['data'];
                this.inviteByEmail = false;
            } else {
                this.users = [];
                this.displaytext = `Sorry, we cannot find ${this.searchText} in our members list. Would you want to try to send an email to tell
                your friend`;
                this.inviteByEmail = true;
            }
        });
    }

    onClose() {
        this.searchText = '';
        this.getUsersWithlimit();
    }

    onKeydown($event) { }

    onFilterSelect($event) { }

    onAddByEmail() {
        this.searchText = '';
        // 
        if (this.inviteByEmail) {
            this.inviteByEmail = false;
        } else {
            this.getUsersWithlimit();
            this.inviteByEmail = true;
            this.displaytext =
                'Send an invitation to your friend and let them be part of your team';
        }
    }

    isInArray(val, arr) {
        return arr.indexOf(val) > -1;
    }

    validateEmail(event, index) {

        const value = event.target.value;
        // tslint:disable-next-line:max-line-length
        // console.log(this.emailRegex.test(String(value).toLowerCase()));
        if (!this.emailRegex.test(String(value).toLowerCase())) {
            document.getElementById(index)!.style.border = '1px solid red';
            this.valid = false;
        } else {
            document.getElementById(index)!.style.border = 'none';
            this.valid = true;
        }
    }

    invEmail() {

        for (let i = 0; i < this.invByEmailForm.value.newMembers.length; i++) {
            if (
                !this.emailRegex.test(
                    String(this.invByEmailForm.value.newMembers[i].email).toLowerCase()
                )
            ) {
                console.log('invalid', i);
                return;
            }
        }
        if (this.invByEmailForm.invalid) {
            console.log('invalid');
            return;
        }

        for (let i = 0; i < this.invByEmailForm.value.newMembers.length; i++) {
            const emailExists = this.isInArray(
                this.invByEmailForm.value.newMembers[i].email.toLowerCase(),
                this.userEmails
            );

            if (emailExists) {
                this.invNoti(this.invByEmailForm.value.newMembers[i].email, 2);
            } else {
                console.log('doesnt exists');
                const _noti = new Msg();
                const currentUnixTime = new Date();

                _noti.batchId = '';
                _noti.requesterUid = this.currentUser.id;
                _noti.senderUid = this.currentUser.id;
                _noti.requesterOrgId = this.currentOrg.id;
                _noti.invitedUid = '';
                _noti.receiverId = '';
                _noti.invitedOrgId = '';
                _noti.receiverName = '';
                _noti.requestDate = currentUnixTime.getTime().toString();
                _noti.title = 'Invitation from ' + this.currentOrg.name + '.';
                _noti.content =
                    `Hello, I am ${this.currentUser.fullname} ` +
                    ` & I would like to invite you to connect with our company ` +
                    `${this.currentOrg.name} on the platform.`;
                _noti.invitationType = '0';
                _noti.createdAt = currentUnixTime.getTime().toString();
                _noti.createdBy = this.currentUser.id;
                _noti.updatedAt = currentUnixTime.getTime().toString();
                _noti.updatedBy = this.currentUser.id;

                this.dataSrv.createNewInv(_noti).subscribe(
                    (data) => {
                        if (data['result'] === 'successful') {
                            this.emailTitle = `You are invited to join ${this.currentOrg.name} on Rescale Lab Platform!`;
                            let link = window.location.origin;

                            this.emailSrv
                                .sendInviteUserEmail(
                                    this.emailTitle,
                                    this.invByEmailForm.value.newMembers[i].email,
                                    link,
                                    this.currentUser.id,
                                    this.currentUser.fullname,
                                    this.currentOrg.name,
                                    data['invitation'].notificationId
                                )
                                .subscribe();
                            this.toasterSrv.showToast(
                                '',
                                `An e-mail has been sent to ${this.invByEmailForm.value.newMembers[i].email}.`,
                                this.toasterSrv.iconClasses.success
                            );
                            this.invByEmailForm.reset();
                            // setTimeout(() => {
                            //     this.invMessage = '';
                            //     this.msgType = '';
                            // }, 3000);
                        } else if (data['error']) {
                            console.log(data['error']);
                        }
                    },
                    (error) => {
                        console.error('createNewInv :', error);
                    }
                );
            }
        }
    }

    invNoti(user, type, index?) {
        const _noti = new Msg();
        const currentUnixTime = new Date();

        _noti.batchId = this.batchId;
        _noti.requesterUid = this.currentUser.id;
        _noti.senderUid = this.currentUser.id;
        _noti.requesterOrgId = this.currentOrg.organizationId;
        _noti.invitedUid = user.userId;
        _noti.receiverId = user.userId;
        _noti.invitedOrgId = user.organizationId;
        _noti.receiverName = user.fullname;
        _noti.requestDate = currentUnixTime.getTime().toString();
        _noti.title = 'Invitation from ' + this.currentOrg.name;
        _noti.content =
            `Hello ${_noti.receiverName},` +
            ` I am ${this.currentUser.fullname} & ` +
            `I would like to invite you to join `;
        // WIP
        // `${this.currentOrg.name} ${this.currentBatch.name} on the platform.`;
        _noti.invitationType = '2';
        _noti.createdAt = currentUnixTime.getTime().toString();
        _noti.createdBy = this.currentUser.id;
        _noti.updatedAt = currentUnixTime.getTime().toString();
        _noti.updatedBy = this.currentUser.id;

        this.dataSrv.createNewInv(_noti).subscribe(
            (data) => {
                if (data['result'] == 'successful') {
                    const link = window.location.origin + '/login?time=' + new Date().getTime();
                    this.emailSrv
                        .sendBatchInviteEmail(
                            _noti.title,
                            user.email,
                            link,
                            this.currentUser.id,
                            this.currentUser.fullname,
                            this.currentOrg.name
                        )
                        .subscribe((value) => {
                
                            // this.ShowMsg(
                            //   `E-mail and notification sent to ${organization.name}.`,
                            //   0
                            // );
                            setTimeout(() => {
                                this.invMessage = '';
                                this.msgType = '';
                            }, 2000);
                        });
                }
            },
            (error) => console.error('createNewInv :', error),
            () => { }
        );
    }

    getBackgroundColor(value) {
        if (value !== 0) {
            return 'linear-gradient(122deg, #00BA6B 0%, #008B80 100%)';
        }
    }

    onInvite(id) {
        this.users[id].inviteCount = 1;

        // document.getElementById(id)!.style.background = "linear-gradient(122deg, #00BA6B 0%, #008B80 100%)";
        // document.getElementById(id + '-btn')!.style.color = "white";
        // document.getElementById(id + '-btn')!.style.backgroundColor = "#2ABF6C";
        // document.getElementById(id + '-btn')!.style.border = "solid #2ABF6C";

        // document.getElementById(id + '-img')!.setAttribute('src', 'assets/icons/check.svg');
        // document.getElementById(id + '-img')!.style.filter = "brightness(0) invert(1)";
    }

    onImgError(event) {
        event.target.src = this.globals.defaultUserLogo;
    }
}
