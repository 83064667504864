
export class WidgetAccess {
    organizationWidgetId: string;
    accessRoleTypeId: string;
    createData = 0;
    readData = 0;
    updateData = 0;
    deleteData = 0;
    downloadData = 0;
    fullAccess = 0;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    deleted: boolean;
}
