import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ProfileCacheService } from "./institution.service";
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
@Injectable({
  providedIn: "root",
})
export class InstitutionDashboardService {
  constructor(private http: HttpClient,
    private activityCacheSrv: ProfileCacheService,
    private socrehistoryCacheSrv: ProfileCacheService) { }

  executeStoredProcedure(procedureName, orgId, batchId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/executeStoredProcedure`,
      {
        procedureName: procedureName,
        orgId: orgId,
        batchId: batchId,
      }
    );
  }

  async getBatchTeam3HStatistics(batchId) {
    return await this.http.get<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getBatchTeam3HStatistics/${batchId}`,
      {
      }
    ).toPromise();
  }

  getBatchIndividual3HStatistics(batchId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getBatchIndividual3HStatistics`,
      {
        batchId: batchId,
      }
    );
  }

  getBatchGroupCount(batchId, orgId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getBatchGroupCount`,
      {
        batchId: batchId,
        orgId: orgId,
      }
    );
  }

  async getTeamNetworkStatistics(batchId) {
    return await this.http.get<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getTeamNetworkStatistics/${batchId}`,
      {

      }
    ).toPromise();
  }
  getIndividualBatchStatistics(batchId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getIndividualBatchStatistics`,
      {
        batchId,
      }
    );
  }

  getBatchTodoStatistics(batchId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getBatchTodoStatistics`,
      {
        batchId: batchId,
      }
    );
  }

  getBatchSharedContentStatistics(batchId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getBatchSharedContentStatistics`,
      {
        batchId: batchId,
      }
    );
  }

  getTeamArticleReadingHistory(batchId) {
    return this.http.post<any>(
      `${environment.analysisApiUrl}/institutionDashboard/getTeamArticleReadingHistory`,
      {
        batchId: batchId,
      }
    );
  }

  getTeamSubmittedDeliverableCount(batchId) {
    return this.http.get<any>(
      `${environment.apiUrl}/institution/batch_teams_deliverables_submitted/${batchId}`
    );
  }

  getTeamDeliverableScores(batchId) {
    return this.http.get<any>(
      `${environment.apiUrl}/institution/batch_teams_deliverable_scored/${batchId}`
    );
  }

  async getRecentActivity(batchId) {
    let activity$ = this.activityCacheSrv.getValue(batchId);
    if (!activity$) {
      activity$ = await this.http.get(`${environment.apiUrl}/institution/batch_teams_recent_activity/${batchId}`,
        {}).pipe(
          map((response: any) => response), shareReplay(1)).toPromise();
      this.activityCacheSrv.setValue(activity$, batchId);
    }
    return activity$;
    /*  return await this.http.get<any>(
       `${environment.apiUrl}/institution/batch_teams_recent_activity/${batchId}`
     ).toPromise(); */
  }

  getIndividualScores(batchId) {
    return this.http.get<any>(
      `${environment.apiUrl}/institution/getIndividualScores/${batchId}`
    );
  }

  async getScoreHistory(batchId) {

    let history$ = this.socrehistoryCacheSrv.getValue(batchId);
    if (!history$) {
      history$ = await this.http.get(`${environment.apiUrl}/institution/getScoreHistory/${batchId}`,
        {}).pipe(
          map((response: any) => response), shareReplay(1)).toPromise();
      this.socrehistoryCacheSrv.setValue(history$, batchId);
    }
    return history$;
    /*  return this.http.get<any>(
       `${environment.apiUrl}/institution/getScoreHistory/${batchId}`
     ); */
  }

  pastRatingHistory(batchId) {
    return this.http.post<any>(
      `${environment.apiUrl}/institution/pastRatingHistory`,
      {
        batchId: batchId
      }
    );
  }

  updateChartDropout(batchId, organizationId, uid) {
    return this.http.post<any>(
      `${environment.apiUrl}/institution/updateChartDropout`,
      {
        batchId,
        organizationId,
        uid
      }
    );
  }

  updateChartRestart(batchId, organizationId, uid) {
    return this.http.post<any>(
      `${environment.apiUrl}/institution/updateChartRestart`,
      {
        batchId,
        organizationId,
        uid
      }
    );
  }
}
