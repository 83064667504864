import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  ResResult
} from '../models';
import { environment } from '../../../environments/environment';


@Injectable()
export class ToDoRelationshipService {

  constructor(
    private http: HttpClient
  ) {
  }

//   async getAll() {
//     const res = await this.http.get<ResResult>(`${environment.apiUrl}/organizationRelationship/getAll`, {}).toPromise();
//     return res;
//   }

  insert(toDoId: string,
    assigneeUid: string,
    assigneeName: string,
    uid: string
  ) {
    const res = this.http.post(`${environment.apiUrl}/todoRelationship/insert`, {
        toDoId: toDoId,
        assigneeUid: assigneeUid,
        assigneeName: assigneeName,
      uid: uid
    });

    return res;
  }

  getAssigneesByToDoId(toDoId) {
    return this.http.post(`${environment.apiUrl}/todoRelationship/getAssigneesByToDoId`,
      {
        toDoId: toDoId
      });
  }

}
