import { LayoutAuthComponent } from './layout-auth/layout-auth.component';
import { LayoutAdminComponent } from './layout-admin/layout-admin.component';
import { LayoutBlogComponent } from './layout-blog/layout-blog.component';
import { LayoutContentComponent } from './layout-content/layout-content.component';
import { LayoutErrorComponent } from './layout-error/layout-error.component';
import { LayoutWebSiteComponent } from './layout-website/layout-website.component';

export const layouts = [
     LayoutAuthComponent,
     LayoutAdminComponent,
     LayoutBlogComponent,
     LayoutContentComponent,
     LayoutErrorComponent,
     LayoutWebSiteComponent,
];

export * from './layout-auth/layout-auth.component';
export * from './layout-error/layout-error.component';
export * from './layout-blog/layout-blog.component';
export * from './layout-content/layout-content.component';
export * from './layout-website/layout-website.component';
export * from './layout-admin/layout-admin.component';