import { BatchFilterPipe } from '@modules/workspace/components/batch-profile-members/batch-filter.pipe';
import { FilterPipe } from './filter.pipe';
import { SanitizedHtmlPipe } from './sanitized-html.pipe';
import { ProjectFilterPipe } from '@modules/workspace/components/batch-profile-members/project-filter.pipe';
import { ConsultantFilterPipe } from '@modules/workspace/components/batch-profile-members/consultant-filter.pipe';

export const pipes = [
    FilterPipe,
    SanitizedHtmlPipe,
    BatchFilterPipe,
    ProjectFilterPipe,
    ConsultantFilterPipe
];

export * from './filter.pipe';
