import { 
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} 
from '@angular/core';
import { UtilityService, DocViewerService } from '@common/services';
import { Subscription, interval } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lab-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DocViewerComponent implements OnInit, OnDestroy {
  @ViewChild('video') videoRef: ElementRef;

  docStatusSub: Subscription;
  docSub: Subscription;
  fileName: string;
  file: string;
  type: string;
  urlSafe: any;
  timeIntervalFrame: any;
  el: any;
  progressBar: any;
  isLoading: boolean = false;
  viewer: string = 'google';
  isOpened: boolean = false;
  fileType: any;
  
  constructor(
    private utilitySrv: UtilityService,
    private docSrv: DocViewerService,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {

    this.docStatusSub = this.docSrv.getDocStatus().subscribe( 
      (show) => {

        if (show) {
          this.isOpened = show;

          this.docSub = this.docSrv.getData().subscribe( 
            ({path, type, fileType}) => {
              if (path === this.file) return

              // check file type and set viewer mode for ngx-doc-viewer
              this.viewer = fileType.match(/xls/) ? 'google' : fileType.match(/doc|ppt/) ? 'office' : 'url';
              this.type = type;
              this.file = path;
              this.fileType = fileType;
              // display file
              const url = new URL(path).pathname.split('/');
              this.fileName = url[url.length - 1];

              if (type === 'video') {
                if (this.videoRef) this.videoRef.nativeElement.src = this.file;
              }

              if (type === 'doc') {
                this.isLoading = true;
                this.isDocLoaded();
              }

              if (type === 'media' && path.includes('youtu')) {
                const url = 'https://www.youtube.com/embed/' + path.split('/')[3];
                this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
              }

          })
        } else {
          this.type = '';
          this.file = '';
          this.fileName = '';
        }

      })

  }

  isDocLoaded() {
    this.timeIntervalFrame = setInterval(() => {
      const iframe = this.el.querySelector('#iframe');
      
      if (iframe) {
        clearInterval(this.timeIntervalFrame);
        iframe.addEventListener('load', () => {
          // console.log('load event: iframe doc loaded.');
          this.isLoading = false;
          // iframe.addEventListener('load', () => console.log('load event: iframe doc loaded.'), true);
        }, false);
      }
    }, (200));
    
    // setTimeout(() => {
    //   this.isLoading = false;
    // }, 300);
  }


  onDownload(file) {
    window.open(file, '_blank');
  }

  onClose() {
    this.docSrv.docViewStatus(false);
  }

  ngOnDestroy() {
    this.docSub.unsubscribe();
    this.docStatusSub.unsubscribe();
  }

}
