export class Promotion {
    id: string;
    title: string;
    content: string;
    banner: string;
    link: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
    deleted = false;
}

