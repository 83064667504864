import {
    Component,
    Input,
    OnInit,
    Renderer2,
    ElementRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InstitutionService, OrganizationService, RoleService } from '@common/services';
import { ModalMemberListComponent } from '../modal-member-list/modal-member-list.component';
import { ModalUserListComponent } from '../modal-user-list/modal-user-list.component';
import { ToastrService } from 'ngx-toastr';
import { createHostListener } from '@angular/compiler/src/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { take } from 'rxjs/operators';

@Component({
    selector: 'batch-profile-members',
    templateUrl: './batch-profile-members.component.html',
    styleUrls: ['batch-profile-members.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BatchProfileMembersComponent implements OnInit {
    _currentOrg: any;
    batchStatus: any;
    isLoaded: boolean = false;
    permissions: any[] = [];
    @Input() set currentOrg(object) {
        if (object) {
            this._currentOrg = object;
            this.batchStatus = object.batchStatus;
            // console.log('_currentOrg', this._currentOrg)
            this.isLoaded = true;
            this.initalCompany(this._currentOrg.id);
            // console.log(this.ngxPermsissionsService.getPermissions());
        }
    }
    @Input() fromOrg;

    startups: Array<any> = [];
    mentors: Array<any> = [];
    batchUser: Array<any> = [];
    students: Array<any> = [];
    profileHeaderName: string = '';
    removeTarget;

    viewAll = true;
    //
    mentorManagement: Array<any> = [];

    isOpenUserList: boolean = false;
    isOpenProfile: boolean = false;
    expand = false;
    loading = true;
    batchId: string;
    // batch stuff/ mentor/ startup
    invitedType: string;
    profileType: string;
    profileId: string;

    // Participant / Mentor
    isOpenMentor: boolean = false;
    mentorType: string = 'Participant';
    selectedOrgId: string = '';
    selectedTeam: any;
    isAddMentorship: boolean = false;
    isOpenDialog: boolean = false;
    mentorshipList: any[] = [];
    orgType;
    showingCardOrgType;
    content;
    subcontent;
    currentUser;
    toastRef;
    inviteTitle;
    currentUserRole: string;
    searchText;
    searchTextMentor;
    searchTextStartup;

    isFilterActive: boolean = false;
    isStartUpFilterActive: boolean = false;
    isMentorFilterActive: boolean = false;
    filters: Record<string, boolean> = {};

    permissionOfUser: boolean = false;

    //new
    isSearchActive: boolean = false;

    isCurrentUserIsManager: boolean = false;

    filterProjectTeams: { name: string; key: string }[] = [
        { name: 'Assigned', key: 'consultanttagged' },
        { name: 'Not - Assigned', key: 'consultantnottagged' },
    ];
    filterConsultants: { name: string; key: string }[] = [
        { name: 'Assigned', key: 'projectsassigned' },
        { name: 'Not - Assigned', key: 'projectsnotassigned' },
        { name: 'Pending', key: 'projectspending' },
    ];
    filterParticipants: { name: string; key: string }[] = [
        { name: 'Assigned', key: 'assigned' },
        { name: 'Not - Assigned', key: 'notassigned' },
        { name: 'Pending', key: 'pending' },
    ];

    @ViewChild('container') containerRef: ElementRef;
    @ViewChild('modalcontainer', { read: ElementRef }) modalcontainer: ElementRef;
    @ViewChild('modalBody', { read: ElementRef }) modalBody: ElementRef;
    @ViewChild(ModalMemberListComponent) MMLComponent: ModalMemberListComponent;
    @ViewChild(ModalUserListComponent) MULComponent: ModalUserListComponent;

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private institutionSrv: InstitutionService,
        private renderer: Renderer2,
        private route: ActivatedRoute,
        private ngxPermsissionsService: NgxPermissionsService,
        private roleSrv: RoleService,
        private orgSrv: OrganizationService
    ) { }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        this.route.queryParams.subscribe((params) => {
            this.batchId = params['batchId'];
            this.orgType = params['batchId'];
            this.showingCardOrgType = params['orgType'];
        });
        this.setFilters();
    }

    setFilters() {
        this.filterProjectTeams.forEach((item) => {
            this.filters[item.key] = true;
        });
        this.filterParticipants.forEach((item) => {
            this.filters[item.key] = true;
        });
        this.filterConsultants.forEach((item) => {
            this.filters[item.key] = true;
        });
    }


    removeTheManagerandAdminData(participants) {
        let manager_admin_ids = this.batchUser.filter((data) => (data.role === 'batchadmin' || data.role === 'batchstaff'));
        console.log("Managers data:::", manager_admin_ids);
        for (let i = 0; i < manager_admin_ids.length; i++) {
            for (let j = 0; j < participants.length; j++) {
                if (participants[j]) {
                    if (manager_admin_ids[i].userId === participants[j].id) {
                        delete participants[j];
                    }
                }
            }
        }
        return Object.values(participants);
    }

    getParticipants(batchId) {
        this.institutionSrv.getParticipantsList(batchId).then((res) => {
            if (res['status'] === 'success') {
                if (res['data'] && res['data'].length > 0) {
                    this.students = res['data']//.filter((user_data) => (this._currentOrg.type === '0') ? (user_data.id !== this.currentUser.id && this.currentUserRole !== 'manager') || (user_data.id !== this.currentUser.id && this.currentUser['IsAdmin'] !== 1) : true);

                    // if (this._currentOrg.type === 0) {
                    //     this.students = this.removeTheManagerandAdminData(res['data']);
                    // }


                    if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                        if (this._currentOrg.type == 1) {
                            this.students.sort((a, b) => {
                                // invite_state == 1 and consultant_count != null first
                                if (
                                    a.invite_state === 1 &&
                                    a.consultant_count != null &&
                                    !(b.invite_state === 1 && b.consultant_count != null)
                                )
                                    return -1;
                                if (
                                    !(a.invite_state === 1 && a.consultant_count != null) &&
                                    b.invite_state === 1 &&
                                    b.consultant_count != null
                                )
                                    return 1;

                                // invite_state == 1 and consultant_count == null second
                                if (
                                    a.invite_state === 1 &&
                                    a.consultant_count == null &&
                                    !(b.invite_state === 1 && b.consultant_count == null)
                                )
                                    return -1;
                                if (
                                    !(a.invite_state === 1 && a.consultant_count == null) &&
                                    b.invite_state === 1 &&
                                    b.consultant_count == null
                                )
                                    return 1;

                                // invite_state == 0 third
                                if (a.invite_state === 0 && b.invite_state !== 0) return -1;
                                if (a.invite_state !== 0 && b.invite_state === 0) return 1;

                                return 0;
                            });
                        } else {
                            this.students.sort((a, b) => {
                                // invite_state == 1 and org_name != "" first
                                if (
                                    a.invite_state === 1 &&
                                    a.org_name !== '' &&
                                    !(b.invite_state === 1 && b.org_name !== '')
                                )
                                    return -1;
                                if (
                                    !(a.invite_state === 1 && a.org_name !== '') &&
                                    b.invite_state === 1 &&
                                    b.org_name !== ''
                                )
                                    return 1;

                                // invite_state == 1 and org_name == "" second
                                if (
                                    a.invite_state === 1 &&
                                    a.org_name === '' &&
                                    !(b.invite_state === 1 && b.org_name === '')
                                )
                                    return -1;
                                if (
                                    !(a.invite_state === 1 && a.org_name === '') &&
                                    b.invite_state === 1 &&
                                    b.org_name === ''
                                )
                                    return 1;

                                // invite_state == 0 third
                                if (a.invite_state === 0 && b.invite_state !== 0) return -1;
                                if (a.invite_state !== 0 && b.invite_state === 0) return 1;

                                return 0;
                            });
                            console.log('Test :', this.students);
                        }
                    } else {
                        if (this._currentOrg.type == 1) {
                            this.students = this.students.filter(
                                (student) =>
                                    student.invite_state == 1 && student.consultant_count != null
                            );
                        } else {
                            this.students = this.students.filter(
                                (student) => student.invite_state == 1 && student.org_name !== ''
                            );
                        }
                    }
                }
            }
        });
    }

    initalCompany(batchId) {
        console.log('BatchId;::', batchId);
        if (batchId) {
            //this.getParticipants(batchId);
            let role_id;
            // this.roleSrv
            //     .getUserRoleIdByUidAndBatch(this.currentUser.id, this.batchId)
            //     .subscribe((res) => {
            //         console.log("User role:::", res);
            //         role_id = res['data'][0]['roleID'];
            //     });

            this.institutionSrv
                .getBatchProfile(batchId)
                .then((res) => {
                    if (res['result'] === 'successful') {
                        if (res['users'] && res['users'].length > 0) {
                            let mangager_check = false;
                            res['users'].map((user_data) => {
                                if (
                                    user_data.role === 'batchstaff' &&
                                    user_data.userId === this.currentUser.id
                                ) {
                                    mangager_check = true;
                                    return;
                                }
                            });
                            this.batchUser = res['users'].filter((user) => {
                                if (user.userId === this.currentUser.id) {
                                    this.currentUserRole = 'manager';
                                }
                                if (user.role === 'batchadmin' || user.role === 'batchstaff') {
                                    // user.isDeleted = user.role === 'batchadmin' ? false : true;

                                    user.isDeleted = true;

                                    // // Batch manager/staff don't have permission to remove other program managers
                                    if (
                                        !this.ngxPermsissionsService.getPermission(
                                            'removeProgramBatchStaff'
                                        )
                                    ) {
                                        user.isDeleted = false;
                                    }

                                    if (this.currentUser['IsAdmin'] === 1) {
                                        user.isDeleted = true;
                                    }

                                    // Batch owner cannot be deleted
                                    if (this._currentOrg.createdBy === user.userId) {
                                        user.isDeleted = false;
                                    }
                                    if (mangager_check) {
                                        user.isDeleted = false;
                                    }
                                    return true;
                                }

                                if (user.invite_state === 0) {
                                    if (this.currentUserRole === 'manager' && !mangager_check) {
                                        user.isDeleted = true;
                                    }
                                    return true;
                                }
                            });

                            this.batchUser.forEach((user) => {
                                if (this.currentUser.id === user.userId) {
                                    this.isCurrentUserIsManager = true;
                                }
                            });

                            if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                                this.batchUser = this.batchUser.sort((a, b) => {
                                    if (a.invite_state === 1 && b.invite_state === 0)
                                        return -1;
                                    if (a.invite_state === 0 && b.invite_state === 1)
                                        return 1;
                                    return 0;
                                });
                            } else {
                                this.batchUser = this.batchUser.filter((data) => data.invite_state === 1);
                            }

                        }

                        //matchedOrgType 1: startup 4:mentor

                        // for (const organization in res['relationships']) {
                        //     if ((res['relationships'][organization][0]['matchedOrgType'] === '1') && (res['relationships'][organization][0]['status'] === 'joined')) {
                        //         this.startups.push(res['relationships'][organization][0])
                        //     }
                        // }

                        // for (const organization in res['relationships']) {
                        //     if ((res['relationships'][organization][0]['matchedOrgType'] === '4') && (res['relationships'][organization][0]['status'] === 'joined')) {
                        //         this.mentors.push(res['relationships'][organization][0])
                        //     }
                        // }

                        if (res['relationships'] && res['relationships'].length > 0) {
                            this.startups = res['relationships'].filter((org) => {
                                if (
                                    org.matchedOrgType === '1' &&
                                    (org.status == 'joined' ||
                                        org.status == 'inviting' ||
                                        org.status == 'abort')
                                ) {
                                    return true;
                                }
                            });

                            // console.log(this.startups, "PROJECT TEAMS : ")

                            if ((this.orgType = 'batch')) {
                                const uniqueIds: any[] = [];

                                this.startups = this.startups.filter((element) => {
                                    const isDuplicate = uniqueIds.includes(element.id);

                                    if (!isDuplicate) {
                                        uniqueIds.push(element.id);
                                        return true;
                                    }
                                    return false;
                                });
                            }

                            if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {

                                this.startups = this.startups.sort((a, b) => {
                                    if (a.consultant_count != null && b.consultant_count == null)
                                        return -1;
                                    if (a.consultant_count == null && b.consultant_count != null)
                                        return 1;
                                    return 0;
                                });
                            } else {
                                if (this.showingCardOrgType === 'professionalservice' || this.showingCardOrgType === 'project') {
                                    this.startups = this.startups.filter((startup) => startup);
                                } else {
                                    this.startups = this.startups.filter(
                                        (startup) => startup.consultant_count != null
                                    );
                                }


                            }

                            this.mentors = res['relationships'].filter((org) => {
                                if (org.matchedOrgType === '4') {
                                    return true;
                                }
                            });

                            if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                                if (this._currentOrg.type == 1) {
                                    this.mentors.sort((a, b) => {
                                        // Status "joined" and participants_count != null first
                                        if (
                                            a.status === 'joined' &&
                                            a.participants_count != null &&
                                            !(b.status === 'joined' && b.participants_count != null)
                                        )
                                            return -1;
                                        if (
                                            !(
                                                a.status === 'joined' &&
                                                a.participants_count != null
                                            ) &&
                                            b.status === 'joined' &&
                                            b.participants_count != null
                                        )
                                            return 1;

                                        // Status "joined" and participants_count == null second
                                        if (
                                            a.status === 'joined' &&
                                            a.participants_count == null &&
                                            !(b.status === 'joined' && b.participants_count == null)
                                        )
                                            return -1;
                                        if (
                                            !(
                                                a.status === 'joined' &&
                                                a.participants_count == null
                                            ) &&
                                            b.status === 'joined' &&
                                            b.participants_count == null
                                        )
                                            return 1;

                                        // Status "inviting" third
                                        if (a.status === 'inviting' && b.status !== 'inviting')
                                            return -1;
                                        if (a.status !== 'inviting' && b.status === 'inviting')
                                            return 1;

                                        return 0;
                                    });
                                } else {
                                    this.mentors.sort((a, b) => {
                                        // Status "joined" and userCount != null first
                                        if (
                                            a.status === 'joined' &&
                                            a.userCount != null &&
                                            !(b.status === 'joined' && b.userCount != null)
                                        )
                                            return -1;
                                        if (
                                            !(a.status === 'joined' && a.userCount != null) &&
                                            b.status === 'joined' &&
                                            b.userCount != null
                                        )
                                            return 1;

                                        // Status "joined" and userCount == null second
                                        if (
                                            a.status === 'joined' &&
                                            a.userCount == null &&
                                            !(b.status === 'joined' && b.userCount == null)
                                        )
                                            return -1;
                                        if (
                                            !(a.status === 'joined' && a.userCount == null) &&
                                            b.status === 'joined' &&
                                            b.userCount == null
                                        )
                                            return 1;

                                        // Status "inviting" third
                                        if (a.status === 'inviting' && b.status !== 'inviting')
                                            return -1;
                                        if (a.status !== 'inviting' && b.status === 'inviting')
                                            return 1;

                                        return 0;
                                    });
                                }
                            } else {
                                if (this.showingCardOrgType === 'professionalservice' || this.showingCardOrgType === 'project') {
                                    if (this._currentOrg.type == 1) {
                                        this.mentors = this.mentors.filter(
                                            (mentor) =>
                                                mentor.status == 'joined'
                                        );
                                    } else {
                                        this.mentors = this.mentors.filter(
                                            (mentor) =>
                                                mentor.status == 'joined'
                                        );
                                    }
                                } else {
                                    if (this._currentOrg.type == 1) {
                                        this.mentors = this.mentors.filter(
                                            (mentor) =>
                                                mentor.status == 'joined' &&
                                                mentor.participants_count != null
                                        );
                                    } else {
                                        this.mentors = this.mentors.filter(
                                            (mentor) =>
                                                mentor.status == 'joined' && mentor.userCount != null
                                        );
                                    }
                                }


                            }
                        }
                    }
                })
                .catch((error) => {
                    console.error('getInvitedOrg :', error);
                })
                .finally(() => {
                    this.loading = false;
                });
            this.getParticipants(batchId);
        }
    }

    onToggleUserList(event, type) {
        if (type === 'batch') {
            this.inviteTitle = 'Member';
        } else if (type === 'mentor') {
            this.inviteTitle = 'Consultant';
        } else if (type === 'startup') {
            this.inviteTitle = 'Project team';
        } else if (type === 'students') {
            this.inviteTitle = 'Participants';
        }

        this.invitedType = '';

        this.selectedTeam = null;
        if (type !== 'close') {
            this.invitedType = type;
        }
        this.isOpenUserList = !this.isOpenUserList;
        this.notAllowTargetScrollable(document.body, this.isOpenUserList);
    }

    onToggleProfile(event, type) {
        this.profileType = '';
        this.profileId = '';
        if (type !== 'close') {
            this.profileType = type;
        }
        this.isOpenProfile = !this.isOpenProfile;
        this.notAllowTargetScrollable(document.body, this.isOpenProfile);
    }

    // view startup , mentor or participant
    // display detail panel - there are three types
    // 1. startup detail (organization)
    // 2. mentor detail (organization)
    // 3. participent detail from mentor (user)
    onToggleMentor(id, type) {
        if (this.isAddMentorship) {
            this.isAddMentorship = false;
        } else if (typeof id === 'string') {
            this.mentorType = '';
            if (type !== 'close') {
                this.mentorType = type;
            } else {
                this.mentorType = '';
                this.mentorManagement = [];
                this.selectedTeam = null;
                this.notAllowTargetScrollable(document.body, false);
            }

            this.isOpenMentor = !this.isOpenMentor;
            // const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName("modal-container")[0];
            // this.renderer.setStyle(childComponentOne, "width", `665px`);

            // this.notAllowTargetScrollable(this.modalcontainer.nativeElement, this.isOpenMentor);
            /* this.MULComponent.onClose(); */
        } else {
            if (type === 'close') {
                this.mentorType = '';
                this.selectedTeam = null;
                this.isOpenMentor = !this.isOpenMentor;
                this.mentorManagement = [];
                this.notAllowTargetScrollable(document.body, false);
            }
        }
    }

    notAllowTargetScrollable(element: any, condition: boolean) {
        if (condition) {
            element.style.overflow = 'hidden';
        } else {
            element.removeAttribute('style');
        }
    }

    // show mentor or mentee
    // onSelected(event, type, team) {
    //     console.log('event=====', event)
    //     console.log('type=====', type)
    //     console.log('team=====', team)
    //     if (typeof event == 'string' && this.selectedTeam != event) {
    //         this.selectedTeam = team;
    //         this.mentorType = type;
    //         // this.isOpenMentor = !this.isOpenMentor;
    //         // if (this.mentorType == "mentor") {
    //         //     this.mentorshipList = this.startups;
    //         // } else {
    //         //     this.mentorshipList = this.mentors;
    //         // }

    //         if (this.mentorType == "mentor") {
    //             this.router.navigate(['/w/consult/profile'], {
    //                 queryParams: {
    //                     orgId: team.orgId,
    //                     orgTypeId: team.organizationTypeId,
    //                     orgType: 'consult',
    //                     tab: type
    //                 }
    //             });
    //         } else {
    //             this.router.navigate(['./w/startup/profile'], {
    //                 queryParams: {
    //                     orgId: team.orgId,
    //                     orgTypeId: team.organizationTypeId,
    //                     orgType: 'project',
    //                     tab: 'project'
    //                 }
    //             });
    //         }

    //     }
    //     this.notAllowTargetScrollable(document.body, this.isOpenMentor);

    // }

    //WIP
    onSelected(event, type, team) {
        if (typeof event == 'string' && this.selectedTeam != event) {
            this.selectedTeam = team;
            this.mentorType = type;
            this.profileType = type;
            this.isOpenProfile = true;
            this.profileId = event;
            this.profileHeaderName = team.name;
            // this.isOpenMentor = !this.isOpenMentor;
            // if (this.mentorType == "mentor") {
            //     this.mentorshipList = this.startups;
            // } else {
            //     this.mentorshipList = this.mentors;
            // }

            // WIP
            // if (this.mentorType == 'mentor') {
            //     this.router.navigate(['/w/mentor-profile/' + event], {
            //         queryParams: {
            //             batchId: this.batchId,
            //             orgId: team.orgId,
            //             orgTypeId: team.organizationTypeId,
            //             // orgType: 'consult',
            //             // tab: type,
            //             role: this.currentUserRole || 'user',
            //         },
            //     });
            // } else {
            //     this.router.navigate(['./w/organization-profile/' + event], {
            //         queryParams: {
            //             batchId: this.batchId,
            //             orgId: team.orgId,
            //             orgTypeId: team.organizationTypeId,
            //             // orgType: 'project',
            //             // tab: 'project',
            //             role: this.currentUserRole || 'user',
            //         },
            //     });
            // }
        }
        this.notAllowTargetScrollable(document.body, this.isOpenProfile);
    }

    onClickUser(event) {
        this.profileHeaderName = '';
        if (typeof event === 'string') {
            // this.router.navigate(['/w/profile/' + event], {
            //     queryParams: {
            //         batchId: this.batchId,
            //         // role: this.currentUserRole || 'user'
            //         from: 'batch',
            //     },
            // });
            this.mentorType = 'profile';
            this.profileType = 'profile';
            this.isOpenProfile = true;
            this.profileId = event;
            this.notAllowTargetScrollable(document.body, this.isOpenProfile);
        }
    }

    onToggleAddMentorship() {
        this.isAddMentorship = !this.isAddMentorship;
        if (this.mentorType == 'mentor') {
            this.mentorshipList = this.startups;
        } else {
            this.mentorshipList = this.mentors;
        }
    }

    onRemove(org, type) {
        this.isOpenDialog = !this.isOpenDialog;
        console.log("Type data:::", type);
        if (type === 'mentor' && (org.participants_count > 0 || org.userCount > 0)) {
            const typeText = org.participants_count > 0 ? 'participants' : 'project teams';
            this.content = `You are about to remove <b>${org.name}</b> from the platform. Before proceeding, please ensure that <b>${org.name}</b> is un-tagged from all ${typeText} he is currently associated with. `;
            this.subcontent = `A consultant cannot be deleted while still tagged to active ${typeText}.`;
        } else {
            this.content = `Are you sure you want to delete <b>${org.name}</b>? This action cannot be undone.`;
            this.subcontent = '';
        }
        this.removeTarget = { obj: org, type };
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
    }

    showActionMsg(title, msg) {
        this.toastRef = this.toastr.show(msg, title, {
            tapToDismiss: true,
            toastClass: 'toastpayment ',
            titleClass: 'green-title',
            closeButton: false,
            timeOut: 5000,
        });
    }

    isAllowDeleteUser(userInfo) {
        if (this._currentOrg.owner == userInfo.userId) {
            return false;
        }
        if (userInfo.userId == this.currentUser.id) {
            return false;
        }
        return true;
    }

    // call lab-dialog-remove
    onRemoveBatchUser(user) {
        const name = user.fullname;
        this.isOpenDialog = !this.isOpenDialog;
        this.content = `Are you sure you want to remove <b>${name}</b> from program organizers?`;
        this.removeTarget = { obj: user, type: 'user' };
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
    }

    // remove user from dialog and batchUser array
    handleRemoveBatchUser(user) {
        const { organizationuserId, batchId, userId, invite_state } = user;
        if (invite_state === 1) {
            this.institutionSrv
                .removeUserFromBatchById(organizationuserId, batchId, userId)
                .subscribe((res) => {
                    if (res['result'] === 'successful') {
                        this.roleSrv.deleteUserRoleByUserRoleId(user.userRoleId).subscribe((res) => {
                            if (res['result'] === 'successful') {
                                //
                            } else {
                                console.log('deleteUserRoleByUserRoleId failed');
                            }
                        });
                        this.batchUser = this.batchUser.filter(
                            (user) => user.organizationuserId !== organizationuserId
                        );
                        this.showActionMsg(
                            `Success!`,
                            `Removed ${user.fullname} from program managers successfully`
                        );
                    }
                });
        } else {
            this.institutionSrv
                .removePendingUserFromBatchById(batchId, userId)
                .subscribe((res) => {
                    if (res['result'] === 'successful') {
                        this.batchUser = this.batchUser.filter(
                            (user) => user.userId !== userId
                        );
                        this.showActionMsg(
                            `Success!`,
                            `Removed ${user.fullname} from program managers successfully`
                        );
                    }
                });
        }

    }

    // remove organization from mentors(mentors) or startups(mentees)
    handleRemoveRelationship(item, text) {
        const { name, relationshipId } = item;
        this.institutionSrv
            .removeRelationship(relationshipId, this.currentUser.id)
            .subscribe((res) => {
                if (res['result'] === 'successful') {
                    this.showActionMsg(`Success!`, `Removed ${name} from ${text}s successfully`);
                    if (text === 'Mentor') {
                        this.initalCompany(this._currentOrg.id);
                        return (this.mentors = this.mentors.filter(
                            (mentor) => mentor.relationshipId !== relationshipId
                        ));
                    }
                    if (text === 'Startup') this.initalCompany(this.batchId);
                    return (this.startups = this.startups.filter(
                        (startup) => startup.relationshipId !== relationshipId
                    ));
                }
            });
    }

    handleRemoveParticipant(item) {
        const { name, id, orgId, typeId, roleId, owner } = item;

        if (this.currentUser.id === owner) {
            this.orgSrv
                .removeUserFromOrgUser(orgId, typeId, roleId, this.currentUser.id, id)
                .subscribe(
                    (data) => {
                        if (data['result'] === 'successful') {
                            this.students = this.students.filter((student) => student.id !== id);
                            this.showActionMsg(
                                'Success',
                                `Removed ${name} from participants successfully`
                            );
                        } else {
                            this.showActionMsg('Failed', `${name} is not removed!`);
                        }
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            return;
        }

        this.institutionSrv.removeParticipant(id, this.batchId).subscribe((res) => {
            if (res['status'] === 'Success') {
                this.showActionMsg(`Success!`, `Removed ${name} from participants successfully`);
                return (this.students = this.students.filter((student) => student.id !== id));
            }
        });
    }

    removeMentorRelationships(item) {
        this.institutionSrv
            .removeMentorManagementByBatchIdAndMentorId(this.batchId, item.id)
            .pipe(take(1))
            .subscribe((res) => {
                console.log(res);
            });
    }

    // for dialog submitted
    onSubmit() {
        const { obj, type } = this.removeTarget;
        const text = type === 'mentor' ? 'Mentor' : 'Startup';
        // return console.log(obj, type);
        this.isOpenDialog = !this.isOpenDialog;
        // make body is not able to scroll
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
        // check type to call the differnet functions
        this.subcontent = '';
        if (type === 'user') return this.handleRemoveBatchUser(obj);
        if (type === 'mentor') this.removeMentorRelationships(obj);
        if (type === 'mentor' || type === 'startup')
            return this.handleRemoveRelationship(obj, text);
        if (type === 'student') return this.handleRemoveParticipant(obj);
    }

    onCloseDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        this.subcontent = '';
        document.body.removeAttribute('style');
    }

    reloadCurrentPage() {
        window.location.reload();
    }

    testBtn() {
        console.log('Btn clicked');
    }

    changeRoleToBatchManager() {
        alert('Role changed to Batch Manager');

        this.roleSrv
            .getUserRoleIdByUidAndBatch(this.currentUser.id, this.batchId)
            .subscribe((res) => {
                let userRoleId = '';
                if (res['result'] === 'successful') {
                    userRoleId = res['data'][0]['userRoleId'];

                    this.roleSrv.changeRoleToBatchStaff(userRoleId).subscribe((res) => {
                        if (res['result'] === 'successful') {
                            this.roleSrv
                                .getPermissionsByUidAndBatchId(this.currentUser.id, this.batchId)
                                .subscribe((res) => {
                                    if (res['data'] !== null) {
                                        const permissionArray = res['data'];
                                        permissionArray.forEach((permission) => {
                                            this.permissions.push(permission.permissionName);
                                        });
                                        console.log('Permisionns::', this.permissions);
                                        this.ngxPermsissionsService.loadPermissions(
                                            this.permissions
                                        );
                                    }
                                });
                        }
                    });
                }
            });
    }

    changeRoleToBatchAdmin() {
        alert('Role changed to Batch Admin');

        this.roleSrv
            .getUserRoleIdByUidAndBatch(this.currentUser.id, this.batchId)
            .subscribe((res) => {
                let userRoleId = '';
                if (res['result'] === 'successful') {
                    userRoleId = res['data'][0]['userRoleId'];

                    this.roleSrv.changeRoleToBatchAdmin(userRoleId).subscribe((res) => {
                        if (res['result'] === 'successful') {
                            this.roleSrv
                                .getPermissionsByUidAndBatchId(this.currentUser.id, this.batchId)
                                .subscribe((res) => {
                                    if (res['data'] !== null) {
                                        const permissionArray = res['data'];
                                        permissionArray.forEach((permission) => {
                                            this.permissions.push(permission.permissionName);
                                        });

                                        this.ngxPermsissionsService.loadPermissions(
                                            this.permissions
                                        );
                                    }
                                });
                        }
                    });
                }
            });
    }

    changeRoleToBatchOwner() {
        alert('Role changed to Owner');

        // End goal, please call an api to change the role in backend
        // Below are permissions for batch owner.
        const batchOwnerPermissions = [
            'assignProjectTeam',
            'assignConsultant',
            'inviteBatchManager',
            'removeBatchManager',
            'assignProjectTeam',
            'assignConsultant',
            'removeProjectTeam',
            'removeConsultant',
            'createPhaseSubmissionCriteria',
            'editPhaseSubmissionCriteria',
            'deletePhaseSubmissionCriteria',
            'uploadFileInFileManager',
            'editFileInFileManager',
            'publishPhase',
            'viewProjectTeamsProgressList',
            'submitSubmission',
            'changeRatings',
            'restartSubmissions',
            'acceptRestart',
            'rejectRestart',
            'pivotSubmissions',
            'acceptPivot',
            'rejectPivot',
            'viewDashboard',
            'viewRecentActivityLogs',
            'viewScoreHistoryChart',
            'createBanner',
            'editBanner',
            'shareBanner',
            'deleteBanner',
            'viewTasks',
            'createTasks',
            'editBatch',
            'launchBatch',
            'pauseBatch',
            'stopBatch',
            'editTasks',
            'deleteTasks',
            'viewSchedule',
            'createSchedule',
            'cancelSchedule',
            'editSchedule',
            'deleteSchedule',
            'viewBatches',
            'inviteUsersToBatch',
            'inviteOrgMember',
            'createBatch',
        ];
        this.ngxPermsissionsService.loadPermissions(batchOwnerPermissions);
    }

    launchBatch() {
        alert('Redirect page to payment link');
        this.batchStatus = 1;
        this.institutionSrv.launchBatch(this.batchId);
    }

    pauseBatch() {
        alert('Batch is paused');
        this.batchStatus = 2;
        this.institutionSrv.pauseBatch(this.batchId);
    }

    unpauseBatch() {
        this.batchStatus = 1;
        this.institutionSrv.launchBatch(this.batchId);
    }

    suspendBatch() {
        alert('Batch is suspended'), (this.batchStatus = 3);
        this.institutionSrv.hideBatch(this.batchId);
        this.router.navigate(['./w/'], {});
    }

    editBatch() {
        alert('Redirect to edit batch link');
    }

    searchTextChange(val: string) {
        this.searchText = val;
    }

    searchTextChangeMentor(val: string) {
        this.searchTextMentor = val;
    }

    searchTextChangeStartup(val: string) {
        this.searchTextStartup = val;
    }

    setIsFiltersActive() {
        this.isFilterActive = !this.isFilterActive;
    }

    setIsStartUpFiltersActive() {
        this.isStartUpFilterActive = !this.isStartUpFilterActive;
    }

    setIsMentorFiltersActive() {
        this.isMentorFilterActive = !this.isMentorFilterActive;
    }

    statusChange(status) {
        const { key, value } = status;
        this.filters[key] = value;
    }

    setIsSearchActive() {
        this.isSearchActive = !this.setIsSearchActive;
    }

    canDeleteParticipants(student) {
        if (this.batchStatus !== 0) return false;
        if (this._currentOrg.type === 1 && this.currentUserRole === 'manager') {
            return true;
        }
        if (student.invite_state === 0 && this.currentUserRole === 'manager') return true;
        if (!student.org_name && this.currentUserRole === 'manager') return true;
        if (this.currentUser.id === student.owner && this.currentUser.id !== student.id)
            return true;

        return false;
    }

    reloadRemoveMentees(type) {
        this.initalCompany(this.batchId)
    }
}
