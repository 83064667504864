import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter
} from '@angular/core';
import { BatchesService } from '@modules/workspace/services';
import { DocViewerService } from '@common/services';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';
import { Batches } from '../../interfaces/batches';

@Component({
  selector: 'lab-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class BatchesComponent implements OnInit {
  //batches: Batches[] = [];
  public _batches: any[] = [];
  public _displayBatches: any[] = [];
  @Input() set batches(objects) {
    if (objects && objects.length > 0) {
      this._batches = objects;

      /*  let startBatches = this._batches.filter(batch => {
        return batch.organizationType == "project"
      });
      if (startBatches.length > 0) {
        this._displayBatches = startBatches;
      } else {
         let startBatches = this._batches.filter(batch => {
            return batch.organizationType == "professionalservice"
          });
          if (startBatches.length > 0) {
            this._displayBatches = startBatches;
          } 
      }*/
    }
  }
  @Output() selected = new EventEmitter();

  tabs: any[] = [
    {
      id: 1,
      text: 'Start Up',
      key: 'project'
    },
    {
      id: 2,
      text: 'Consultant',
      key: 'professionalservice'
    },
    {
      id: 3,
      text: 'Venture Lab',
      key: 'lab'
    }
  ]

  
  constructor(public batchesService: BatchesService,  private toastr: ToastrService, private ngxPermissionService: NgxPermissionsService, private docSrv: DocViewerService) {
  }

  ngOnInit() {
    //this.batches = [...this.batchesService.getData()];


  }

  onTabsChange(tab: string) {
    if (typeof tab === 'string') {

      this._displayBatches = this._batches.filter(batch => {
        return batch.organizationType == tab
      });

    }
  }

  handleSelected(item: any) {
   
      // make sure close program resources preview when go to batch
      this.docSrv.docViewStatus(false);
      this.batchesService.selected(item)
      this.selected.emit(item)
    
  }

  onClick() {

  }

}
