import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@common/models';
import {
    EatToasterService,
    IndividualService,
    InstitutionService,
    OrganizationService,
    UtilityService,
    OrganizationBatchService
} from '@common/services';
import { environment } from 'environments/environment';
import { ModalUserListComponent } from '../modal-user-list/modal-user-list.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'participant-profile-members',
    templateUrl: './participant-profile-members.component.html',
    styleUrls: ['./participant-profile-members.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ParticipantProfileMembersComponent implements OnInit {
    @Input() type;

    @Input() hideInviteBtn;

    @Input() set params(object) {
        if (object['batchId']) {
            this.batchId = object['batchId'];
        }
        if (object['role']) {
            this.role = object['role'];
        }
    }

    _currentOrg: any;

    @Input() set currentOrg(object) {
        if (object) {
            this._currentOrg = object;
            {
                this.initalCompany(this.batchId);
            }
        }
    }

    @Input() isCurrentUserIsManager: boolean = false;

    currentUser;
    profile_users: Array<any> = [];
    mentorManagement = [];
    mentors = [];
    orgType: string = '';
    batchId: string = '';
    editedOrg: string;
    loading = true;
    coFounders: Array<any> = [];
    members: Array<any> = [];
    batchUser: Array<any> = [];
    batchParticipants: Array<any> = [];
    showInviteBtn: boolean = true;
    startupInfo: any;
    editedOrgType: string = '';
    currentOrgRole: any;
    currentOrgStaffRoleId: any;
    currentOrgStaffName: any;
    isOpenModal: boolean = false;
    viewAll: boolean = true;
    expand: boolean = false;
    batchCompany: any;
    startups = [];

    // mentor/mentee
    inviteTitle: string = '';
    invitedType: string = '';
    isOpenMentorshipList: boolean = false;
    mentorType: string = '';
    isAddMentorship: boolean = true;
    mentorshipList: any[] = [];
    isOpenDialog: boolean = false;
    content: string = '';
    removeTarget;
    toastRef;
    role: string;

    @ViewChild('container') containerRef: ElementRef;
    @ViewChild('modalcontainer', { read: ElementRef }) modalcontainer: ElementRef;
    @ViewChild('modalBody', { read: ElementRef }) modalBody: ElementRef;
    @ViewChild(ModalUserListComponent) MULComponent: ModalUserListComponent;

    @Output() onRemoveUser = new EventEmitter<any>();
    @Output() onRemoveReload = new EventEmitter<any>();
    constructor(
        private route: ActivatedRoute,
        private orgSrv: OrganizationService,
        private individualSrv: IndividualService,
        private institutionSrv: InstitutionService,
        private toasterSrv: EatToasterService,
        private utilitySrv: UtilityService,
        private renderer: Renderer2,
        private orgBatchSrv: OrganizationBatchService,
        private utility: UtilityService,
        private toastr: ToastrService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        this.route.queryParams.subscribe((params) => {
            this.batchId = params['batchId'];
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params.role) this.role = params.role;
        });
        this.getTaggedUsers();
    }

    changeWidth() {
        if (!this.expand) {
            const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-container'
            )[0];
            this.renderer.setStyle(childComponentOne, 'width', `100%`);
            const childComponentTwo = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-user-list-container'
            )[0];
            this.renderer.setStyle(childComponentTwo, 'grid-template-columns', `repeat(5, 1fr)`);
            this.expand = true;
        } else {
            const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-container'
            )[0];
            this.renderer.setStyle(childComponentOne, 'width', `665px`);

            const childComponentTwo = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-user-list-container'
            )[0];
            this.renderer.setStyle(childComponentTwo, 'grid-template-columns', `repeat(3, 1fr)`);
            this.expand = false;
        }
    }

    onToggleModal(value) {
        this.isOpenModal = value;
        if (!this.isOpenModal) {
            this.MULComponent.onClose();
        }
    }

    notAllowTargetScrollable(element: any, condition: boolean) {
        const header = document.querySelector('#header');
        const height = header?.clientHeight;
        if (condition) {
            element.style.overflow = 'hidden';
            element.style.height = `calc(100vh - ${height}px)`;
        } else {
            element.removeAttribute('style');
        }
    }

    initalUser(batchId, key) {
        this.institutionSrv.getBatchUserByBatchId(batchId, key).subscribe(
            (value) => {
                if (value.result === 'successful') {
                    if (value.data.length > 0) {
                        value.data.map((data) => {
                            if (data.roleID === 13 || data.roleID === 14) {
                                this.batchUser.push(data);
                            } else if (data.roleID === 15) {
                                this.batchParticipants.push(data);
                            }
                        });

                        if (this.batchUser instanceof Array) {
                            this.batchUser = this.batchUser.sort((a, b) => {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            });
                        }
                        if (this.batchParticipants instanceof Array) {
                            this.batchParticipants = this.batchParticipants.sort((a, b) => {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            });
                        }
                    }
                } else if (value['error']) {
                    console.error(value['error']);
                }
            },
            (error) => {
                console.error('getBatchUserByBatchId :', error);
            },
            () => {
                this.loading = false;
            }
        );
    }

    // userType: cofounder , member
    removeUser(orgId: string, orgTypeId: string, user, userType) {
        let _user = user;

        this.orgSrv
            .removeUserFromOrgUser(
                orgId,
                orgTypeId,
                _user.userRoleId,
                this.currentUser.id,
                _user.userinfo.userId
            )
            .subscribe(
                (data) => {
                    if (data['result'] === 'successful') {
                        if (userType == 'cofounder') {
                            this.coFounders = this.coFounders.filter(
                                (user) => user.userId !== _user.userId
                            );
                        } else if (userType == 'member') {
                            this.members = this.members.filter((user) => {
                                return user.userId !== _user.userId;
                            });
                        }
                        this.toasterSrv.showToast('', `${_user.fullname} is removed!`, 'success');
                    } else {
                        this.toasterSrv.showToast('', `remove ${_user.fullname} failed!`, 'error');
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onOpenDialog(value) {
        this.onRemoveUser.emit(value);
    }

    initalCompany(batchId) {
        this.institutionSrv.getInvitedOrg(batchId).subscribe(
            (res) => {
                if (res['result'] !== 'failed') {
                    this.batchCompany = [...res['projects'], ...res['funders'], ...res['experts']];
                    this.startups = res['projects'];
                    this.mentors = res['experts'];
                }
            },
            (error) => console.error('getInvitedOrg :', error),
            () => (this.loading = false)
        );
    }

    getType(type: string) {
        if (type === '1') {
            return 'project';
        } else if (type === '2') {
            return 'funder';
        } else if (type === '3') {
            return 'institution';
        } else if (type === '4') {
            return 'professionalservice';
        }
    }

    getStaffName(type: string) {
        if (type === '1') {
            this.currentOrgStaffRoleId = '9';
            return 'staff';
        } else if (type === '2') {
            this.currentOrgStaffRoleId = '10';
            return 'funderstaff';
        } else if (type === '3') {
            this.currentOrgStaffRoleId = '11';
            return 'institutionstaff';
        } else if (type === '4') {
            this.currentOrgStaffRoleId = '12';
            return 'expertstaff';
        }
    }

    onToggleMentorshipList(event, type) {
        if (type === 'batch') {
            this.inviteTitle = 'Member';
        } else if (type === 'mentor') {
            this.inviteTitle = 'Mentor';
            this.mentorshipList = this.startups;
        } else if (type === 'startup') {
            this.inviteTitle = 'Start Up';
        } else if (type === 'mentee') {
            this.inviteTitle = 'Mentee';
            this.mentorshipList = this.mentors;
        }
        this.mentorType = '';
        if (type !== 'close') {
            this.mentorType = type;
        } else {
            this.mentorType = '';
            this.notAllowTargetScrollable(document.body, false);
        }
        this.invitedType = '';

        //this.selectedTeam = null;
        if (type !== 'close') {
            this.invitedType = type;
        }
        this.isOpenMentorshipList = !this.isOpenMentorshipList;
        const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
            'modal-container'
        )[0];
        this.renderer.setStyle(childComponentOne, 'width', `665px`);
        this.notAllowTargetScrollable(document.body, this.isOpenMentorshipList);
    }

    onRemove(obj, type) {
        this.isOpenDialog = !this.isOpenDialog;
        this.content = `Are you sure you want to remove <b>${obj.name}</b> from ${type}s?`;
        if (type === 'project team' || type === 'Participant') {
            this.content = `Are you sure you want to untag <b>${obj.name}</b> from ${this._currentOrg?.name}?`
        }
        this.removeTarget = { obj, type };
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
    }

    onRemoveMentees({ id, name }) {
        this.institutionSrv.removeMentorManagement(id, this.currentUser.id).subscribe((res) => {
            if ((res['result'] = 'successful')) {
                this.showActionMsg(name);
                this.mentorManagement = this.mentorManagement.filter(
                    (mentor) => mentor['id'] !== id
                );
                this.getTaggedUsers();
                this.onOpenDialog(this.currentOrg?.id);
                this.onRemoveReload.emit(this.removeTarget.type);
            }
        });
    }

    onSubmit() {
        this.isOpenDialog = !this.isOpenDialog;
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
        this.onRemoveMentees(this.removeTarget.obj);
    }

    showActionMsg(msg) {
        this.toastRef = this.toastr.show(`${msg} has been successfully removed.`, 'Success', {
            disableTimeOut: false,
            tapToDismiss: true,
            toastClass: 'toast border-blue',
            closeButton: false,
        });
    }

    onCloseDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        document.body.removeAttribute('style');
    }
    getTaggedUsers() {
        if (this._currentOrg?.id) {
            this.orgBatchSrv.getTaggedUsersByBatch(this._currentOrg.organizationId, this.batchId).then(data => {
                if (data['status'] === 'success') {
                    this.profile_users = data['data']; //[...data['data']['org_data'], ...data['data']['batch_data']];
                    if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                        this.profile_users.sort((a, b) => {
                            // invite_state == 1 and consultantCount != "" first
                            if (a.invite_state === 1 && a.consultantCount !== null && !(b.invite_state === 1 && b.consultantCount !== null)) return -1;
                            if (!(a.invite_state === 1 && a.consultantCount !== null) && b.invite_state === 1 && b.consultantCount !== null) return 1;

                            // invite_state == 1 and consultantCount == "" second
                            if (a.invite_state === 1 && a.consultantCount === null && !(b.invite_state === 1 && b.consultantCount === null)) return -1;
                            if (!(a.invite_state === 1 && a.consultantCount === null) && b.invite_state === 1 && b.consultantCount === null) return 1;

                            // invite_state == 0 third
                            if (a.invite_state === 0 && b.invite_state !== 0) return -1;
                            if (a.invite_state !== 0 && b.invite_state === 0) return 1;

                            return 0;
                        });
                    } else {
                        this.profile_users = this.profile_users.filter(student => student.invite_state == 1 && student.consultantCount !== null);
                    }

                    // console.log("INDIVIDUALS : CONSULTANTS : TAGGED PARTICIPANTS :", this.profile_users)
                } else {
                    this.profile_users = [];
                }
                this.loading = false;
            });
        }
    }
}
