import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Widget, ResResult } from '../models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class WidgetSetService {
    widgetSets: Widget[] = [];
    private widgetSet = new BehaviorSubject([]);
    currentWidgetSet = this.widgetSet.asObservable();
    widgetSets2 = [
        {
            id: 0,
            organizationId: '0',
            name: 'individual',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 1,
            organizationId: '1',
            name: 'startup',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 2,
            organizationId: '2',
            name: 'vendor',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 3,
            organizationId: '3',
            name: 'investor',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 4,
            organizationId: '4',
            name: 'advisor',
            widgets: ['0', '1', '2', '3', '4']
        },
        {
            id: 0,
            organizationId: '5',
            name: 'individual',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 1,
            organizationId: '6',
            name: 'startup',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 2,
            organizationId: '7',
            name: 'vendor',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 3,
            organizationId: '8',
            name: 'investor',
            widgets: ['0', '1', '2', '3', '4']
        }, {
            id: 4,
            organizationId: '9',
            name: 'advisor',
            widgets: ['0', '1', '2', '3', '4']
        }];

    constructor(private http: HttpClient) {

    }

    getAllAdminWidget() { // getAdminRolesList
        return this.http.get<ResResult>(`${environment.apiUrl}/widgetGroup/getAdminRolesList`);
    }

    // for admin plan
    addWidgetGroupList(groupId: string, widgetId: string, uid: string) {
        try {
            return this.http.post(`${environment.apiUrl}/widget/addWidgetGroupList`,
                { widgetGroupId: groupId, widgetId: widgetId, uid: uid })
                .subscribe(data => { });
        } catch (error) {
            console.error('add widget group failed', error);
            return null;
        }
    }

    getWidgetSetById(Id: string) {
        const _widgetSet = this.widgetSets.filter(widgetSet => {
            return widgetSet.id === Id;
        });
        if (_widgetSet.length > 0) {
            return _widgetSet;
        } else {
            return null;
        }

    }
    getWidgetSetByOrgId(Id: string) {
        let _widgetSet = this.widgetSets2.filter(widgetSet => {
            return widgetSet.organizationId === Id;
        });
        if (_widgetSet.length > 0) {
            return _widgetSet[0].widgets;
        } else {
            return null;
        }

    }
    /*
     */
    async assignWidgetToOrganizationByRoleId(organizationId: string,
        roleId: string,
        organizationTypeId: string,
        userId: string) {

        return await this.http.post(`${environment.apiUrl}/widget/assignWidgetToOrganizationWidgetByRoleId`, {
            'organizationId': organizationId,
            'roleId': roleId,
            'organizationTypeId': organizationTypeId,
            'userId': userId
        }).subscribe(
            (response) => {

            },
            (error) => {
                console.error('assignWidgetToOrganizationByRoleId error', error);
            }
        );
    }

    createOrganizationWidget(value) {
        return this.http.post<any>(`${environment.apiUrl}/widget/createOrganizationWidget`, value);
    }
}
