import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResourcesService {

    constructor(
        private http: HttpClient
    ) { }

    async addResource(value) {
        return this.http.post<any>(`${environment.apiUrl}/resources/addResource`, value).toPromise();
    }

    updateResource(value) {
        return this.http.post<any>(`${environment.apiUrl}/resources/updateResource`, value);
    }

    updateResourceTitle(id, title, uid) {
        return this.http.post<any>(`${environment.apiUrl}/resources/updateResourceTitle`, {id,title, uid});
    }

    async getResourceById(id) {
        return this.http.get<any>(`${environment.apiUrl}/resources/getResourceById/${id}`).toPromise();
    }

    getResourcesByBatchId(batchId) {
        return this.http.get<any>(`${environment.apiUrl}/resources/getResourcesByBatchId/${batchId}`);
    }

    getResourcesByBatchIdProgress(batchId) {
        return this.http.get<any>(`${environment.apiUrl}/resources/getResourcesByBatchIdProgress/${batchId}`);
    }

    deleteResource(id) {
        return this.http.delete<any>(`${environment.apiUrl}/resources/deleteResource/${id}`);
    }

    getAssociatedByResourceId(resId) {
        return this.http.get<any>(`${environment.apiUrl}/resources/getAssociatedByResourceId/${resId}`);
    }

    getAssociatedByResourceIdProgress(resId) {
        return this.http.get<any>(`${environment.apiUrl}/resources/getAssociatedByResourceIdProgress/${resId}`);
    }

}