import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@common/models';
import {
    EatToasterService,
    IndividualService,
    InstitutionService,
    OrganizationService,
    UtilityService,
} from '@common/services';
import { environment } from 'environments/environment';
import { ModalUserListComponent } from '../modal-user-list/modal-user-list.component';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'startup-profile-members',
    templateUrl: './startup-profile-members.component.html',
    styleUrls: ['./startup-profile-members.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StartupProfileMembersComponent implements OnInit {
    @Input() type;

    @Input() hideInviteBtn;

    @Input() set params(object) {
        if (object['batchId']) {
            this.batchId = object['batchId'];
        }
        if (object['role']) {
            this.role = object['role'];
        }
    }

    _currentOrg: any;

    @Input() set currentOrg(object) {
        if (object) {
            this._currentOrg = object;
            // if (this.type === 'batch') {
            //     this.initalUser(this.batchId, "default");
            // } else
            {
                /* this.currentOrgId = params['orgId'];
        this.orgTypeId = params['orgTypeId']; */
                this.initalCompany(this.batchId);
                this.getStaffList();
                this.viewDetails(this._currentOrg);
            }
        }
    }

    @Input() isCurrentUserIsManager: boolean = false;

    currentUser;
    mentorManagement: Array<any> = [];
    mentors = [];
    orgType: string = '';
    batchId: string = '';
    editedOrg: string;
    loading = true;
    coFounders: Array<any> = [];
    members: Array<any> = [];
    batchUser: Array<any> = [];
    batchParticipants: Array<any> = [];
    showInviteBtn: boolean = true;
    startupInfo: any;
    editedOrgType: string = '';
    currentOrgRole: any;
    currentOrgStaffRoleId: any;
    currentOrgStaffName: any;
    isOpenModal: boolean = false;
    viewAll: boolean = true;
    expand: boolean = false;
    batchCompany: any;
    startups = [];

    // mentor/mentee
    inviteTitle: string = '';
    invitedType: string = '';
    isOpenMentorshipList: boolean = false;
    mentorType: string = '';
    isAddMentorship: boolean = true;
    mentorshipList: any[] = [];
    isOpenDialog: boolean = false;
    content: string = '';
    removeTarget;
    toastRef;
    role: string;

    @ViewChild('container') containerRef: ElementRef;
    @ViewChild('modalcontainer', { read: ElementRef }) modalcontainer: ElementRef;
    @ViewChild('modalBody', { read: ElementRef }) modalBody: ElementRef;
    @ViewChild(ModalUserListComponent) MULComponent: ModalUserListComponent;

    @Output() onRemoveUser = new EventEmitter<any>();

    constructor(
        private route: ActivatedRoute,
        private orgSrv: OrganizationService,
        private individualSrv: IndividualService,
        private institutionSrv: InstitutionService,
        private toasterSrv: EatToasterService,
        private utilitySrv: UtilityService,
        private renderer: Renderer2,
        private utility: UtilityService,
        private toastr: ToastrService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
        this.route.queryParams.subscribe((params) => {
            this.batchId = params['batchId'];
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params.role) this.role = params.role;
        });
    }

    changeWidth() {
        if (!this.expand) {
            const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-container'
            )[0];
            this.renderer.setStyle(childComponentOne, 'width', `100%`);
            const childComponentTwo = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-user-list-container'
            )[0];
            this.renderer.setStyle(childComponentTwo, 'grid-template-columns', `repeat(5, 1fr)`);
            this.expand = true;
        } else {
            const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-container'
            )[0];
            this.renderer.setStyle(childComponentOne, 'width', `665px`);

            const childComponentTwo = this.modalcontainer.nativeElement.getElementsByClassName(
                'modal-user-list-container'
            )[0];
            this.renderer.setStyle(childComponentTwo, 'grid-template-columns', `repeat(3, 1fr)`);
            this.expand = false;
        }
    }

    onToggleModal(value) {
        this.isOpenModal = value;
        // const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName("modal-container")[0];
        // this.renderer.setStyle(childComponentOne, "width", `665px`);
        // this.notAllowTargetScrollable(this.containerRef.nativeElement, this.isOpenModal);
        if (!this.isOpenModal) {
            this.MULComponent.onClose();
        }
    }

    notAllowTargetScrollable(element: any, condition: boolean) {
        const header = document.querySelector('#header');
        const height = header?.clientHeight;
        if (condition) {
            element.style.overflow = 'hidden';
            element.style.height = `calc(100vh - ${height}px)`;
        } else {
            element.removeAttribute('style');
        }
    }

    getStaffList() {
        // to get all the members in the current organization

        this.loading = true;
        this.coFounders = [];
        this.members = [];
        if (this._currentOrg) {
            this.orgSrv
                .getOrgUserListWithValue(this._currentOrg.id, this._currentOrg.organizationTypeId)
                .then(
                    (staffList) => {
                        if (staffList['result'] === 'successful') {
                            if (staffList['data'].length > 0) {
                                let orguserlist = staffList['data'].filter(
                                    (user) => user.roleID != '15' && user.roleID != '16'
                                );

                                orguserlist = [
                                    ...new Map(
                                        orguserlist.map((item) => [item['userId'], item])
                                    ).values(),
                                ];

                                orguserlist.map((user) => {
                                    if (!this.utilitySrv.IsNullOrEmpty(user.personalQuizzes)) {
                                        user[
                                            'hipster'
                                        ] = this.individualSrv.calculate_Hipster_percentage(
                                            user.personalQuizzes[0]
                                        );
                                        user[
                                            'hacker'
                                        ] = this.individualSrv.calculate_Hacker_percentage(
                                            user.personalQuizzes[0]
                                        );
                                        user[
                                            'hustler'
                                        ] = this.individualSrv.calculate_Hustler_percentage(
                                            user.personalQuizzes[0]
                                        );
                                    }
                                    user.isPersonalPersonality = true;
                                    if (this.utilitySrv.IsNullOrEmpty(user.personalPersonality)) {
                                        user.isPersonalPersonality = false;
                                        // if(cofounder.personalPersonality[0].mBIndicator)
                                        // user.isPersonalPersonality = false;
                                    } else if (
                                        this.utilitySrv.IsNullOrEmpty(user.personalPersonality[0])
                                    ) {
                                        user.isPersonalPersonality = false;
                                    } else if (
                                        this.utilitySrv.IsNullOrEmpty(
                                            user.personalPersonality[0].mBIndicator
                                        )
                                    ) {
                                        user.isPersonalPersonality = false;
                                    }

                                    /*    if (!this.utilitySrv.IsNullOrEmpty(user.imageUrl)) {
                     if (user.imageUrl.indexOf('asset') > -1) {
                         user.imageUrl = environment.assetUrl + user.imageUrl;
                     }
                 } */
                                });
                                this.coFounders = orguserlist;
                                /*  this.coFounders = staffList['coFounder'];
             for (let item in this.coFounders) { 

                 this.coFounders[item][0]['hipster'] = this.individualSrv.calculate_Hipster_percentage(this.coFounders[item][0].personalQuizzes);
                 this.coFounders[item][0]['hacker'] = this.individualSrv.calculate_Hacker_percentage(this.coFounders[item][0].personalQuizzes);
                 this.coFounders[item][0]['hustler'] = this.individualSrv.calculate_Hustler_percentage(this.coFounders[item][0].personalQuizzes);
                 let logo = this.coFounders[item][0].userinfo.imageUrl;

                 if (!this.utilitySrv.IsNullOrEmpty(logo)) {
                     if (logo.indexOf('asset') > -1) {
                         this.coFounders[item][0].userinfo.imageUrl = environment.assetUrl + logo;
                     }
                 }
             } */

                                // remove-duplicate
                                //  this.coFounders = [...new Map(this.coFounders.map(item => [item['id'], item])).values()]

                                // It is used to decide whether show remove user button
                                /*  let isCofounder = this.coFounders.filter(user => user[0].userinfo.id === this.currentUser.id);
             if (isCofounder.length !== 0) {
                 this.currentUser['isCofounder'] = true;
             }
             else {
                 this.currentUser['isCofounder'] = false;
             } */
                            }
                        } else if (staffList['error']) {
                            console.log(staffList['error']);
                        }
                        this.loading = false;
                    },
                    (error) => {
                        console.error('getOrgStaffList :', error);
                    }
                );
        }
    }

    initalUser(batchId, key) {
        this.institutionSrv.getBatchUserByBatchId(batchId, key).subscribe(
            (value) => {
                if (value.result === 'successful') {
                    if (value.data.length > 0) {
                        value.data.map((data) => {
                            if (data.roleID === 13 || data.roleID === 14) {
                                this.batchUser.push(data);
                            } else if (data.roleID === 15) {
                                this.batchParticipants.push(data);
                            }
                        });

                        if (this.batchUser instanceof Array) {
                            this.batchUser = this.batchUser.sort((a, b) => {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            });
                        }
                        if (this.batchParticipants instanceof Array) {
                            this.batchParticipants = this.batchParticipants.sort((a, b) => {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            });
                        }
                    }
                } else if (value['error']) {
                    console.error(value['error']);
                }
            },
            (error) => {
                console.error('getBatchUserByBatchId :', error);
            },
            () => {
                this.loading = false;
            }
        );
    }

    // userType: cofounder , member
    removeUser(orgId: string, orgTypeId: string, user, userType) {
        let _user = user;

        this.orgSrv
            .removeUserFromOrgUser(
                orgId,
                orgTypeId,
                _user.userRoleId,
                this.currentUser.id,
                _user.userinfo.userId
            )
            .subscribe(
                (data) => {
                    if (data['result'] === 'successful') {
                        if (userType == 'cofounder') {
                            this.coFounders = this.coFounders.filter(
                                (user) => user.userId !== _user.userId
                            );
                        } else if (userType == 'member') {
                            this.members = this.members.filter((user) => {
                                return user.userId !== _user.userId;
                            });
                        }
                        this.toasterSrv.showToast('', `${_user.fullname} is removed!`, 'success');
                    } else {
                        this.toasterSrv.showToast('', `remove ${_user.fullname} failed!`, 'error');
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    onOpenDialog(value) {
        this.onRemoveUser.emit(value);
    }

    viewDetails(content) {
        this.loading = false;
        if (!this.utility.IsNullOrEmpty(content.organizationId)) {
            this.editedOrg = content;
            this.orgSrv.getOrgById(this.editedOrg['organizationId']).subscribe(
                (data) => {
                    if ((data['result'] = 'successful')) {
                        // display
                        this.startupInfo = data['data'];
                        // organization type

                        this.editedOrgType = data['orgType'].type;

                        this.orgSrv.getTypeFromTypeId(data['data'].organizationTypeId).subscribe(
                            (type) => {
                                this.currentOrgRole = this.getType(type['data'][0].type);

                                this.currentOrgStaffName = this.getStaffName(type['data'][0].type);
                            },
                            (error) => console.error('getTypeFromTypeId :', error)
                        ),
                            () => {};
                        if (this.editedOrgType === '1') {
                            this.getMentorManagement(
                                this.batchId,
                                this.editedOrg['id'],
                                'organization'
                            );
                        } else if (this.editedOrgType === '4') {
                            this.getMenteeManagement(
                                this.batchId,
                                this.editedOrg['id'],
                                'organization'
                            );
                        }
                        if (this.editedOrgType === '5') {
                            // this.getMenteeManagement(this.batchId, this.editedOrg['orgId'], 'participant');
                        }

                        window.scroll(0, 0);
                    }
                },
                (error) => console.error('getOrgById :', error)
            ),
                () => {};
        } else {
        }
    }

    getMenteeManagement(batchId, mentorId, clientType) {
        this.institutionSrv.getMentorManagementByMentorId(batchId, mentorId, clientType).subscribe(
            (res) => {
                if (res['result'] == 'successful') {
                    res.data.map((management) => {
                        if (management.clientType != 'participant') {
                            let _startup = this.startups.filter(
                                (startup) => startup['id'] === management.clientId
                            );
                            if (_startup.length > 0) {
                                management['name'] = _startup[0]['name'];
                                management['compLogo'] = _startup[0]['compLogo'];
                            }
                            /*   management["compLogo"] = this.startups.filter(
                    (startup) => startup['id'] === management.clientId
                  )[0]['compLogo']; */
                        } else {
                            let _participants = this.batchParticipants.filter(
                                (participant) => participant.userId === management.clientId
                            );
                            if (_participants.length > 0) {
                                management['name'] = _participants[0].name;
                                // standardize property
                                management['compLogo'] = _participants[0].imageUrl;
                                // management["imageUrl"] = _participants[0].imageUrl;
                            }
                        }
                    });
                    if (res.data.length > 0) {
                        this.mentorManagement = res.data.sort((a, b) => {
                            if (
                                !this.utilitySrv.IsNullOrEmpty(b.name) &&
                                !this.utilitySrv.IsNullOrEmpty(a.name)
                            ) {
                                return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                            }
                        });

                        if (this.currentUser.IsAdmin === 1 || this.isCurrentUserIsManager) {
                            this.mentorManagement = this.mentorManagement.sort((a, b) => {
                                if (a.consultantCount != null && b.consultantCount == null) return -1;
                                if (a.consultantCount == null && b.consultantCount != null) return 1;
                                return 0;
                            });

                        } else {
                            this.mentorManagement = this.mentorManagement.filter(startup => startup.consultantCount != null)
                        }
                        // console.log("TEAMS222 : CONSULTANTS  : Tagged Projects", this.mentorManagement)

                    } else {
                        this.mentorManagement = [];
                    }
                }
            },
            (error) => console.log(error),
            () => {}
        );
    }

    getMentorManagement(batchId, clientId, clientType) {
        const params = {
            batchId: batchId,
            clientId: clientId,
            clientType: clientType,
        };

        this.institutionSrv.getMentorManagement(params).subscribe(
            (res) => {
                if (res['result'] === 'successful') {
                    res.data.map((management) => {
                        management['name'] = this.mentors.filter(
                            (mentor) => mentor['id'] === management.masterId
                        )[0]['name'];
                        management['compLogo'] = this.mentors.filter(
                            (mentor) => mentor['id'] === management.masterId
                        )[0]['compLogo'];
                    });
                    if (res.data.length > 0) {
                        this.mentorManagement = res.data.sort((a, b) => {
                            return b.name.toUpperCase() < a.name.toUpperCase() ? 1 : -1;
                        });
                        // console.log("TEAMS : CONSULTANTS  : Tagged Projects", this.mentorManagement)
                    } else {
                        this.mentorManagement = [];
                    }
                }
            },
            (error) => console.log(error),
            () => {}
        );
    }

    initalCompany(batchId) {
        this.institutionSrv.getInvitedOrg(batchId).subscribe(
            (res) => {
                if (res['result'] !== 'failed') {
                    this.batchCompany = [...res['projects'], ...res['funders'], ...res['experts']];
                    this.startups = res['projects'];
                    this.mentors = res['experts'];
                }
            },
            (error) => console.error('getInvitedOrg :', error),
            () => (this.loading = false)
        );
    }

    getType(type: string) {
        if (type === '1') {
            return 'project';
        } else if (type === '2') {
            return 'funder';
        } else if (type === '3') {
            return 'institution';
        } else if (type === '4') {
            return 'professionalservice';
        }
    }

    getStaffName(type: string) {
        if (type === '1') {
            this.currentOrgStaffRoleId = '9';
            return 'staff';
        } else if (type === '2') {
            this.currentOrgStaffRoleId = '10';
            return 'funderstaff';
        } else if (type === '3') {
            this.currentOrgStaffRoleId = '11';
            return 'institutionstaff';
        } else if (type === '4') {
            this.currentOrgStaffRoleId = '12';
            return 'expertstaff';
        }
    }

    onToggleMentorshipList(event, type) {
        if (type === 'batch') {
            this.inviteTitle = 'Member';
        } else if (type === 'mentor') {
            this.inviteTitle = 'Mentor';
            this.mentorshipList = this.startups;
        } else if (type === 'startup') {
            this.inviteTitle = 'Start Up';
        } else if (type === 'mentee') {
            this.inviteTitle = 'Mentee';
            this.mentorshipList = this.mentors;
        }
        this.mentorType = '';
        if (type !== 'close') {
            this.mentorType = type;
        } else {
            this.mentorType = '';
            this.notAllowTargetScrollable(document.body, false);
        }
        this.invitedType = '';

        //this.selectedTeam = null;
        if (type !== 'close') {
            this.invitedType = type;
        }
        this.isOpenMentorshipList = !this.isOpenMentorshipList;
        const childComponentOne = this.modalcontainer.nativeElement.getElementsByClassName(
            'modal-container'
        )[0];
        this.renderer.setStyle(childComponentOne, 'width', `665px`);
        this.notAllowTargetScrollable(document.body, this.isOpenMentorshipList);
    }

    onAdded(event) {
        this.getMenteeManagement(this.batchId, this._currentOrg.id, 'organization');
    }

    onRemove(obj, type) {
        this.isOpenDialog = !this.isOpenDialog;
        this.content = `Are you sure you want to remove <b>${obj.name}</b> from ${type}s?`;
        this.removeTarget = { obj, type };
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
    }

    onRemoveMentees({ id, name }) {
        this.institutionSrv.removeMentorManagement(id, this.currentUser.id).subscribe((res) => {
            if ((res['result'] = 'successful')) {
                this.showActionMsg(name);
                this.mentorManagement = this.mentorManagement.filter(
                    (mentor) => mentor['id'] !== id
                );
            }
        });
    }

    onSubmit() {
        this.isOpenDialog = !this.isOpenDialog;
        this.notAllowTargetScrollable(document.body, this.isOpenDialog);
        this.onRemoveMentees(this.removeTarget.obj);
    }

    showActionMsg(msg) {
        this.toastRef = this.toastr.show(`${msg} has been successfully removed.`, 'Success', {
            disableTimeOut: false,
            tapToDismiss: true,
            toastClass: 'toast border-blue',
            closeButton: false,
        });
    }

    onCloseDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        document.body.removeAttribute('style');
    }
}
