import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilityService } from '@common/services';

@Component({
  selector: 'lab-dialog-link',
  templateUrl: './dialog-link.component.html',
  styleUrls: ['./dialog-link.component.scss']
})
export class DialogLinkComponent implements OnInit {
  @Input() url: string = '';
  @Input() show: string;

  @Output() close = new EventEmitter();
  @Output() submit = new EventEmitter();

  constructor(
    public utilitySrv: UtilityService,
  ) { }

  ngOnInit() {
  }

  onClick() {
    this.close.emit();
  }

  onSubmit(url: string) {
    this.submit.emit(url);
  }

  onChange(e: any) {
    this.url = e.target.value
  }

}
