import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class PaypalService {
    items: Observable<any[]>;
    admins: string[] = [];

    constructor(
        private http: HttpClient) {

    }

    getAll() {
        return this.http.get(`${environment.apiUrl}/paypalPayment/getAll`, {});
    }

    insert(data) {
        return this.http.post(`${environment.apiUrl}/paypalPayment/createPaypalPayment`, data);
    }

}
