import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  InstitutionService,
  UtilityService,
  ConfigService
} from '@common/services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-modal-presentationblock-edit-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        right: '0px'
      })),
      state('close', style({
        opacity: 0,
        right: '-50px'
      })),
      transition('open => close', [
        animate('.3s')
      ]),
      transition('close => open', [
        animate('.3s')
      ])
    ])
  ],
  templateUrl: './modal-presentationblock-edit-list.component.html',
  styleUrls: ['./modal-presentationblock-edit-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalPresentationBlockEditListComponent implements OnInit {
  @Input() open: boolean;
  @Input() currentUser: any;
  //type: projectTeam, mentor, programmer 
  // noted projectTeam cannot rating
  @Input() type: string;
  @Input() organizationType: string;
  @Input() programId: string;


  loading: boolean = true;
  searchText = '';

  public _program: any;
  @Input() set program(object) {

    if (object) {
      this._program = object;
      this.initialProgram(this._program.id);
    }
  }

  @Output() close = new EventEmitter();

  public isOpenDialog: boolean = false;

  isOpenNewCriteriaDialog: boolean = false;
  isOpenNewSubmissionDialog: boolean = false;
  isOpenDeleteDialog: boolean = false;

  toastRef;
  count: number = 0;

  deliverables: any[] = [];
  //submission
  programs: any;
  deliverable: any;
  selectedDeliverable: any;


  tabs: Array<any> = [
    { id: 1, text: 'Submission' },
    { id: 2, text: 'Criteria' }
  ]
  currentTab = 1;

  constructor(
    private toastr: ToastrService,
    private institutionSrv: InstitutionService,
    private utilitySrv: UtilityService,
    private cdf: ChangeDetectorRef,
    private configSrv: ConfigService
  ) { }

  ngOnInit() {

  }

  initialProgram(id) {
    this.loading = true;
    this.institutionSrv.getPresentationBlockByProgramId(id).then(res => {
      if (res['result'] == "successful") {
        this.deliverables = res['data'];
      }
    }).catch(error => {
      console.log("error", error);
    }).finally(() => {
      this.loading = false;
      this.cdf.detectChanges();
    });
  }

  // close dialog
  onCloseDailog() {
    if (this.isOpenDeleteDialog) return this.onToggleDialogWarning();
    if (this.isOpenNewCriteriaDialog) return this.onNewCriteria();
    if (this.isOpenNewSubmissionDialog) return this.onNewSubmission();
  }

  onDeleteSubmission(event, item) {
    this.selectedDeliverable = item;
    // this.deleteDeliverable(item.id);
    this.isOpenDeleteDialog = true;

  }

  onConfirmDeleteSubmission(event) {
    this.deleteDeliverable(this.selectedDeliverable.id);
    this.isOpenDeleteDialog = false;
  }
  onSaveSubmission(event, item) {
    const deliverable = {
      id: item.id,
      columns: [
        "description",
        "startDate",
        "deadline",
      ],
      attribute: [
        event.description,
        event.startDate,
        event.deadline
      ],
      userId: this.currentUser.id,
    };

    this.institutionSrv.updateDeliverable(deliverable).subscribe(
      (res) => {
        if (res["result"] == "successful") {
          this.initialProgram(this._program.id);
        } else {

        }
      },
      (error) => console.log(error),
      () => {

      }
    );

  }
  onToggleDialogWarning() {
    this.isOpenDeleteDialog = !this.isOpenDeleteDialog;
    this.selectedDeliverable = null;
  }

  onCreate() {
    if (this.currentTab === 1) return this.onNewSubmission();
    if (this.currentTab === 2) return this.onNewCriteria()
  }

  onNewCriteria() {
    this.isOpenNewCriteriaDialog = !this.isOpenNewCriteriaDialog;
  }

  onNewSubmission() {
    this.isOpenNewSubmissionDialog = !this.isOpenNewSubmissionDialog;
  }

  showActionMsg(msg) {
    this.toastRef = this.toastr.show('Successfully updated', 'Link', {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-blue',
      closeButton: false,
    });

  }


  onBatchExpand(event: any) {
    const target = event.target;
    target.parentNode.classList.toggle('collapse');

    const isExpaned = target.parentNode.classList.contains('collapse')
    target.parentNode.setAttribute('aria-expanded', !isExpaned);
    target.nextSibling.setAttribute('aria-hidden', isExpaned);

  }

  onTabsChange(x: any) {
    this.currentTab = x;
    if (this.isOpenNewSubmissionDialog) return this.isOpenNewSubmissionDialog = !this.isOpenNewSubmissionDialog;
    if (this.isOpenNewCriteriaDialog) return this.isOpenNewCriteriaDialog = !this.isOpenNewCriteriaDialog;
  }

  onCriteriaSubmit(event) {


    const params = {
      programId: this._program.id,
      description: event.name,
      submissionTypeId: event.typeId,
      submissiontype: 'NA',
      "type": "1",
      startDate: "null",
      deadline: "null",
      userId: this.currentUser.id
    };

    this.institutionSrv.createDeliverable(params).subscribe(
      (res) => {

        if (res["result"] == "successful") {
          this.initialProgram(this._program.id);
          /*   this.deliverables.push(params); */
          this.isOpenNewCriteriaDialog = false;
          this.cdf.detectChanges();

        } else {
          console.error("createDeliverable failed")
        }
      },
      (error) => console.log(error),
      () => {

      }
    );
  }

  onSubmissionSubmit(event) {

    const params = {
      programId: this._program.id,
      description: event.name,
      submissionTypeId: event.typeId,
      submissionType: event.type,
      "type": "1",
      startDate: "null",
      deadline: "null",
      userId: this.currentUser.id
    };

    this.institutionSrv.createDeliverable(params).subscribe(
      (res) => {
        if (res["result"] == "successful") {

          /*  this.deliverables.push(params); */
          this.initialProgram(this._program.id);
          this.isOpenNewSubmissionDialog = false;
          this.cdf.detectChanges();

        } else {
          console.error("createDeliverable failed")
        }
      },
      (error) => console.log(error),
      () => {

      }
    );


  }

  deleteDeliverable(deliverableId) {

    let _deliverableId = deliverableId;
    this.institutionSrv.deleteDeliverable(deliverableId, '', '').subscribe(
      (res) => {
        if (res["result"] == "successful") {
          this.deliverables = this.deliverables.filter(item => item.id != _deliverableId);
          this.cdf.detectChanges();
        } else {

        }
      },
      (error) => console.log(error),
      () => {

      }
    );

  }

}
