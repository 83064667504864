import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class GoogleService {

    constructor(
        private http: HttpClient
    ) { }

    testapi(url) {
        return this.http.post<any>(`${environment.apiUrl}/institution/testgoogleapi`,
          { url: url });
      }

    onGetGoogleUrl() {
        return this.http.get<any>(`${environment.apiUrl}/google/urlGoogle`);
    }

    getToken(code, uid) {
        return this.http.post<any>(`${environment.apiUrl}/google/getGoogleAccountFromCode`,
            { code: code, uid: uid });
    }

    getTokenByUid(uid) {
        return this.http.post<any>(`${environment.apiUrl}/google/getTokenByUid`,
            { uid: uid });
    }

    createGoogleEvent(token, event) {
        console.log('createGoogleEvent')
        return this.http.post<any>(`${environment.apiUrl}/google/createGoogleEvent`,
            { token: token, event: event });
    }


}