import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoleService, UserService, UtilityService } from '@common/services';
import { AuthenticationService } from '../services';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';

@Injectable()
export class AuthGuard implements CanActivate {
    roles: string[] = [];
    permissions: string[] = [];
    haveToken: boolean;

    constructor(
        private router: Router,
        private authSrv: AuthenticationService,
        private utilitySrv: UtilityService,
        private userSrv: UserService,
        private roleSrv: RoleService,
        private ngxPermsissionsService: NgxPermissionsService,
        private ngxRoleService: NgxRolesService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean | Promise<boolean> {
        const currentUserString = localStorage.getItem('currentUser');
        const currentUser = currentUserString + '';
        const user = JSON.parse(currentUser);

        if (user) {
            this.roleSrv.getRoleByUid(user.id).then((res) => {});

            this.roleSrv.getPermissionsByUid(user.id).then((res) => {
                if (res['data'] !== null) {
                    const roleArray = res.data;
                    roleArray.forEach((role) => {
                        this.roles.push(role.permissionName);
                    });

                    this.ngxPermsissionsService.loadPermissions(this.roles);
                }
            });
        }

        if (!this.utilitySrv.IsNullOrEmpty(next.queryParams['type'])) {
            if (next.queryParams['type'] == 'public') {
                return true;
            }
        }

        if (!!localStorage.getItem('token')) {
            return this.authSrv.verifyToken(localStorage.getItem('token')).pipe(
                map((res) => {
                    if (res['data']) {
                        return true;
                    } else {
                        localStorage.clear();
                        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                        return false;
                    }
                })
            );
        } else {
            localStorage.clear();
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }

    canAdminActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (!!localStorage.getItem('token')) {
            return this.authSrv.verifyToken(localStorage.getItem('token')).pipe(
                map((res) => {
                    let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

                    if (res['data'] && currentUser.isAdmin == '1') {
                        return true;
                    } else {
                        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
                        return false;
                    }
                })
            );
        } else {
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}

@Injectable()
export class AuthAdminGuard implements CanActivate {
    haveToken: boolean;

    constructor(private router: Router, private authSrv: AuthenticationService) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        if (!!localStorage.getItem('token')) {
            return this.authSrv.verifyToken(localStorage.getItem('token')).pipe(
                map((res) => {
                    let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
                    if (res['data'] && currentUser.IsAdmin == '1') {
                        return true;
                    } else {
                        this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
                        return false;
                    }
                })
            );
        } else {
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
