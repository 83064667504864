import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lab-dialog-buy-plans',
  templateUrl: './dialog-buy-plans.component.html',
  styleUrls: ['./dialog-buy-plans.component.scss']
})
export class DialogBuyPlansComponent implements OnInit {
  @Input() show: string;
  @Output() close = new EventEmitter();
  @Output() buyPlan = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.close.emit();
  }

  onSubmit() {
    this.buyPlan.emit();
  }


}
