import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {
  InstitutionService, ResourcesService, UtilityService,
} from '@common/services';
import {
  FormGroup,
  FormBuilder,
  Validators
} from '@angular/forms';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lab-dialog-deliverable',
  templateUrl: './dialog-deliverable.component.html',
  styleUrls: ['./dialog-deliverable.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogDelivertableComponent implements OnInit {
  @Input() title: string = '';
  @Input() field: string = '';
  @Input() value: string = '';
  @Input() show: string;
  @Input() programId: string;
  @Input() data;

  submissionType: string = "";

  @Output() close = new EventEmitter();
  @Output() addPhase = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();

  // for conversation
  submissionTypes: any;
  phaseForm: FormGroup;
  currentUser: any;

  submitted: boolean = false;

  maxStartDate;

  slidesUrl: any;
  spreadsheetUrl: any;

  slidesFileSizeExceed = false;
  spreadFileSizeExceed = false;

  isOpenResources: boolean = false;
  selectedSlidesResource;
  selectedSpreadResource;
  slidesResource = false;
  spreadsheetResource = false;
  type;

  slidesTitle;
  spreadsheetTitle;

  isOpenUpload: boolean = false;
  batchId: string = "";

  constructor(
    private fb: FormBuilder,
    private institutionSrv: InstitutionService,
    private utilitySrv: UtilityService,
    private resourcesSrv: ResourcesService,
    private route: ActivatedRoute
  ) {

    this.institutionSrv.getInstitutionType().then(
      res => {
        if (res.result === 'successful') {

          if (res["submissionType"]) {
            this.submissionTypes = res["submissionType"];
          }
        }
      }
    ).catch(error => {
      console.log('getInstitutionType Error: ', error);
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

    const urlRegx = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.phaseForm = this.fb.group({
      title: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      slidesName: [''],
      slidesUrl: [''],
      slidesFile: [null],
      slidesResId: [''],
      spreadsheetName: [''],
      spreadsheetUrl: [''],
      spreadsheetFile: [null],
      spreadsheetResId: [''],
      link: ['', Validators.pattern(urlRegx)],
      linkTitle: [''],
      publish: [false],
      type: [1, Validators.required],
    });

    this.route.queryParams.subscribe(params => {
      this.batchId = params['batchId'];
      // console.log(this.batchId);
    });

  }

  ngOnInit() {
    if (this.data) {
      const _startDate = moment(Number(this.data.startDate)).format("YYYY-MM-DD");
      const _endDate = moment(Number(this.data.endDate)).format("YYYY-MM-DD");
      this.maxStartDate = moment(Number(this.data.endDate)).format("YYYY-MM-DD");

      if (this.data.slidesName) {
        this.slidesTitle = this.data.slidesName;
      } else if (this.data.sResTitle) {
        this.slidesTitle = this.data.sResTitle;
        this.phaseForm.patchValue({
          slidesResId: this.data.slidesresourceid
        });
        this.resourcesSrv.getResourceById(this.data.slidesresourceid).then(res => {
          if (res['result'] === 'successful') {
            this.selectedSlidesResource = res['data'][0];
          }
        });
      }
      if (this.data.spreadsheetName) {
        this.spreadsheetTitle = this.data.spreadsheetName;
      } else if (this.data.ssResTitle) {
        this.spreadsheetTitle = this.data.ssResTitle;
        this.phaseForm.patchValue({
          spreadsheetResId: this.data.spreadsheetresourceid
        });
        this.resourcesSrv.getResourceById(this.data.spreadsheetresourceid).then(res => {
          if (res['result'] === 'successful') {
            this.selectedSpreadResource = res['data'][0];
          }
        });
      }

      this.phaseForm.patchValue({
        title: this.data.name,
        startDate: _startDate.toString(),
        endDate: _endDate.toString(),
        publish: this.data.publish,
        slidesName: this.slidesTitle,
        spreadsheetName: this.spreadsheetTitle
      });
      if (!this.utilitySrv.IsNullOrEmpty(this.data.link)) {
        this.phaseForm.patchValue({
          link: this.data.link
        });
      }
      if (!this.utilitySrv.IsNullOrEmpty(this.data.linkTitle)) {
        this.phaseForm.patchValue({
          linkTitle: this.data.linkTitle
        });
      }
    } else {
      this.phaseForm.reset();
      this.phaseForm.patchValue({
        publish: false,
        type: 1
      });

    }

  }

  get f() { return this.phaseForm.controls; }

  setMaxStartDate() {
    this.maxStartDate = moment(this.phaseForm.value.endDate).format('YYYY-MM-DD');
  }

  onClose() {
    this.close.emit();
  }

  handleChecked(event) {
    if (event === true) {
      this.phaseForm.value.publish = 1;
    } else if (event === false) {
      this.phaseForm.value.publish = 0;
    }

  }

  onSubmit() {

    this.submitted = true;

    if (this.phaseForm.invalid) {
      for (let el in this.phaseForm.controls) { // help to check which control have error
        if (this.phaseForm.controls[el].errors) {
          console.log(el);
        }
      }
      return;
    }

    this.handleChecked(this.phaseForm.value.publish);

    const values = this.phaseForm.value;

    this.addPhase.emit({
      name: values.title,
      startDate: values.startDate.getTime(),
      endDate: values.endDate.getTime(),
      publish: values.publish,
      uid: this.currentUser.id,
      type: values.type,
      slidesName: values.slidesName,
      slidesUrl: values.slidesUrl,
      slidesFile: values.slidesFile,
      slidesResId: values.slidesResId,
      spreadsheetName: values.spreadsheetName,
      spreadsheetUrl: values.spreadsheetUrl,
      spreadsheetFile: values.spreadsheetFile,
      spreadsheetResId: values.spreadsheetResId,
      link: values.link,
      linkTitle: values.linkTitle,
      selectedSlidesRes: this.selectedSlidesResource,
      selectedSpreadRes: this.selectedSpreadResource
    });

  }

  onChange(e: any) {
    this.value = e.target.value;
  }


  GetSubmissionTypeId(value) {
    try {
      let _types = this.submissionTypes.filter(type => type['keyname'] == value);
      if (_types.length > 0) {
        return _types[0]['id'];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  onChangeType(type) {
    this.phaseForm.controls['submissionType'].setValue(type);
    this.submissionType = type;
  }

  async onUpdate() {
    this.submitted = true;

    if (this.phaseForm.invalid) {
      for (let el in this.phaseForm.controls) { // help to check which control have error
        if (this.phaseForm.controls[el].errors) {
          console.log(el);
        }
      }
      return;
    }

    const values = this.phaseForm.value;

    this.data.name = values.title;
    this.data.startDate = new Date(moment(values.startDate).format('YYYY-MM-DD')).getTime();
    this.data.endDate = new Date(moment(values.endDate).format('YYYY-MM-DD')).getTime();
    this.data.publish = values.publish;

    if (this.slidesResource) { // new resource selected
      this.data.slidesName = null;
      this.data['slidesFile'] = null;
      if (!this.data.slidesresourceid && values.slidesResId) { // add new resource
        this.data.slidesresourceid = values.slidesResId;
        this.data['newSlideResource'] = true;
      } else if (this.data.slidesresourceid && values.slidesResId) { // update new resource
        this.data['newSlideResourceId'] = values.slidesResId;
        this.data['updateSlideResource'] = true;
      }
    } else if (values.slidesFile && !this.slidesResource) { // new upload
      this.data.slidesName = values.slidesName;
      this.data['slidesFile'] = values.slidesFile;
      // this.data['removeSlideResource'] = true;
      this.data['newUploadSlideResource'] = true;
    } else if (this.data.sResTitle && !values.slidesName) { // remove file
      this.data.slidesName = null;
      this.data['slidesFile'] = null;
      this.data.slidesUrl = '';
      this.data['removeSlideResource'] = true;
    } else if (this.data.slidesresourceid && !values.slidesResId) { // remove resource
      this.data['removeSlideResource'] = true;
    }

    if (this.data.slidesresourceid && (values.slidesName !== this.slidesTitle) && !this.data['removeSlideResource'] && !this.data['updateSlideResource']) {
      await this.resourcesSrv.getResourceById(this.data.slidesresourceid).then(res => {
        if (res['result'] === 'successful') {
          this.data['selectedSlidesRes'] = res['data'][0];
          this.data['newSlidesResName'] = values.slidesName;
        }
      });
    } else if (this.data['updateSlideResource']) {
      await this.resourcesSrv.getResourceById(this.data['newSlideResourceId']).then(res => {
        if (res['result'] === 'successful') {
          this.data['selectedSlidesRes'] = res['data'][0];
          this.data['newSlidesResName'] = values.slidesName;
        }
      });
    } else {
      this.data['selectedSlidesRes'] = null;
    }

    if (this.spreadsheetResource) { // new resource selected
      this.data.spreadsheetName = null;
      this.data['spreadsheetFile'] = null;
      if (!this.data.spreadsheetresourceid && values.spreadsheetResId) { // add new resource
        this.data.spreadsheetresourceid = values.spreadsheetResId;
        this.data['newSpreadsheetResource'] = true;
      } else if (this.data.spreadsheetresourceid && values.spreadsheetResId) { // update new resource
        this.data['newSpreadsheetResourceId'] = values.spreadsheetResId;
        this.data['updateSpreadsheetResource'] = true;
      }
    } else if (values.spreadsheetFile && !this.spreadsheetResource) { // new upload
      this.data.spreadsheetName = values.spreadsheetName;
      this.data['spreadsheetFile'] = values.spreadsheetFile;
      // this.data['removeSpreadsheetResource'] = true;
      this.data['newUploadSpreadResource'] = true;
    } else if (this.data.ssResTitle && !values.spreadsheetName) { // remove file
      this.data.spreadsheetName = null;
      this.data['spreadsheetFile'] = null;
      this.data.spreadsheetUrl = '';
      this.data['removeSpreadsheetResource'] = true;
    } else if (this.data.spreadsheetresourceid && !values.spreadsheetResId) {// remove resource
      this.data['removeSpreadsheetResource'] = true;
    }

    if (this.data.spreadsheetresourceid && (values.spreadsheetName !== this.spreadsheetTitle) && !this.data['removeSpreadsheetResource'] && !this.data['updateSpreadsheetResource']) {
      await this.resourcesSrv.getResourceById(this.data.spreadsheetresourceid).then(res => {
        if (res['result'] === 'successful') {
          this.data['selectedSpreadRes'] = res['data'][0];
          this.data['newSpreadResName'] = values.spreadsheetName;
        }
      });
    } else if (this.data['updateSpreadsheetResource']) {
      await this.resourcesSrv.getResourceById(this.data['newSpreadsheetResourceId']).then(res => {
        if (res['result'] === 'successful') {
          this.data['selectedSpreadRes'] = res['data'][0];
          this.data['newSpreadResName'] = values.spreadsheetName;
        }
      });
    } else {
      this.data['selectedSpreadRes'] = null;
    }

    this.data.linkTitle = values.linkTitle;
    this.data.link = values.link;

    this.update.emit(this.data);
  }

  onDetectImg(event, imagePosition) {
    this.loadImage(event.target.files, imagePosition);
  }

  loadImage(files, type = '1') {
    if (files.length === 0) {
      return;
    }

    if (files[0].size > 5000000) {
      if (type === '1') {
        this.slidesFileSizeExceed = true;
      } else if (type === '2') {
        this.spreadFileSizeExceed = true;
      }
      return
    } else {
      if (type === '1') {
        this.slidesFileSizeExceed = false;
      } else if (type === '2') {
        this.spreadFileSizeExceed = false;
      }
    }

    let reader = new FileReader();

    if (type === '1') {
      this.phaseForm.patchValue({
        slidesName: files[0].name,
        slidesFile: files[0],
        slidesUrl: '',
        slidesResId: ''
      });
      this.phaseForm.get('slidesFile')!.updateValueAndValidity();
      this.slidesResource = false;
    }

    if (type === '2') {
      this.phaseForm.patchValue({
        spreadsheetName: files[0].name,
        spreadsheetFile: files[0],
        spreadsheetUrl: '',
        spreadsheetResId: ''
      });
      this.phaseForm.get('spreadsheetFile')!.updateValueAndValidity();
      this.spreadsheetResource = false;
    }

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (type === '1') {
        if (files[0].size > 5000000) {
          // this.filereaderErrorHeader = true;
        } else {
          this.slidesUrl = reader.result;
          // this.filereaderErrorHeader = false;
          // this.noImageUploaded = false;
        }
      } else if (type === '2') {
        if (files[0].size > 5000000) {
          // this.filereaderErrorAdditional = true;
        } else {
          this.spreadsheetUrl = reader.result;
          // this.filereaderErrorAdditional = false;
          // this.noImageUploaded = false;
        }
      }
    };
  }

  onRemoveFile(type) {
    if (type === '1') {
      this.phaseForm.patchValue({
        slidesName: null,
        slidesUrl: null,
        slidesFile: null,
        slidesResId: ''
      });
      this.slidesResource = false;
    } else if (type === '2') {
      this.phaseForm.patchValue({
        spreadsheetName: null,
        spreadsheetUrl: null,
        spreadsheetFile: null,
        spreadsheetResId: ''
      });
      this.spreadsheetResource = false;
    }
  }

  onOpenResoucesDialog(type) {
    this.isOpenResources = !this.isOpenResources;
    this.type = type;
  }

  onConfirmResource(value) {
    if (this.type === 'slides') {
      this.selectedSlidesResource = value;
      this.phaseForm.patchValue({
        slidesName: this.selectedSlidesResource.title,
        slidesFile: null,
        slidesResId: this.selectedSlidesResource.id
      });
      this.slidesResource = true;
    } else if (this.type === 'spreadsheet') {
      this.selectedSpreadResource = value;
      this.phaseForm.patchValue({
        spreadsheetName: this.selectedSpreadResource.title,
        spreadsheetFile: null,
        spreadsheetResId: this.selectedSpreadResource.id
      });
      this.spreadsheetResource = true;
    }

    this.onOpenResoucesDialog(this.type);
  }

  onOpenUpload(type) {
    this.type = type;
    this.isOpenUpload = true;
  }

  onCloseUpload() {
    this.isOpenUpload = false;
  }

  onAddResource(data) {

    let formData = new FormData();

    formData.append('batchId', this.batchId);

    if (data.title) {
      formData.append('title', data.title);
    } else {
      formData.append('title', 'null');
    }

    if (data.link) {
      formData.append('link', data.link);
    } else {
      formData.append('link', '');
    }

    if (data.description) {
      formData.append('description', data.description);
    } else {
      formData.append('description', '');
    }

    if (data.file) {
      formData.append('resourceFile', data.file);
      formData.append('filesize', data.file.size);
    } else {
      formData.append('resourceFile', '');
      formData.append('filesize', '');
    }

    formData.append('uid', this.currentUser.id);

    this.resourcesSrv.addResource(formData).then(
      (res) => {
        if (res["result"] == "successful") {
          this.onCloseUpload();
          if (this.type === 'slides') {
            this.selectedSlidesResource = {
              id: res['resourceId'],
              title: data.title
            };
            this.phaseForm.patchValue({
              slidesName: data.title,
              slidesFile: null,
              slidesResId: res['resourceId']
            });
            this.slidesResource = true;
          } else if (this.type === 'spreadsheet') {
            this.selectedSpreadResource = {
              id: res['resourceId'],
              title: data.title
            };
            this.phaseForm.patchValue({
              spreadsheetName: data.title,
              spreadsheetFile: null,
              spreadsheetResId: res['resourceId']
            });
            this.spreadsheetResource = true;
          }
        } else {
          console.error("addResource failed");
        }
      },
      (error) => console.log(error)
    );
  }

}
