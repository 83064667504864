import { User } from "@common/models";

export class Recommendation {
    id: string;
    date: string;
    requestedRoles: string;
    requesterOrgId: string;
    requester: User;
    requestDate: string;
    notiStatus = 0;
    status1 = 0;
    status2 = 0;
    status3 = 0;
    org1: User;
    org2: User;
    org3: User;
    org1Name: string;
    org2Name: string;
    org3Name: string;
}
