import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'itemFilter',
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], searchText: string, label: string, canCreate?: boolean): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        if (searchText === '' || searchText === null) {
            return [];
        }

        const filteredValues = items.filter(function Islable(user) {
            if (user[label] === undefined) {
                return false;
            } else {
                return user[label].toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            }
        });
        return canCreate && filteredValues.length === 0 ? ['No Data'] : filteredValues;
    }
}
