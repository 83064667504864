import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class PaymentService {

    constructor(
        private http: HttpClient) {
    }

    getAllPurchase() {
        return this.http.get(`${environment.apiUrl}/purchase/getAllPurchase`, {

        });
    }

    checkPurchase(uid, productId) {
        return this.http.post(`${environment.apiUrl}/purchase/checkPurchase`, {
            uid: uid, productId
        });
    }

    async purchase(purchase) {
        return await this.http.post(`${environment.apiUrl}/purchase/purchase`, {
            data: purchase
        }).toPromise();
    }

    getPurchaseByUid(uid) {
        return this.http.post(`${environment.apiUrl}/purchase/getPurchaseByUid`, {
            uid: uid
        });
    }

    async getPurchaseByProductIdNOrgId(productId, orgId) {
        return await this.http.post(`${environment.apiUrl}/purchase/getPurchaseByProductIdNOrgId`, {
            productId: productId, orgId: orgId
        }).toPromise();
    }

    getPurchaseByIdNOrgId(id, orgId) {
        return this.http.post(`${environment.apiUrl}/purchase/getPurchaseByIdNOrgId`, {
            id: id, orgId: orgId
        });
    }

    async completePurchase(purchaseId, stripeId, promoCode, promoId, totalAmt) {
        return await this.http.post(`${environment.apiUrl}/purchase/completePurchase`, {
            purchaseId: purchaseId,
            stripeId: stripeId,
            promoCode: promoCode,
            promoId: promoId,
            totalAmt: totalAmt
        }).toPromise();
    }


    cancelPurchase(purchaseId) {
        return this.http.post(`${environment.apiUrl}/purchase/cancelPurchase`, {
            purchaseId: purchaseId
        });
    }

    completePurchaseWidget(purchaseId) {
        return this.http.post(`${environment.apiUrl}/purchase/completePurchaseWidget`, {
            purchaseId: purchaseId
        });
    }

    updatePurchaseOrgId(purchaseId, orgId, orgName, orgTypeId) {
        return this.http.post(`${environment.apiUrl}/purchase/updatePurchaseOrgId`, {
            purchaseId: purchaseId, orgId: orgId, orgName: orgName, orgTypeId: orgTypeId
        });
    }

    getPurchaseById(id) {
        return this.http.post(`${environment.apiUrl}/purchase/getPurchaseById`, {
            id: id
        });
    }
}
