import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { BatchesService } from '@modules/workspace/services';
import { 
  DocViewerService,
} from '@common/services';

@Component({
  selector: 'lab-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./../../styles.scss', './programs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ProgramComponent implements OnInit {

  public _batches: any[] = [];
  public _displayBatches: any[] = [];
  @Input() set batches(objects) {
    if (objects && objects.length > 0) {
      this._batches = objects;
    }
  }

  @Input() select: string;

  @Output() selected = new EventEmitter<any>();

  @ViewChild('filterList') filterList: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;

  searchText: string = "";
  currentUser: string;
  uid: any;

  constructor(
    public batchesService: BatchesService,
    private docSrv: DocViewerService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.uid = this.currentUser['id'];
  }

  ngOnInit() {
  }

  handleSelected(item: any) {
    this.docSrv.docViewStatus(false);
    this.batchesService.selected(item);
    this.selected.emit(item);
  }

  // for clear search event
  onClearSearchText() {
    this.searchText = '';
    this.searchInputRef.nativeElement.focus();
  }

}
