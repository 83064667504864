import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ConfigService, UtilityService
} from "@common/services"

@Component({
  selector: 'lab-dialog-upload',
  templateUrl: './dialog-upload.component.html',
  styleUrls: ['./dialog-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogUploadComponent implements OnInit {
  @Input() show: string;
  @Input() data;

  @Output() close = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() update = new EventEmitter();

  uploadForm: FormGroup;

  prefix: string = '';
  selectedFile;
  fileName: string = '';

  submitted: boolean = false;
  invalidResource: boolean = false;

  removeFile: boolean = false;

  constructor(
    private fb: FormBuilder,
    private configSrv: ConfigService,
    public utilitySrv: UtilityService
  ) {

    const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

    this.uploadForm = this.fb.group({
      title: ['', Validators.required],
      link: ['', Validators.pattern(urlRegex)],
      description: ['']
    });

    this.prefix = this.configSrv.defaultAssetUrl;
  }

  ngOnInit() {

    if (this.data) {
      this.uploadForm.patchValue({
        title: this.data.title
      });
      if (this.data.link) {
        this.uploadForm.patchValue({
          link: this.data.link
        });
      }
      if (this.data.description) {
        this.uploadForm.patchValue({
          description: this.data.description
        });
      }
      if (this.data.filename) {
        this.fileName = this.data.filename;
      }
    }
  }

  get f() { return this.uploadForm.controls; }

  onClick() {
    this.close.emit();
  }

  handleUpload(event: any) {
    if (event.target.files[0]) {
      this.selectedFile = event.target.files[0];
      this.fileName = this.selectedFile.name;
      this.invalidResource = false;
      this.removeFile = false;
    }
  }

  onRemoveFile() {
    this.selectedFile = null;
    this.fileName = '';
    this.removeFile = true;
  }

  onSubmit() {

    this.submitted = true;

    if (this.uploadForm.invalid) {
      for (let el in this.uploadForm.controls) { // help to check which control have error
        if (this.uploadForm.controls[el].errors) {
          console.log(el);
        }
      }
      return;
    }

    if (!this.uploadForm.value.link && !this.selectedFile) {
      this.invalidResource = true;
      return
    } else {
      this.invalidResource = false;
    }

    if (this.selectedFile) {
      this.uploadForm.value.link = '';
    }
    this.add.emit({
      title: this.uploadForm.value.title,
      link: this.uploadForm.value.link,
      description: this.uploadForm.value.description,
      file: this.selectedFile
    });
  }

  onUpdate() {

    this.submitted = true;

    if (this.uploadForm.invalid) {
      for (let el in this.uploadForm.controls) { // help to check which control have error
        if (this.uploadForm.controls[el].errors) {
          console.log(el);
        }
      }
      return;
    }

    if (!this.uploadForm.value.link && !this.selectedFile && !this.data.filepath) {
      this.invalidResource = true;
      return
    } else {
      this.invalidResource = false;
    }
    this.update.emit({
      title: this.uploadForm.value.title,
      link: this.uploadForm.value.link,
      description: this.uploadForm.value.description,
      file: this.selectedFile,
      removeFile: this.removeFile
    });
  }

}
