
export class Widget {
    id: string;
    key: string;
    name: string;
    description: string;
    iconPath: string;
    path: string;
    v3IconPath: string;
    v3Path: string;
    class: string;
    type: number; // 0: free 1: paid
    status: boolean; // 0: offline 1: online
    mode = 'normal'; // normal,edit
    price: number;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    roles: any;
    productId: string;
    note: string;
    available: number;
    batchId: string;
}
