import { ChangeDetectionStrategy, ViewEncapsulation, Component, OnInit } from '@angular/core';

@Component({
    selector: 'lab-footer',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss', './../../styles/styles.css'],
    encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
    currentUser: any;
    haveCurrentUser = false;

    socials = [
        { icon: 'fa-facebook-square', link: 'https://www.facebook.com/rescalelab' },
        { icon: 'fa-linkedin', link: 'https://www.linkedin.com/company/rescalelab' },
        { icon: 'fa-instagram-square', link: 'https://www.instagram.com/rescalelab/' },
        { icon: 'fa-youtube-square', link: 'https://www.youtube.com/channel/UCYtUdnrCPOmixV2vH1iuhPA' },
        { icon: 'fa-telegram', link: 'https://t.me/eatentrepreneur' },
    ]
    constructor() { }
    ngOnInit() {
        let _currentUser = localStorage.getItem('currentUser')
        if (_currentUser !== null) {
            this.currentUser = JSON.parse(_currentUser);

            if (this.currentUser == null) {
                this.haveCurrentUser = false;
            } else {
                this.haveCurrentUser = true;
            }
        }
    }
}
