import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup
} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-dialog-restart',
  templateUrl: './dialog-restart.component.html',
  styleUrls: ['./dialog-restart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class DialogRestartComponent implements OnInit {
  @Input() title;
  @Input() class?: string;
  @Input() show: boolean;
  @Input() allowCancel: boolean;
  @Input() role;

  @Output() close = new EventEmitter();
  @Output() reject = new EventEmitter();
  @Output() onRestart = new EventEmitter();

  public Editor = ClassicEditor;
  restartForm: FormGroup;
  submitted: boolean = false;

  qns1 = '';
  qns2 = '';
  qns3 = '';


  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {
    this.restartForm = this.formBuilder.group({
      allAgreeChecked: false,
      mentorsAgreeChecked: false,
      selfAgreeChecked: false,
      submitAgreeChecked: false,
      description: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.submitted = false;
    if (this.role === 'project') {
      this.qns1 = 'All members of the team have agreed to abandon the current project and restart working on a new idea';
      this.qns2 = 'Consultants have been consulted and agreed the best way forward is to restart the project';
      this.qns3 = 'By choosing to move forward, you agree to URGENTLY redo all the deliverables from the start in order to stay on track';
    } else if (this.role === 'professionalservice') {
      this.qns1 = 'As a Consultant designated to this Project Team, you agree to move forward with this restart';
      this.qns2 = 'All Project Team members and Program Managers will be notified, and will need to agree to this restart';
      this.qns3 = 'By choosing to move forward, the chosen Project Team will need to redo ALL the deliverables from the start and you will need to reassess them urgently';
    } else if (this.role === 'batch') {
      this.qns1 = 'All Project Team members and Consultants will be notified and will need to agree to this restart';
      this.qns2 = 'Consultants have been consulted and agreed the best way forward is to restart the project';
      this.qns3 = 'By choosing to move forward, the chosen Project Team will need to redo ALL the deliverables from the start and Consultants will need to reassess them urgently';
    }
  }

  get f() { return this.restartForm.controls; }

  onClose() {
    this.close.emit();
    this.submitted = false;
  }

  onReject(){
    this.submitted = true;
    if(this.restartForm.invalid) {
      return
    }
    this.reject.emit(this.restartForm.value);
  }

  onSubmit() {
    // console.log('restart => get values =========', this.restartForm.value);
    this.submitted = true;
    if (this.restartForm.invalid
      || !this.restartForm.value.allAgreeChecked
      || !this.restartForm.value.mentorsAgreeChecked
      || !this.restartForm.value.selfAgreeChecked
      || !this.restartForm.value.submitAgreeChecked
    ) {
      this.toastr.show('Please check all checkbox and fill in the reason for restart!', '', {
        disableTimeOut: false,
        tapToDismiss: true,
        toastClass: 'toast border-red',
        closeButton: false,
      });
      return
    }
    this.submitted = false;
    this.onRestart.emit(this.restartForm.value);
    this.restartForm.reset();
  }

}
