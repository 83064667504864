import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  forwardRef,
  EventEmitter,
  ViewChild,
  ElementRef,
  HostListener,
} from '@angular/core';

import {
  ControlContainer,
  FormControl,
  ControlValueAccessor,
  AbstractControl,
  NG_VALUE_ACCESSOR,
  FormGroup
} from '@angular/forms';

import { options } from 'marked';

@Component({
  selector: 'ui-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutocompleteComponent)
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class AutocompleteComponent implements OnInit {

  @Input() data: [];
  @Input() value: any;
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() parentForm: FormGroup;

  control: AbstractControl;

  @Output() change = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();

  @ViewChild('input') inputRef: ElementRef;
  @ViewChild('clear') clearBtn: ElementRef;
  @ViewChild('list') listItems: ElementRef;

  @HostListener('document:click', ['$event'])
  clickedOut(event: any) {
    if (this.eRef.nativeElement.contains(event.target)) return
    if (this.isExpanded) return this.isExpanded = !this.isExpanded;
  }

  isExpanded: boolean = false;
  isShowClear: boolean = false;
  selected: any;
  searchText: string = '';
  options: any[] = [];
  filter: any[] = [];


  constructor(private eRef: ElementRef, private controlContainer: ControlContainer) { }

  ngOnInit() {
    //   if(this.controlContainer && this.formControlName){
    //     this.control = this.controlContainer.control?.get(this.formControlName);
    //  }
    if (this.data.length > 0) this.options = [...this.data];
  }

  writeValue(value: any) {

  }

  onChange(val: any) {
    this.searchText = val;
    this.onSearch(val);
    this.change.emit(val);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {

  }

  // tracking keyboard when pressed
  onKeypress(event: any) {
    const { key, keyCode, metaKey, ctrlKey } = event
    const val = event.target.value

    if (!this.isExpanded) this.isExpanded = !this.isExpanded;
    if (val !== '' || val !== ' ') this.searchText = val;

    if (keyCode === 8 || key === 'backspace') {
      if (this.filter.length <= 1) this.options = [...this.data];
      if (this.searchText === '') this.selected = '';
    }

    if (keyCode === 13 || key === 'Enter') {
      event.preventDefault();
    }

    // if (metaKey || ctrlKey) {
    //   return;
    // }

    // if ((keyCode === 91 || keyCode === 93) && keyCode === 65 ||  keyCode === 17 && keyCode === 65) {
    //   return;
    // }

  }

  onKeydown(event: any) {
    const { key, keyCode, metaKey, ctrlKey } = event
    const val = event.target.value

    if (!this.isExpanded) this.isExpanded = !this.isExpanded;
    if (val !== '' || val !== ' ') this.searchText = val;

    if (keyCode === 8 || key === 'Backspace') {
      if (this.filter.length <= 1) this.options = [...this.data];
      if (this.searchText === '') this.selected = '';
    }

    if (keyCode === 13 || key === 'Enter') {
      event.preventDefault();
    }

    // if (metaKey || ctrlKey || (keyCode === 91 || keyCode === 93) || keyCode === 17) {
    //   return;
    // }

    // if ((keyCode === 91 || keyCode === 93) && keyCode === 65 ||  keyCode === 17 && keyCode === 65) {
    //   return;
    // }
  }


  // tracking keyboard when keyup
  onKeyup(event: any) {
    const { key, keyCode, metaKey, ctrlKey } = event
    const val = event.target.value
    this.searchText = val;
    this.onSearch(val);

    if (metaKey || ctrlKey) {
      return;
    }

    if ((keyCode === 91 || keyCode === 93) && keyCode === 65 || keyCode === 17 && keyCode === 65) {
      return;
    }
  }

  onBlur() {
    if (this.searchText === '') this.selected = '';
    if (this.isExpanded) this.isExpanded = !this.isExpanded;
  }

  // display options
  onExpand(event) {
    event.preventDefault();
    if (!this.isExpanded) this.isExpanded = !this.isExpanded;
    this.selected = this.searchText;
    this.onSearch(this.searchText);
  }

  // onFocus() {
  //   if (this.searchText !== '') {
  //     this.inputRef.nativeElement.setSelectionRange(0, this.searchText.length);
  //   }
  // }

  // select option
  onSelect(val) {
    this.selected = val.text;
    this.searchText = val.text;
    this.isExpanded = !this.isExpanded;
    this.select.emit(val);
  }

  // search filter
  onSearch(val: any) {
    if (!val) return;
    this.filter = this.options.filter(option => option.text.toLowerCase().includes(val.toLowerCase()));
    this.options = val?.length >= 1 ? [...this.filter] : [...this.data];
  }

  onClearSearch(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.searchText = '';
    this.selected = '';
    this.options = [...this.data];
    this.inputRef.nativeElement.focus();
    this.isShowClear = !this.isShowClear;
  }
}
