import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpHeaders
} from '@angular/common/http';

import {
    User,
    Organization
} from '../models';

import {
    Observable,
    BehaviorSubject,
} from 'rxjs';
import {
    UtilityService
} from './';
import moment from 'moment';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
const CACHE_SIZE = 1;
export class OrganizationsCacheService {

    readonly CACHE_DURATION_IN_MINUTES = 3;

    private cache: {
        expires: Date,
        value: Observable<Organization[]>
    } = null as any;
    constructor(
    ) {
    }

    getValue(): Observable<Organization[]> {
        if (!this.cache) {
            return null as any;
        }

        if (moment(new Date()).isAfter(this.cache.expires)) {
            return null as any;
        }

        return this.cache.value;
    }

    setValue(value: Observable<Organization[]>) {
        this.cache = {
            value,
            expires: moment(new Date()).add(this.CACHE_DURATION_IN_MINUTES, 'minutes').toDate()
        };

    }

    clearCache() {
        this.cache = null as any;
    }
}

@Injectable()
export class OrganizationService {
    items: Observable<any[]>;
    private organization = new BehaviorSubject(null);
    currentOrganization = this.organization.asObservable();

    expertise = [];

    cachedOrgs$: Observable<any[]>;

    constructor(
        private http: HttpClient,
        private utility: UtilityService,
        private organizationsCacheSrv: OrganizationsCacheService
    ) {
    }

    getAll() {
        return this.http.get(`${environment.apiUrl}/organization/getAll`, {});
    }

    getAllOrgWOrgType(keyword) {
        const params = new HttpParams().set('keyword', keyword);
        return this.http.get(`${environment.apiUrl}/organization/getAllOrgWOrgType`,
            { params: params });
    }

    // include that organization is deleted
    getAllOrgWOrgTypeForAdmin(keyword) {
        const params = new HttpParams().set('keyword', keyword);
        return this.http.get(`${environment.apiUrl}/organization/getAllOrgWOrgTypeForAdmin`,
            { params: params });
    }

    getAllOrgWOrgTypeForExternal() {
        return this.http.get(`${environment.apiUrl}/organization/getAllOrgWOrgTypeForExternal`);
    }

    getAllOrgWOrgTypeForExternalSetting() {
        return this.http.get(`${environment.apiUrl}/organization/getAllOrgWOrgTypeForExternalSetting`);
    }

    getTypeFromTypeId(typeId: string) {
        const _resResult = this.http.post(`${environment.apiUrl}/organization/getTypeFromTypeId`, { id: typeId });
        return _resResult;
    }

    async getOrgByUid(uid: string) {

        const params = new HttpParams().set('uid', uid);
        const _resResult = await this.http.get<any>(`${environment.apiUrl}/organization/getOrgByUid`,
            { params: params }).toPromise();
        if (_resResult.result == 'successful') {

            return _resResult.data as Organization[];
        } else {
            return null;
        }
    }

    async getProjectTeamByUid(uid: string) {

        const params = new HttpParams().set('uid', uid);
        const _resResult = await this.http.get<any>(`${environment.apiUrl}/organization/getProjectTeamByUid`,
            { params: params }).toPromise();
        if (_resResult.result == 'successful') {

            return _resResult.data as Organization[];
        } else {
            return null;
        }
    }

    updateMatchingForUser() {

        const res = this.http.get(`${environment.apiUrl}/matching/ping`).subscribe(data => {

        }, error => {
            console.error('updateMatchingForUser error', error);
        });

        return res;
    }

    changeCurrentOrginaztion(Org) {
        this.organization.next(Org);
        localStorage.setItem('currentOrg', JSON.stringify(Org));
    }

    updateUserRole(userRoleId: string, roleName: string) {
        return this.http.post(`${environment.apiUrl}/userRole/updateUserRoleByUserRoleId`,
            {
                userRoleId: userRoleId, roleName: roleName
            });
    }

    addUserToOrgUser(userRoleId, orgId, orgTypeId, title, titleOthers, uid) {
        return this.http.post(`${environment.apiUrl}/organization/addOrganizationUser`,
            {
                userRoleId: userRoleId, orgId: orgId, orgTypeId: orgTypeId, title: title, titleOthers: titleOthers, uid: uid
            });
    }

    moveuserBatchToOrg(batchId, userId) {
        return this.http.post(`${environment.apiUrl}/organization/moveuserBatchToOrg`,
            {
                batchId: batchId, userId: userId
            });
    }


    insertUserToOrgUser(
        userId,
        userRoleTypeName,
        orgId,
        orgTypeId,
        batchId,
        title,
        titleOthers,
        employeeStatus,
        uid) {

        return this.http.post(`${environment.apiUrl}/organization/insertUserToOrgUser`,
            {
                userId,
                userRoleTypeName,
                orgId,
                orgTypeId,
                batchId,
                title,
                titleOthers,
                employeeStatus,
                uid
            });
    }

    removeUserFromOrgUser(orgId: string, orgTypeId: string, userRoleId: string, uid: string, receiverUid: string) {
        return this.http.post(`${environment.apiUrl}/organization/removeUserFromOrgUser`,
            {
                orgId, orgTypeId, userRoleId, uid, receiverUid
            });
    }


    /* Create Organization */
    createWithUser(
        organization,
        user: User,
        userRoleId: string,
        organizationTypeId: string) {

        organization.uid = user.id;
        organization.userRoleId = userRoleId;
        organization.organizationTypeId = organizationTypeId;

        return this.http.post<any>(`${environment.apiUrl}/organization/createWithUser`,
            organization
        );
    }

    async createWUser(formData: FormData) {

        return await this.http.post<any>(`${environment.apiUrl}/organization/createWUser`,
            formData).toPromise();

    }

    async updateOrgProfile(formData: FormData) {

        return await this.http.put<any>(`${environment.apiUrl}/organization/updateProfile`,
            formData).toPromise();

    }


    getOrgDataWithOrgWidgetsById(orgId: string,
        imgLogoPath: string = '', batchId: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgDataWithOrgWidgetsById`,
            {
                orgId: orgId,
                imgLogoPath: imgLogoPath,
                batchId: batchId
            });
    }


    getOrgDataWithWidgetsById(orgId: string,
        imgLogoPath: string,
        batchId: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgDataWithWidgetsById`,
            {
                orgId: orgId,
                imgLogoPath: imgLogoPath,
                batchId: batchId
            });
    }

    getWidgetsByAccessRoleNUidNOrgId(userRoleId: string, orgId: string, batchId: string) {
        return this.http.post(`${environment.apiUrl}/organization/getWidgetsByAccessRoleNUidNOrgId`,
            {
                userRoleId: userRoleId, orgId: orgId, batchId: batchId
            });
    }

    getOrgById(orgId: string,
        imgLogoPath: string = '') {
        return this.http.post(`${environment.apiUrl}/organization/getDataById`,
            {
                orgId: orgId,
                imgLogoPath: imgLogoPath
            });
    }

    async getDataById(orgId: string) {
        return await this.http.get(`${environment.apiUrl}/organization/data/${orgId}`,
            {
            }).toPromise();
    }

    getOrgDataByUidNOrgId(uid: string, orgId: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgDataByUidNOrgId`,
            {
                uid: uid, orgId: orgId
            });
    }

    getOrgAndOwnerById(orgId: string, imgLogoPath: string = '') {
        return this.http.post(`${environment.apiUrl}/organization/getOrgAndOwnerById`,
            {
                orgId: orgId,
                imgLogoPath: imgLogoPath
            });
    }

    getOrgAndOrgTypeByOrgId(orgId: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgAndOrgTypeByOrgId`,
            {
                orgId: orgId
            });
    }

    getOrgStaffList(orgId: string, orgTypeId: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgStaffList`,
            {
                orgId: orgId, orgTypeId: orgTypeId
            });
    }

    async getOrgUserListWithValue(orgId: string, orgTypeId: string) {
        return await this.http.get(`${environment.apiUrl}/organization/orgUserList/personalData/${orgId}/${orgTypeId}`,
            {

            }).toPromise();
    }

    async getOrgStaffListv2(orgId: string, orgTypeId: string) {
        return await this.http.post(`${environment.apiUrl}/organization/getOrgStaffList`,
            {
                orgId: orgId, orgTypeId: orgTypeId
            }).toPromise();
    }

    getOrgStaffListByRole(orgId: string, roleName: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgStaffListByRole`,
            {
                orgId: orgId, roleName: roleName
            });
    }

    removeOrgUser(orgUserId: string) {
        return this.http.post(`${environment.apiUrl}/organization/removeOrgUser`,
            {
                orgUserId: orgUserId
            });
    }

    update(formData: FormData) {
        return this.http.post(`${environment.apiUrl}/organization/updateV4`,
            formData);
    }

    updateCompanyLogo(orgId, companylogoUrl) {
        return this.http.post(`${environment.apiUrl}/organization/updateCompLogo`,
            { orgId: orgId, url: companylogoUrl });

    }

    updateCompanystructureLogo(orgId, companystructureUrl) {
        return this.http.post(`${environment.apiUrl}/organization/updateCompanystructureLogo`,
            { orgId: orgId, url: companystructureUrl });

    }


    deleteOrg(orgId: string) {
        return this.http.post(`${environment.apiUrl}/organization/deleteOrg`,
            { orgId });
    }


    async getOrgTypes() {
        return await
            this.requestOrganizationType();
    }

    getAllOrgTypes() {
        return this.http.get<any>(`${environment.apiUrl}/organization/getAllOrgTypes`);
    }

    getOrgTypeIdByOrgType(organizationType: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgTypeIdByOrgType`,
            {
                organizationType: organizationType
            });
    }

    private async requestOrganizationType() {
        return await this.http.get<any>(`${environment.apiUrl}/organization/getAllOrgTypes`)
            .toPromise();
    }

    async getOrgList() {
        return this.http.get<any[]>(`${environment.apiUrl}/list/getOrgList`);
    }

    bulkInsertOrgWidget(organizationId, organizationTypeId, widgetIds, sequences, uid) {
        return this.http.post(`${environment.apiUrl}/widget/bulkInsertOrganizationWidget`,
            {
                'organizationId': organizationId,
                'organizationTypeId': organizationTypeId,
                'widgetIds': widgetIds,
                'sequences': sequences,
                'uid': uid
            }).subscribe(data => {
            }, error => {
                console.error('bulkInsertOrgWidget error', error);
            });
    }

    getOrgByName(orgName: string) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgByOrgName`,
            {
                orgName: orgName
            });
    }

    async GetAllOrgWithType() {
        let orgs$ = this.organizationsCacheSrv.getValue();
        if (!orgs$) {
            orgs$ = await this.http.get(`${environment.apiUrl}/organization/allOrgWithType`,
                {}).pipe(
                    map((response: any) => response), shareReplay(1)).toPromise();
            this.organizationsCacheSrv.setValue(orgs$);
        }
        return orgs$;
    }

    getOrgUserByOrgIdNUserRoleId(orgId, userRoleId) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgUserByOrgIdNUserRoleId`,
            {
                orgId: orgId, userRoleId: userRoleId
            });
    }

    updateOrganizationUser(organizationUser) {
        return this.http.post(`${environment.apiUrl}/organization/updateOrganizationUser`,
            {
                organizationUser: organizationUser
            });
    }

    getAllOrgSortByRoles() {
        return this.http.get(`${environment.apiUrl}/organization/getAllOrgSortByRoles`,
            {

            });
    }

    getOrganizationBatch(organizationId) {
        return this.http.get<any>(`${environment.apiUrl}/organization/organizationBatch/${organizationId}`);
    }

    createOrganizationBatch(batch) {
        return this.http.post<any>(`${environment.apiUrl}/organization/createOrganizationBatch`, batch);
    }

    updateOrganizationBatch(batch) {

        return this.http.post<any>(`${environment.apiUrl}/organization/updateOrganizationBatch`, { batch });
    }

    deleteOrganizationBatch(orgId: string, batchId: string, uid: string) {
        return this.http.post(`${environment.apiUrl}/organization/deleteOrgDataWithOrgWidgetsById`,
            {
                orgId: orgId,
                uid: uid,
                batchId: batchId
            });
    }

    addWidgetToBatch(batchId: string, roleId: string, uid: string) {
        return this.http.post(`${environment.apiUrl}/organization/assignWidgetToBatchWidgetByRoleId`,
            { batchId: batchId, roleId: roleId, uid: uid });
    }

    createExpertise(value) {
        return this.http.post<any>(`${environment.apiUrl}/organization/createExpertise`, value);
    }

    getExpertise(userId) {
        return this.http.get<any>(`${environment.apiUrl}/organization/getExpertise/${userId}`);
    }

    updateExpertise(value) {
        return this.http.post<any>(`${environment.apiUrl}/organization/updateExpertise`, value);
    }

    deleteOrgUser(orgId: string, userRoleId: string) {
        return this.http.post(`${environment.apiUrl}/organization/deleteOrgUser`,
            {
                orgId: orgId, userRoleId: userRoleId
            });
    }

    getOrganizationByRole() {
        return this.http.get<any>(`${environment.apiUrl}/organization/getOrganizationByRole`);
    }

    getOrganizationById(organizationId) {
        return this.http.get<any>(`${environment.apiUrl}/organization/getOrganizationById/${organizationId}`);
    }

    async getOrganizationByNameId(nameId) {
        const params = new HttpParams().set('nameId', nameId);
        return await this.http.get<any>(`${environment.apiUrl}/company/getOrgDataByNameId`,
            { params: params }).toPromise();
    }

    GetDisplayOrgTypeName(type) {
        let _type = '';
        switch (type) {
            case 4:
            case 'expert':
            case 'professionalservice':
                _type = 'Expert';
                break;

            case 6:
            case 'investor':
            case 2:
            case 'funder':
                _type = 'Funder';
                break;
            case 3:
            case 'institution':
                _type = 'Institution';
                break;
            case 1:
            case 'project':
                _type = 'Project';
                break;
        }

        return _type;
    }

    GetDisplayCompanySize(value, uint) {
        let _strCompanySize = '1~5 ';
        if (!this.utility.IsNullOrEmpty(value)) {
            switch (value) {
                case '1':
                    _strCompanySize = '6~10';
                    break;
                case '2':
                    _strCompanySize = '11~20';
                    break;
                case '3':
                    _strCompanySize = '21~50';
                    break;
                case '4':
                    _strCompanySize = ' > 50';
                    break;
            }
        }
        if (this.utility.IsNullOrEmpty(uint)) {
            return _strCompanySize;
        } else {
            return _strCompanySize + ' ' + uint;
        }
    }

    getTotalOrgUserWithOrgType() {
        return this.http.get<any>(`${environment.apiUrl}/organization/getTotalOrgUserWithOrgType`);
    }

    getOrgType() {
        return this.http.get<any>(`${environment.apiUrl}/organization/getOrgType`);
    }

    updateOrgOwner(orgId, newOwnerUid, uid) {
        return this.http.post(`${environment.apiUrl}/organization/updateOrgOwner`,
            {
                orgId: orgId,
                newOwnerUid: newOwnerUid,
                uid: uid
            });
    }

    getOrgMember(orgId, orgTypeId) {
        return this.http.get<any>(`${environment.apiUrl}/organization/getOrgMember/${orgId}/${orgTypeId}`);
    }

    updateMentoring(orgId) {
        return this.http.post(`${environment.apiUrl}/organization/updateMentoring`,
            {
                orgId: orgId
            });
    }

    getOrgUserList(orgId, orgTypeId) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgUserList`,
            {
                orgId: orgId, orgTypeId: orgTypeId
            });
    }

    getOrgUserListByType(orgId, orgType) {
        return this.http.post(`${environment.apiUrl}/organization/getOrgUserListByType`,
            {
                orgId: orgId, orgType: orgType
            });
    }

    async getBatchWidgetByuid(uid) {
        return await this.http.get(`${environment.apiUrl}/organization/batchWidgetByuid/${uid}`, {}).toPromise();
    }

    async getBatchDatabyBatchId(batchId) {
        return await this.http.get(`${environment.apiUrl}/organization/getBatchDatabyBatchId/${batchId}`, {}).toPromise();
    }

    searchMentor(batchId, type, text) {
        return this.http.post(`${environment.apiUrl}/organization/searchMentor`,
            {
                batchId: batchId, type: type, text: text
            });
    }

    getAllOrgSortByRolesInvite(batchId, type) {
        return this.http.post(`${environment.apiUrl}/organization/getAllOrgSortByRolesInvite/`,
            {
                batchId: batchId, type: type
            });
    }

    searchStartup(batchId, type, text) {
        return this.http.post(`${environment.apiUrl}/organization/searchStartup`,
            {
                batchId: batchId, type: type, text: text
            });
    }

    changeOwnerShip(payload) {
        return this.http.post(`${environment.apiUrl}/organization/changeOwnership`, payload);
    }

}
