import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SideNavDirection } from './side-nav-direction';
import { Observable } from 'rxjs';
import { NavigationService } from '@modules/navigation/services';
/* https://itnext.io/simple-sliding-side-bar-for-your-angular-web-apps-d54fef7c1654 */
@Component({
    selector: 'lab-side-nav',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './side-nav.component.html',
    styleUrls: ['side-nav.component.scss'],
})
export class SideNavComponent implements OnInit {
    showSideNav: Observable<boolean>;

    @Input() sidenavTemplateRef: any;
    @Input() duration: number = 0.25;
    @Input() navWidth: number = window.innerWidth;
    @Input() direction: SideNavDirection = SideNavDirection.Left;

    constructor(private navService: NavigationService) { }
    ngOnInit() {
        this.showSideNav = this.navService.getShowNav();
    }
    onSidebarClose() {
        this.navService.setShowNav(false);
    }

    getSideNavBarStyle(showNav: boolean) {
        let navBarStyle: any = {};

        navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
        navBarStyle.width = this.navWidth + 'px';
        navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';

        return navBarStyle;
    }

}
