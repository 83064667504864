export class Notification {
    id: string;
    key: string;
    receiverName: string;
    receiverId: string;
    receiverOrgName: string;
    receiverOrgId: string;
    title: string;
    text: string;
    createdAt: string;
    type: number; // type 0 == invitation | type 1 == transaction
    clicked = 0;
}
