import { AlertComponent } from './alert/alert.component';
import { AppFormComponent } from './form/app-form.component';
import { BrushChartComponent } from './chart/brush-chart/brush-chart.component';
import { CardViewDetailsComponent } from './card-view-details/card-view-details.component';
import { CardComponent } from './card/card.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DonutChartComponent } from './chart/donut-chart/donut-chart.component';
import { EditableComponent } from './editable/editable.component';
import { ViewModeDirective } from './editable/view-mode.directive';
import { EditModeDirective } from './editable/edit-mode.directive';
import { EditableOnEnterDirective } from './editable/editable-on-enter.directive';
import { LineBarComponent } from './line-bar/line-bar.component';
import { ModalComponent } from './modal/modal.component';
import { MentorCardComponent } from './mentor-card/mentor-card.component';
import { PercentageBarComponent } from './percentage-bar/percentage-bar.component';
import { ProgressItemComponent } from './progressItem/progressItem.component';
import { ProgressItemDetailComponent } from './progressItemDetail/progressItemDetail.component';
import { SpinnerEduComponent } from './spinnerEdu/spinnerEdu.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { ToggleIconComponent } from './toggleIcon/toggleIcon.component';
import { UserButtonComponent } from './userbutton/button.component';
import { CustomToastComponent } from './custom_toast/custom_toast.component';
import { ButtonComponent } from './button/button.component';
import { SwitchComponent } from './switch/switch.component';
import { DialogComponent } from './dialog/dialog.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { DialogFooterComponent } from './dialog-footer/dialog-footer.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { TabsComponent } from './tabs/tabs.component';
import { CommentsComponent } from './comments/comments.component';
import { CommentComponent } from './comment/comment.component';
import { TodoCommentsComponent } from './todo-comments/todo-comments.component';
import { CustomSelectorComponent } from './custom-selector/custom-selector.component';
import { SelectorComponent } from './selector/selector.component';
import { ProfileHeaderComponent } from './profile-header/profile-header.component';
import { ProfileAboutComponent } from './profile-about/profile-about.component';
import { AvatarComponent } from './avatar/avatar.component';
import { SectionHeaderComponent } from './section-header/section-header.component';
import { SectionTitleComponent } from './../../workspace/widgets/section-title/section-title.component';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ActionsComponent } from './actions/actions.component';
import { SelectFilterComponent } from './select-filter/select-filter.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TaskInfoComponent } from './../../workspace/components/task-info/task-info.component';
import { AvatarNameComponent } from './avatar-name/avatar-name.component';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent } from './radio/radio.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ResourceInfoComponent } from '@modules/workspace/components/resource-info/resource-info.component';
import {DocViewerComponent } from './doc-viewer/doc-viewer.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CustomPopoverComponent } from './custom-popover/custom-popover.component';

export const components = [
    ViewModeDirective,
    AlertComponent,
    AppFormComponent,
    ButtonComponent,
    BrushChartComponent,
    ConfirmDialogComponent,
    CardComponent,
    CardViewDetailsComponent,
    CommentsComponent,
    CommentComponent,
    CustomSelectorComponent,
    CustomModalComponent,
    CustomToastComponent,
    DialogComponent,
    DialogHeaderComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DonutChartComponent,
    EditableComponent,
    EditModeDirective,
    EditableOnEnterDirective,
    LineBarComponent,
    MentorCardComponent,
    ModalComponent,
    PercentageBarComponent,
    ProgressItemComponent,
    ProgressItemDetailComponent,
    SwitchComponent,
    SpinnerEduComponent,
    SideNavComponent,
    SearchBarComponent,
    SelectorComponent,
    TodoCommentsComponent,
    ToggleIconComponent,
    TabsComponent,
    UserButtonComponent,
    ProfileHeaderComponent,
    ProfileAboutComponent,
    AvatarComponent,
    SectionHeaderComponent,
    SectionTitleComponent,
    ContentCardComponent,
    ContactsComponent,
    ActionsComponent,
    SelectFilterComponent,
    ToggleSwitchComponent,
    TaskInfoComponent,
    AvatarNameComponent,
    PlanCardComponent,
    CheckboxComponent,
    RadioComponent,
    AutocompleteComponent,
    ResourceInfoComponent,
    DocViewerComponent,
    CheckoutComponent,
    CustomPopoverComponent,
];

export { AppFormComponent } from './form/app-form.component';
export { BrushChartComponent } from './chart/brush-chart/brush-chart.component';
export * from './card/card.component';
export * from './card-view-details/card-view-details.component';
export { DonutChartComponent } from './chart/donut-chart/donut-chart.component';
export * from './side-nav/side-nav.component';
export { ModalComponent } from './modal/modal.component';
export { AlertComponent } from './alert/alert.component';

export { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
export { SpinnerEduComponent } from './spinnerEdu/spinnerEdu.component';
export { LineBarComponent } from './line-bar/line-bar.component';
export { MentorCardComponent } from './mentor-card/mentor-card.component';
export { PercentageBarComponent } from './percentage-bar/percentage-bar.component';
export * from './progressItem/progressItem.component';
export * from './progressItemDetail/progressItemDetail.component';
export * from './search-bar/search-bar.component';
export * from './toggleIcon/toggleIcon.component';
export * from './userbutton/button.component';
export { EditableComponent } from './editable/editable.component';
export { ViewModeDirective } from './editable/view-mode.directive';
export { EditModeDirective } from './editable/edit-mode.directive';
export { EditableOnEnterDirective } from './editable/editable-on-enter.directive';
export * from './custom_toast/custom_toast.component';
export { SelectorComponent } from './selector/selector.component';