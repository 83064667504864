import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentIntent } from '@stripe/stripe-js';
import { PlacementForBs5 } from 'ngx-bootstrap/positioning';
import { BatchDetailComponent } from '@modules/admin/containers';

// @Injectable()
@Injectable({
    providedIn: 'root'
})
export class StripePaymentService {
    items: Observable<any[]>;
    admins: string[] = [];
    private chosenPlan: BehaviorSubject<string> = new BehaviorSubject('');
    private numberOfCohort: BehaviorSubject<number> = new BehaviorSubject(1);
    private totalAmount: BehaviorSubject<number> = new BehaviorSubject(0);
    private inPaymentPage = new BehaviorSubject(false);

    constructor(
        private http: HttpClient
    ) {

    }

    getChosenPlan() {
        return this.chosenPlan;
    }

    getNumberOfCohort() {
        return this.numberOfCohort;
    }

    getTotalAmount() {
        return this.totalAmount;
    }

    selectedPlan(plan) {
        this.chosenPlan.next(plan)
    }

    setNumberOfCohorts(number) {
        this.numberOfCohort.next(number);
    }

    setTotalAmount(number) {
        this.totalAmount.next(number);
    }

    getInPaymentPage() {
        return this.inPaymentPage;
    }

    setInPaymentPage(data) {
        this.inPaymentPage.next(data);
    }

    getOrganizationBatch(organizationId) {
        return this.http.get<any>(`${environment.apiUrl}/stripePayment/organizationBatch/${organizationId}`);
    }

    getPlans(organizationId) {
        return this.http.get<any>(`${environment.apiUrl}/stripePayment/getPlans/${organizationId}`);
    }

    getBatchCreditPacks(organizationId) {
        return this.http.get<any>(`${environment.apiUrl}/stripePayment/getBatchCreditPacks/${organizationId}`);
    }


    async createPayment(total, token) {
        return await this.http.post(`${environment.apiUrl}/stripePayment/createStripePayment`,
            {
                total: total, token: token
            }).toPromise();
    }

    getAllProductsAndPlans() {
        return this.http.get(`${environment.apiUrl}/stripePayment/getAllProductsAndPlans`,
            {

            });
    }

    createCustomerAndSubscription(customerEmail, stripeToken, planId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/createCustomerAndSubscription`,
            {
                customerEmail: customerEmail, stripeToken: stripeToken, planId: planId
            });
    }

    charge() {
        return this.http.post(`${environment.apiUrl}/stripePayment/charge`,
            {

            });
    }

    createCustomer(data) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-StripecustomerId`,
            {
                email: data.email,
                name: data.name,
                rescaleId: data.rescaleId
            });
    }

    // StartSubscription(priceId, customerId) {
    //     console.log(priceId)
    //     return this.http.post(`${environment.apiUrl}/stripePayment/create-subscription`,
    //         {
    //             priceId: priceId,
    //             customerId: customerId
    //         });
    // }

    createLicenceSubscription(customerId, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-licence-subscription`, {
            customerId: customerId,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createLicenceSubscriptionNoAutoRenewal(customerId, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-licence-subscription-no-autorenewal`, {
            customerId: customerId,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionMonthly(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-monthly`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    // -----------test in production
    testCreateCohortSubscriptionMonthly(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/test-create-cohort-subscription-monthly`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    testCreateCohortSubscriptionMonthlyNoAutoRenewal(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/test-create-cohort-subscription-monthly-no-autorenewal`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }


    //--------------------------

    createCohortSubscriptionMonthlyNoAutoRenewal(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-monthly-no-autorenewal`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionYearly(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-yearly`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionYearlyNoAutoRenewal(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-yearly-no-autorenewal`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionMonthlyWithLicence(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-monthly-with-licence`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionMonthlyWithLicenceNoAutoRenewal(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-monthly-with-licence-no-autorenewal`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionYearlyWithLicence(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-yearly-with-licence`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    createCohortSubscriptionYearlyWithLicenceNoAutoRenewal(customerId, numberOfCohort, userId, organizationId, couponId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-subscription-yearly-with-licence-no-autorenewal`, {
            customerId: customerId,
            numberOfCohort: numberOfCohort,
            userId: userId,
            organizationId: organizationId,
            couponId: couponId
        })
    }

    updateAutoRenewal(subscriptionId, event) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-auto-renewal-licence`, {
            subscriptionId: subscriptionId,
            event: event
        })
    }

    updateAutoRenewalSubscription(subscriptionId, event) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-auto-renewal-subscription`, {
            subscriptionId: subscriptionId,
            event: event
        })
    }

    createCohortOneTimePayment(customerId, totalAmount, checkoutItems, receiptId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-cohort-one-time-payment`, {
            customerId: customerId,
            totalAmount: totalAmount,
            checkoutItems: checkoutItems,
            receiptId: receiptId
        })
    }

    createPaymentIntent(amount: number) {
        return this.http.post<PaymentIntent>(
            `${environment.apiUrl}/stripePayment/create-payment-intent`,
            { amount }
        );
    }

    config() {
        return this.http.get(
            `${environment.apiUrl}/stripePayment/config`,
            {}
        );
    }

    async getSubscriptionsByOrgId(orgId) {
        return await this.http.get<any>(`${environment.apiUrl}/stripePayment/getSubscriptionsByOrgId/${orgId}`).toPromise();
    }

    async getLicenceInvoices(organizationId) {
      return await this.http.get<any>(`${environment.apiUrl}/stripePayment/licenceInvoices/${organizationId}`).toPromise();
    }

    cancelSubscription(subscriptionId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/cancel-subscription`,
            {
                subscriptionId: subscriptionId
            });
    }

    updateCreditTableOneOff(plan, organizationId, userId, duration, numberOfCohort) {
        return this.http.post(`${environment.apiUrl}/stripePayment/addCredits`, {
            plan: plan,
            organizationId: organizationId,
            userId: userId,
            duration: duration,
            numberOfCohort: numberOfCohort
        })
    }

    updateSubscriptionLicenceTable(customerId, organizationId, userId, subscriptionInvoice, subscriptionPaymentIntentId, subscriptionId, currentPeriodEnd) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription-licence-table`, {
            customerId: customerId,
            subscriptionInvoice: subscriptionInvoice,
            userId: userId,
            organizationId: organizationId,
            subscriptionPaymentIntentId: subscriptionPaymentIntentId,
            subscriptionId: subscriptionId,
            currentPeriodEnd: currentPeriodEnd
        })
    }

    updateSubscriptionLicenceTableNoAutoRenewal(customerId, organizationId, userId, subscriptionInvoice, subscriptionPaymentIntentId, subscriptionId, cancelAt) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription-licence-table-no-autorenewal`, {
            customerId: customerId,
            subscriptionInvoice: subscriptionInvoice,
            userId: userId,
            organizationId: organizationId,
            subscriptionPaymentIntentId: subscriptionPaymentIntentId,
            subscriptionId: subscriptionId,
            cancelAt: cancelAt
        })
    }

    updateSubscriptionCreditTable(customerId, organizationId, userId, subscriptionInvoice, subscriptionPaymentIntentId, subscriptionId, plan, numberOfCohort, startDate) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription-credit-table`, {
            customerId: customerId,
            subscriptionInvoice: subscriptionInvoice,
            userId: userId,
            organizationId: organizationId,
            subscriptionPaymentIntentId: subscriptionPaymentIntentId,
            subscriptionId: subscriptionId,
            plan: plan,
            numberOfCohort: numberOfCohort,
            startDate: startDate
        })
    }

    updateSubscriptionCreditTableNoAutoRenewal(customerId, organizationId, userId, subscriptionInvoice, subscriptionPaymentIntentId, subscriptionId, plan, numberOfCohort, cancelAt, startDate) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription-credit-table-no-autorenewal`, {
            customerId: customerId,
            subscriptionInvoice: subscriptionInvoice,
            userId: userId,
            organizationId: organizationId,
            subscriptionPaymentIntentId: subscriptionPaymentIntentId,
            subscriptionId: subscriptionId,
            plan: plan,
            numberOfCohort: numberOfCohort,
            cancelAt: cancelAt,
            startDate: startDate
        })
    }

    fetchStripeId() {
        return this.http.get(`${environment.apiUrl}/stripePayment/fetch-stripe-customerId`
        );
    }

    fetchCoupons() {
        return this.http.get(`${environment.apiUrl}/stripePayment/fetch-all-coupons`
        );
    }

    fetchStripePaymentIntent() {
        return this.http.get(`${environment.apiUrl}/stripePayment/fetch-stripe-customer-paymentIntent`
        );
    }


    fetchStripeCard() {
        return this.http.get(`${environment.apiUrl}/stripePayment/fetch-stripe-customer-card`
        );
    }

    fetchAllSubscriptionsByCustomerId(customerId) {
        const params = new HttpParams().set('customerId', customerId)
        return this.http.get(`${environment.apiUrl}/stripePayment/fetchAllSubscriptionsByCustomerId`, {
            params
        }
        );
    }

    updateExistingStripeSubscription(subscriptionId, numberOfCohort) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription`, {
            subscriptionId: subscriptionId,
            numberOfCohort: numberOfCohort
        })
    }

    updateSubscriptionCredits(creditId, quantity) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription-credits`, {
            creditId: creditId,
            quantity: quantity
        })
    }

    updateDefaultPaymentSubscription(subscriptionId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/update-subscription-card`, {
            subscriptionId: subscriptionId
        })
    }

    updateStripe(customerId, paymentMethod) {

        return this.http.put(`${environment.apiUrl}/stripePayment/update-stripe-customer`, {
            customerId: customerId,
            paymentMethod: paymentMethod
        }
        );
    }

    attachPaymentMethod(customerId, paymentMethodId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/attach-payment-method`, {
            customerId: customerId,
            paymentMethodId: paymentMethodId
        }
        );
    }

    setDefaultPaymentMethod(customerId, paymentMethodId) {
        console.log(customerId);
        console.log(paymentMethodId);
        return this.http.post(`${environment.apiUrl}/stripePayment/set-default-payment`, {
            customerId: customerId,
            paymentMethodId: paymentMethodId
        }
        );
    }

    createSetupIntent(customerId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/create-setupIntent`, {
            customerId: customerId
        })
    }

    retrieveCustomerPaymentMethod(customerId) {
        const params = new HttpParams().set('customerId', customerId);

        return this.http.get(`${environment.apiUrl}/stripePayment/fetch-stripe-customer-payment-method`, {
            params: params
        }
        );
    }

    // retrieveUserLicenceExpiry(userId) {
    //     const params = new HttpParams().set('userId', userId);

    //     return this.http.get(`${environment.apiUrl}/stripePayment/get-licence-expiry-by-UserId`, {
    //         params: params
    //     }
    //     );
    // }

    retrieveUserLicenceExpiry(userId, organizationId) {
        const params = new HttpParams().set('userId', userId).set('organizationId', organizationId);

        return this.http.get(`${environment.apiUrl}/stripePayment/get-licence-expiry-by-UserId`, {
            params: params
        }
        );
    }

    getAssignedBatchBySubscriptionId(subscriptionId) {
        const params = new HttpParams().set('subscriptionId', subscriptionId);

        return this.http.get(`${environment.apiUrl}/stripePayment/get-assigned-batch-by-subscriptionId`, {
            params: params
        }
        );
    }

    getAssignedBatchByBatchCreditPack(planId) {
        const params = new HttpParams().set('planId', planId);

        return this.http.get(`${environment.apiUrl}/stripePayment/get-assigned-batch-by-BatchCreditPack`, {
            params: params
        }
        );
    }

    addBatchToCreditPack(planId, batchId, creditId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/add-batch-to-creditPack`, {
            planId: planId,
            batchId: batchId,
            creditId: creditId
        })
    }

    addBatchToSubscription(subscriptionId, batchId, creditId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/add-batch-to-plan`, {
            subscriptionId: subscriptionId,
            batchId: batchId,
            creditId: creditId
        })
    }


    cancelSubscriptionAtPeriodEnd(subscriptionId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/cancel-subscription-at-period-end`, {
            subscriptionId: subscriptionId
        })
    }

    reactivateSubscription(subscriptionId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/reactivate-subscription`, {
            subscriptionId: subscriptionId
        })
    }

    launchBatch(batchId, creditId, planId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/launch-batch`, {
            batchId: batchId,
            creditId: creditId,
            planId: planId
        })
    }
    launchBatchCreditTest(batchId, duration) {
        return this.http.post(`${environment.apiUrl}/stripePayment/launch-batchCreditTest`, {
            batchId: batchId,
            duration: duration
        })
    }

    stopBatch(batchId, planId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/stop-batch`, {
            batchId: batchId,
            planId: planId
        })
    }

    removeBatchFromSubscription(batchId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/remove-batch-from-subscription`, {
            batchId: batchId
        })
    }

    disableBatchesByExpiredCreditPack() {
        return this.http.post(`${environment.apiUrl}/stripePayment/disable-credit-batches`, {})
    }


    removeCredits(subscriptionId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/remove-credit`, {
            subscriptionId: subscriptionId
        })
    }

    removeCreditsInStripe(subscriptionId, quantity) {
        return this.http.post(`${environment.apiUrl}/stripePayment/remove-Stripecredit`, {
            subscriptionId: subscriptionId,
            quantity: quantity
        })
    }

    removeBatchFromPlan(creditId) {
        return this.http.post(`${environment.apiUrl}/stripePayment/remove-batch-from-plan`, {
            creditId: creditId
        })
    }

    getBatchExpiryByOrgIdBatchId(orgId: string, batchId: string) {
        const params = new HttpParams().set('orgId', orgId).set('batchId', batchId);
        return this.http.get(`${environment.apiUrl}/stripePayment/getBatchExpiryByOrgIdBatchId`,
            { params: params }
        );
    }


    getInvoiceByOrgId(orgId: string) {
        const params = new HttpParams().set('orgId', orgId);
        return this.http.get(`${environment.apiUrl}/stripePayment/getBatchExpiryByOrgIdBatchId`,
            { params: params }
        );
    }

    getSubscriptionFromStripe(subscriptionId) {
        const params = new HttpParams().set('subscriptionId', subscriptionId);
        return this.http.get(`${environment.apiUrl}/stripePayment/getSubscriptionFromStripe`,
            { params: params }
        );
    }

    getInvoiceFromStripe(invoiceId) {
        const params = new HttpParams().set('invoiceId', invoiceId);
        return this.http.get(`${environment.apiUrl}/stripePayment/getInvoiceFromStripe`,
            { params: params }
        );
    }

    getChargeFromStripe(chargeId) {
        const params = new HttpParams().set('chargeId', chargeId);
        return this.http.get(`${environment.apiUrl}/stripePayment/getChargeFromStripe`,
            { params: params }
        );
    }
}
