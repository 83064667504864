export class PersonalInvestor {
    id: string;
    roleKind: string;
    roleKindOthers: string;
    actAs: string;
    actAsOthers: string;
    targetSector: string;
    targetSectorOthers: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
}
