import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
@Component({
    selector: 'batch-profile-banner',
    templateUrl: './batch-profile-banner.component.html',
    styleUrls: ['batch-profile-banner.component.scss'],
})
export class BatchProfileAboutComponent implements OnInit {

    @Input() currentBatch;
    @Input() styles: any = {};


    constructor(
        private router: Router,
    ) {

    }

    ngOnInit() {

    }

    onEdit() {

        this.router.navigate(['./w/lab-form'], {
            queryParams: {
                orgId: this.currentBatch.organizationId,
                orgTypeId: this.currentBatch.organizationTypeId,
                batchId: this.currentBatch.id
            }
        });
    }

}