export class Group {
    id: string;
    key: string;
    name: string;
    createdAt: string;
    createdBy: string;
    updatedAt: string;
    updatedBy: string;
    companies: Array<any> = [];
    batchId: string;
}
