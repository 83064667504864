import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
    InstitutionService,
} from '@common/services';
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormArray
} from '@angular/forms';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'lab-dialog-criteria',
    templateUrl: './dialog-criteria.component.html',
    styleUrls: ['./../../styles.scss', './dialog-criteria.component.scss']
})

export class DialogCriteriaComponent implements OnInit {

    @Input() show: string;
    @Output() close = new EventEmitter();
    @Output() addCriteria = new EventEmitter<any>();
    criteriaForm: FormGroup;
    submitted = false;

    constructor(
        private fb: FormBuilder,
        private toastr: ToastrService,
    ) {

    }

    ngOnInit() {
        this.criteriaForm = this.fb.group({
            criterias: this.fb.array([
                this.fb.group({
                    description: ['']
                })
            ])
        });
    }

    onClose() {
        this.close.emit();
    }

    getCriterias(): FormArray {
        return this.criteriaForm.get("criterias") as FormArray
    }

    newCriteria(): FormGroup {
        return this.fb.group({
            description: ['']
        });
    }

    addMoreCriteria() {
        this.getCriterias().push(this.newCriteria());
    }

    removeCritera(i: number) {
        this.getCriterias().removeAt(i);
    }

    onSubmit() {
        this.submitted = true;

        if (this.criteriaForm.invalid) {
            // console.log('invalid');
            // console.log(this.criteriaForm.value);
            for (let el in this.criteriaForm.controls) { // help to check which control have error
                if (this.criteriaForm.controls[el].errors) {
                    console.log(el);
                }
            }
            this.toastr.show('Please fill in all fields!', '', {
                disableTimeOut: false,
                tapToDismiss: true,
                toastClass: 'toast border-red',
                closeButton: false,
            });
            return;
        } else {
            this.addCriteria.emit(this.criteriaForm.value.criterias);
        }
    }

}