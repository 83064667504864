import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@common/models';
import { EatToasterService } from '@common/services';
import { GoogleService } from '@common/services/google.service';

@Component({
    selector: 'google-calendar',
    templateUrl: './google-calendar.component.html',
    styleUrls: ['./../../styles.scss', './google-calendar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class GoogleCalendarComponent implements OnInit {

    currentUser = new User();

    googleToken: any;

    constructor(
        private googleSrv: GoogleService,
        private toaster: EatToasterService,
        private route: ActivatedRoute
    ) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

        this.googleSrv.getTokenByUid(this.currentUser.id).subscribe(tokenRes => {
            // console.log(tokenRes);
            if (tokenRes['result'] === 'successful') {
                this.googleToken = tokenRes['data'];
            }
        }, error => {
            console.error('getTokenByUid error', error);
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            if (params['code']) { // there will be a code if user autehenticate sucessfully with google
                this.onGetCode(params['code']);
            }
        });
    }

    onAuthGoogleCalendar() {

        if (!this.googleToken) {

            this.googleSrv.onGetGoogleUrl().subscribe(res => {

                window.location.href = res;
            });
        } else {
            this.toaster.showToast('', 'You have already sync to your google calendar.', this.toaster.iconClasses.success);
        }
    }

    onGetCode(code) {
     
        this.googleSrv.getToken(code, this.currentUser.id).subscribe(res => {

            if (res['result'] == 'successful') {
                this.googleToken = res['token'];
            }
        }, error => {
            console.error('getToken error', error);
        });
    }



}
