import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  InstitutionService, ResourcesService, UtilityService,
} from '@common/services';
import moment from 'moment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lab-dialog-submission',
  templateUrl: './dialog-submission.component.html',
  styleUrls: ['./dialog-submission.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogSubmissionComponent implements OnInit, AfterViewInit {
  @Input() title: string = '';
  @Input() field: string = '';
  @Input() value: string = '';
  @Input() show: string;

  type: string = '';

  @Output() close = new EventEmitter();
  @Output() addSubmission = new EventEmitter<any>();

  submissionTypes: any;
  submissionForm: FormGroup;
  submitted: boolean = false;
  maxStartDate;

  handout: any;
  othersource: any;

  handoutFileSizeExceed = false;
  otherFileSizeExceed = false;

  public Editor = ClassicEditor;
  public config = {
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://'
    }
  };
  message: string = '';

  isOpenResources: boolean = false;
  resourceUploadType: string = '';
  selectedHandoutRes;
  selectedOtherRes;
  handoutResource = false;
  othersourceResource = false;

  isOpenUpload: boolean = false;
  batchId: string = "";
  currentUser: any;

  constructor(
    private institutionSrv: InstitutionService,
    private fb: FormBuilder,
    private utilitySrv: UtilityService,
    private route: ActivatedRoute,
    private resourcesSrv: ResourcesService,
  ) {

    const urlRegx = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    this.submissionForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      handoutName: [''],
      handoutUrl: [''],
      handoutFile: [null],
      handoutResId: [''],
      othersourceName: [''],
      othersourceUrl: [''],
      othersourceFile: [null],
      othersourceResId: [''],
      link: ['', Validators.pattern(urlRegx)],
      linkTitle: [''],
    });

    this.institutionSrv.getInstitutionType().then(
      res => {
        if (res.result === 'successful') {

          if (res["submissionType"]) {
            this.submissionTypes = res["submissionType"];
          }
        }
      }
    ).catch(error => {
      console.log('getInstitutionType Error: ', error)
    });

    this.route.queryParams.subscribe(params => {
      this.batchId = params['batchId'];
      // console.log(this.batchId);
    });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');

  }

  ngOnInit() {
    this.value = "";
  }

  get f() { return this.submissionForm.controls; }

  setMaxStartDate() {
    this.maxStartDate = moment(this.submissionForm.value.endDate).format('YYYY-MM-DD');
  }

  ngAfterViewInit() {
    this.value = "";
  }

  onClose() {
    this.close.emit();
    // this.value = "";
    // this.type = "checkbox";
  }

  onRemoveFile(type) {
    if (type === '1') {
      this.submissionForm.patchValue({
        handoutName: null,
        handoutUrl: null,
        handoutFile: null,
        handoutResId: ''
      });
    } else if (type === '2') {
      this.submissionForm.patchValue({
        othersourceName: null,
        othersourceUrl: null,
        othersourceFile: null,
        othersourceResId: ''
      });
    }
  }

  onSubmit() {

    this.submitted = true;

    if (this.submissionForm.invalid) {
      for (let el in this.submissionForm.controls) { // help to check which control have error
        if (this.submissionForm.controls[el].errors) {
          console.log(el);
        }
      }
      return;
    }

    let startDate;
    let deadline;
    if (!this.utilitySrv.IsNullOrEmpty(this.submissionForm.value.startDate)) {
      startDate = new Date(moment(this.submissionForm.value.startDate).format('YYYY-MM-DD')).getTime();
    } else {
      startDate = "null";
    }
    if (!this.utilitySrv.IsNullOrEmpty(this.submissionForm.value.endDate)) {
      deadline = new Date(moment(this.submissionForm.value.endDate).format('YYYY-MM-DD')).getTime();
    } else {
      deadline = "null";
    }

    let _submissionTypeId = this.GetSubmissionTypeId(this.type);
    this.addSubmission.emit({
      title: this.submissionForm.value.title,
      description: this.submissionForm.value.description,
      startDate: startDate,
      endDate: deadline,
      typeId: _submissionTypeId,
      type: this.type,
      handoutName: this.submissionForm.value.handoutName,
      handoutUrl: this.submissionForm.value.handoutUrl,
      handoutFile: this.submissionForm.value.handoutFile,
      handoutresourceid: this.submissionForm.value.handoutResId,
      othersourceName: this.submissionForm.value.othersourceName,
      othersourceUrl: this.submissionForm.value.othersourceUrl,
      othersourceFile: this.submissionForm.value.othersourceFile,
      othersourceresourceid: this.submissionForm.value.othersourceResId,
      link: this.submissionForm.value.link,
      linkTitle: this.submissionForm.value.linkTitle,
      selectedHandoutRes: this.selectedHandoutRes,
      selectedOtherRes: this.selectedOtherRes
    });
    // this.value = "";
    // this.type = "checkbox";
  }

  onChange(e: any) {
    this.value = e.target.value
  }

  onChangeType(type) {
    this.type = type;
  }

  GetSubmissionTypeId(value) {
    try {
      let _types = this.submissionTypes.filter(type => type['keyname'] == value);
      if (_types.length > 0) {
        return _types[0]['id'];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  onDetectImg(event, imagePosition) {
    this.loadImage(event.target.files, imagePosition);
  }

  loadImage(files, type = '1') {

    if (files.length === 0) {
      return;
    }

    if (files[0].size > 5000000) {
      if (type === '1') {
        this.handoutFileSizeExceed = true;
      } else if (type === '2') {
        this.otherFileSizeExceed = true;
      }
      return
    } else {
      if (type === '1') {
        this.handoutFileSizeExceed = false;
      } else if (type === '2') {
        this.otherFileSizeExceed = false;
      }
    }

    let reader = new FileReader();

    if (type === '1') {
      this.submissionForm.patchValue({
        handoutName: files[0].name,
        handoutFile: files[0],
        handoutUrl: '',
        handoutResId: ''
      });
      this.submissionForm.get('handoutUrl')!.updateValueAndValidity();
    }

    if (type === '2') {
      this.submissionForm.patchValue({
        othersourceName: files[0].name,
        othersourceFile: files[0],
        othersourceUrl: '',
        othersourceResId: ''
      });
      this.submissionForm.get('othersourceUrl')!.updateValueAndValidity();
    }

    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      if (type === '1') {
        if (files[0].size > 5000000) {
          // this.filereaderErrorHeader = true;
        } else {
          this.handout = reader.result;

          // this.filereaderErrorHeader = false;
          // this.noImageUploaded = false;
        }
      } else if (type === '2') {
        if (files[0].size > 5000000) {
          // this.filereaderErrorAdditional = true;
        } else {
          this.othersource = reader.result;
          // this.filereaderErrorAdditional = false;
          // this.noImageUploaded = false;
        }
      }
    };
  }

  onOpenResoucesDialog(type) {
    this.isOpenResources = !this.isOpenResources;
    this.resourceUploadType = type;
  }

  onConfirmResource(value) {
    if (this.resourceUploadType === 'handout') {
      this.selectedHandoutRes = value;
      this.submissionForm.patchValue({
        handoutName: this.selectedHandoutRes.title,
        handoutFile: null,
        handoutResId: this.selectedHandoutRes.id
      });

      // if (this.selectedResource.filepath) {
      //   this.submissionForm.patchValue({
      //     handoutUrl: this.selectedResource.filepath
      //   });
      // } else {
      //   this.submissionForm.patchValue({
      //     handoutUrl: this.selectedResource.link
      //   });
      // }

      this.handoutResource = true;
    } else if (this.resourceUploadType === 'othersource') {
      this.selectedOtherRes = value;
      this.submissionForm.patchValue({
        othersourceName: this.selectedOtherRes.title,
        othersourceFile: null,
        othersourceResId: this.selectedOtherRes.id
      });
      // if (this.selectedResource.filepath) {
      //   this.submissionForm.patchValue({
      //     othersourceUrl: this.selectedResource.filepath
      //   });
      // } else {
      //   this.submissionForm.patchValue({
      //     othersourceUrl: this.selectedResource.link
      //   });
      // }
      this.othersourceResource = true;
    }

    this.onOpenResoucesDialog(this.resourceUploadType);
  }

  onOpenUpload(type) {
    this.resourceUploadType = type;
    this.isOpenUpload = true;
  }

  onCloseUpload() {
    this.isOpenUpload = false;
  }

  onAddResource(data) {

    let formData = new FormData();

    formData.append('batchId', this.batchId);

    if (data.title) {
      formData.append('title', data.title);
    } else {
      formData.append('title', 'null');
    }

    if (data.link) {
      formData.append('link', data.link);
    } else {
      formData.append('link', '');
    }

    if (data.description) {
      formData.append('description', data.description);
    } else {
      formData.append('description', '');
    }

    if (data.file) {
      formData.append('resourceFile', data.file);
      formData.append('filesize', data.file.size);
    } else {
      formData.append('resourceFile', '');
      formData.append('filesize', '');
    }

    formData.append('uid', this.currentUser.id);

    this.resourcesSrv.addResource(formData).then(
      (res) => {
        if (res["result"] == "successful") {
          this.onCloseUpload();
          if (this.resourceUploadType === 'handout') {
            this.selectedHandoutRes = {
              id: res['resourceId'],
              title: data.title
            };
            this.submissionForm.patchValue({
              handoutName: data.title,
              handoutFile: null,
              handoutResId: res['resourceId']
            });
            this.handoutResource = true;
          } else if (this.resourceUploadType === 'othersource') {
            this.selectedOtherRes = {
              id: res['resourceId'],
              title: data.title
            };
            this.submissionForm.patchValue({
              othersourceName: data.title,
              othersourceFile: null,
              othersourceResId: res['resourceId']
            });
            this.othersourceResource = true;
          }
        } else {
          console.error("addResource failed");
        }
      },
      (error) => console.log(error)
    );
  }

}
