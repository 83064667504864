import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  InstitutionService,
  UtilityService,
  ConfigService
} from '@common/services';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lab-modal-deliverable-edit-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        opacity: 1,
        right: '0px'
      })),
      state('close', style({
        opacity: 0,
        right: '-50px'
      })),
      transition('open => close', [
        animate('.3s')
      ]),
      transition('close => open', [
        animate('.3s')
      ])
    ])
  ],
  templateUrl: './modal-deliverable-edit-list.component.html',
  styleUrls: ['./modal-deliverable-edit-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalDeliverableEditListComponent implements OnInit {
  @Input() open: boolean;
  @Input() currentUser: any;
  //type: projectTeam, mentor, programmer 
  // noted projectTeam cannot rating
  @Input() type: string;
  @Input() selectedOrgId: string;
  @Input() organizationType: string;

  loading: boolean = true;
  isOpenNewDeliverableDialog: boolean = false;

  public _program: any;
  @Input() set program(object) {
    this.searchText = "";
    if (object) {
      this._program = object;
      this.initialProgram(this._program.id);

    }
  }

  @Output() close = new EventEmitter();
  @Output() openComments = new EventEmitter();

  @ViewChild('filterList') filterList: ElementRef;
  @ViewChild('searchInput') searchInputRef: ElementRef;


  toastRef;
  count: number = 0;
  searchText: string = '';
  filterType: string = 'all';

  deliverables: any[] = [];
  current: string = '';

  //submission
  selectedDeliverable: any;


  constructor(
    private toastr: ToastrService,
    private institutionSrv: InstitutionService,
    private utilitySrv: UtilityService,
    private cdf: ChangeDetectorRef,
    private configSrv: ConfigService
  ) { }

  ngOnInit() {

  }

  // close modal batch list
  onCloseModal() {
    this.searchText = "";
    this.close.emit();
  }

  showActionMsg(title, msg) {
    this.toastRef = this.toastr.show(msg, title, {
      disableTimeOut: false,
      tapToDismiss: false,
      toastClass: 'toast border-blue',
      closeButton: false,
    });

  }

  initialProgram(id) {
    this.loading = true;
    this.institutionSrv.getDeliverableSetByProgramId(id).then(res => {
      if (res['result'] == "successful") {
        this.deliverables = res['data'];
      }
    }).catch(error => {
      console.log("error", error);
    }).finally(() => {
      this.loading = false;
      this.cdf.detectChanges();
    })
  }

  onDeleteSubmission(event, item) {
    this.deleteDeliverable(item.id);
  }
  onFilterSelect(event: any) {
    const { childNodes } = this.filterList.nativeElement
    const list = [...childNodes]
    list.forEach(item => {
      if (item.dataset.option === event.target.dataset.option && item.classList.contains('selected')) return;
      item.classList.remove('selected');
      if (item.dataset.option === event.target.dataset.option) return item.classList.add('selected');
    })
    // Store filter type for search using
    if (this.filterType !== event.target.dataset.option) this.filterType = event.target.dataset.option;
  }

  onSearchChange(event: any) {
    this.searchText = event.target.value;
  }


  // for clear search event
  onClearSearchText() {
    this.searchText = '';
    this.searchInputRef.nativeElement.focus();
  }

  // close modal batch list
  onCloseDailog() {
    if (this.isOpenNewDeliverableDialog) {
      this.isOpenNewDeliverableDialog = !this.isOpenNewDeliverableDialog;
    }
    //  this.close.emit();
  }



  onNewDeliverable() {
    this.isOpenNewDeliverableDialog = !this.isOpenNewDeliverableDialog;
  }

  onDeliverableSubmit(event) {

    this.institutionSrv.createDeliverable(event).subscribe(
      (res) => {
  
        if (res["result"] == "successful") {
          this.isOpenNewDeliverableDialog = !this.isOpenNewDeliverableDialog;
        }
      },
      (error) => console.log(error),
      () => {

      }
    );


  }


  onSaveSubmission(event, item) {
    const deliverable = {
      id: item.id,
      columns: [
        "description",
        "startDate",
        "deadline",
      ],
      attribute: [
        event.description,
        event.startDate,
        event.deadline
      ],
      userId: this.currentUser.id,
    };



    this.institutionSrv.updateDeliverable(deliverable).subscribe(
      (res) => {
        if (res["result"] == "successful") {
          this.initialProgram(this._program.id);
        } else {

        }
      },
      (error) => console.log(error),
      () => {

      }
    );

  }

  deleteDeliverable(deliverableId) {

    let _deliverableId = deliverableId;
    this.institutionSrv.deleteDeliverable(deliverableId, '', '').subscribe(
      (res) => {
        if (res["result"] == "successful") {
          this.deliverables = this.deliverables.filter(item => item.id != _deliverableId);
          this.cdf.detectChanges();
        } else {

        }
      },
      (error) => console.log(error),
      () => {

      }
    );

  }

}
