import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {
  Widget,
  ResResult,
  WidgetGroup
} from '../models';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable()
export class WidgetService {
  widget = [];
  widgets: any = '';
  widgetsLength = 0;
  constructor(private http: HttpClient) {


  }
  private widgetSet = new BehaviorSubject([]);
  currentWidgetSet = this.widgetSet.asObservable();


  async getAll() {
    const _result = await this.http.get<ResResult>(`${environment.apiUrl}/widget/getAll`).toPromise();
    if (_result.result == 'successful') {
      return _result.data as Widget[];
    }
    return null;
  }

  async getGroupWidget() {
    const res = await this.http.get<ResResult>(`${environment.apiUrl}/widget/getAllGroupWidget`).toPromise();
    return res.data as Array<any>[];
  }


  /************Zencode CRUD Widget Start*************/


  createNew(widget: Widget) {
    try {
      return this.http.post(`${environment.apiUrl}/widget/createNew`, widget);
    } catch (error) {
      console.error('add widget failed', error);
      return null;
    }
  }

  getWidgetById(id: string) {
    const params = new HttpParams().set('widgetId', id);
    return this.http.get<any>(`${environment.apiUrl}/widget/getWidgetById`, { params: params });
  }


  deleteWidget(id: string, updatedBy: string) {
    return this.http.post(`${environment.apiUrl}/widget/deleteWidget`, { id: id, updatedBy: updatedBy });
  }

  updateWidget(widget: Widget) {

    return this.http.post(`${environment.apiUrl}/widget/updateWidget`, { id: widget.id, widget: widget }).subscribe(data => {
    }, error => {
      console.error('failed to update widget:', error);
    });
  }

  getUserWidgetsByUid(uid: string) {
    const params = new HttpParams().set('uid', uid);
    return this.http.get<Widget[]>(`${environment.apiUrl}/widget/getUserWidgetsByUid`, { params: params });
  }

  getUserWidgetsByOrgIdAndUserroleID(orgId: string, userroleId: string) {
    let params = new HttpParams().set('orgId', orgId);
    params = params.set('userroleId', userroleId);
    return this.http.get<ResResult>(`${environment.apiUrl}/widget/getUserWidgetsByOrgIdAndUserroleID`, { params: params });
  }

  getWidgetByOrgId(orgId) {
    return this.http.post(`${environment.apiUrl}/widget/getWidgetByOrgId`,
      {
        orgId: orgId
      });
  }

  getWidgetSetByBatchId(batchId) {
    return this.http.post(`${environment.apiUrl}/widget/getWidgetSetByBatchId`,
      {
        batchId: batchId
      });
  }


  getWidgetByOrgType(orgtype, orgId) {
    return this.http.post(`${environment.apiUrl}/widget/getWidgetByOrgType`,
      {
        orgtype: orgtype, orgId: orgId
      });
  }

  getWidgetByOrgTypeV2(orgtype) {
    return this.http.post(`${environment.apiUrl}/widget/getWidgetByOrgTypeV2`,
      {
        orgtype: orgtype,
      });
  }

  getOrgWidgetByOrgIdNWidgetId(orgId, widgetId, batchId, uid) {
    return this.http.post(`${environment.apiUrl}/widget/getOrgWidgetByOrgIdNWidgetId`,
      {
        orgId: orgId, widgetId: widgetId, batchId: batchId, uid: uid
      });
  }

  createOrgWidget(orgId, widgetId, orgTypeId, uid) {
    return this.http.post(`${environment.apiUrl}/widget/createOrgWidget`,
      {
        orgId: orgId, widgetId: widgetId, orgTypeId: orgTypeId, uid: uid
      });
  }

  getOrgBatchWidgetByBatchIdNWidgetId(batchId, widgetId) {
    return this.http.post(`${environment.apiUrl}/widget/getOrgBatchWidgetByBatchIdNWidgetId`,
      {
        batchId: batchId, widgetId: widgetId
      });
  }

  createOrgBatchWidget(batchId, widgetId, uid) {
    return this.http.post(`${environment.apiUrl}/widget/createOrgBatchWidget`,
      {
        batchId: batchId, widgetId: widgetId, uid: uid
      });
  }

  /************Zencode CRUD Widget End*************/

  /************Zencode CRUD Widget Group Start*************/


  createNewGroup(widgetGroup: WidgetGroup) {
    try {
      return this.http.post(`${environment.apiUrl}/widget/createNewGroup`, widgetGroup);
    } catch (error) {
      console.error('add widget group failed', error);
      return null;
    }
  }

  addWidgetGroupList(groupId: string, widgetId: string, uid: string) {
    try {
      return this.http.post(`${environment.apiUrl}/widget/addWidgetGroupList`,
        { widgetGroupId: groupId, widgetId: widgetId, uid: uid })
        .subscribe(data => { });
    } catch (error) {
      console.error('add widget group failed', error);
      return null;
    }
  }



  deleteWidgetGroup(id) {
    return this.http.post(`${environment.apiUrl}/widget/deleteWidgetGroupById`, { widgetGroupId: id }).subscribe(data => {

    }, error => {
      console.error('failed to delete widget group:', error);
    });
  }

  deleteWidgetGroupListByGroupId(id) {
    return this.http.post(`${environment.apiUrl}/widget/deleteWidgetGroupListByGroupId`, { widgetGroupId: id }).subscribe(data => {

    }, error => {
      console.error('failed to delete widget group list:', error);
    });
  }


  getWidgetGroupById(id: string) {
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${environment.apiUrl}/widget/getWidgetGroupById`, { params: params });
  }

  getAllWidgetGroups() {
    return this.http.get<Widget[]>(`${environment.apiUrl}/widget/getAllWidgetGroups`);
  }

  getGroupNameAndItsWidgets() {
    return this.http.get<Widget[]>(`${environment.apiUrl}/widget/getGroupNameAndItsWidgets`);
  }

  getWidgetGroupListByWidgetId(id: string) {
    const params = new HttpParams().set('widgetId', id);
    return this.http.get<any>(`${environment.apiUrl}/widget/getWidgetGroupListByWidgetId`, { params: params });
  }


  updateWidgetGroup(widgetId: any, name: string, notes: string, updatedBy: string) {
    return this.http.post(`${environment.apiUrl}/widget/updateWidgetGroupById`,
      { widgetGroupId: widgetId, name: name, notes: notes, updatedBy: updatedBy });
  }

  insertWidgetsIntoWidgetGroupList(widget: any, id: string, currentUser: string) {
    return this.http.post(`${environment.apiUrl}/widget/insertWidgetsIntoWidgetGroupList`,
      { data: widget, widgetGroupId: id, createdBy: currentUser, updatedBy: currentUser });
  }

  getWidgetByBatchId(batchId: string) {
    return this.http.post(`${environment.apiUrl}/widget/getWidgetByBatchId`,
      {
        batchId: batchId
      });
  }

  /************Zencode CRUD Widget Group End*************/

  /************Organization Widget Group Start*************/
  deleteOrganizationWidgetById(id: string, uid: string) {
    return this.http.post(`${environment.apiUrl}/widget/deleteOrganizationWidgetById`,
      { id, uid });
  }

  getWidgetByOrganizationType(organziationTypeName: string) {
    const params = new HttpParams().set('type', organziationTypeName);
    return this.http.get(`${environment.apiUrl}/widget/getWidgetByOrganizationType`, { params: params });
  }

  deleteOrganizationBatchWidgetById(id: string, uid: string) {

    return this.http.post(`${environment.apiUrl}/widget/deleteOrganizationBatchWidgetById`,
      { id, uid });
  }
  /************Organization Widget Group Start*************/

  getAllUserWidgets() {

    return this.http.get(`${environment.apiUrl}/widget/getAllUserWidgets`,
      {});
  }

  getUserWidgetByUid(uid) {
    return this.http.post(`${environment.apiUrl}/userwidget/getUserWidgetByUid`,
      { uid: uid });
  }

  addUserWidget(uid, widgetId) {
    return this.http.post(`${environment.apiUrl}/userwidget/addUserWidget`,
      { uid: uid, widgetId: widgetId });
  }

  deleteUserWidget(id) {
    return this.http.post(`${environment.apiUrl}/userwidget/deleteUserWidget`,
      { id: id });
  }

  // delete all batch widget from userWidget table by batchId and userId
  deleteUserBatchUserWidget(batchId, batchUserId, uid) {
    return this.http.post(`${environment.apiUrl}/userwidget/deleteUserBatchUserWidget`,
      { batchId, batchUserId, uid });
  }

  getIndividualWidgetsByUid(uid) {
    return this.http.post(`${environment.apiUrl}/widget/getIndividualWidgetsByUid`,
      { uid: uid });
  }

  createUserWidget(value) {
    return this.http.post<any>(`${environment.apiUrl}/userwidget/createUserWidget`, value);
  }

}
