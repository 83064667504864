export class PromoCode {
    id: string;
    productId: string;
    productName: string;
    unit: number;
    noOfUnitsLeft: number;
    description: string;
    expireDate: string;
    discountAmount: number = 0;
    discountCode: string;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
}
