import {
    Component,
    Input,
    OnInit,
    ViewEncapsulation,
    OnDestroy
} from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService, RoleService } from '@common/services';
import { tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import {
    User
  } from '@common/models';

@Component({
    selector: 'lab-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['header-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderBarComponent implements OnInit, OnDestroy {
    launch$;
    batchStatusColor: string = '';
    batchStatus: string;
    showBatchStatus: boolean = false;
    roles: string[] = [];
    @Input() class?: string;
    @Input() title: string;
    @Input() set status(object) {
        this.batchStatus = object;
        this.showBatchStatus = true;
    }

    batchId: string;
    orgId: string;
    orgType: string;
    currentUser;

    constructor(
        private location: Location,
        private router: Router,
        private route: ActivatedRoute,
        private dataSrv: DataService,
        private permissionsService: NgxPermissionsService,
        private roleSrv: RoleService
    ) {
        this.currentUser = new User();
        this.currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.orgId = params['orgId'];
            this.orgType = params['orgType'];
        });

        this.roleSrv.getPermissionsByUid(this.currentUser.id).then((res) => {
            if (res['data'] !== null) {
                const roleArray = res.data;
                roleArray.forEach((role) => {
                    this.roles.push(role.permissionName);
                });

                this.permissionsService.loadPermissions(this.roles);
                
            }
        });
    }

    ngOnDestroy(): void {}

    // ngOnChanges(changes: SimpleChanges) {
    //   const batchStatus = changes['status'];
    //   if (batchStatus.currentValue === 'Running') this.batchStatus = 'Running'
    // }

    handleGoBack() {
        // define click to go back to the home page of workspace
        // this.router.navigate(['/w'], { relativeTo: this.route })

        // define click to go back to previuos page

        if (this.orgType === 'batch') {
            this.router.navigate(['/w/lab/profile'], {
                queryParams: {
                    orgId: this.orgId,
                    orgTypeId: '5c5b6ca4-da99-11e9-b68a-bb4d51af784e',
                    orgType: 'lab',
                    tab: 'lab',
                },
            });
        } else {
            this.location.back();
        }
    }

    launchBatch() {
        // if (this.batchStatus === 'Expired') return;

        this.dataSrv.onChangeLaunchModalStatus(true);
    }
}
